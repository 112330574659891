import React from 'react'
import { compose } from 'react-recompose'
import withResourceList from 'store/utils/withResourceList'
import withResourceActions from 'store/utils/withResourceActions'
import Alert from 'components/Alert'
import UserDetails from 'containers/UserDetails'
import Button from 'components/Button'
import { SITE_URL } from 'config/constants'

function Opportunities ({ records, remove, isRemoving, ...props }) {
  if (!records.length) return null

  return (
    <UserDetails>
      {details => records.map(record => (
        <Alert key={record.id} onCloseRequest={!isRemoving ? () => remove(record.id, { makeRequest: false }) : null}>
          <div>
            Olá {details.name}, temos uma oportunidade para você com os seguintes produtos:
            <ul>
              {record.produtos.map((produto, index) => (
                <li key={index}>{produto}</li>
              ))}
            </ul>
            <Button
              href={ record.combo_id ? `${SITE_URL}/loja/cesta/adicionarAjax/?redirecionar_para_cesta=1&combo_id=${record.combo_id}&oportunidade_id=${record.id}` : `${SITE_URL}/aluno/oportunidade/?oportunidade_id=${record.id}`}
              renderWith='a'
              size='small'
              target='_blank'
              onClick={() => remove(record.id, { makeRequest: false })}
              disabled={isRemoving}
            >
              Aceitar Oportunidade
            </Button>
          </div>
        </Alert>
      ))}
    </UserDetails>
  )
}

export default compose(
  withResourceList({
    resource: 'opportunities',
    handleEmptyRecords: false,
    displaySpinner: false
  }),
  withResourceActions({ resource: 'opportunities' })
)(Opportunities)
