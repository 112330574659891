import React from 'react'
import MessageCollapse from 'components/MessageCollapse'
import withResourceList from 'store/utils/withResourceList'
import { actionCreators } from 'store/actions/resources/alerts'

function Alerts ({ records, dispatch }) {
  return records.map(alert => (
    <MessageCollapse
      key={alert.id}
      authorName={alert.usuario}
      authorAvatar={alert.imagem}
      course={alert.produto}
      date={alert.data}
      body={alert.mensagem}
      unread={!alert.lido}
      onOpen={() => alert.lido ? null : dispatch(actionCreators.readAlert(alert.id))}
    />
  ))
}

export default withResourceList({
  resource: 'alerts',
  spinnerText: 'Carregando alertas...'
})(Alerts)
