/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { actionTypes } from 'store/actions/resources/messages'
import { actionTypes as authActionTypes } from 'store/ducks/auth'
import createResourceReducer from 'store/utils/createResourceReducer'

/**
 * Messages Reducer.
 */

export default createResourceReducer({
  actionTypes,
  state: {
    unread: 0,
  },
  handlers: {
    [authActionTypes.LOGIN_FULFILLED]: (state, { payload }) =>
      update(state, {
        unread: { $set: payload.mensagens }
      }),

    [actionTypes.READ_MESSAGE]: (state, { payload }) =>
      update(state, {
        records: {
          $set: state.records.map(message => {
            if (message.mensagem.id === payload.id) {
              message.lido = true
            }
            return message
          })
        },
        unread: {
          $set: state.unread > 0 ? state.unread - 1 : 0
        }
      })
  }
})
