import React from 'react'
import classNames from 'classnames'
import 'styles/Label.css'

export default function Label ({ children, SubComponent, isActive, small, ...props }) {
  const className = classNames({
    Label: true,
    isActive,
    small
  })

  return (
    <div className={className}>
      <label
        className='Label-text'
        {...props}
      >
        {children}
      </label>
      {SubComponent && (
        <div className='Label-subcomponent'>
          <SubComponent />
        </div>
      )}
    </div>
  )
}
