import styled from 'styled-components'

export const BoxAviso = styled.section`
    float: left;
    width: 100%;
    position: relative;
    background: #0096EA;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

export const Icone = styled.img`
    min-width: 24px;
`

export const Texto = styled.span`
    color: #fff;
    font-size: 13px;
    line-height: 22px;
    margin: 0 10px;
    flex: 1;

    a {
        font-weight: bold;
    }
`

export const IconeClose = styled.img`
    min-width: 24px;
    cursor: pointer;
`