import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import {
  Title, SubTitle, Info, InfoText, Payment, PaymentTexts, PaymentText, PaymentDetails, PaymentDetailsText, CardBrandName
} from './styles.js'
import CardBrand from '../../CardBrand'

export default function PlanoCorrente ({ dados }) {
  return (
    <section>

      {/* Título */}
      <Title>{dados.faltam > 1 ? 'Faltam' : 'Falta'} <strong>{dados.faltam} {dados.faltam > 1 ? 'dias' : 'dia'}</strong> para seu plano atual vencer.</Title>

      {/* Sub Título */}
      <SubTitle>Acesso até {format(dados.validade_fim, 'DD/MM/YYYY')}</SubTitle>

      {/* Informações do Plano Atual */}
      <Info>
        <InfoText>Em {format(dados.validade_inicio, 'DD/MM/YYYY')}, você contratou o plano a seguir: {dados.nome && dados.nome !== '' ? dados.nome : 'Plano não identificado'} (contratado por {dados.valor_cobrado ? dados.valor_cobrado : 'Valor não identificado'})</InfoText>
        <InfoText>Em <strong>{format(dados.validade_fim, 'DD/MM/YYYY')}</strong>, você terá o seu plano renovado: <strong>{dados.nome && dados.nome !== '' ? dados.nome : 'Plano não identificado'}</strong> (pelo valor de <strong>{dados.valor_cobrado ? dados.valor_cobrado : 'Valor não identificado'}</strong>)</InfoText>
      </Info>

      {/* Informações de Pagamento */}
      <Payment>
        <PaymentTexts>
          <PaymentText>Se o valor da promoção for menor: Aproveite o preço promocional que é menor do que você pagou pela sua assinatura.</PaymentText>
          <PaymentText>Se o atual (garantia de preço) for menor: Garanta o menor preço e assine com o mesmo valor da sua assinatura.</PaymentText>
        </PaymentTexts>
        { dados.cartao !== ''
          ? <PaymentDetails>
            <CardBrand brand={dados.bandeira} />
            <PaymentDetailsText>
              Cartão <CardBrandName>{dados.bandeira}</CardBrandName> com final <strong>{dados.cartao}</strong>
            </PaymentDetailsText>
          </PaymentDetails>
          : null
        }
      </Payment>
    </section>
  )
}

PlanoCorrente.propTypes = {
  dados: PropTypes.object.isRequired
}
