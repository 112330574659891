/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { actionTypes as researchActionTypes } from 'store/ducks/researches'
import createReducer from 'store/utils/createReducer'
import Cursos from 'api/Cursos'
import createActionTypes from 'store/utils/createActionTypes'
import { getLessons } from './aulas'

/**
 * ActionTypes.
 */

export const actionTypes = createActionTypes('CURSOS', [
  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED',

  'UPDATE_RATING',
  'UPDATE_RATING_PENDING',
  'UPDATE_RATING_FULFILLED',
  'UPDATE_RATING_REJECTED',

  'SEND_DISCURSIVE',
  'SEND_DISCURSIVE_PENDING',
  'SEND_DISCURSIVE_FULFILLED',
  'SEND_DISCURSIVE_REJECTED',

  'DOWNLOAD_LESSON_PDF'
])

/**
 * Initial State.
 */

const initialState = {
  isFetching: false,
  isSubmitting: false,
  entities: {},
  detailedEntities: [],
  isListLoaded: false,
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Fetch All.
   */

  [actionTypes.FETCH_ALL_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ALL_FULFILLED] (state, { payload }) {
    return update(state, {
      entities: { $set: payload.entities.cursos },
      detailedEntities: { $set: [] },
      isFetching: { $set: false },
      isListLoaded: { $set: true },
      error: { $set: null }
    })
  },

  [actionTypes.FETCH_ALL_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      isListLoaded: { $set: true },
      error: { $set: payload }
    })
  },

  /**
   * Fetch One.
   */

  [actionTypes.FETCH_ONE_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      entities: {
        [payload.result]: {
          $set: payload.entities.cursos[payload.result]
        }
      },
      detailedEntities: { $set: [ payload.result ] }
    })
  },

  [actionTypes.FETCH_ONE_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Update Rating.
   */

  [actionTypes.UPDATE_RATING_PENDING] (state, { meta: { courseId, rating } }) {
    return update(state, {
      entities: {
        [courseId]: {
          nota: { $set: rating }
        }
      }
    })
  },

  [actionTypes.UPDATE_RATING_FULFILLED] (state) {
    return state
  },

  [actionTypes.UPDATE_RATING_REJECTED] (state, { meta: { courseId, currentRating } }) {
    return update(state, {
      entities: {
        [courseId]: {
          nota: { $set: currentRating }
        }
      }
    })
  },

  /**
   * Discursive.
   */

  [actionTypes.SEND_DISCURSIVE_PENDING] (state) {
    return update(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.SEND_DISCURSIVE_FULFILLED] (state, { payload, meta }) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: null },
      entities: {
        [meta.courseId]: {
          discursivas: {
            respostas: {
              $push: [ payload.data ]
            },
            pode_enviar: {
              $set: false
            }
          }
        }
      }
    })
  },

  [actionTypes.SEND_DISCURSIVE_REJECTED] (state, { payload }) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Adiciona o ID da aula no array de `aulas baixadas`.
   */

  [actionTypes.DOWNLOAD_LESSON_PDF] (state, { payload: { courseId, lessonId } }) {
    return update(state, {
      entities: {
        [courseId]: {
          aulas_baixadas: {
            $apply: lessons => lessons.includes(lessonId)
              ? lessons
              : lessons.concat(lessonId)
          },
          aulas_baixadas_hoje: {
            $apply: lessons => lessons.includes(lessonId)
              ? lessons
              : lessons.concat(lessonId)
          },
          downloads_restantes: {
            $apply: count => state.entities[courseId].aulas_baixadas.includes(lessonId) || count === 0
              ? count
              : count - 1
          }
        }
      }
    })
  },

  /**
   * Pesquisa enviada com sucesso. Nesse caso a gente pode desabilitar a pesquisa.
   */

  [researchActionTypes.SEND_RESEARCH_FULFILLED] (state, { meta: { courseId } }) {
    return update(state, {
      entities: {
        [courseId]: {
          pesquisa_habilitada: { $set: false }
        }
      }
    })
  },

  /**
   * Caso a pesquisa seja ignorada, também podemos desabilitar a pesquisa para liberar acesso ao download.
   */

  [researchActionTypes.IGNORE_RESEARCH_FULFILLED] (state, { meta: { courseId } }) {
    return update(state, {
      entities: {
        [courseId]: {
          pesquisa_habilitada: { $set: false }
        }
      }
    })
  }
})

/**
 * Action Creators.
 */

export const fetchAll = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_ALL,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar cursos!'
    },
    payload: Cursos.fetchAll()
  })
}

export const fetchOne = id => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ONE,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar curso!'
    },
    payload: Cursos.fetchOne(id)
  })
}

export const updateRating = (courseId, rating, currentRating) => ({
  type: actionTypes.UPDATE_RATING,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao atualizar avaliação do curso!',
    courseId,
    rating,
    currentRating
  },
  payload: Cursos.updateRating(courseId, { nota: rating })
})

export const sendDiscursive = (courseId, data) => ({
  type: actionTypes.SEND_DISCURSIVE,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao enviar redação!',
    courseId
  },
  payload: Cursos.sendDiscursive(courseId, data)
})

export const downloadLessonPdf = (courseId, lessonId) => ({
  type: actionTypes.DOWNLOAD_LESSON_PDF,
  payload: { courseId, lessonId }
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.conteudo.cursos.isFetching

export const isSubmitting = state =>
  state.conteudo.cursos.isSubmitting

export const hasDetailedCourse = (state, courseId) =>
  state.conteudo.cursos.detailedEntities.includes(courseId)

export const isListLoaded = state =>
  state.conteudo.cursos.isListLoaded

export const getCourses = state =>
  state.conteudo.cursos.entities

export const getDetailedCourse = (state, courseId) =>
  hasDetailedCourse(state, courseId)
    ? getCourses(state)[courseId]
    : null

export const getCourseLessons = (state, courseId) => {
  const course = getCourses(state)[courseId]
  if (!course || !course.aulas) return []

  return course.aulas.map(lessonId => (
    getLessons(state)[lessonId]
  ))
}
