import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'
import store from 'store'

export default function Wrapper ({ children }) {
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_ESTRATEGIA_APP_ROOT}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}
