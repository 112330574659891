/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('notifications', [
  'PUSH_NOTIFICATION',
  'REMOVE_NOTIFICATION',
  'CLEAR_ALL',
  'CLEAR'
])

/**
 * Initial State.
 */

const initialState = {
  messages: {
    ALL: []
  }
}

/**
 * Notifications Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.PUSH_NOTIFICATION]: (state, { payload }) =>
    update(state, {
      messages: {
        [payload.notificationsId]: {
          $apply: notifications => Array.isArray(notifications)
            ? notifications.concat(payload.notification)
            : [ payload.notification ]
        }
      }
    }),

  [actionTypes.REMOVE_NOTIFICATION]: (state, { payload: { notificationsId, index } }) =>
    update(state, {
      messages: {
        [notificationsId]: {
          $set: state.messages[notificationsId].filter((message, i) => (
            i !== index
          ))
        }
      }
    }),

  [actionTypes.CLEAR_ALL]: state =>
    update(state, {
      messages: {
        $set: { ALL: [] }
      }
    }),

  [actionTypes.CLEAR]: (state, { payload }) =>
    update(state, {
      messages: {
        [payload.notificationsId]: { $set: [] }
      }
    })
})

/**
 * Action Creators.
 */

export const pushNotification = (message, type, notificationsId = 'ALL') => ({
  type: actionTypes.PUSH_NOTIFICATION,
  payload: {
    notificationsId,
    notification: {
      type,
      message
    }
  }
})

export const removeNotification = (index, notificationsId) => ({
  type: actionTypes.REMOVE_NOTIFICATION,
  payload: {
    notificationsId,
    index
  }
})

export const clear = notificationsId => ({
  type: actionTypes.CLEAR,
  payload: { notificationsId }
})

export const clearAll = () => ({
  type: actionTypes.CLEAR_ALL
})

/**
 * Selectors.
 */

export const getNotifications = (state, notificationsId = 'ALL') =>
  state.notifications.messages[notificationsId] || []
