import React from 'react'
import Truncate from 'react-dotdotdot'
import 'styles/Calendar/CalendarCellCourse.css'

export default function CalendarCellCourse ({ course }) {
  return (
    <div className='CalendarCellCourse'>
      <Truncate clamp={2}>
        {course.titulo}
      </Truncate>
    </div>
  )
}
