import { parse } from 'date-fns'

export default function transformEvents (data) {
  if (!data) return []

  return data.data.map(obj => ({
    date: parse(obj.dia),
    events: obj.concursos.map(el => ({
      title: el.titulo,
      items: el.aulas.map(aula => ({
        title: aula.nome,
        produto_id: aula.produto_id,
        id: aula.id,
        hora: aula.conferencia_hora
      }))
    }))
  }))
}
