/**
 * Dependencies.
 */

import Alerts from 'api/Alerts'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('alerts', Alerts, 'Alerta')

/**
 * Action Types.
 */

actionTypes.READ_ALERT = 'ALERTS/READ_ALERT'

/**
 * `Read Alert` action creator.
 */

actionCreators.readAlert = id => {
  Alerts.read(id)
  return {
    type: actionTypes.READ_ALERT,
    payload: { id }
  }
}
