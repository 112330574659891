import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'styles/Sidebar.css'
import MenuSwitcher from 'components/MenuSwitcher'
import LinkMenuButton from 'components/LinkMenuButton'
import routes from 'config/routes'
import UnreadAlerts from 'containers/UnreadAlerts'
import UserDetails from 'containers/UserDetails'
import { setLocalStorage, getLocalStorage, validaItemMenu } from 'utils/helpers'
import Logo from 'components/Logo'

// Ícones
import icCatalogo from 'images/icons/ic-catalogo.svg'
import icMatriculas from 'images/icons/ic-minhas-matriculas.svg'
import icBound from 'images/icons/ic-bound.svg'
import icComunidades from 'images/icons/ic-comunidades.svg'
import icMonitorias from 'images/icons/ic-monitorias.svg'
import icCursosExclusivos from 'images/icons/ic-cursos-exclusivos.svg'
import icSalaVip from 'images/icons/ic-sala-vip.svg'
import icSimulados from 'images/icons/ic-simulados.svg'
import icBlog from 'images/icons/ic-blog.svg'
import icEstudoEstrategico from 'images/icons/ic-estudo-estrategico.svg'
import icInformativo from 'images/icons/ic-informativo.svg'
import icRodadas from 'images/icons/ic-rodadas.svg'
import icVadeMecum from 'images/icons/ic-vade-mecum.svg'
import icMonitor from 'images/icons/ic-meter.svg'
import icCast from 'images/icons/ic-cast.svg'
import icMinhasPreferencias from 'images/icons/ic-minha-conta.svg'

export default function Sidebar({ isSidebarOpen, salesForce }) {

  const className = classNames({
    Sidebar: true,
    isActive: isSidebarOpen
  })
  const [menu, setMenu] = useState('concursos')

  useEffect(() => {
    let verticaisStore = JSON.parse(getLocalStorage('verticais'))

    if (getLocalStorage('menuSidebar')) {
      switch (getLocalStorage('menuSidebar')) {
        case 'concursos':
          setMenu('concursos')
          break
        case 'ecj':
          validaItemMenu('Carreira Jurídica', verticaisStore) ? setMenu('ecj') : setMenu('concursos')
          break
        case 'oab':
          validaItemMenu('OAB', verticaisStore) ? setMenu('oab') : setMenu('concursos')
          break
        default:
          setMenu('concursos')
      }
    } else {
      setMenu('concursos')
    }
  })

  const changeSidebar = (menuAberto) => {
    setLocalStorage('menuSidebar', menuAberto)
    setMenu(menuAberto)
  }

  return (
    <aside className={className}>
      <Logo onlyMobile isNew />
      <MenuSwitcher onSwitch={(menuAberto) => changeSidebar(menuAberto)} isActive={menu} />
      <nav className='Sidebar-menu'>
        <UserDetails>
          {details => (
            <React.Fragment>

              {/* Menus ECJ */}
              {(validaItemMenu('Carreira Jurídica', details.verticais) && menu === 'ecj') ? (
                <React.Fragment>
                  <a class="linkPlataformaECJ" href="https://cj.estrategia.com/mesa-de-estudo/" target='_blank' rel='noopener noreferrer'>Conheça a nova plataforma</a>
                </React.Fragment>
              ) : null}

              {/* Seta as verticais na LocalStorage */}
              {setLocalStorage('verticais', JSON.stringify(details.verticais))}

              {details.vem_para_maior_2021 && (
                <div className='Sidebar-menu-item' style={{ display: 'inline-block', width: '100%', margin: '5px 0 15px 0'}}>
                  <LinkMenuButton to={routes.VemParaOMaiorComprovante} sidebar disableHover textProps={{ style: 
                      { width: '100%', 
                        padding: '16px',
                        background: '#FF6A58',
                        fontSize: '15px',
                        textAlign: 'center',
                        color: '#fff',
                        borderRadius: '4px'                        
                      }}}>
                        ENVIAR COMPROVANTE
                  </LinkMenuButton>
                </div>
              )}

              {(details.tem_promocao && menu === 'concursos') && (
                <div className='Sidebar-menu-item'>
                  <LinkMenuButton to={routes.promocao} icon='offer' sidebar>Promoção</LinkMenuButton>
                </div>
              )}

              {details.renovar_assinatura && (
                <div className='Sidebar-menu-item'>
                  <LinkMenuButton to={routes.renovacaoAssinatura} icon='star' sidebar>Renovação de Assinatura</LinkMenuButton>
                </div>
              )}

              {(details.renovar_assinatura_antecipada) && (
                <div className='Sidebar-menu-item'>
                  <LinkMenuButton to={routes.assinaturaVitalicia} icon='star' sidebar>Assinatura Vitalícia</LinkMenuButton>
                </div>
              )}

              {details.mostrar_assinatura && (
                <div className='Sidebar-menu-item'>
                  <LinkMenuButton to={routes.assinaturas} customIcon={icCatalogo} sidebar>Catálogo de Cursos</LinkMenuButton>
                </div>
              )}

              <React.Fragment>

                <div className='Sidebar-menu-item'>
                  <LinkMenuButton replace to={{ pathname: routes.cursos, state: { tipo: menu } }} customIcon={icMatriculas} sidebar>
                    Minhas Matrículas
                  </LinkMenuButton>
                </div>

              </React.Fragment>

              {/* Menus ECJ */}
              {(validaItemMenu('Carreira Jurídica', details.verticais) && menu === 'ecj') ? (
                <React.Fragment>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.cursosExclusivosECJ} customIcon={icCatalogo} sidebar>Cursos exclusivos</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.cursosExtensao} customIcon={icCatalogo} sidebar>Cursos de extensão</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.cursosOrais} customIcon={icCatalogo} sidebar>Cursos orais</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.estudoEstrategico} customIcon={icEstudoEstrategico} sidebar>Estudo estratégico</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.trilhasEcj} customIcon={icBound} sidebar>Trilhas Estratégicas</LinkMenuButton>
                  </div>

                  <UserDetails>
                    {details => (
                      <React.Fragment>

                        <div className='Sidebar-menu-item'>
                          <LinkMenuButton to={`https://cj.estrategia.com/cadernos-e-simulados/`} icon='list-numbered' sidebar external>
                            Caderno de Questões
                          </LinkMenuButton>
                        </div>

                        <div className='Sidebar-menu-item'>
                          <LinkMenuButton to={`https://cj.estrategia.com/cast/`} customIcon={icCast} sidebar external>
                            Casts
                          </LinkMenuButton>
                        </div>

                        {details.tem_sistema_de_questoes
                          ? <div className='Sidebar-menu-item'>
                            <LinkMenuButton to={`https://cj.estrategia.com/performance/`} customIcon={icMonitor} external sidebar>Monitor de Performance</LinkMenuButton>
                          </div>
                          : null
                        }

                      </React.Fragment>
                    )}
                  </UserDetails>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.simuladosECJ} customIcon={icSimulados} sidebar>Simulados</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.rodadas} customIcon={icRodadas} sidebar>Rodadas</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.retaFinalEcj} customIcon={icCatalogo} sidebar>Reta Final</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.informativosEstrategicos} customIcon={icInformativo} sidebar>Informativos estratégicos</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.vadeMecum} customIcon={icVadeMecum} sidebar>Vade-Mécum estratégico</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.comunidadesEcj} customIcon={icComunidades} sidebar>Comunidades</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={`https://cj.estrategia.com/portal/`} customIcon={icBlog} external sidebar>Portal ECJ</LinkMenuButton>
                  </div>

                </React.Fragment>
              ) : null}

              {/* Menus OAB */}
              {(validaItemMenu('OAB', details.verticais) && menu === 'oab') ? (
                <React.Fragment>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.temasEspeciaisOab} customIcon={icCatalogo} sidebar>Temas Especiais</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.trilhasOab} customIcon={icBound} sidebar>Trilhas Estratégicas</LinkMenuButton>
                  </div>

                  <UserDetails>
                    {details => (
                      <React.Fragment>

                        <div className='Sidebar-menu-item'>
                          <LinkMenuButton to={`https://oab.estrategia.com/cadernos-e-simulados/`} icon='list-numbered' sidebar external>
                            Caderno de Questões
                          </LinkMenuButton>
                        </div>

                        <div className='Sidebar-menu-item'>
                          <LinkMenuButton to={`https://oab.estrategia.com/cast/`} customIcon={icCast} sidebar external>
                            Casts
                          </LinkMenuButton>
                        </div>

                        {details.tem_sistema_de_questoes
                          ? <div className='Sidebar-menu-item'>
                            <LinkMenuButton to={`https://oab.estrategia.com/performance/`} customIcon={icMonitor} external sidebar>Monitor de Performance</LinkMenuButton>
                          </div>
                          : null
                        }

                      </React.Fragment>
                    )}
                  </UserDetails>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.livraoOab} customIcon={icCatalogo} sidebar>Livrão</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.simuladosOab} customIcon={icSimulados} sidebar>Simulados</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.retaFinalOab} customIcon={icCatalogo} sidebar>Reta Final</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.monitoriasOab} customIcon={icMonitorias} sidebar>Monitorias</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.salaVipOab} customIcon={icSalaVip} sidebar>Sala Vip</LinkMenuButton>
                  </div>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={routes.vmDigitalOab} customIcon={icVadeMecum} sidebar>Vade-Mécum Digital</LinkMenuButton>
                  </div>

                </React.Fragment>
              ) : null}

            </React.Fragment>
          )}
        </UserDetails>

        {menu === 'concursos' && (
          <React.Fragment>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.trilhas} customIcon={icBound} sidebar>Trilhas Estratégicas</LinkMenuButton>
            </div>

            <UserDetails>
              {details => (
                <React.Fragment>

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={`https://concursos.estrategia.com/cadernos-e-simulados/`} icon='list-numbered' sidebar external>
                      Caderno de Questões
                    </LinkMenuButton>
                  </div>

                  {details.ldi_url !== null ?
                    <div className='Sidebar-menu-item'>
                      <LinkMenuButton to={details.ldi_url} customIcon={icCatalogo} sidebar external newItem>
                        LDI
                      </LinkMenuButton>
                    </div>
                    : null
                  }

                  <div className='Sidebar-menu-item'>
                    <LinkMenuButton to={`https://concursos.estrategia.com/cast/`} customIcon={icCast} sidebar external>
                      Casts
                    </LinkMenuButton>
                  </div>

                  {details.tem_sistema_de_questoes
                    ? <div className='Sidebar-menu-item'>
                      <LinkMenuButton to={`https://concursos.estrategia.com/performance/`} customIcon={icMonitor} external sidebar>Monitor de Performance</LinkMenuButton>
                    </div>
                    : null
                  }

                </React.Fragment>
              )}
            </UserDetails>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.comunidades} customIcon={icComunidades} sidebar>Comunidades</LinkMenuButton>
            </div>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.estudeEmGrupo} customIcon={icSalaVip} sidebar>Sala Vip</LinkMenuButton>
            </div>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.monitorias} customIcon={icMonitorias} sidebar>Monitorias</LinkMenuButton>
            </div>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.simulados} customIcon={icSimulados} sidebar>Simulados</LinkMenuButton>
            </div>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.cursosExclusivos} customIcon={icCursosExclusivos} sidebar>Cursos Exclusivos</LinkMenuButton>
            </div>

            <div className='Sidebar-menu-item'>
              <LinkMenuButton to={routes.meusDados} customIcon={icMinhasPreferencias} sidebar>Minhas Preferências</LinkMenuButton>
            </div>

          </React.Fragment>)
        }

        <div className='Sidebar-menu-item onlyMobile'>
          <UnreadAlerts>
            {count => (
              <div className='Sidebar-menu-item'>
                <LinkMenuButton to={routes.alertas} icon='bell' count={count} onlyMobile sidebar>Alertas</LinkMenuButton>
              </div>
            )}
          </UnreadAlerts>
        </div>

        <div className='Sidebar-menu-item onlyMobile'>
          <LinkMenuButton to={routes.logout} icon='exit' onlyMobile sidebar>Sair</LinkMenuButton>
        </div>

      </nav>

    </aside>
  )
}

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  salesForce: PropTypes.object.isRequired
}
