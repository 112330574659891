
// Retorna o total de slides
export const totalSlides = (data) => {
    let total = data.length
    return total > 4
}

// Verfica o autoplay
export const verificaAutoPlay = (data) => {
    let autoplay = true
    data.forEach(function (item) {
      if (item.fixo) autoplay = false
    })
    return autoplay
}