import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import { fetchOne } from 'store/ducks/conteudo/blackFriday'
import * as selectors from 'store/ducks/conteudo/blackFriday'

class BlackFriday extends Component {

  componentWillMount () {
    if (this.props.fetch) {
      this.props.fetchBlackFriday()
    }
  }

  render () {
    if (this.props.isFetching) {
      return this.props.showSpinner
        ? <Spinner text='Carregando...' />
        : null
    }

    if (!this.props.data) {
      return 'Conteúdo não encontrado!'
    }

    return this.props.children({
      data: this.props.data,
      isSubmitting: this.props.isSubmitting
    })
  }

  static propTypes = {
    // From component
    children: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
    fetch: PropTypes.bool,

    // From connect (state)
    isFetching: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    data: PropTypes.object,
    isListLoaded: PropTypes.bool.isRequired,

    // From connect (dispatch)
    fetchBlackFriday: PropTypes.func.isRequired
  }

  static defaultProps = {
    showSpinner: true,
    fetch: true
  }

}

const mapStateToProps = (state) => ({
  isFetching: selectors.isFetching(state),
  isSubmitting: selectors.isSubmitting(state),
  data: selectors.data(state),
  isListLoaded: selectors.isListLoaded(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchBlackFriday () {
    dispatch(fetchOne())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlackFriday)
