import React from 'react'
import Box from 'components/Box'
import Title from 'components/Title'
import List from 'components/List'
import 'styles/Coaching.css'
import imageBox from 'images/ic-ilustra-coaching.svg'
import IconPlanejamento from 'images/ic-planejamento.svg'
import IconEmocional from 'images/ic-emocional.svg'
import IconErros from 'images/ic-erros.svg'
import CircledItem from 'components/CircledItem'
import SquaredItem from 'components/SquaredItem';
import FetchApi from '../components/FetchApi';
import { SITE_URL } from '../config/constants';

function Coaching() {
  const advantages = [
    'Aumento da produtividade nos estudos',
    'Maior absorção do conteúdo estudado',
    'Melhora no rendimento dos exercícios',
    'Mais segurança durante a jornada de estudos até a aprovação',
    'Ciclos de estudos planejados de acordo com a sua rotina e concursos de interesse',
    'Planilhas de revisões sistemáticas e avaliações de desempenho',
    'Deixá-lo preparado para planejar e otimizar seu estudo em um alto nível, possibilitando alto rendimento em provas',
  ]

  return (
    <FetchApi
      baseURL={SITE_URL}
      url='assinaturas/main/carregarajax/'
      spinner
    >
      {({ data }) => {
        if (data) {
          return (
            <React.Fragment>
              <Box padded>
                <div className='Coaching'>
                  <div className='Coaching-column'>
                    <Title isSubtitle classesName="is-dark">Melhore seu rendimento nos estudos com o programa de Coaching</Title>
                    <List items={advantages} />
                  </div>
                  <div className="Coaching-column">
                    <img src={imageBox} alt="Coaching" />
                  </div>
                </div>
              </Box>
              <Box isTransparent isMargined>
                <div>
                  <div className='Coaching-full-column'>
                    <Title isSubtitle classesName="is-dark is-centered">Principais pilares do programa</Title>
                  </div>
                  <div className='Coaching-full-column CardsList'>
                    <CircledItem>
                      <img src={IconPlanejamento} className="CustomIcon" alt="Planejamento" />
                      <span>Planejamento personalizado e individualizado</span>
                    </CircledItem>
                    <CircledItem>
                      <img src={IconEmocional} className="CustomIcon" alt="Apoioo" />
                      <span>Apoio emocional durante a preparação</span>
                    </CircledItem>
                    <CircledItem>
                      <img src={IconErros} className="CustomIcon" alt="Ajuda" />
                      <span>Não deixar que os alunos cometam os nossos erros do passado</span>
                    </CircledItem>
                  </div>
                </div>
              </Box>
              <Box isTransparent isMargined>
                <div>
                  <div className='Coaching-full-column'>
                    <Title isSubtitle classesName="is-dark is-centered">O que está incluso no programa</Title>
                  </div>
                  <div className='Coaching-full-column CardsList'>
                    <SquaredItem>Assessoramento individual e personalizado com um coach especialista em concursos.</SquaredItem>
                    <SquaredItem>Sessões individuais de videochamada com o seu coach (com horários pré-agendados).</SquaredItem>
                    <SquaredItem>Trilha Estratégica personalizada com orientações específicas para o seu perfil e objetivo.</SquaredItem>
                    <SquaredItem>Acesso ao coach para dúvidas, feedbacks e auxílio.</SquaredItem>
                    <SquaredItem>Acesso à comunidade de alunos do coaching no Telegram.</SquaredItem>
                    <SquaredItem>Simulados exclusivos de cada matéria (para treinamento).</SquaredItem>
                  </div>
                </div>
              </Box>

              <Box isTransparent isMargined>
                <div>
                  <div className='Coaching-full-column'>
                    <Title isSubtitle classesName="is-dark is-centered">O que está incluso no programa</Title>
                  </div>
                  <div className='Coaching-full-column' style={{ marginTop: 32, textAlign: 'center' }}>
                    <p>Após a realização da primeira sessão de videochamada individual com seu coach, você tem 14 dias para testar nosso serviço.</p>
                    <p>Durante esse período (14 dias após a primeira sessão via skype), se você entender que nosso serviço não está te ajudando como você esperava, fique tranquilo: você pode pedir o cancelamento enviando um simples email (sem burocracia) e receber 100% do dinheiro de volta.</p>
                    <p>Em outras palavras, o Programa de Coaching do Estratégia não tem risco para você. Se você não gostar do serviço, basta cancelá-lo dentro do prazo da garantia. Será devolvido 100% do dinheiro gasto, simples assim. :)</p>
                  </div>
                </div>
              </Box>

              <Box isTransparent isMargined>
                <div>
                  <div className='Coaching-full-column'>
                    <Title isSubtitle classesName="is-dark is-centered">Adquira agora assinando o pacote Platinum</Title>
                  </div>
                  <div className='Coaching-full-column' style={{ marginTop: 32, textAlign: 'center' }}>
                    <a style={
                      {
                        'display': 'inline-block',
                        'font-weight': '600',
                        'color': '#ffffff',
                        'border-radius': '3px',
                        'border': 'none',
                        'padding': '20px 40px',
                        'cursor': 'pointer',
                        'text-align': 'center',
                        'font-size': '14px',
                        'text-transform': 'uppercase',
                        'background-color': '#FF6A58'
                      }} href={data.planos[0].combos.filter(f => f.nome === "Assinatura Platinum")[0].produtos[0].link.replace("'\"/", "/").replace("'\"/", "/")} color="special">Assinar</a>
                  </div>
                </div>
              </Box>
              <Box isTransparent isMargined>
                <div>
                  <div className='Coaching-full-column'>
                    <Title isSubtitle classesName="is-dark is-centered">Quer saber mais sobre o programa?</Title>
                  </div>
                  <div className='Coaching-full-column' style={{ marginTop: 32, textAlign: 'center' }}>
                    {/* <p>Envie-nos uma mensagem no Whatsapp ou um e-mail para agendarmos uma conversa!</p> */}
                    <p><a href="mailto:platinum@estrategiaconcursos.com.br" className="CoachingLink">platinum@estrategiaconcursos.com.br</a></p>
                  </div>
                  {/* <div className='Coaching-full-column CardsList'>
            {whatsApp.map((number, index) => {
              return (<SquaredItem small><a href={number} target="_blank">WhatsApp {index+1}</a></SquaredItem>)
            })}
          </div>*/}
                </div>
              </Box>

            </React.Fragment>
          )
        }
        return 'Não foi possível recuperar assinatura'
      }}
    </FetchApi>
  )
}


export default Coaching
