import styled from 'styled-components'
import theme from 'theme'
import media from 'utils/mediaQuery'

export const Card = styled.section`
  display: flex;
  border-radius: 3px;
  border: 1px solid #D8D8D8;
  background-color: #ffffff;
  font-family: ${theme.fonts.primary};
`

export const Wrapper = styled.div`
  display: flex;
  padding: 15px 20px;
  width: 100%;
  text-decoration: none;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${props => props.isList && media.greaterThan('medium')`
    flex-direction: row;
    align-items: center;
    
    > button,
    > a {
      flex-shrink: 0;
      margin-left: 15px;
    }
  `}
`

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;

  ${props => props.isList && media.greaterThan('medium')`
    display: none;  
  `}
`

export const Title = styled.h1`
  color: ${props => props.disabled ? 'rgba(127, 127, 127, 0.6)' : '#333'};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  margin: 0 0 20px 0;
  min-height: 45px;
`

export const Label = styled.span`
  margin-top: 15px;
  font-size: 14px;
  color: rgb(95, 95, 95);
  position: relative;
  line-height: 25px;
  padding-left: ${props => props.padding ? '30px' : '0px'}
`

export const AlertLabel = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`
