import React, { useState } from 'react'
import { BoxAviso, Icone, Texto, IconeClose } from './Styled'
import icAviso from 'images/icons/ic-aviso-renovacao.svg'
import icFecha from 'images/icons/ic-close-aviso.svg'

export default function Aviso ({ dados }) {

    const [aviso, setAviso] = useState(dados.assinatura_atual ? dados.assinatura_atual.delta : false)

    const fechaAviso = () => {
        setAviso(false)
    }

    const returnHtml = () => (
        <BoxAviso>
            <Icone src={icAviso} />
            <Texto>Após a renovação, o acesso aos seus materiais continuará sendo realizado através da nossa nova Área do Aluno.</Texto>
            <IconeClose src={icFecha} onClick={fechaAviso} />
        </BoxAviso>
    )

    return aviso ? returnHtml() : null
    
}
