import { normalize } from 'normalizr'
import Client from 'modules/Client'
import { aula } from './schemas'

export default {
  fetchOne (aulaId) {
    return Client.get(`api/aluno/aula/${aulaId}`)
      .then(response => {
        return normalize(response.data, aula)
      })
  },

  updateLessonView (lessonId, viewed) {
    return Client.put(`api/aluno/aula/${lessonId}/visualizacao`, {
      data: { visualizada: viewed }
    })
  }
}
