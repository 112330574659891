import styled from 'styled-components'

export const Container = styled.section`
	float: left;
	width: 100%;
	background: url(${props => props.background}) center center no-repeat;
	display: flex;
	justify-content: flex-start;

	@media only screen and (max-width: 900px) {
		
	}
`

export const BoxConteudo = styled.div`
	width: 35%;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 1500px) {
		width: 50%;
	}

	@media only screen and (max-width: 900px) {
		width: 100%;
	}
`

export const TituloConteudo = styled.span`
	float: left;
	width: 100%;
	color: #F09C00;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin: 0 0 50px 0;

	@media only screen and (max-width: 768px) {
		margin: 0 0 40px 0;
	}
`

export const BoxPrecos = styled.div`
	float: left;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;

	@media only screen and (max-width: 768px) {
		gap: 15px;
	}
`

export const BoxPreco = styled.div`
	float: left;
	width: 100%;
	text-align: center;

	@media only screen and (max-width: 900px) {
		
	}
`

export const TituloPreco = styled.span`
	float: left;
	width: 100%;
	color: #fff;
	font-weight: bold;
	margin: 0 0 10px 0;
	font-size: 22px;

	@media only screen and (max-width: 768px) {
		font-size: 18px;
	}
`

export const DescricaoPreco = styled.span`
	float: left;
	width: 100%;
	color: #fff;
	margin: 0 0 20px 0;
	min-height: 20px;

	@media only screen and (max-width: 900px) {
		
	}
`

export const PrecoDe = styled.span`
	float: left;
	width: 100%;
	color: #fff;
	font-size: 14px;
	text-decoration: line-through;
	margin-bottom: 5px;

	@media only screen and (max-width: 900px) {
		
	}
`

export const PrecoPor = styled.span`
	float: left;
	width: 100%;
	color: #fff;
	font-weight: bold;
	margin-bottom: 20px;
	font-size: 22px;

	@media only screen and (max-width: 768px) {
		font-size: 20px;
	}
`

export const LinkPreco = styled.a`
	color: #fff;
	background: #A36B00;
	display: inline-block;
	padding: 12px 30px;
	border-radius: 4px;
	font-weight: bold;

	@media only screen and (max-width: 768px) {
		padding: 12px 15px;
	}
`
