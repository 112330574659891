import styled from 'styled-components'

export const Lista = styled.ul`
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
`

export const Item = styled.li`
  list-style: none;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
`

export const Icone = styled.img`
  margin-right: 10px;
`

export const Texto = styled.span`
  color: #ffffff;
  font-size: 14px;
`

export const Linha = styled.div`
  background-color: #ffffff;
  width: calc(100% - 30px);
  height: 1px;
`
