import React, { useState } from 'react'
import { DebounceInput } from 'react-debounce-input'

export default function FormAssinaturaCursosFilter ({ disabled, searchList, ...props }) {

  const [termo, setTermo] = useState('')

  return (
    <DebounceInput
        minLength={1}
        className='Input'
        value={termo}
        debounceTimeout={1500}
        onInput={ e => {
            let vlr = e.target.value;
            setTermo(vlr)
        }}
        onChange={e => {
          searchList(e.target.value)
        }}
        placeholder='Pesquisar...' 
    />
  )
}
