import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import { required } from 'utils/forms'

export default function FormVideoNotes ({ onSubmit, isSubmitting, initialValues }) {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, dirty }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Field
              name='anotacoes'
              component={CustomField}
              renderWith='textarea'
              rows={5}
              {...required}
            />
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting || (pristine && dirty)}>
              {!isSubmitting ? 'Salvar Anotações' : 'Salvando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormVideoNotes.propTypes = {
  /** Callback do form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired
}
