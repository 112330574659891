import React from 'react'
import Section from 'components/Section'
import CardTokens from 'containers/CardTokens'

export default function SectionCardTokens () {
  return (
    <Section title='Métodos de pagamento' id='payment-cards'>
      <CardTokens />
    </Section>
  )
}
