import React from 'react'
import { Form, Field } from 'react-final-form'
import { emailRequired } from 'utils/forms'
import { equalTo } from 'utils/validators'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'

export default function FormUserEmail ({ onSubmit, isSubmitting }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <SectionTitle>Alterar e-mail</SectionTitle>
          <FormGroup>
            <Field
              name='email'
              type='email'
              label='E-mail'
              component={CustomField}
              {...emailRequired}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name='email_confirmation'
              type='email'
              label='Repita o novo e-mail'
              component={CustomField}
              validate={equalTo({ field: 'email', label: 'E-mail' })}
            />
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Salvar alterações' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}
