import React from 'react'
import {
  Box,
  TopoBox,
  BoxSplash,
  BoxSplashText,
  BoxSplashDiscount,
  TopoBoxFlex,
  TagPlanoAtual,
  TagPlano,
  TituloPlano,
  TipoPlano,
  TituloLista
} from './Styled'

import icSplash from 'images/icons/ic-splash.svg'

/* Componentes */
import Bonus from './Components/Bonus/Bonus'
import List from './Components/List/List'
import Cartao from './Components/Cartao/Cartao'
import Boleto from './Components/Boleto/Boleto'

export default function Plano ({ dadosPlano, atual, tipo, produto }) {    

    const editTitle = (title) => {
        let newTitle = title.split(" - ")
        return newTitle[0]
    }    

    const tituloPlano = !atual ? editTitle(dadosPlano.nome) : editTitle(dadosPlano);

    return (
        <Box>
        
            {/* Box Topo */}
            <TopoBox atual={atual}>

                <TopoBoxFlex>

                    {/* Tag */}
                    {/* { atual ? <TagPlanoAtual>Este é o seu plano atual</TagPlanoAtual> : <TagPlano tipo={dadosPlano.tag === 'Faça upgrade com a melhor oferta' ? 1 : 2}>{dadosPlano.tag}</TagPlano> } */}

                    {/* Título do Plano Atual */}
                    {/* {!dadosPlano.nome ? <TituloPlano cor={ !atual ? '#fff' : '#0A121F' } atual={atual} dangerouslySetInnerHTML={{ __html: tituloPlano.replace(' ', '<br>') }} /> : null} */}

                    {/* Título do Plano */}
                    {dadosPlano.nome ? <TituloPlano cor={ !atual ? '#fff' : '#0A121F' } atual={atual} dangerouslySetInnerHTML={{ __html: tituloPlano }} /> : null}

                    {/* Tipo do Plano */}
                    {/* {dadosPlano.plano ? <TipoPlano cor={ !atual ? '#fff' : '#0A121F' } atual={atual}>{dadosPlano.plano}</TipoPlano> : null} */}

                </TopoBoxFlex>

                {/* { !atual 
                ? <BoxSplash bg={icSplash}>
                    <BoxSplashText>até</BoxSplashText>
                    <BoxSplashDiscount>{dadosPlano.boleto.desconto}%</BoxSplashDiscount>
                    <BoxSplashText>off</BoxSplashText>
                    </BoxSplash> 
                : null 
                } */}

            </TopoBox>

            {/* Lista de Bônus */}
            {/* { !atual ? <Bonus bonus={dadosPlano.cartao.bonus} mentoria={dadosPlano.cartao.mentoria} produto={produto} /> : null } */}
            
            {/* Título das Vantagens */}
            { !atual ? <TituloLista>O que faz parte da assinatura:</TituloLista> : null }

            {/* Lista de Vantagens */}
            {dadosPlano.vantagens && !atual ? <List itens={dadosPlano.vantagens} /> : null}

            {/* Cartão */}
            {dadosPlano.cartao ? <Cartao tipo={tipo} dados={dadosPlano.cartao} /> : null}

            {/* Boleto */}
            {dadosPlano.boleto ? <Boleto tipo={tipo} dados={dadosPlano.boleto} /> : null}
        
        </Box>
    )
}
