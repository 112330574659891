import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import SectionTitle from 'components/SectionTitle'
import Text from 'components/Text'
import Button from 'components/Button'
import FormGroup from 'components/FormGroup'
import FormResearch from 'forms/FormResearch'
import Notifications from 'containers/Notifications'
import * as researchesDuck from 'store/ducks/researches'
import { isEmpty } from 'ramda'

class ResearchModal extends Component {
  state = {
    done: false
  }

  componentWillMount () {
    this.props.fetchResearch(this.props.courseId)
  }

  componentDidUpdate (prevProps) {
    if (((prevProps.isIgnoring && !this.props.isIgnoring) || (prevProps.isSubmitting && !this.props.isSubmitting)) && !this.props.error) {
      this.setState({
        done: true
      })
    }
  }

  render () {
    return (
      <Modal
        isOpen
        onRequestClose={this.props.onClose}
        withAlerts
      >
        {this.renderContent()}
      </Modal>
    )
  }

  renderContent () {
    if (this.props.isFetching) {
      return <Spinner text='Carregando Pesquisa...' />
    }

    if (isEmpty(this.props.research)) {
      return 'Pesquisa não encontrada!'
    }

    if (this.state.done) {
      return 'Obrigado por responder a pesquisa'
    }

    return (
      <div>
        <Notifications notificationsId='formResearch' />
        <SectionTitle>Avaliação do Curso</SectionTitle>
        <FormGroup>
          <Text>
            Prezado Aluno,
          </Text>
          <Text>
            Gostaríamos de pedir sua colaboração. Sua opinião é muito importante!
          </Text>
          <Text>
            Por gentileza, responda à pesquisa abaixo com a maior sinceridade possível. Assim, teremos o feedback necessário para o constante aperfeiçoamento de nossos cursos.
          </Text>
          <Text>
            Atenciosamente,
          </Text>
          <Text>
            Coordenação do Estratégia.
          </Text>
        </FormGroup>
        <FormGroup>
          <Button
            type='button'
            size='small'
            onClick={() => this.props.ignoreResearch(this.props.courseId)}
            disabled={this.props.isIgnoring}
          >
            {!this.props.isIgnoring ? 'Ignorar pesquisa' : 'Processando...'}
          </Button>
        </FormGroup>
        <FormGroup>
          <FormResearch
            onSubmit={data => this.props.sendResearch(this.props.courseId, data, 'formResearch')}
            isSubmitting={this.props.isSubmitting}
            research={this.props.research}
          />
        </FormGroup>
      </div>
    )
  }

  static propTypes = {
    /** Callback para quando a modal para fechar a modal. */
    onClose: PropTypes.func.isRequired,

    /** ID do curso */
    courseId: PropTypes.number.isRequired,

    /** Pesquisa sendo carregada */
    isFetching: PropTypes.bool.isRequired,

    /** Respostas da pesquisa estão sendo enviadas */
    isSubmitting: PropTypes.bool.isRequired,

    /** Pesquisa sendo ignorada */
    isIgnoring: PropTypes.bool.isRequired,

    /** Pesquisa */
    research: PropTypes.object.isRequired,

    /** Carrega a pesquisa do curso */
    fetchResearch: PropTypes.func.isRequired,

    /** Envia as respostas da pesquisa */
    sendResearch: PropTypes.func.isRequired,

    /** Ignora o formulário de avaliação */
    ignoreResearch: PropTypes.func.isRequired,

    /** Possível objeto de erro */
    error: PropTypes.any
  }
}

const mapStateToProps = (state, { courseId }) => ({
    research: researchesDuck.getResearch(state, courseId),
    isFetching: researchesDuck.isFetching(state),
    isSubmitting: researchesDuck.isSubmitting(state),
    isIgnoring: researchesDuck.isIgnoring(state),
    error: researchesDuck.getError(state)
  })

const mapDispatchToProps = {
  fetchResearch: researchesDuck.fetchResearch,
  sendResearch: researchesDuck.sendResearch,
  ignoreResearch: researchesDuck.ignoreResearch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchModal)
