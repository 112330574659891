import Client from 'modules/Client'

export default {

  fetchOne () {
    return Client.get(`api/aluno/minhas-preferencias`)
      .then(response => response)
  }
  
}
