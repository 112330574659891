import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SectionTitle from 'components/SectionTitle'
import Text from 'components/Text'
import 'styles/LessonCollapseHeader.css'

export default class LessonCollapseHeader extends Component {
  render () {
    const { lesson, subject } = this.props
    return (
      <header>
        <div className='LessonCollapseHeader'>
          <div className='LessonCollapseHeader-title'>
            <SectionTitle noMargin>{lesson.nome}</SectionTitle>
            {subject && (
              <Text style={{ marginTop: 10, fontSize: 14 }}>
                {subject}
              </Text>
            )}
          </div>
        </div>
      </header>
    )
  }

  static propTypes = {
    isOpened: PropTypes.bool.isRequired,
    lesson: PropTypes.object.isRequired,
    isAvailable: PropTypes.bool.isRequired,
    subject: PropTypes.string
  }
}
