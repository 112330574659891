import React, { useState } from 'react'
import { Container } from './Styled'

// Componentes
import Switch from './Components/Switch/Switch'
import Plano from './Components/Plano/Plano'
import Seta from './Components/Seta/Seta'

export default function Planos ({ dados, lote }) {

  const [tipo, setTipo] = useState(2)

  const listaAssinaturas = (tipoPlano, lista) => {
    let listaItens = lista.map((item, index) => (
      <Plano dadosPlano={item} key={index} tipo={tipoPlano} lote={lote} />
    ))
    return listaItens
  }

  const changeSwitch = (tipoPlano) => {
    if (tipoPlano === 1) {
      setTipo(2)
    } else {
      setTipo(1)
    }
  }

  return (
    <React.Fragment>
      { dados.planos && dados.planos.length > 1 ? <Switch status={tipo} changeSwitch={(plano) => plano ? setTipo(plano) : changeSwitch(tipo)} planos={dados.planos} /> : null }
      <Container>
        { dados.produto_atual ? <Plano dadosPlano={dados.produto_atual} atual /> : null }
        { dados.produto_atual && dados.planos.length > 0 ? <Seta /> : null }
        { tipo === 1 && dados.planos && dados.planos.length > 0 ? listaAssinaturas(2, dados.planos[0].assinaturas) : null }
        { tipo === 2 && dados.planos && dados.planos.length > 1 ? listaAssinaturas(2, dados.planos[1].assinaturas) : null }
      </Container>
    </React.Fragment>
  )
}
