import React, { useState } from 'react'
import PropTypes from 'prop-types'
import getRoute from 'utils/getRoute'
import Subtitle from 'components/Subtitle'
import routes from 'config/routes'
import { format } from 'modules/Date'
import { isBefore } from 'date-fns'
import Client from 'modules/Client'
import Tooltip from 'components/Tooltip'
import arquivar from 'images/ic-arquivar.svg'
import desarquivar from 'images/ic-desarquivar.svg'
import Spinner from 'components/Spinner'
import {
  Card,
  LinkStyled,
  Content,
  BoxLoaderFavorito,
  Favorito,
  SubtitleContainer,
  Title,
  Bottom,
  Label,
  LabelValue,
  LinkA,
  BotaoArquive,
  BarraDePorcentagem,
  BarraDePorcentagemInterna
} from './styles'

export default function CursoCard ({ id, type, title, startDate, expiryDate, icon, redirectLink, inativar, ativar, favorito, totalAulas, aulasRealizadas }) {

  const isExpired = isBefore(expiryDate, new Date())
  const [carregandoFavorito, setCarregandoFavorito] = useState(false)
  const [favoritoState, setFavoritoState] = useState(favorito)

  const conteudoCard = () => {
    return (
      <Content>
        <SubtitleContainer>
          <Subtitle disabled={isExpired}>{type}</Subtitle>
        </SubtitleContainer>
        <Title disabled={isExpired}>{title}</Title>

        <Bottom disabled={isExpired}>
          <div>
            <Label>
              {!isExpired ? 'Disponível entre ' : 'Este curso expirou em '}
            </Label>
            <LabelValue disabled={isExpired}>
              {!isExpired
                ? <React.Fragment>
                  {format(startDate)} e {format(expiryDate)}
                </React.Fragment>
                : format(expiryDate)
              }
            </LabelValue>
          </div>
        </Bottom>

      </Content>
    )
  }

  const archiveCurse = (event, id, type) => {
    event.preventDefault()

    return Promise.resolve()
      .then(() => {
        return Client.post(`api/aluno/curso/${id}/arquivar?arquivar=${type}`)
      })
      .then(data => {
        if (data.success === true) {
          let fadeTarget = document.querySelector('#card' + id)
          fadeTarget.style.opacity = 0
          setTimeout(() => {
            fadeTarget.style.display = 'none'
          }, 1000)
        } else {
          alert('Ocorreu um erro ao arquivar o curso!')
        }
      })
  }

  const changeFavorite = (event, id, type) => {
    event.preventDefault()

    return Promise.resolve()
      .then(() => {
        setCarregandoFavorito(true)
        return Client.post(`api/aluno/curso/${id}/favoritar`)
      })
      .then(data => {
        if (data.success === true) {
          setFavoritoState(!favoritoState)
        } else {
          type ? alert('Ocorreu um erro ao remover dos favoritos!') : alert('Ocorreu um erro ao favoritar o curso!')
        }
        setCarregandoFavorito(false)
      })
      .catch(err => {
        setCarregandoFavorito(false)
        alert('Ocorreu um erro, tente mais tarde!')
      })
  }

  return (
    <Card id={`card${id}`}>

      {/* Favorito */}
      { carregandoFavorito 
        ? <BoxLoaderFavorito>
            <Spinner />
          </BoxLoaderFavorito>
        : <Tooltip placement='right' overlay={ favoritoState ? 'Remover dos Favoritos' : 'Favoritar' }>
            <Favorito className={`icon-star buttonFavoriteCourse${id} ${favoritoState ? 'active' : ''} ${carregandoFavorito ? 'none' : ''} `} favoritado={favoritoState} onClick={(event) => changeFavorite(event, id, !favoritoState)} />
          </Tooltip> 
      }

      { redirectLink === null || !redirectLink || redirectLink === undefined
        ? <LinkStyled
          to={getRoute(routes.curso, { courseId: id })}
          disabled={isExpired}
          onClick={e => isExpired && e.preventDefault()}
        >
          {conteudoCard()}
        </LinkStyled>
        : <LinkA href={redirectLink} target='_blank'>{conteudoCard()}</LinkA>
      }

      {/* Botão Arquivar */}
      { inativar
        ? <Tooltip placement='right' overlay={'Arquivar'}>
          <BotaoArquive onClick={(event) => archiveCurse(event, id, true)}>
            <img src={arquivar} alt='Arquivar' />
          </BotaoArquive>
        </Tooltip>
        : null
      }

      {/* Botão Desarquivar */}
      { ativar
        ? <Tooltip placement='right' overlay={'Desarquivar'}>
          <BotaoArquive onClick={(event) => archiveCurse(event, id, false)}>
            <img src={desarquivar} alt='Desarquivar' />
          </BotaoArquive>
        </Tooltip>
        : null
      }

      {/* Barra de Porcentagem */}
      <Tooltip placement='top' overlay={ `${ aulasRealizadas && totalAulas ? (( aulasRealizadas / totalAulas ) * 100).toFixed(0) : 0 }% das aulas baixadas` }>
        <BarraDePorcentagem>
          <BarraDePorcentagemInterna 
            width={ totalAulas && aulasRealizadas ? ( aulasRealizadas / totalAulas ) * 100 : 0 } 
            color={ (( aulasRealizadas / totalAulas ) * 100) === 100 ? '#1717CE' : '#FF6A58' }
          />
        </BarraDePorcentagem>
      </Tooltip> 

    </Card>
  )
}

CursoCard.propTypes = {

  /** ID do curso */
  id: PropTypes.number.isRequired,

  /** Tipo do curso. Ex: Pré-edital, Pós-edital */
  type: PropTypes.string.isRequired,

  /** Nome do curso */
  title: PropTypes.string.isRequired,

  /** Data de início do curso */
  startDate: PropTypes.instanceOf(Date).isRequired,

  /** Data de expiração da curso */
  expiryDate: PropTypes.instanceOf(Date).isRequired,

  /** Ícone */
  icon: PropTypes.string

}
