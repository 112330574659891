import React from 'react'
import PropTypes from 'prop-types'
import VideoWrapper from 'components/VideoWrapper'
import Text from 'components/Text'

export default function LivestreamVideo({
  livestream: { link, senha: password },
  ...props
}) {
  return (
    <div {...props}>
      {password && <Text style={{ marginBottom: 5 }}>Senha: {password}</Text>}
      <VideoWrapper>
        <iframe
          src={link}
          width='640'
          height='360'
          title='Vídeo'
          frameborder='0'
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        />
      </VideoWrapper>
    </div>
  )
}

LivestreamVideo.propTypes = {
  /** Objeto livestream com event id e account id */
  livestream: PropTypes.any.isRequired
}
