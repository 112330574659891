import qs from 'querystringify'

export default function getRoute (route, params = {}, query) {
  const path = route.replace(/:([^/]+)/g, (a, b) =>
    params.hasOwnProperty(b)
      ? params[b]
      : a
  )

  return `${path}${qs.stringify(query, true)}`
}
