import React from 'react'
import { Lista, Item, Texto } from './Styled'

export default function Bonus () {

  const createArray = () => {
    let listArray = []
    listArray.push('+ Coaching Grátis')
    return listArray
  }

  const ItensList = () => {
    let listaItens = createArray()
    listaItens = listaItens.map((item, index) => (
      <Item key={index}>
        <Texto>{item}</Texto>
      </Item>
    ))
    return listaItens
  }

  return (
    <Lista>
      {ItensList()}
    </Lista>
  )
}
