/**
 * Dependencies.
 */

import update from 'immutability-helper'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'
import { format, isWithinRange, parse } from 'date-fns'
import Calendar from 'api/Calendar'
import getDateRange from 'utils/getDateRange'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('CALENDAR', [
  'QUERY',
  'QUERY_PENDING',
  'QUERY_FULFILLED',
  'QUERY_REJECTED',

  'SELECT_DATE',

  'SET_CONCOURSE_FOR_FILTER'
])

/**
 * Initial State.
 */

const initialState = {
  isFetching: false,
  error: null,
  ranges: {},
  dias: {},
  concursos: {},
  aulas: {},
  selectedDate: new Date(),
  selectedRange: {
    startDate: new Date(),
    endDate: new Date()
  },
  filterByConcourse: 0 // All
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Select Date.
   */

  [actionTypes.SELECT_DATE] (state, { payload }) {
    return update(state, {
      selectedDate: { $set: payload.date },
      selectedRange: { $set: payload.range },
      filterByConcourse: { $set: 0 }
    })
  },

  /**
   * Query Range.
   */

  [actionTypes.QUERY_PENDING] (state) {
    return update(state, {
      isFetching: {$set: true}
    })
  },

  [actionTypes.QUERY_FULFILLED] (state, { payload: { data, range } }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: false },
      ranges: {
        [range]: { $set: data.result }
      },
      dias: { $merge: data.entities.dias || {} },
      concursos: { $merge: data.entities.concursos || {} },
      aulas: { $merge: data.entities.aulas || {} }
    })
  },

  [actionTypes.QUERY_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: {$set: false},
      error: { $set: payload }
    })
  },

  /**
   * Filter Concourse.
   */

  [actionTypes.SET_CONCOURSE_FOR_FILTER] (state, { payload }) {
    return update(state, {
      filterByConcourse: { $set: payload.concourseId }
    })
  }
})

/**
 * Action Creators.
 */

export const selectDate = date => (dispatch, getState) => {
  const range = getDateRange(date)

  dispatch({
    type: actionTypes.SELECT_DATE,
    payload: {
      date,
      range
    }
  })

  const startDate = format(range.startDate, 'YYYY-MM-DD')
  const endDate = format(range.endDate, 'YYYY-MM-DD')

  if (isRangeLoaded(getState(), startDate, endDate)) return

  dispatch({
    type: actionTypes.QUERY,
    payload: Calendar.query({ startDate, endDate }),
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar dias do calendário'
    }
  })
}

export const setConcourseForFilter = concourseId => ({
  type: actionTypes.SET_CONCOURSE_FOR_FILTER,
  payload: { concourseId }
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.calendar.isFetching

export const isRangeLoaded = (state, startDate, endDate) =>
  state.calendar.ranges.hasOwnProperty(`${startDate}-${endDate}`)

export const getSelectedDate = state =>
  state.calendar.selectedDate

export const getSelectedRange = state =>
  state.calendar.selectedRange

export const getDays = state =>
  state.calendar.dias

export const getConcourses = state =>
  state.calendar.concursos

export const getLessons = state =>
  state.calendar.aulas

export const getConcoursesByIds = state => ids =>
  ids.map(id => getConcourses(state)[id])

export const getLessonsByIds = state => ids =>
  ids.map(id => getLessons(state)[id])

export const getDaysWithinSelectedRange = state => {
  const days = getDays(state)
  const { startDate, endDate } = getSelectedRange(state)
  return Object.keys(days)
    .filter(date => (
      isWithinRange(parse(date), startDate, endDate)
    ))
}

export const getConcoursesWithinSelectedRange = state => {
  const days = getDays(state)
  const ids = uniq(flatten(
    getDaysWithinSelectedRange(state).map(date => days[date].concursos)
  ))
  return getConcoursesByIds(state)(ids)
}

export const getSelectedConcourseForFilter = state =>
  state.calendar.filterByConcourse
