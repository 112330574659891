import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Box from 'components/Box'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import FormFieldFile from 'components/FormFieldFile'
import FormGroup from 'components/FormGroup'
import Text from 'components/Text'
import Icon from 'components/Icon'
import CustomField from 'components/CustomField'
import { Field, Form } from 'react-final-form'
import 'styles/PromoMaior.css'
import { fileSize, fileType } from 'utils/validators'
import { isEmpty, isNil } from 'ramda'
import getRoute from 'utils/getRoute'
import routes from 'config/routes'
import Modal from 'components/Modal'
import { Promo2020, isSubmitting, isDone } from 'store/ducks/promo2020'
import Terms from 'components/PromoMaior2020/Terms'

const FILE_SUPPORTED_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'image/bmp',
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/msword',
  'text/plain',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]


const MAX_FILE_SIZE = (1024 ** 2) * 5 // 5 megabytes

const validate = value => fileType(FILE_SUPPORTED_TYPES)(value) || fileSize(MAX_FILE_SIZE)(value)

const competitors = {
  1: 'TEC',
  2: 'QC'
}

function Upload ({ isSubmitting, onSubmit, isDone }) {

  const [currentSystem, setCurrentSystem] = useState(0)
  const [willSendLater, setWillSendLater] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [submitted, setSubmitted] = useState(false)


  const [isAgreedModalOpen, setIsAgreedModalOpen] = useState(false)
  const toggleAgreedModal = (newValue) => { setIsAgreedModalOpen(!isAgreedModalOpen); setAgreed(newValue); }

  const [isWillSendLaterModalOpen, setIsWillSendLaterModalOpen] = useState(false)
  const toggleWillSendLaterModal = (newValue) => { setIsWillSendLaterModalOpen(!isWillSendLaterModalOpen); setWillSendLater(newValue); }

  const isActiveStyle = system => (system === currentSystem) ? `is-active` : null;

  const ButtonSystem = ({ systemId, text }) => {
    return (
      <div className={`PromoMaior-system ${isActiveStyle(systemId) || ''}`} onClick={(e) => setCurrentSystem(systemId)}>
        <button onClick={() => {}} type="button">
          <Icon name={`check`} />
        </button>
        <span>{text}</span>
      </div>
    )
  } 

  if (submitted && isDone) {
    const to = getRoute(routes.promoMaiorLoja)
    return <Redirect to={to} />
  }

  const onFormSubmit = (formFields) => {
    formFields.comprovante_enviar_depois = willSendLater

    if ((isNil(formFields.concorrente_nome) || isEmpty(formFields.concorrente_nome)) && currentSystem !== 4) {
      formFields.concorrente_nome = competitors[currentSystem]
    }

    setSubmitted(true)
    
    return onSubmit(formFields)
  }

  return (
    <React.Fragment>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit }) => (      
        <form onSubmit={handleSubmit}>
          <Box padded>
            <div className='PromoMaior'>
              <div className='PromoMaior-column -is-full'>
                <div className="PromoMaior-systems">
                  <ButtonSystem systemId={1} text={competitors[1]} />
                  <ButtonSystem systemId={2} text={competitors[2]} />
                  <ButtonSystem systemId={3} text="Outros" />
                  <ButtonSystem systemId={4} text="Nenhum, mas quero desconto" />
                </div>
              </div>
            </div>
            {currentSystem === 3 && (
              <div style={{ display: "flex", justifyContent: "center"}}>
                <Field
                    type='text'
                    name='concorrente_nome'
                    component={CustomField}
                    className={`Input PromoMaior-Input`}
                    placeholder="Digite o nome do sistema de questões que você assina"
                  />
              </div>
            )}
          </Box>          
          {(1 <= currentSystem && currentSystem <= 3) && (
          <Box padded isTransparent>
            <div className='PromoMaior'>
              <div className='PromoMaior-column'>
                <Text className="SectionTitle is-secondary">
                    Por favor, anexe o seu comprovante de matrícula do sistema de questões selecionado (você pode fazer isso em até 7 dias depois da compra, basta selecionar a primeira opção abaixo).
                </Text>
              </div>
              <div className='PromoMaior-column'>
                <FormGroup>
                  <Field
                    type='file'
                    name='comprovante_arquivo'
                    component={FormFieldFile}
                    validate={validate}
                    disabled={willSendLater}
                  />
                </FormGroup>
              </div>
            </div>
          </Box>
          )}
          <Box padded isTransparent>
            <div className='PromoMaior'>
              <div className='PromoMaior-column'>
              {(1 <= currentSystem && currentSystem <= 3) && (
                <Checkbox
                    alignLeft={true}
                    color={`#FF6A58`}
                    type='checkbox'
                    onChange={(e) => setWillSendLater(!willSendLater)}
                    checked={willSendLater}
                    name="comprovante_enviar_depois"
                    Component={WillSendLaterCheckboxComponent}
                    onTermsClick={toggleWillSendLaterModal}
                  />
                  )}
              
                <Checkbox
                    alignLeft={true}
                    onChange={(e) => setAgreed(!agreed)}
                    checked={agreed}
                    color={`#FF6A58`}
                    labelStyles={{fontWeight: 'bold'}}
                    type='checkbox'
                    Component={AgreedTermsCheckboxComponent}
                    onTermsClick={toggleAgreedModal}
                  />
              </div>
              <div className='PromoMaior-column' style={{display: 'flex'}}>
                <div style={{alignSelf: 'flex-end'}}>
                  <Button type='submit' color="special" disabled={!agreed || currentSystem === 0}>
                    {!isSubmitting ? ' Escolher meu plano' : 'Processando...'}
                  </Button>
                </div>
              </div>
            </div>
          </Box>          
        </form>
        )}
      </Form>      

      <Modal
        isOpen={isWillSendLaterModalOpen}
        onRequestClose={() => toggleWillSendLaterModal(willSendLater)}
      >
        <div style={{marginBottom: 15}}>
          <Terms text="willSendLater" />
        </div>
        <div>
          <Button type='button' size="small" onClick={(e) => toggleWillSendLaterModal(true)}>Concordar</Button>
          {" "}
          <Button type='button' size="small" onClick={(e) => toggleWillSendLaterModal(willSendLater)}>Fechar</Button>
        </div>
      </Modal>

      <Modal
        isOpen={isAgreedModalOpen}
        onRequestClose={() => toggleAgreedModal(agreed)}
      >
        <div style={{marginBottom: 15}}>
          <Terms text="agreed" />
        </div>
        <div>
          <Button type='button' size="small" onClick={(e) => toggleAgreedModal(true)}>Concordar</Button>
          {" "}
          <Button type='button' size="small" onClick={(e) => toggleAgreedModal(agreed)}>Fechar</Button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const AgreedTermsCheckboxComponent = ({ onTermsClick }) => {
  return (<div className={`PromoMaior-checkbox`}>Estou ciente dos <span onClick={onTermsClick}><b><u>termos da promoção</u></b></span></div>)
}

const WillSendLaterCheckboxComponent = ({ onTermsClick }) => {
  return (<div className={`PromoMaior-checkbox`} style={{color: `#FF6A58`}}><b>Quero enviar o comprovante depois, <span onClick={onTermsClick}><u>leia os termos</u></span></b></div>)
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state),
  isDone: isDone(state)
})

const mapDispatchToProps = dispatch => ({
  onSubmit (data) {
    dispatch(Promo2020(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload)
