import Client from 'modules/Client'

export default {
  viewVideo (aulaId, videoId, value) {
    return Client.put(`api/aluno/aula/${aulaId}/video/${videoId}/visualizar`, {
      data: { visualizado: value }
    })
  },

  saveVideoLastPosition (lessonId, videoId, time, resolution) {
    return Client.put(`api/aluno/aula/${lessonId}/video/${videoId}/posicao`, {
      data: { posicao: time, resolucao: resolution }
    })
  },

  saveNotes (lessonId, videoId, data) {
    return Client.put(`api/aluno/aula/${lessonId}/video/${videoId}/anotacao`, { data })
  }
}
