import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import { fetchOne, fetchAll } from 'store/ducks/downloads'
import * as selectors from 'store/ducks/downloads'

class Downloads extends Component {

  componentWillMount () {
    if (this.props.fetch) {
      this.props.fetchDownloads()
    }
  }

  render () {
    if (this.props.isFetching) {
      return this.props.showSpinner
        ? <Spinner text='Carregando...' />
        : null
    }

    if (!this.props.data || !this.props.total) {
      return 'Conteúdo não encontrado!'
    }

    return this.props.children({
      data: this.props.data,
      total: this.props.total,
      isFetching: this.props.isFetching
    })
  }

  static propTypes = {
    // From component
    children: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
    fetch: PropTypes.bool,

    // From connect (state)
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,

    // From connect (dispatch)
    fetchDownloads: PropTypes.func.isRequired
  }

  static defaultProps = {
    showSpinner: true,
    fetch: true
  }

}

const mapStateToProps = (state) => ({
  isFetching: selectors.isFetching(state),
  data: selectors.data(state),
  total: selectors.total(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchDownloads () {
    dispatch(fetchOne())
    dispatch(fetchAll())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Downloads)
