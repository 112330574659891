import React from 'react'
import Screen from 'containers/Screen'
import CourseRating from 'containers/CourseRating'
import Course from 'containers/Course'
import CourseLessons from 'components/CourseLessons'

export default function CursoScreen ({ match }) {
  const id = parseInt(match.params.courseId, 10)
  return (
    <Course id={id} showSpinner>
      {({ course, lessons, sendDiscursive, isSubmitting }) => (
        <Screen
          title='Curso'
          Side={() => <CourseRating id={id} />}
          course
        >
          <CourseLessons
            showSpinner
            course={course}
            lessons={lessons}
            sendDiscursive={sendDiscursive}
            isSubmitting={isSubmitting}
          />
        </Screen>
      )}
    </Course>
  )
}
