export default function createResourceSelectors (resource, additionalSelectors) {
  return {
    getResource (state) {
      return state.resources[resource]
    },

    isFetching (state) {
      return this.getResource(state).isFetching
    },

    isSubmitting (state) {
      return this.getResource(state).isSubmitting
    },

    isRemoving (state) {
      return this.getResource(state).isRemoving
    },

    getRecords (state) {
      return this.getResource(state).records
    },

    getError (state) {
      return this.getResource(state).error
    },

    getPagination (state) {
      return this.getResource(state).pagination
    },

    getLoadedPages (state) {
      return this.getResource(state).loadedPages
    },

    isPageLoaded (state) {
      return page => this.getLoadedPages(state).indexOf(page) !== -1
    },

    ...additionalSelectors
  }
}
