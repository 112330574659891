import React from 'react'
import ReactSelect from 'react-select'
import Icon from 'components/Icon'
import 'styles/Select.css'

export default function Select (props) {
  return (
    <ReactSelect
      arrowRenderer={() => (
        <span className='Select-icon'>
          <Icon name='angle-down' />
        </span>
      )}
      clearable={false}
      placeholder='Selecione'
      searchPromptText='Digite para pesquisar'
      loadingPlaceholder='Carregando...'
      noResultsText='Nenhum resultado'
      clearValueText='Limpar valor'
      clearAllText='Limpar tudo'
      backspaceToRemoveMessage='Aperte BACKSPACE para remover {last label}'
      {...props}
    />
  )
}
