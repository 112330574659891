export default {
  fonts: {
    primary: `'OpenSans', 'Roboto', sans-serif`
  },

  colors: {
    primary: '#1352aa',
    secondary: '#FF6A58',
    secondaryDark: '#d7604e',
    text: '#5f5f5f',
    textLight: '#979797',
    grayBorder: '#e5e5e5',
    grayForm: '#c1c1c1',
    grayLight: '#f6f6f6',
    gray: '#e9e9e9',
    success: '#00a671',
    danger: '#d83f33'
  },

  breakpoints: {
    xSmall: '1px',
    small: '400px',
    medium: '600px',
    large: '800px',
    huge: '1000px'
  },

  dimensions: {
    smallerContentPadding: 15,
    contentPadding: 40,
  }
}
