/**
 * Dependencies.
 */

import createResourceReducer from 'store/utils/createResourceReducer'
import { actionTypes } from 'store/actions/resources/userNotifications'

/**
 * Cerfificates reducer.
 */

export default createResourceReducer({ actionTypes })
