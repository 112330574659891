import React from 'react'
import PropTypes from 'prop-types'
import Box from 'components/Box'
import Avatar from 'components/Avatar'
import Badge from 'components/Badge'
import UserContent from 'components/UserContent'
import ModalThumb from 'components/ModalThumb'
import { format } from 'modules/Date'
import 'styles/ForumMessage.css'

export default function ForumMessage ({ message }) {
  return (
    <div className='ForumMessage'>
      <Box>
        <div className='ForumMessage-row'>
          <div className='ForumMessage-row-avatar'>
            <Avatar src={message.cliente.imagem} />
          </div>
          <div className='ForumMessage-row-content'>
            <div className='ForumMessage-header'>
              <div className='ForumMessage-header-avatar'>
                <Avatar src={message.cliente.imagem} />
              </div>
              <span className='ForumMessage-header-author'>
                {message.cliente.nome}
              </span>
              <span className='ForumMessage-header-date'>
                {format(message.data_pergunta)}
              </span>
              {!message.data_resposta && (
                <Badge dark>Aguardando Resposta</Badge>
              )}
            </div>
            <div>
              <pre className='ForumMessage-body'>
                {message.pergunta}
              </pre>
            </div>
            {message.imagem && (
              <div style={{ marginTop: 10 }}>
                <ModalThumb src={message.imagem} alt='Imagem anexada à pergunta' />
              </div>
            )}
          </div>
        </div>
        {message.data_resposta && (
          <div className='ForumMessage-row -answer'>
            {message.professor && (
              <div className='ForumMessage-row-avatar'>
                <Avatar src={message.professor.imagem} />
              </div>
            )}
            <div className='ForumMessage-row-content'>
              <div className='ForumMessage-header'>
                {message.professor && (
                  <div className='ForumMessage-header-avatar'>
                    <Avatar src={message.professor.imagem} />
                  </div>
                )}
                {message.professor && (
                  <span className='ForumMessage-header-author'>
                    {message.professor.nome}
                  </span>
                )}
                <span className='ForumMessage-header-date'>
                  {format(message.data_resposta)}
                </span>
                <Badge>Resposta</Badge>
              </div>
              {message.audio && (
                <audio src={message.audio} controls />
              )}
              {message.resposta && (
                <div>
                  <UserContent dangerouslySetInnerHTML={{ __html: message.resposta }} />
                </div>
              )}
            </div>
          </div>
        )}
      </Box>
    </div>
  )
}

ForumMessage.propTypes = {
  message: PropTypes.object.isRequired
}
