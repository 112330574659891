import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 100%;
  color: #FF6A58;
  margin-top: 30px;
`

export const PrecoDe = styled.span`
  float: left;
  width: 100%;
  color: #0A121F;
  font-size: 13px;
  text-decoration: line-through;
`

export const PrecoPor = styled.span`
  float: left;
  width: 100%;
  color: #0A121F;
  font-size: 22px;
  margin: 7px 0;
  font-weight: bold;
`

export const Link = styled.a`
  float: left;
  background-color: #FF6464;
  border: 0;
  width: 90%;
  border-radius: 3px;
  padding: 0 15px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    line-height: 18px;
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
