import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import Box from 'components/Box'

export default function AulasHistorico ({ data, isSubmitting }) {
  useEffect(() => {
    if (data.length > 0) montaArray()
  })

  const montaArray = () => {
    let aulas = data.map((aula, index) => {
      return (
        <Link key={aula.id} to={`${routes.cursos}/${aula.produto.id}/aulas/${aula.produto.aula.id}?aula=${aula.produto.aula.id}`}>
          <Box padded>
            <div className='ItemBox'>
              <span>{aula.produto.nome}</span>
              <p>{aula.produto.aula.nome}</p>
            </div>
          </Box>
        </Link>
      )
    })

    return aulas
  }

  return (
    <div className={'containerAulas'}>
      { (data.length > 0) ? montaArray() : null }
    </div>
  )
}
