import React, { Component } from 'react'
import FetchAuth from 'components/FetchAuth'
import CoursesSearch from 'components/CoursesSearch'

export default class FetchCourses extends Component {
  state = {
    term: ''
  }

  render() {
    const { url, children, type, baseURL, ...props } = this.props

    return (
      <div>
        <FetchAuth
          url={url}
          baseURL={baseURL}
          {...props}
          cacheResponse={false}
        >
          {({ doFetch, data, fetching }) => (
            <React.Fragment>
              {children({
                doFetch,
                data,
                search: (
                  <div>
                    <FetchAuth url={`api/aluno/acesso-rapido?tipo=${type}`} spinner>
                      {({ data }) => {

                        if (!data) return null

                        return (
                          <CoursesSearch
                            term={this.state.term}
                            onTermChange={term => this.setState({ term })}
                            doFetch={doFetch}
                            url={url}
                            baseURL={baseURL}
                            loading={fetching}
                            quickSearch={[data.map(item => item.nome)]}
                          />
                        )
                      }}
                    </FetchAuth>
                  </div>

                )
              })}
            </React.Fragment>
          )}
        </FetchAuth>
      </div>
    )
  }
}
