import styled, { css } from 'styled-components'
import mediaQuery from 'utils/mediaQuery'
import theme from 'theme'
const BP = '1396px'
const BP2 = '700px'

export const Container = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  color: #333333;
  float: left;
  width: 100%;
  padding: 30px 0 0 0;

  ${props => props.withBorder && css`
    border: 1px solid ${theme.colors.primary};
  `}

  ${mediaQuery.greaterThan(BP)`
    padding-bottom: 0;
  `}

  ${mediaQuery.lessThan(BP)`
    flex-direction: column;
  `}

  ${mediaQuery.lessThan(BP2)`
    padding: 15px;
    text-align: center;
    margin-bottom: 25px;
    display: inline-block;
    width: 100%;
  `}
`
export const InfoMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;


  ${mediaQuery.lessThan(BP2)`
    width: 100%;
  `}
`

export const InfoPlan = styled.div``

export const ColumnInfo = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery.greaterThan(BP)`
    padding-bottom: 40px;
  `}

  ${mediaQuery.lessThan(BP2)`
    width: 100%;
    display: inline-block;
  `}
`

export const ColumnPicture = styled.div`
  text-align: center;
`

export const InfoTitle = styled.h1`
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
`

export const InfoText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 0.9;
  margin-top: 30px;
  font-family: 'Roboto', sans-serif;
  line-height: 150%;

  span > {
      margin-top: 5px;
  }

  @media only screen and (max-width: 600px) {
    margin: 20px 0 0 0!important;
  }
`
export const InfoDestaque = styled.span`
  color: #1717CE;
`

export const PictureImage = styled.div`
  width: 430px;
  height: 310px;
  margin-right: 120px;
  background: transparent url(${require('images/renovacaonovo.svg')}) top center no-repeat;

  ${mediaQuery.lessThan(BP)`
    display: none;
  `} 
`
