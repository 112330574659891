import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 30%;
  padding: 0 10px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 40px;
  }

`

export const TopoBox = styled.div`
  float: left;
  width: 100%;
  height: 180px;
  background: ${props => props.atual === true ? '#000' : '#fff'};
  border: solid 1px #fff;
  display: flex;
  align-itens: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`

export const TopoBoxFlex = styled.div`
  float: left;
  width: 100%;
`

export const TagPlanoAtual = styled.span`
  background-color: ${props => props.tipo === '1' ? '#FF6464' : '#F09C00'};
  color: #000;
  font-size: 12px;
  padding: 5px 10px;
  line-height: 100%;
  margin-bottom: 13px;
  display: inline-flex;
  font-weight: bold;
`

export const TituloPlano = styled.span`
  color: ${props => props.atual === true ? '#fff' : props.cor};
  font-size: 22px;
  font-weight: bold;
  line-height: 130%;
  margin-bottom: 13px;
  float: left;
  width: 100%;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    font-size: 18px;
    top: 55px;
  }
`

export const TipoPlano = styled.span`
  color: ${props => props.atual === true ? '#fff' : props.cor};
  font-size: 13px;
  line-height: 100%;
  float: left;
  width: 100%;
`

export const BonusPlano = styled.span`
  color: #fff;
  font-size: 13px;
  line-height: 100%;
  font-weight: bold;
  background: ${props => props.cor ? props.cor : '#FF6A58'};
  padding: 3px;
  margin-top: 10px;
  display: inline-block;
`

export const TituloLista = styled.span`
  color: #A9ABBD;
  float: left;
  width: 100%;
  margin: 20px 0;
`
