import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import routes from 'config/routes'

// Ícones
import icCursosExclusivos from 'images/icons/ic-cursos-exclusivos.svg'
import icCatalogo from 'images/icons/ic-catalogo.svg'
import icEstudoEstrategico from 'images/icons/ic-estudo-estrategico.svg'
import icSimuladosEJC from 'images/icons/ic-simulados.svg'
import icInformativosEstrategicos from 'images/icons/ic-informativo.svg'
import icVadeMecumEstrategigo from 'images/icons/ic-vade-mecum.svg'
import icSeries from 'images/icons/ic-series.svg'
import icRodadas from 'images/icons/ic-rodadas.svg'
import icMonitorias from 'images/icons/ic-monitorias.svg'
import icBound from 'images/icons/ic-bound.svg'
import icSalaVip from 'images/icons/ic-sala-vip.svg'

export default function CoursePacote ({ data, isSubmitting, filtro }) {
  useEffect(() => {
    if (data.id) montaArray()
  })

  const retornaIcone = (id) => {
    switch (id) {
      case 1:
        return <img src={icCatalogo} alt={'Regular'} />
      case 2:
        return <img src={icCursosExclusivos} alt={'Cursos exclusivos'} />
      case 3:
        return <img src={icMonitorias} alt={'Monitorias'} />
      case 4:
        return <img src={icSimuladosEJC} alt={'Simulados'} />
      case 5:
        return <img src={icBound} alt={'Trilha estratégica'} />
      case 6:
        return <img src={icSalaVip} alt={'Sala Vip'} />
      case 7:
        return <img src={icCatalogo} alt={'Passo Estratégico'} />
      case 8:
        return <img src={icCursosExclusivos} alt={'Cursos exclusivos'} />
      case 9:
        return <img src={icCatalogo} alt={'Cursos de extensão'} />
      case 10:
        return <img src={icEstudoEstrategico} alt={'Estudo Estratégico'} />
      case 11:
        return <img src={icSimuladosEJC} alt={'Simulados'} />
      case 12:
        return <img src={icInformativosEstrategicos} alt={'Informativos Estratégicos'} />
      case 13:
        return <img src={icVadeMecumEstrategigo} alt={'Vade-Mécum estratégico'} />
      case 15:
        return <img src={icCatalogo} alt={'Cursos Orais'} />
      case 16:
        return <img src={icRodadas} alt={'Rodadas'} />
      case 17:
        return <img src={icCatalogo} alt={'Temas Especiais'} />
      case 18:
        return <img src={icCatalogo} alt={'Livrão'} />
      case 19:
        return <img src={icSimuladosEJC} alt={'Simulados'} />
      case 20:
        return <img src={icCatalogo} alt={'Reta Final'} />
      case 21:
        return <img src={icMonitorias} alt={'Monitorias'} />
      case 22:
        return <img src={icSalaVip} alt={'Salas Vip'} />
      case 23:
        return <img src={icVadeMecumEstrategigo} alt={'VM Digital'} />
      case 24:
        return <img src={icBound} alt={'Trilha estratégica'} />
      case 25:
        return <img src={icBound} alt={'Trilha estratégica'} />
      default:
        return <img src={icCatalogo} alt={'Curso'} />
    }
  }

  const montaArray = () => {
    var busca = new RegExp(filtro.toLowerCase())

    let dados = data.cursos.map((curso, index) => {
      if (busca.test(curso.nome.toLowerCase())) {
        return (
          <Link to={routes.cursos + '/' + curso.id + '/aulas'}>
            <div className={'boxCurso'} key={curso.id}>
              <p>{curso.nome}</p>
              { retornaIcone(curso.tipo_curso_id) }
            </div>
          </Link>
        )
      } else {
        return null
      }
    })

    return dados
  }

  return (
    <div className={'containerCursos'}>
      { data.id ? montaArray() : null }
    </div>
  )
}
