import React from 'react'
import { dateToBR } from 'utils/helpers'
import Box from 'components/Box'
import Button from 'components/Button'

export default function BlogCollapse (props) {
  return (
    <div className='listPosts'>
      <Box padded>
        <div className='tituloPost'>
          <span className='titulo'>
            {props.title}
          </span>
          <span className='data'>
            {dateToBR(props.date)}
          </span>
        </div>
        <Button renderWith='a' size='medium' href={props.url} target='_blank'>Ver mais</Button>
      </Box>
    </div>
  )
}
