import React from 'react'
import FetchAuth from 'components/FetchAuth'
import SliderBox from './Componentes/Slider/Slider'

export default function Carrossel(props) {
  return (
    <FetchAuth url={`api/aluno/carrossel/${props.tipo}`} spinner={true} cacheResponse={false}>
      {({ data }) => data && data.data
        ? (
          data.data.items.length > 0
            ? (<SliderBox data={data} props={props} />)
            : null
        )
        : null
      }
    </FetchAuth>
  )
}
