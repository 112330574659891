import React from 'react'
import PropTypes from 'prop-types'
import CollapseContent from 'components/CollapseContent'
import LivestreamVideo from 'components/LivestreamVideo'

export default function VideoLivestream ({ livestream }) {
  return (
    <CollapseContent
      title='Transmissão'
      icon='camera'
      label=''
    >
      <LivestreamVideo livestream={livestream} />
    </CollapseContent>
  )
}

VideoLivestream.propTypes = {
  /** Livestream */
  livestream: PropTypes.object.isRequired
}
