/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import UserInterests from 'api/UserInterests'
import createActionTypes from 'store/utils/createActionTypes'

/**
 * ActionTypes.
 */
export const actionTypes = createActionTypes('INTERESSES', [
  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED'
])

/**
 * Initial State.
 */
const initialState = {
  isFetching: false,
  data: [],
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {

  /**
   * Fetch One.
   */

  [actionTypes.FETCH_ONE_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      data: { $set: payload }
    })
  },

  [actionTypes.FETCH_ONE_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  }

})

/**
 * Action Creators.
 */

export const fetchOne = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ONE,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar dados!'
    },
    payload: UserInterests.fetchOne()
  })
}

/**
 * Selectors.
 */

export const isFetching = state => state.interests.isFetching
export const isSubmitting = state => state.interests.isSubmitting
export const data = state => state.interests.data
