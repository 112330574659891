import React from 'react'
import IconBarCode from 'images/ic-barcode-white.svg'
import { Box, PrecoDe, PrecoPor, Link } from './Styled'

export default function Boleto ({ dados, tipo }) {
  return (
    <Box>
      { dados
        ? <React.Fragment>
          { dados.preco_de ? <PrecoDe>{dados.preco_de}</PrecoDe> : null }
          { dados.preco_por ? <PrecoPor>{dados.preco_por}</PrecoPor> : null }
          <Link tipo={tipo} href={dados.link} target='_blank'>
            <span>10% de desconto <br/>no PIX ou boleto</span>
            <img src={IconBarCode} alt='Pagamento com Boleto' />
          </Link>
        </React.Fragment>
        : null
      }
    </Box>
  )
}
