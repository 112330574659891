import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API_URL } from 'config/constants'
import { pushNotification } from 'store/ducks/notifications'
import Screen from 'containers/Screen'
import Box from 'components/Box'
import Busca from './Components/Busca/Busca'
import Listagem from './Components/Listagem/Listagem'
import Client from 'modules/Client'
import Spinner from 'components/Spinner'
import { 
    BoxRegras, 
    BoxRegrasTitulo, 
    BoxRegrasLista, 
    BoxRegrasListaItem,
    BoxAtualizar,
    BotaoAtualizar,
    AvisoDaAtualizacao,
    SemResultados
} from './Styled'

class Certificados extends Component {

    state = {
        carregando: false,
        mensagem: '',
        error: false,
        termo: '',
        certificates: [],
        pagination: {},
        page: 0,
        loading: true
    }

    componentDidMount () {
        this.loadData()
    }

    // Busca os certificados ao entrar na tela
    async loadData () {
        try {
          
            let certificates = await Promise.resolve(Client.get('/api/aluno/certificado'));
        
            this.setState({
                loading: false,
                certificates: certificates.data,
                pagination: certificates.meta,
                page: 0
            })
            
        } catch (err) {
            this.setState({ loading: false, error: true })
            this.pushError('Ocorreu um erro ao carregar os certificados!')
        }
    }

    // Busca novos certificados
    gerarCertificados = () => {

        this.setState({
            carregando: true,
            mensagem: 'Esta operação pode ser demorada, aguarde!'
        })

        Client.get(`api/aluno/certificado/gerar`)
            .then(response => {

                this.setState({
                    carregando: false
                })

                if (response.data.length > 0) {
                    this.setState({
                        mensagem: `Foram encontrados ${response.data.length} novos certificados!`
                    })
                    this.queryCoursesPage(1)
                } else {
                    this.setState({
                        mensagem: `Não foram encontrados novos certificados!`
                    })
                }
            })
            .catch(err => {
                this.setState({
                    carregando: false,
                    mensagem: `Erro ao atualizar os Certificados!`
                })
            })

    }

    // Busca certificados
    buscaCertificados = (termo) => {
        this.setState({ termo, page: 1, mensagem: '' }, () => {
            this.fetchCertificates()
        })
    }

    // Error
    pushError (message) {
        this.props.push(message, 'error')
    }

    fetchCertificates = async params => {
        try {

            this.setState({ loading: true })

            let page = this.state.page ? this.state.page : 0;
          
            let certificates = await Promise.resolve(Client.get(`${API_URL}/api/aluno/certificado?nome=${this.state.termo}&page=${page}`));

            this.setState({
                certificates: certificates.data,
                pagination: certificates.meta,
                loading: false
            })
    
        } catch (err) {
            this.setState({ loading: false })
            this.pushError('Ocorreu um erro ao carregar os certificados!')
        }
    }

    queryCoursesPage = (page) => {
        this.setState({ page, mensagem: '' }, () => {
          this.fetchCertificates()
        })
    }

    render () {

        return (
            <Screen title='Certificados' setHeadTitle>

                <Box padded>
                    <BoxRegras>
                        <BoxRegrasTitulo>Sobre os certificados:</BoxRegrasTitulo>
                        <BoxRegrasLista>
                        <BoxRegrasListaItem>A data de início do curso que consta no certificado corresponde à data de download do material da primeira aula do curso, que entendemos como a aula com a data de publicação mais antiga.</BoxRegrasListaItem>
                        <BoxRegrasListaItem>Da mesma forma, a data de conclusão do curso no seu certificado corresponde à data de download da última aula do curso, que é a aula com data de publicação mais recente.</BoxRegrasListaItem>
                        <BoxRegrasListaItem>A carga horária do curso no certificado corresponde a 2 horas para cada aula e 30 minutos para cada vídeo.</BoxRegrasListaItem>
                        <BoxRegrasListaItem>A carga horária máxima de estudos por dia é de 8 horas.</BoxRegrasListaItem>
                        <BoxRegrasListaItem>Se o intervalo em dias entre a data de início e data de conclusão do curso não comportar um máximo de 8 horas de estudos diários, será adicionado um dia para cada 8 horas excedentes.</BoxRegrasListaItem>
                        <BoxRegrasListaItem>Para aulas presenciais o certificado ficará disponível no dia seguinte ao fim do evento.</BoxRegrasListaItem>
                        </BoxRegrasLista>
                    </BoxRegras>
                    <BoxAtualizar>
                        <BotaoAtualizar onClick={this.gerarCertificados} disabled={this.state.carregando} className={ this.state.carregando ? 'carregando' : false }>{ this.state.carregando ? 'Atualizando certificados...' : 'Atualizar Certificados' }</BotaoAtualizar>
                        { this.state.carregando ? <Spinner /> : null }
                        { this.state.mensagem !== '' ? <AvisoDaAtualizacao>{this.state.mensagem}</AvisoDaAtualizacao> : null }
                    </BoxAtualizar>
                </Box>

                <Busca busca={(termo) => this.buscaCertificados(termo)} />

                { this.state.certificates.length > 0 ?
                    <Listagem 
                        certificados={this.state.certificates} 
                        loading={this.state.loading} 
                        pagination={this.state.pagination} 
                        queryCoursesPage={this.queryCoursesPage}
                    />
                    : <SemResultados>Não foram encontrados certificados!</SemResultados>
                }
                

            </Screen>
        )

    }
}

export default connect (
    null,
    { push: pushNotification }
)(Certificados)
  