import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 30%;
  padding: 0 10px;

  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-bottom: 40px;
  }

`

export const TopoBox = styled.div`
  float: left;
  width: 100%;
  height: 172px;
  position: relative;
  background: ${props => props.atual === true ? '#000000' : '#D59A04'};
  border: ${props => props.atual === true ? '0.5px solid #FFFFFF' : '0.5px solid #000000'};
`

export const TagPlanoAtual = styled.span`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #D59A04;
  color: #000000;
  font-size: 12px;
  padding: 5px 10px;
  line-height: 100%;
`

export const TituloPlano = styled.span`
  position: absolute;
  top: 60px;
  left: 20px;
  right: 20px;
  color: ${props => props.atual === true ? '#ffffff' : '#000000'};
  font-size: 20px;
  max-width: 100%;
  font-weight: bold;
  line-height: 135%;

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    font-size: 18px;
    top: 55px;
  }
`

export const TipoPlano = styled.span`
  position: absolute;
  bottom: 35px;
  left: 20px;
  color: ${props => props.atual === true ? '#A9A9A9' : '#000000'};
  font-size: 13px;
  line-height: 100%;
`

export const BonusPlano = styled.span`
  position: absolute;
  bottom: 18px;
  left: 20px;
  color: ${props => props.atual === true ? '#A9A9A9' : '#000000'};
  font-size: 13px;
  line-height: 100%;
  font-weight: bold;
`

export const TituloLista = styled.span`
  color: #A9A9A9;
  float: left;
  width: 100%;
  margin: 20px 0;
`
