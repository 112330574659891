import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import 'styles/LoadMore.css'

export default function LoadMore ({ isLoading, onClick }) {
  return (
    <div className='LoadMore'>
      <Button
        type='button'
        onClick={onClick}
        disabled={isLoading}
      >
        {!isLoading ? 'Carregar mais' : 'Carregando...'}
      </Button>
    </div>
  )
}

LoadMore.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
