import React from 'react'
import Screen from 'containers/Screen'
import RenovacaoBox from 'components/RenovacaoAntecipada/RenovacaoAntecipada'

export default function RenovacaoAntecipadaScreen () {
  return (
    <Screen title={''}>
      <RenovacaoBox />
    </Screen>
  )
}
