import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { prop, ifProp, ifNotProp } from 'styled-tools'
import * as dateFns from 'date-fns'
import LineBall from 'components/LineBall'

const colors = [
  ['#ffffff', '#2cd1fd', '#00c6ff'],
  ['#00c6ff', '#425BFF', '#5600FF'],
  ['#5600FF', '#5100EF', '#4900D6']
]

const currency = value => 'R$ ' + value.replace('.', ',')

export default function LoteProduto ({ produto, index, disabled, isFuture, disableButtons }) {
  const color = disabled || disableButtons ? (isFuture ? '#616161' : '#9e9e9e') : undefined

  return (
    <section>
      <Content color={color}>
        <Title color={color} active={produto.comprou}>
          <div>{produto.nome}</div>
        </Title>
        <div style={{ padding: '0 20px' }}>
          <div style={{ textAlign: 'center' }}>
            <PricesContainer>
              <OriginalPrice color={color}>
                12x de {currency(produto.cartao_preco_base)}
              </OriginalPrice>
              <Price>
                12x {currency(produto.cartao_preco_efetivo)}{!disabled && <span style={{ fontSize: 12, verticalAlign: 'top' }}>*</span>}
              </Price>
              <PriceInfo color={color}>
                Sem juros no cartão de crédito
              </PriceInfo>
              <PriceAlternative color={color}>
                Ou {currency(produto.boleto_preco_efetivo)}
              </PriceAlternative>
              <PriceInfo color={color}>
                10% de desconto à vista
              </PriceInfo>
            </PricesContainer>
          </div>
          {!disabled && (
            <ButtonsContainer>
              <Button
                href={disableButtons ? '#' : produto.cartao_url}
                disabled={disableButtons}
                onClick={disableButtons ? e => e.preventDefault() : undefined}
              >
                Assinar com cartão
              </Button>
              <Button
                href={disableButtons ? '#' : produto.boleto_url}
                outline
                disabled={disableButtons}
                onClick={disableButtons ? e => e.preventDefault() : undefined}
              >
                Assinar com boleto
              </Button>
            </ButtonsContainer>
          )}
        </div>
      </Content>
      {!disabled && (
        <React.Fragment>
          <LineBall
            colors={colors[index]}
            text={['+1', '+2', '∞'][index]}
          />
          <Content>
            <div style={{ textAlign: 'center' }}>
              <BlockInfo>
                <BlockInfoTitle>{produto.estude_ate ? `Estude até ${dateFns.format(dateFns.parse(produto.estude_ate), 'DD/MM/YYYY')}` : 'Acesso vitalício'}{!disabled && <span style={{ fontSize: 12, verticalAlign: 'top' }}></span>}</BlockInfoTitle>
                {produto.bonus && produto.bonus.length > 0 && produto.bonus.map((bonus, index) => (
                  <BlockInfoItem key={index}>
                    {bonus}
                  </BlockInfoItem>
                ))}
              </BlockInfo>
            </div>
          </Content>
        </React.Fragment>
      )}
    </section>
  )
}

LoteProduto.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  disableButtons: PropTypes.bool.isRequired,
  isFuture: PropTypes.bool.isRequired,
  isPast: PropTypes.bool.isRequired,
  produto: PropTypes.any.isRequired
}

const Content = styled.div`
  color: ${prop('color', '#212121')};
`

const Title = styled.h1`
  color: ${prop('color', '#2828d0')};
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 20px;
  padding-top: 40px;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  ${ifProp('active', css`
    background-color: #24cffd;
    color: #303030;
    &::before {
      content: "Plano contratado";
      position: absolute;
      left: 0;
      top: 20px;
      width: 100%;
      text-align: center;
      color: #303030;
      font-weight:bold;
      font-size: 12px;
      text-transform: none;
    }
  `)}
`

const OriginalPrice = styled.div`
  color: ${prop('color', '#727272')};
  text-decoration: line-through;
  font-size: 12px;
`

const Price = styled.div`
  font-size: 32px;
`

const PriceInfo = styled.div`
  color: ${prop('color', '#424242')};
  font-size: 12px;
`

const PriceAlternative = styled.div`
  font-size: 20px;
  margin-top: 20px;
  color: ${prop('color', '#424242')};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  > * + * {
    margin-top: 20px;
  }
`

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  padding: 15px;
  padding-top: 16px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid transparent;
  
  ${ifNotProp('outline', css`
    background-color: #2e2efd;
    border-color: #2e2efd;
    color: #fff;

    &:not([disabled]):hover {
      background-color: #2828d0;
      border-color: #2828d0;
    }

    &:not([disabled]):active {
      background-color: #24325b;
      border-color: #24325b;
    }

    &[disabled] {
      background-color: rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.12);
      cursor: not-allowed;
    }
  `)}

  ${ifProp('outline', css`
    background-color: #fff;
    color: #2e2efd;
    border-color: #2e2efd;

    &:not([disabled]):hover {
      color: #2828d0;
      border-color: #2828d0;
    }

    &:not([disabled]):active {
      background-color: #fafafa;
      color: #2828d0;
      border-color: #2828d0;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.24);
      border-color: rgba(0, 0, 0, 0.24);
      cursor: not-allowed;
    }
  `)}
`

const PricesContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  text-align: left;
`

const BlockInfo = styled.div`
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  margin-top: 15px;
`

const BlockInfoTitle = styled.div`
  color: #757575;
  font-size: 14px;
  font-weight:bold;
  margin-bottom: 10px;
`

const BlockInfoItem = styled.div`
  color: #9e9e9e;
  font-size: 12px;
`
