import React from 'react'
import classNames from 'classnames'
import 'styles/Tabs/Tab.css'

export default function Tab ({ children, disabled, isActive, ...props }) {
  const className = classNames({
    Tab: true,
    isActive
  })

  return (
    <button
      type='button'
      className={className}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
