import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from 'components/Icon'
import { range } from 'range'
import { noop } from 'utils/helpers'
import 'styles/StarRating.css'

export default class StarRating extends Component {
  state = {
    hoveredIndex: null
  }

  setHoveredIndex = hoveredIndex => {
    this.setState({ hoveredIndex })
  }

  onChange = value => {
    this.setState({ hoveredIndex: null })
    this.props.onChange(value)
  }

  render () {
    const { hoveredIndex } = this.state
    const { value } = this.props
    return (
      <div className='StarRating'>
        <span className='StarRating-label'>
          {this.props.label}
        </span>
        <div className='StarRating-stars'>
          {range(1, 6).map(index => (
            <StarRatingButton
              key={index}
              index={index}
              isActive={(
                value && !hoveredIndex
                  ? value >= index
                  : hoveredIndex >= index
              )}
              setHoveredIndex={this.setHoveredIndex}
              onChange={this.onChange}
            />
          ))}
        </div>
      </div>
    )
  }

  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func
  }

  static defaultProps = {
    label: 'Avalie:',
    value: PropTypes.number.isRequired,
    onChange: noop
  }
}

function StarRatingButton ({ index, isActive, setHoveredIndex, onChange }) {
  const className = classNames({
    StarRatingButton: true,
    isActive
  })

  return (
    <button
      type='button'
      className={className}
      onMouseOver={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
      onFocus={() => setHoveredIndex(index)}
      onBlur={() => setHoveredIndex(null)}
      onClick={() => onChange(index)}
    >
      <Icon name='star' />
    </button>
  )
}
