export const SITE_URL = process.env.REACT_APP_ESTRATEGIA_SITE_URL
export const API_URL = process.env.REACT_APP_ESTRATEGIA_API_URL
export const PERFIL_URL = process.env.REACT_APP_ESTRATEGIA_PERFIL_URL
export const REQUEST_TOKEN_URL = `${SITE_URL}/oauth/token/`
export const SITE_LOGIN_URL = `${SITE_URL}/loja/entrar/`
export const SITE_LOGOUT_URL = `${SITE_URL}/loja/sair/`
export const UPDATE_PASSWORD_URL = `${PERFIL_URL}/meus-dados/alterar-senha/`
export const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET
export const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID
export const GRANT_TYPE = process.env.REACT_APP_API_GRANT_TYPE

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]