import styled from 'styled-components'

export const Header = styled.section`
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  padding: 15px 15px 20px 0;

  :before {
    content: '';
    width: 50%;
    height: 20px;
    bottom: 0;
    right: 0;
    background: #D6E3F8;
    position: absolute;

    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  }  
`

// Box Fechar 
export const BoxFechar = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 20px;

  a {
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #64748B;
    height: 30px;
    width: 30px;
    border-radius: 5px;
  }
`

// Box Conteúdo
export const BoxConteudo = styled.div`
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px 0 30px;

  img {
    max-width: 100%;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

// Box Texto
export const BoxTexto = styled.div`
  float: left;
  width: 100%;
  flex: 1;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 40px;
  }
`

// Pré Título
export const PreTitulo = styled.span`
  float: left;
  width: 100%;
  color: #475569;
  font-size: 16px;
  font-family: 'Poppins';
`

// Título
export const Titulo = styled.span`
  float: left;
  width: 100%;
  color: #156EF3;
  font-size: 40px;
  font-family: 'Poppins';
  font-weight: bold;
  margin: 10px 0 15px 0;

  @media only screen and (max-width: 560px) {
    font-size: 30px;
  }
`

// Tag 
export const Tag = styled.span`
  float: left;
  color: #fff;
  font-size: 30px;
  font-family: 'Poppins';
  background: #FF6464;
  padding: 5px 8px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`

// Texto 
export const Texto = styled.p`
  float: left;
  color: #1E2A3B;
  font-size: 16px;
  font-family: 'Poppins';
  margin: 20px 0 0 0;
  line-height: 26px;
  width: 100%;

  strong {
    color: #156EF3;
    font-weight: bold;
  }

  br {
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }
`
