import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Field, FormSpy } from 'react-final-form'
import * as forms from 'utils/forms'
import Button from 'components/Button'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'
import Link from 'components/Link'
import Utils from 'api/Utils'
import 'styles/FormUser.css'

export default class FormUserProfile extends Component {
  state = {
    isLoadingCepData: false
  }

  onCepChange = (cep, change) => {
    if (!(cep && /\d{8}/.test(cep))) return

    this.setState({ isLoadingCepData: true })

    Utils.queryCep(cep)
      .then(data => {
        this.setState({ isLoadingCepData: false })
        const fields = ['bairro', 'cidade', 'uf', 'logradouro']
        fields.forEach(field => (
          change(`endereco.${field}`, data[field])
        ))
      })
      .catch(() => {
        this.setState({ isLoadingCepData: false })
      })
  }

  render () {
    const { onSubmit, isSubmitting, initialValues, ...props } = this.props
    const { isLoadingCepData } = this.state
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, pristine, change }) => (
          <form onSubmit={handleSubmit} className='FormUser'>
            <FormSpy
              subscription={{ active: true, values: true }}
              onChange={({ active, values }) => {
                if (active === 'endereco.cep' && values) {
                  this.onCepChange(values.endereco.cep, change)
                }
              }}
            />
            <div className='FormUser-grid'>
              <div className='FormUser-grid-column'>
                <Field
                  name='nome'
                  type='text'
                  label='Nome'
                  component={CustomField}
                  {...forms.required}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='email'
                  type='email'
                  label='E-mail'
                  disabled
                  component={CustomField}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='cpf'
                  type='tel'
                  label='CPF'
                  component={CustomField}
                  disabled
                  {...forms.cpfRequired}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='data_nascimento'
                  type='tel'
                  label='Data de nascimento'
                  component={CustomField}
                  {...forms.dateRequired}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='telefone'
                  type='tel'
                  label='Celular'
                  component={CustomField}
                  {...forms.phone}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='endereco.cep'
                  type='tel'
                  label='CEP'
                  component={CustomField}
                  {...forms.cepRequired}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='endereco.logradouro'
                  type='text'
                  label='Endereço'
                  component={CustomField}
                  {...forms.required}
                  disabled={isLoadingCepData}
                />
              </div>
              <div className='FormUser-grid-column -two-fields'>
                <div className='FormUser-grid-column'>
                  <Field
                    name='endereco.numero'
                    type='tel'
                    label='Número'
                    component={CustomField}
                  />
                </div>
                <div className='FormUser-grid-column'>
                  <Field
                    name='endereco.complemento'
                    type='text'
                    label='Complemento'
                    component={CustomField}
                  />
                </div>
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='endereco.uf'
                  label='Estado'
                  component={CustomField}
                  renderWith='select'
                  {...forms.required}
                  disabled={isLoadingCepData}
                >
                  <option value='' disabled>Selecione um estado</option>
                  <option value='AC'>Acre</option>
                  <option value='AL'>Alagoas</option>
                  <option value='AP'>Amapá</option>
                  <option value='AM'>Amazonas</option>
                  <option value='BA'>Bahia</option>
                  <option value='CE'>Ceará</option>
                  <option value='DF'>Distrito Federal</option>
                  <option value='ES'>Espírito Santo</option>
                  <option value='GO'>Goiás</option>
                  <option value='MA'>Maranhão</option>
                  <option value='MT'>Mato Grosso</option>
                  <option value='MS'>Mato Grosso do Sul</option>
                  <option value='MG'>Minas Gerais</option>
                  <option value='PA'>Pará</option>
                  <option value='PB'>Paraíba</option>
                  <option value='PR'>Paraná</option>
                  <option value='PE'>Pernambuco</option>
                  <option value='PI'>Piauí</option>
                  <option value='RJ'>Rio de Janeiro</option>
                  <option value='RN'>Rio Grande do Norte</option>
                  <option value='RS'>Rio Grande do Sul</option>
                  <option value='RO'>Rondônia</option>
                  <option value='RR'>Roraima</option>
                  <option value='SC'>Santa Catarina</option>
                  <option value='SP'>São Paulo</option>
                  <option value='SE'>Sergipe</option>
                  <option value='TO'>Tocantins</option>
                </Field>
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='endereco.cidade'
                  type='text'
                  label='Cidade'
                  component={CustomField}
                  {...forms.required}
                  disabled={isLoadingCepData}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='endereco.bairro'
                  type='text'
                  label='Bairro'
                  component={CustomField}
                  {...forms.required}
                  disabled={isLoadingCepData}
                />
              </div>
              <div className='FormUser-grid-column'>
                <Field
                  name='senha'
                  type='text'
                  label='Senha'
                  disabled
                  component={CustomField}
                  SubComponent={() => (
                    <Link
                      renderWith='button'
                      size='small'
                      type='button'
                      onClick={props.linkUpdatePassword}
                    >
                      alterar senha
                    </Link>
                  )}
                />
              </div>
            </div>
            <FormGroup right>
              <Button type='submit' disabled={isSubmitting || pristine}>
                {!isSubmitting ? 'Salvar alterações' : 'Processando...'}
              </Button>
            </FormGroup>
          </form>
        )}
      </Form>
    )
  }

  static propTypes = {
    /** Callback do form submit */
    onSubmit: PropTypes.func.isRequired,

    /** Form sendo processado */
    isSubmitting: PropTypes.bool.isRequired
  }
}
