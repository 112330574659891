import React, { Component } from 'react'
import Spinner from 'components/Spinner'
import Text from 'components/Text'
import Section from 'components/Section'
import Matriculas from 'components/Matriculas'
import Client from 'modules/Client'

export default class MatriculasDisponiveis extends Component {
  state = {
    data: [],
    pagination: {},
    loading: true,
    error: false
  }

  async loadProdutos (params) {
    try {
      const response = await Client.get('/api/aluno/assinatura/disponiveis', {
        params
      })
      this.setState({
        data: response.data,
        pagination: response.meta,
        loading: false
      })
    } catch (err) {
      this.setState({ error: true, loading: false })
    }
  }

  componentDidMount () {
    this.loadProdutos()
  }

  render () {
    if (this.state.error) {
      return <Text>Algo de errado aconteceu!</Text>
    }

    if (this.state.loading) {
      return <Spinner />
    }

    return (
      <Section title='Assinaturas disponíveis'>
        <Text>
          Você ainda não possui assinatura ou seu pedido não está liberado. Confira as opções abaixo para comprar
          uma nova assinatura:
        </Text>
        <Matriculas
          produtos={this.state.data}
          pagination={this.state.pagination}
          disabled={this.state.loading}
          details
          onPageChange={page => this.loadProdutos({
            page
          })}
        />
      </Section>
    )
  }
}
