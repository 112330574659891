/**
 * Dependencies.
 */

import Certificates from 'api/Certificates'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Actions types and creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('certificates', Certificates, 'Certificado')
