import React from 'react'
import Screen from 'containers/Screen'
import UserDetails from 'containers/UserDetails'
import PlanoCorrente from '../components/renovacaoAssinatura/PlanoCorrente'
import PlanoDisponivel from '../components/renovacaoAssinatura/PlanoDisponivel'
import PlanoVantagens from '../components/renovacaoAssinatura/PlanoVantagens'

export default function RenovacaoAssinatura () {
  return (
    <Screen
      displayHeader={false}
      title='Renovação de assinatura'
      setHeadTitle
    >
      <UserDetails>
        {details => {
          return (
            <React.Fragment>
              {details.renovar_assinatura.atual ? <PlanoCorrente dados={details.renovar_assinatura.atual} /> : null}
              <PlanoDisponivel />
              {details.renovar_assinatura.atual ? <PlanoVantagens planos={details.renovar_assinatura.planos} /> : null}
            </React.Fragment>
          )
        }}
      </UserDetails>
    </Screen>
  )
}
