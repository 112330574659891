import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'ramda'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Fetch, clearResponseCache } from 'react-request'
import objectPath from 'object-path'
import isErrorCode from 'is-error-code'
import qs from 'querystringify'
import Spinner from 'components/Spinner'
import { pushNotification } from 'store/ducks/notifications'
import expand from 'utils/expand'
import { API_URL } from 'config/constants'

class FetchApi extends Component {
  constructor(props) {
    super(props)
    this.onClearCache = this.onClearCache.bind(this)
  }

  state = {
    redirectTo: '',
    body: {}
  }

  initialUrl = `${this.props.baseURL}/${this.props.url}${qs.stringify(this.props.params, true)}`

  requestHeaders = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    ...this.props.headers
  }

  handleResponse = (err, response) => {
    // Ignore request aborts
    if (FetchApi.isAbortError(err)) {
      return
    }

    const responseMessage = objectPath.get(
      response,
      'data.message'
    )

    if (FetchApi.isResponseSuccessful(err, response)) {
      if (this.props.redirectAfterSuccess) {
        this.setState({
          redirectTo: this.props.redirectAfterSuccess(response.data)
        })
      } else if (this.props.successfulMessage || responseMessage) {
        const message = this.props.successfulMessage
          ? this.props.successfulMessage(response.data)
          : responseMessage

        this.props.push(
          message,
          'success'
        )
      }
      this.props.onSuccessfulResponse(response.data, this.body)
    } else {
      this.props.push(
        responseMessage || 'Um erro aconteceu',
        'error'
      )
      this.props.onFailureResponse(response, this.body)
    }

    this.props.onResponse(err, response)
  }

  onClearCache = () => {
    clearResponseCache();
  }

  customDoFetch = doFetch => async (config = {}, callback) => {
    const {
      body,
      onSuccess = () => null,
      onFailure = () => null,
      ...rest
    } = config

    this.body = body

    const response = body
    ? await doFetch({
      body: this.props.serializer(body),
      ...rest
    })
    : await doFetch(rest)

    if (FetchApi.isResponseSuccessful(null, response)) {
      onSuccess(response)
    } else {
      onFailure(response)
    }

    if (callback) {
      return callback(response)
    }

    return expand(objectPath.get(response, 'data.errors'))
  }

  renderChildren = ({ doFetch, response, error, onClearCache, ...rest }) => {
    const data = FetchApi.isResponseSuccessful(error, response)
      ? response.data
      : null

    if (this.props.spinner && rest.fetching) {
      return <Spinner />
    }

    return this.props.children({
      ...rest,
      data,
      headers: this.requestHeaders,
      initialUrl: this.initialUrl,
      doFetch: this.customDoFetch(doFetch),
      onClearCache: this.onClearCache
    })
  }

  render () {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }

    const props = FetchApi.filterProps(this.props)

    return (
        <Fetch
          url={this.initialUrl}
          onResponse={this.handleResponse}
          cacheResponse={this.cacheResponse || false}
          headers={this.requestHeaders}
          children={this.renderChildren}
          {...props}
        />
    )
  }

  static filterProps = omit([
    'url',
    'headers',
    'onResponse',
    'notifications',
    'serializer',
    'children'
  ])

  static isAbortError (err) {
    return err && err.name === 'AbortError'
  }

  static isResponseSuccessful (err, response) {
    return !err &&
      response &&
      response.data != null &&
      !isErrorCode(response.status) &&
      !response.failed
  }

  static propTypes = {
    /** Redirect after success */
    redirectAfterSuccess: PropTypes.func,

    /** Data serializer */
    serializer: PropTypes.func,

    /** Successful response handler */
    onSuccessfulResponse: PropTypes.func,

    /** Failure response handler */
    onFailureResponse: PropTypes.func,

    /** Successful message */
    successfulMessage: PropTypes.func,

    /** Displays a spinner when fetching */
    spinner: PropTypes.bool,

    /** Clear cache*/
    onClearCache: PropTypes.func,

    /** Cache response */
    cacheResponse: PropTypes.bool,

    /** List of query params */
    params: PropTypes.object,

    /** Base URL */
    baseURL: PropTypes.string
  }

  static defaultProps = {
    serializer: data => JSON.stringify(data),
    onSuccessfulResponse: () => {},
    onFailureResponse: () => {},
    onResponse: () => {},
    children: () => null,
    baseURL: API_URL,
    cacheResponse: false
  }
}

export default connect(
  null,
  { push: pushNotification }
)(FetchApi)
