import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { required, phoneRequired, emailRequired } from 'utils/forms'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'

export default function FormInviteFriend ({ onSubmit, isSubmitting }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Field
              name='nome'
              type='text'
              label='Nome do seu amigo:'
              component={CustomField}
              {...required}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name='telefone'
              type='tel'
              label='Telefone do seu amigo:'
              component={CustomField}
              {...phoneRequired}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name='email'
              type='email'
              label='E-mail do seu amigo:'
              component={CustomField}
              {...emailRequired}
            />
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Enviar Indicação' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormInviteFriend.propTypes = {
  /** Callback do form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired
}
