import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 100%;
  color: #A9A9A9;
  margin-top: 30px;
`

export const PrecoDe = styled.span`
  float: left;
  width: 100%;
  color: #A9A9A9;
  font-size: 13px;
  text-decoration: line-through;
`

export const PrecoPor = styled.span`
  float: left;
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  margin: 5px 0;
`

export const Link = styled.a`
  float: left;
  background-color: #D59A04;
  border: 0;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  span {
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
  }
`
