/**
 * Dependencies.
 */

import isEmail from 'is-email'
import isCPF from 'iscpf'
import { isValid, parse } from 'date-fns'
import memoize from 'lodash/memoize'
import PasswordValidator from 'password-validator'
import getDigits from 'utils/getDigits'

/**
 * Required.
 */

export const required = value =>
  typeof value !== 'number' && !value && 'Campo obrigatório'

/**
 * E-mail.
 */

export const email = value => value && !isEmail(value) &&
  'E-mail inválido'

/**
 * Phone.
 */

const isValidPhone = value => {
 return value && value.length === 11 && value[2] === '9';
}

export const phone = value => {
  return !isValidPhone(value) &&
    'Celular inválido'
}

/**
 * CPF.
 */

export const cpf = value => value && !isCPF(value) &&
  'CPF inválido'

/**
 * CEP.
 */

export const cep = value => value && !/\d{8}/.test(value) &&
  'CEP inválido'

/**
 * Date.
 */

export const date = value => value && getDigits(value).length === 8 && !isValid(parse(value)) &&
  'Data inválida'

/**
 * Equal to.
 */

export const equalTo = memoize(({ field, label }) => (value, allValues) =>
  value !== allValues[field] &&
    `Campo diferente de ${label}`
)

/**
 * Password.
 */

const passwordSchema = new PasswordValidator()
passwordSchema
  .is().min(5)

export const password = value => value && !passwordSchema.validate(value) &&
  'Precisa conter pelo menos 5 caracteres'

/**
 * Card CVC.
 */

export const cardCVC = value => value && !/\d+/.test(value) &&
  'Código inválido'

/**
 * File Type.
 */

export const fileType = memoize(types => file =>
  file && !types.includes(file.type) &&
  'Tipo de arquivo não suportado'
)

/**
 * File Size.
 */

export const fileSize = memoize(size => file =>
  file && file.size > size &&
  `Arquivo não pode ser maior que ${size / 1000000}MB`
)
