import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardItemCurso from 'components/CardItemCurso'
import VerticalSpacer from 'components/VerticalSpacer'
import Icon from 'components/Icon'
import { Wrapper, Options, Option } from './styles'
import { Container, PaginationContainer } from 'components/CursosList/styles'
import Pagination from 'components/Pagination'

const CARDS = 'CARDS'
const LIST = 'LIST'

export default class Matriculas extends Component {
  state = {
    layout: CARDS
  }

  handleLayoutChange = layout => {
    if (this.state.layout !== layout) {
      this.setState({ layout })
    }
  }

  render () {
    const {
      produtos,
      pagination,
      disabled,
      details,
      onSubscribe,
      onUnsubscribe,
      onPageChange
    } = this.props

    const { layout } = this.state

    return (
      <div>
        <Options>
          { pagination && pagination.last_page > 1 
            ? <small style={{width:'100%'}}>Mostrando {pagination.per_page} de {(pagination && pagination.total) || 0} resultados</small>
            : null
          }
          <Option selected={layout === CARDS} onClick={() => this.handleLayoutChange(CARDS)}>
            <Icon name='slides' />
          </Option>
          <Option selected={layout === LIST} onClick={() => this.handleLayoutChange(LIST)}>
            <Icon name='list' />
          </Option>
        </Options>
        <Wrapper>
          <div>
            {produtos.length > 0 ? (
              <CondWrapper layout={layout}>
                {produtos.map(p => (
                  <CardItemCurso
                    key={p.id}
                    title={p.nome}
                    subtitle={p.destaque ? 'Destaque' : null}
                    url={details ? `/curso/${p.permalink}` : null}
                    subscribed={p.inscrito}
                    onClick={() => p.inscrito ? onUnsubscribe(p) : onSubscribe(p)}
                    disabled={disabled}
                    list={layout === LIST}
                  />
                ))}
              </CondWrapper>
            ) : 'Refine sua busca adicionando palavras chave. ex: pacote, 2020, pós edital'}

            {
              pagination && pagination.last_page > 1  && (
                <PaginationContainer>
                  <Pagination
                    center
                    current={pagination.current_page}
                    total={pagination.last_page}
                    onChange={onPageChange}
                  />
                </PaginationContainer>
              )
            }
          </div>
        </Wrapper>
      </div>
    )
  }

  static propTypes = {
    produtos: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    disabled: PropTypes.bool,
    onSubscribe: PropTypes.func,
    onUnsubscribe: PropTypes.func,
    onPageChange: PropTypes.func,
    details: PropTypes.bool
  }
}

const CondWrapper = ({ layout, ...props }) =>
  layout === LIST
    ? <VerticalSpacer {...props} />
    : <Container {...props} />
