import styled from 'styled-components'

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: left;
    width: 100%;
    margin: 0 0 20px 0;

    @media only screen and (max-width: 1000px) {
        display: inline-block;
    }
`

// Box Total

export const BoxTotal = styled.div`
    width: 25%;
    padding: 20px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0 2px 5px 0 #0000000c;
    border: solid 1px #e5e5e5;

    @media only screen and (max-width: 1000px) {
        width: 100%;
        margin-bottom: 20px;
        height: auto;
    }
`

export const IconeLista = styled.img`
    width: 30px;
`

export const TituloTotal = styled.span`
    font-size: 16px;
    font-weight: bold;
    margin: 0 15px;
`

export const NumeroTotal = styled.span`
    color: #FF6A58;
    font-weight: bold;
    font-size: 26px;
`

// Box Datas

export const BoxData = styled.div`
    width: 45%;
    padding: 20px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 2px 5px 0 #0000000c;
    border: solid 1px #e5e5e5;

    input {
        width: 38%;
        padding: 12px;
        border: solid 1px #dfdfdf;
        font-size: 14px;
        border-radius: 3px;
        outline: none;
        
        @media only screen and (max-width: 500px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .required {
        border: solid 1px red;
    }

    @media only screen and (max-width: 1000px) {
        width: 100%;
    }

    @media only screen and (max-width: 500px) {
        display: inline-block;
        height: auto;
    }
`

export const InputButton = styled.button`
    width: 20%;
    background: #FF6A58;
    padding: 12px 15px;
    font-weight: bold;
    border: 0;
    border-radius: 3px;
    color: #fff;

    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
`

export const BoxDownloads = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0;
`

export const ListaDownloads = styled.ul`
    width: 100%;
    display: inline-block;
    padding: 0;
    margin: 0 0 0 0;
`

export const ItemDownload = styled.li`
    list-style: none;
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 2px 5px 0 #0000000c;
    border: solid 1px #e5e5e5;

    @media only screen and (max-width: 1000px) {
        display: inline-block;
    }
`

export const BlocoNome = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 1000px) {
        float: left;
        width: 100%;
        margin-bottom: 20px;
        border-bottom: solid 1px #ddd;
        padding-bottom: 20px;
    }
`

export const IconeNome = styled.img`
    width: 40px;
    margin-right: 15px;
`

export const BlocoNomeTexto = styled.div`

`

export const NomeCurso = styled.span`
    float: left;
    clear: both;
    font-weight: bold;
    width: 100%;
    margin-bottom: 7px;
    line-height: 150%;
`

export const NomeAula = styled.span`
    color: #999;
    font-size: 14px;
`

export const BlocoTotalDownloads = styled.div`
    padding: 0 30px;
    text-align: center;

    @media only screen and (max-width: 1000px) {
        float: left;
        width: 33.333%;
        padding: 0;
    }

    @media only screen and (max-width: 600px) {
        float: left;
        width: 100%;
        text-align: left;
    }
`

export const NumeroDownloads = styled.span`
    color: #FF6A58;
    float: left;
    width: 100%;
    font-weight: bold;
    font-size: 26px;
`

export const TextoDownloads = styled.span`
    color: #999;
    font-size: 14px;
    margin-top: 5px;
    float: left;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
        text-align: left;
    }
`

export const BlocoData = styled.div`
    text-align: center;

    @media only screen and (max-width: 1000px) {
        float: left;
        width: 33.333%;
    }

    @media only screen and (max-width: 600px) {
        float: left;
        width: 100%;
        margin-top: 20px;
        text-align: left;
    }
`

export const NumeroData = styled.span`
    color: #FF6A58;
    float: left;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
`

export const TextoData = styled.span`
    color: #999;
    font-size: 14px;
    margin-top: 5px;
    float: left;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
        text-align: left;
    }
`

// Paginação

export const PaginationContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100% !important;
`

