import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as dateFns from 'date-fns'
import mq from 'utils/mediaQuery'

export default function Countdown ({ to, onFinish, date }) {
  const [ now, setNow ] = useState(date)

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (dateFns.differenceInSeconds(to, now) <= 0) {
        return onFinish()
      }

      setNow(dateFns.addSeconds(
        now,
        1
      ))
    }, 1000)

    return () => window.clearInterval(interval)
  }, [now])

  const countdown = getCountdown(to, now)

  return (
    <Container>
      {/* <Title>Acaba em</Title> */}
      <Title>Esta oferta se encerra em</Title>
      <Row>
        <Column>
          <Time>{formatTime(countdown.day)}</Time>
          <Label>dias</Label>
        </Column>
        <Column>
          <Time>{formatTime(countdown.hour)}</Time>
          <Label>horas</Label>
        </Column>
        <Column>
          <Time>{formatTime(countdown.minute)}</Time>
          <Label>minutos</Label>
        </Column>
        <Column>
          <Time>{formatTime(countdown.second)}</Time>
          <Label>segundos</Label>
        </Column>
      </Row>
    </Container>
  )
}

Countdown.propTypes = {
  to: PropTypes.instanceOf(Date),
  onFinish: PropTypes.func.isRequired
}

const Container = styled.div`
  background-color: #fff;
  float: left;
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mq.lessThan('650px')`flex-direction: column;`}
`

const Row = styled.div`
  display: flex;
`

const Column = styled.div`
  width: 80px;
  border: solid 1px #156EF3;
  border-radius: 4px;
  text-align: center;
  position: relative;
  padding: 5px 0;
  ${mq.lessThan('650px')`width: 50px;`}

  + div {
    margin-left: 10px;
    ${mq.lessThan('650px')`margin-left: 5px;`}
  }
  
  /* &:not(:last-child) {
    &::after {
      position: absolute;
      top: 8px;
      right: -1px;
      content: ":";
    }
  } */
`

const Time = styled.div`
  font-size: 28px;
  margin-bottom: 2px;
  font-weight: bold;
  color: #156EF3;
  ${mq.lessThan('600px')`font-size: 20px;`}
`

const Label = styled.div`
  font-size: 12px;
  color: #475569;
  ${mq.lessThan('650px')`font-size: 10px;`}
`

const Title = styled.div`
  color: #475569;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
  ${mq.lessThan('650px')`margin-bottom: 15px;`}
  ${mq.lessThan('650px')`margin-right: 0;`}
  ${mq.lessThan('650px')`font-size: 14px;`}
`

function getCountdown (to, now) {
  let result = {}
  const parts = ['day', 'hour', 'minute', 'second']

  parts.forEach((p, i) => {
    let uP = p.charAt(0).toUpperCase() + p.slice(1)
    let t = dateFns[`differenceIn${uP}s`](to, now)
    if (t) {
      result[p] = t
      if (i < parts.length)
        to = dateFns[`sub${uP}s`](to, t)
    }
  })

  return result
}

const formatTime = time => {
  if (!time) {
    return '00'
  }

  return time < 10 ? `0${time}` : time
}
