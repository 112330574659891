import React from 'react'
import PropTypes from 'prop-types'

const cardImages = {
  visa: require('../images/cards/visa.png'),
  mastercard: require('../images/cards/mastercard.png'),
  discover: require('../images/cards/discover.png'),
  elo: require('../images/cards/elo.png'),
  amex: require('../images/cards/amex.png'),
  diners: require('../images/cards/diners.png'),
  hipercard: require('../images/cards/hipercard.png')
}

export default function CardBrand ({ brand = '' }) {
  if (!cardImages.hasOwnProperty(brand)) return null

  return (
    <img
      src={cardImages[brand]}
      alt={brand}
    />
  )
}

CardBrand.propTypes = {
  brand: PropTypes.string
}
