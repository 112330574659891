import React from 'react'
import Box from 'components/Box'
import Link from 'components/Link'
import Image from 'components/Image'
import FetchAuth from 'components/FetchAuth'
import Text from 'components/Text'
import { CommunitiesContainer, CommunityContainer, CommunityContent, CommunityImage } from './styles'

export default function CommunitiesList({tipo}) {
  return (
    <CommunitiesContainer>
      <FetchAuth
        url={`api/aluno/comunidade?tipo=${tipo}`}
        spinner
      >
        {({ data }) => data && data.data && data.data.map((community) => (
          <CommunityContainer key={community.id}>
            <Link
              href={community.url}
              target='_blank'
              renderWith='a'
            >
            <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px 10px',
                }}
              >
                <CommunityImage>
                  <Image
                    src={community.imagem}
                    alt={`Ícone da comunidade ${community.nome}`}
                    center
                  />
                </CommunityImage>

                <CommunityContent>
                  <Text
                    component='h4'
                  >
                    {community.nome}
                  </Text>

                  <Text
                    component='p'
                    style={{
                      marginTop: 0
                    }}
                  >
                    {community.quantidade} participante{community.quantidade !== 1 ? 's' : ''}
                  </Text>
                </CommunityContent>
              </Box>
            </Link>
          </CommunityContainer>
        ))}
      </FetchAuth>
    </CommunitiesContainer>
  )
}
