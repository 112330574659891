import React from 'react'
import Screen from 'containers/Screen'
import Agenda from 'containers/Agenda'

export default function AgendaScreen () {
  return (
    <Screen title='Agenda' setHeadTitle>
      <Agenda />
    </Screen>
  )
}
