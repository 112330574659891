import { Component } from 'react'

export default class HeadTitle extends Component {
  componentDidMount () {
    document.title = `${this.props.title} - Estratégia Concursos`
  }

  componentWillUnmount () {
    document.title = 'Área do Aluno - Estratégia Concursos'
  }

  render () {
    return null
  }
}
