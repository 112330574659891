import React from 'react'
import { Form, Field } from 'react-final-form'
import { required } from 'utils/forms'
import Button from 'components/Button'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'
import Text from 'components/Text'

export default function FormCourseEvaluation ({ onSubmit, isSubmitting }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Field
              name='codigo'
              label='Tipo de avaliação'
              component={CustomField}
              renderWith='select'
              {...required}
            >
              <option value=''>Selecione um tipo de avaliação</option>
              <option value='EL'>Elogio</option>
              <option value='SG'>Sugestão</option>
              <option value='CR'>Crítica</option>
            </Field>
          </FormGroup>
          <FormGroup>
            <Field
              name='comentario'
              label='Conte-nos o que você achou do curso'
              component={CustomField}
              renderWith='textarea'
              rows={10}
              {...required}
            />
          </FormGroup>
          <FormGroup>
            <Text>A resposta será enviada por email.</Text>
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Enviar' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}
