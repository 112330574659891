import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CollapseHeader, CollapseBody } from './index'
import 'styles/Collapse.css'

export default class Collapse extends Component {
  toggle = () => {
    if (this.props.disabled) return
    this.props.onToggle()
  }

  render () {
    const isOpened = !this.props.disabled && this.props.isOpened
    
    const children = React.Children.map(this.props.children, child => {
      if (child.type === CollapseHeader) {
        return React.cloneElement(child, {
          isOpened,
          onClick: this.toggle
        })
      }

      if (child.type === CollapseBody) {
        return React.cloneElement(child, {
          isOpened,
          unmountedClosed: this.props.unmountedClosed,
          onOpen: this.props.onOpen
        })
      }

      return child
    })

    const className = classNames({
      Collapse: true,
      isDisabled: this.props.disabled,
      isOpened: this.props.isOpened
    })

    return (
      <div className={className}>
        {children}
      </div>
    )
  }

  static propTypes = {
    disabled: PropTypes.bool,
    unmountedClosed: PropTypes.bool,
    onToggle: PropTypes.func,
    onOpen: PropTypes.func,
    isOpened: PropTypes.bool
  }

  static defaultProps = {
    isOpened: false,
    onToggle: () => {}
  }
}
