import styled, { css } from 'styled-components'
import { darken } from 'polished'

export const Wrapper = styled.div`
  margin: 35px 0;
  margin-top: 15px;
  padding: 35px 0;  
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
`

export const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`

export const Option = styled.button.attrs({
  type: 'button'
})`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  width: 35px;
  height: 35px;
  font-size: 20px;
  background-color: ${props => props.theme.colors.grayLight};
  margin-left: 5px;

  &:hover {
    ${props => !props.selected && css`
      background-color: ${props => darken(0.15, props.theme.colors.grayLight)};
    `}
  }

  ${props => props.selected && css`
    background-color: #FF6A58;
    color: #fff;
  `}
`
