import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import striptags from 'striptags'
import strip from 'strip'
import { dateToBR } from 'utils/helpers'
import { Collapse, CollapseHeader, CollapseBody } from 'components/Collapse'
import Box from 'components/Box'
import Avatar from 'components/Avatar'
import Badge from 'components/Badge'
import 'styles/MessageCollapse.css'

export default function MessageCollapse (props) {
  const className = classNames({
    MessageCollapse: true,
    '-unread': props.unread
  })

  return (
    <div className={className}>
      <Box>
        <Collapse onOpen={props.onOpen}>
          <CollapseHeader arrow>
            {({ isOpened }) => (
              <div className='MessageCollapse-header'>
                <div className='MessageCollapse-header-avatar'>
                  <Avatar
                    src={props.authorAvatar}
                    name={props.authorName}
                    width={56}
                    height={56}
                  />
                </div>
                <div className='MessageCollapse-header-info'>
                  <div className='MessageCollapse-header-info-content'>
                    <span className='MessageCollapse-header-info-content-author'>
                      {props.authorName}
                    </span>
                    <span className='MessageCollapse-header-info-content-date'>
                      {dateToBR(props.date)}
                    </span>
                    {props.unread && (
                      <Badge>Não lida</Badge>
                    )}
                  </div>
                  {props.course && (
                    <span className='MessageCollapse-header-info-content-course'>{props.course}</span>
                  )}
                  {props.product && (
                    <span className='MessageCollapse-header-info-content-course'>{props.product}</span>
                  )}
                  {props.subject && (
                    <span
                      className='MessageCollapse-header-info-resume'
                      dangerouslySetInnerHTML={{ __html: strip(props.subject) }}
                    />
                  )}
                </div>
              </div>
            )}
          </CollapseHeader>
          <CollapseBody>
            <div
              className='MessageCollapse-body'
              dangerouslySetInnerHTML={{ __html: props.body }}
            />
          </CollapseBody>
        </Collapse>
      </Box>
    </div>
  )
}

MessageCollapse.propTypes = {
  authorName: PropTypes.string.isRequired,
  authorAvatar: PropTypes.string,
  date: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired
}
