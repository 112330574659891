import React from 'react'

export default function FormError ({ children }) {
  return (
    <span style={styles}>{children}</span>
  )
}

const styles = {
  color: '#d83f33',
  fontSize: 12
}
