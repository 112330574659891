import styled from 'styled-components'

export const Container = styled.section`
  float: left;
  width: 100%;
  background: #000000;
  padding: 0 30px 30px 30px;

  display: flex;
  align-items: initial;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    display: inline-block;
    padding: 0 15px 30px 15px;
  }
`
