import createActionTypes from 'store/utils/createActionTypes'

export default function createResourceActions (resource, Api, readableName = 'recurso', handleFeedback = true) {
  const actionTypes = createActionTypes(resource.toUpperCase(), [
    'FETCH_ALL',
    'FETCH_ALL_PENDING',
    'FETCH_ALL_FULFILLED',
    'FETCH_ALL_REJECTED',

    'CREATE',
    'CREATE_PENDING',
    'CREATE_FULFILLED',
    'CREATE_REJECTED',

    'UPDATE',
    'UPDATE_PENDING',
    'UPDATE_FULFILLED',
    'UPDATE_REJECTED',

    'REMOVE',
    'REMOVE_PENDING',
    'REMOVE_FULFILLED',
    'REMOVE_REJECTED'
  ])

  const fetchAll = params => (dispatch, getState) => {
    const state = getState()
    if (state.resources[resource].loadedPages.includes(params.page || 1)) {
      return
    }

    dispatch({
      type: actionTypes.FETCH_ALL,
      meta: {
        handleError: handleFeedback,
        defaultErrorMessage: `Erro ao carregar lista de ${readableName}`
      },
      payload: Api.fetchAll(params)
    })
  }

  const create = (data, params, notificationsId) => ({
    type: actionTypes.CREATE,
    meta: {
      handleError: handleFeedback,
      defaultErrorMessage: `Erro ao criar ${readableName}!`,
      handleSuccess: handleFeedback,
      defaultSuccessMessage: `${readableName} criado com sucesso!`,
      notificationsId
    },
    payload: Api.create(data, params)
  })

  const update = (data, params) => ({
    type: actionTypes.UPDATE,
    meta: {
      handleError: handleFeedback,
      defaultErrorMessage: `Erro ao atualizar ${readableName}!`,
      handleSuccess: handleFeedback,
      defaultSuccessMessage: `${readableName} atualizado com sucesso!`
    },
    payload: Api.update(data, params)
  })

  const remove = (data, params) => ({
    type: actionTypes.REMOVE,
    meta: {
      handleError: handleFeedback,
      defaultErrorMessage: `Erro ao remover ${readableName}!`,
      handleSuccess: handleFeedback,
      defaultSuccessMessage: `${readableName} removido com sucesso!`
    },
    payload: Api.remove(data, params)
  })

  return {
    actionTypes,
    actionCreators: {
      fetchAll,
      create,
      update,
      remove
    }
  }
}
