/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import Pacotes from 'api/Pacotes'
import createActionTypes from 'store/utils/createActionTypes'

/**
 * ActionTypes.
 */
export const actionTypes = createActionTypes('PACOTES', [
  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED'
])

/**
 * Initial State.
 */
const initialState = {
  isFetching: false,
  isSubmitting: false,
  isListLoaded: false,
  data: {},
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {

  /**
   * Fetch One.
   */

  [actionTypes.FETCH_ONE_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      data: { $set: payload }
    })
  },

  [actionTypes.FETCH_ONE_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  }

})

/**
 * Action Creators.
 */

export const fetchOne = id => (dispatch, getState) => {

  // if (details(getState(), id)) return

  dispatch({
    type: actionTypes.FETCH_ONE,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar pacote!'
    },
    payload: Pacotes.fetchOne(id)
  })
}

/**
 * Selectors.
 */

export const isFetching = state => state.conteudo.pacotes.isFetching
export const isSubmitting = state => state.conteudo.pacotes.isSubmitting
export const data = (state, pacoteId) => state.conteudo.pacotes.data
export const isListLoaded = state => state.conteudo.pacotes.isListLoaded
