/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import Aulas from 'api/Aulas'
import createActionTypes from 'store/utils/createActionTypes'
import { getVideos } from './videos'

/**
 * Action Types.
 */

const cursosActionTypes = createActionTypes('CURSOS', [
  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED',

  'UPDATE_RATING',
  'UPDATE_RATING_PENDING',
  'UPDATE_RATING_FULFILLED',
  'UPDATE_RATING_REJECTED',

  'SEND_DISCURSIVE',
  'SEND_DISCURSIVE_PENDING',
  'SEND_DISCURSIVE_FULFILLED',
  'SEND_DISCURSIVE_REJECTED',

  'DOWNLOAD_LESSON_PDF'
])

export const actionTypes = createActionTypes('AULAS', [
  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED',

  'UPDATE_LESSON_VIEW',
  'UPDATE_LESSON_VIEW_PENDING',
  'UPDATE_LESSON_VIEW_FULFILLED',
  'UPDATE_LESSON_VIEW_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isFetching: false,
  entities: {},
  detailedEntities: [],
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * `Fech One` from `Cursos`.
   */

  [cursosActionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      entities: { $merge: payload.entities.aulas || {} }
    })
  },

  /**
   * `Fetch One` for Lesson.
   */

  [actionTypes.FETCH_ONE_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      entities: {
        [payload.result]: {
          $set: payload.entities.aulas[payload.result]
        }
      },
      detailedEntities: { $push: [ payload.result ] }
    })
  },

  [actionTypes.FETCH_ONE_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * `Update Lesson View`.
   */

  [actionTypes.UPDATE_LESSON_VIEW_PENDING] (state, { meta: { lessonId, viewed } }) {
    return update(state, {
      entities: {
        [lessonId]: {
          visualizada: { $set: viewed },
          isLessonViewUpdating: { $set: true }
        }
      }
    })
  },

  [actionTypes.UPDATE_LESSON_VIEW_REJECTED] (state, { meta: { lessonId, viewed } }) {
    return update(state, {
      entities: {
        [lessonId]: {
          visualizada: { $set: !viewed },
          isLessonViewUpdating: { $set: false }
        }
      }
    })
  },

  [actionTypes.UPDATE_LESSON_VIEW_FULFILLED] (state, { meta: { lessonId, viewed } }) {
    return update(state, {
      entities: {
        [lessonId]: {
          visualizada: { $set: viewed },
          isLessonViewUpdating: { $set: false }
        }
      }
    })
  }
})

/**
 * Action Creators.
 */

export const fetchOne = lessonId => (dispatch, getState) => {
  if (hasDetailedLesson(getState(), lessonId)) return

  dispatch({
    type: actionTypes.FETCH_ONE,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar aula!'
    },
    payload: Aulas.fetchOne(lessonId)
  })
}

export const updateLessonView = (lessonId, viewed) => ({
  type: actionTypes.UPDATE_LESSON_VIEW,
  meta: { lessonId, viewed },
  payload: Aulas.updateLessonView(lessonId, viewed)
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.conteudo.aulas.isFetching

export const hasDetailedLesson = (state, lessonId) =>
  state.conteudo.aulas.detailedEntities.includes(lessonId)

export const getLessons = state =>
  state.conteudo.aulas.entities

export const getDetailedLesson = (state, lessonId) =>
  hasDetailedLesson(state, lessonId)
    ? state.conteudo.aulas.entities[lessonId]
    : null

export const getLessonVideos = (state, lessonId) => {
  if (!hasDetailedLesson(state, lessonId)) {
    return []
  }

  return getDetailedLesson(state, lessonId).videos.map(id => (
    getVideos(state)[id]
  ))
}

export const isLessonViewUpdating = (state, lessonId) => {
  const lesson = getLessons(state)[lessonId]
  return lesson && lesson.isLessonViewUpdating
}

export const isLessonChecked = (state, lessonId) => {
  const lesson = getLessons(state)[lessonId]
  return lesson && lesson.visualizada
}
