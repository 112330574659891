import React from 'react'
import { isEmpty } from 'ramda'
import FetchAuth from 'components/FetchAuth'
import Cursos from 'components/Cursos'
import SemCursos from 'components/SemCursos'

export default function RequestCursos ({ empty: Empty, inativar, ...props }) {
  return (
    <FetchAuth
      url={`api/aluno/curso`}
      spinner
      cacheResponse={false}
      {...props}
    >
      {({ data }) => data && data.data ? (
        <Cursos inativar={inativar} data={transform(data.data)} />
      ) : (
        <Empty />
      )}
    </FetchAuth>
  )
}

RequestCursos.defaultProps = {
  empty: SemCursos
}

const transform = data => {
  if (isEmpty(data)) {
    return []
  }

  const cargos = data.cargos.reduce((memo, cargo) => {
    memo[cargo.id] = cargo
    return memo
  }, {
    '##': { id: '##', nome: 'Outros', descricao: '' }
  })

  return data.concursos.map(concurso => {
    const cargoCursos = { '##': [] }
    concurso.cursos.forEach(curso => {
      if (curso.id_cargos && curso.id_cargos.length > 0) {
        curso.id_cargos.forEach(id => {
          if (cargoCursos.hasOwnProperty(id)) {
            cargoCursos[id].push(curso)
          } else {
            cargoCursos[id] = [curso]
          }
        })
      } else {
        cargoCursos['##'].push(curso)
      }
    })

    return ({
      id: concurso.id,
      modalidadeActiveIndex: concurso.modalidadeActiveIndex,
      titulo: concurso.titulo,
      modalidades: concurso.modalidades,
      cargos: Object.keys(cargoCursos).map(id => ({
        ...cargos[id],
        cursos: cargoCursos[id]
      }))
    })
  })
}
