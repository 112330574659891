import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function LineBall ({ colors, text }) {
  return (
    <Container>
      <Line
        colors={[colors[0], colors[1]]}
      />
      <Ball color={colors[1]} style={{ fontSize: text === '∞' ? 18 : 12 }}>
        {text}
      </Ball>
      <Line
        colors={[colors[1], colors[2]]}
      />
    </Container>
  )
}

LineBall.propTypes = {
  colors: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 1px);
  margin-top: 25px;
`

const Line = styled.div`
  width: 50%;
  height: 4px;
  background-image: linear-gradient(to right, ${props => props.colors.join(', ')});
`

const Ball = styled.div`
  background-color: ${props => props.color};
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 12px;
  font-weight:bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
