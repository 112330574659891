import styled from 'styled-components'

export const Container = styled.div`
  float: left;
  width: 100%;
  background: #000000;
  padding: 80px 40px 40px 40px;
  margin-bottom: 40px;

  @media only screen and (max-width: 900px) {
    padding: 0px 15px 15px 15px;
    margin-bottom: 15px;
  }

`
export const Texto = styled.span`
  float: left;
  width: 100%;
  text-align: left;
  color: #A9A9A9;
  font-size: 13px;
  line-height: 170%;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`
