import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from 'components/SectionTitle'
import CargoSection from 'components/CargoSection'
import CursosList from 'components/CursosList'
import VerticalSpacer from 'components/VerticalSpacer'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs'

export default function ConcursoSection ({ concurso, inativar, ativar }) {

  return (
    <section>
      <Tabs activeIndex={concurso.modalidadeActiveIndex}>
        <TabList>
          {concurso.modalidades.map((modalidade, index) => (
            <Tab key={index}>{modalidade}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {concurso.modalidades.map((modalidade, index) => (
            <TabPanel key={index}>
              <Concurso
                concurso={concurso}
                tipo={modalidade}
                inativar={inativar}
                ativar={ativar}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </section>
  )
}

ConcursoSection.propTypes = {
  /** Objeto concurso que inclui seus cursos */
  concurso: PropTypes.object.isRequired
}

const Concurso = ({ concurso, tipo, inativar, ativar }) => {
  if (concurso.cargos.length === 1 && concurso.cargos[0].id === '##') {
    const cursos = concurso.cargos[0].cursos.filter(curso => curso.tipo === tipo)

    if (!cursos.length) {
      return null
    }

    return (
      <React.Fragment>
        <SectionTitle>{concurso.titulo}</SectionTitle>
        <CursosList
          cursos={cursos}
          inativar={inativar}
          ativar={ativar}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <SectionTitle>{concurso.titulo}</SectionTitle>
      <VerticalSpacer>
        {concurso.cargos.map(cargo => (
          <CargoSection
            key={cargo.id}
            cargo={cargo}
            tipo={tipo}
            inativar={inativar}
            ativar={ativar}
          />
        ))}
      </VerticalSpacer>
    </React.Fragment>
  )
}
