import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import loadScript from 'load-script2'

const SCRIPT_URL = 'https://c.la8cs.salesforceliveagent.com/content/g/js/39.0/deployment.js'
const CHAT_URL = 'https://d.la2-c1-dfw.salesforceliveagent.com/chat'
const SALESFORCE_ID = '572410000008ipU'
const SALESFORCE_ORG = '00D41000001Q9k8'
const BUTTON_ID = '573410000008l7C'

export default class SalesForceChat extends PureComponent {
  state = {
    enabled: false,
    loaded: false
  }

  setup () {
    const liveagent = this.liveagent = window.liveagent

    liveagent.init(CHAT_URL, SALESFORCE_ID, SALESFORCE_ORG)

    liveagent.addButtonEventHandler(BUTTON_ID, function (e) {
      if (e.BUTTON_AVAILABLE) {
        this.setState({ enabled: true })
      }
    })

    liveagent.showWhenOnline(BUTTON_ID, this.button)
  }

  openChat = () => {
    if (this.state.enabled) {
      this.liveagent.startChatWithWindow(BUTTON_ID, '_blank')
    } else {
      window.alert('Chat offline. Tente mais tarde!')
    }
  }

  componentDidMount () {
    const button = this.button = document.createElement('button')
    const wrapper = document.createElement('div')
    button.id = `liveagent_button_online_${BUTTON_ID}`
    wrapper.style.display = 'none'
    wrapper.appendChild(button)
    document.body.appendChild(wrapper)

    loadScript(SCRIPT_URL, err => {
      if (err || !window.liveagent) return
      this.setup()
      this.setState({ loaded: true })
    })
  }

  render () {
    return this.props.children({
      isLoaded: this.state.loaded,
      openChat: this.openChat
    })
  }

  static propTypes = {
    children: PropTypes.func.isRequired
  }
}
