import React from 'react'
import FormGroup from 'components/FormGroup'
import Text from 'components/Text/Text'
import Link from 'components/Link'
import Input from 'components/Input'
import Button from 'components/Button'
import Box from 'components/Box'
import { SITE_URL } from 'config/constants'

export default function SemCursos () {
  return (
    <Box style={{ textAlign: 'center' }} padded>
      <div style={{ maxWidth: 600, margin: '0 auto' }}>
        <form action={`${SITE_URL}/pesquisa`}>
          <FormGroup>
            <Text>
              Você não tem nenhum curso disponível!
            </Text>
            <Text>
              <Link href={`${SITE_URL}/cursos`}>Clique aqui</Link> para ir à loja ou use a busca abaixo para pesquisar um curso:
            </Text>
          </FormGroup>
          <FormGroup>
            <Input name='q' placeholder='Pesquisar curso' required />
          </FormGroup>
          <FormGroup right>
            <Button type='submit'>
              Pesquisar curso
            </Button>
          </FormGroup>
        </form>
      </div>
    </Box>
  )
}
