import React, { Fragment } from 'react'
import Screen from 'containers/Screen'
import EstrategiaUltimaAula from 'containers/EstrategiaUltimaAula'
import AulasHistorico from 'components/AulasHistorico'
import 'styles/EstrategiaUltimaAula.css'

export default function EstrategiaUltimaAulasScreen () {
  return (
    <Screen title='Últimas Aulas Acessadas'>
      <EstrategiaUltimaAula showSpinner>
        {({ data, isSubmitting }) => (
          <Fragment>
            <AulasHistorico
              showSpinner
              data={data}
              isSubmitting={isSubmitting}
            />
          </Fragment>
        )}
      </EstrategiaUltimaAula>
    </Screen>
  )
}
