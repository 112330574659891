import React from 'react'
import { connect } from 'react-redux'
import Box from 'components/Box'
import FormFieldFile from 'components/FormFieldFile'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import { Field, Form } from 'react-final-form'
import 'styles/PromoMaior.css'
import { fileSize, fileType } from 'utils/validators'
import { Promo2020, isSubmitting, isDone } from 'store/ducks/promo2020'
const FILE_SUPPORTED_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'image/bmp',
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/msword',
  'text/plain',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const MAX_FILE_SIZE = (1024 ** 2) * 5 // 5 megabytes

const validate = value => fileType(FILE_SUPPORTED_TYPES)(value) || fileSize(MAX_FILE_SIZE)(value)

function Upload({ isSubmitting, onSubmit }) {
  const onFormSubmit = (formFields) => {
    return onSubmit(formFields)
  }

  return (
    <React.Fragment>
      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {
              <div>
                <Box padded>
                  <div className='PromoMaior'>
                    <div className='PromoMaior-column'>
                      <FormGroup>
                        <Field
                          type='file'
                          name='comprovante_arquivo'
                          component={FormFieldFile}
                          validate={validate}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Box>
                <Box padded isTransparent>
                  <div className='PromoMaior'>
                    <div className='PromoMaior-column' />
                    <div className='PromoMaior-column' style={{ display: 'flex' }}>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <Button type='submit' color="special">
                          {!isSubmitting ? ' Enviar' : 'Processando...'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            }
          </form>
        )}
      </Form>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state),
  isDone: isDone(state)
})

const mapDispatchToProps = dispatch => ({
  onSubmit(data) {
    dispatch(Promo2020(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload)
