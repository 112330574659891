import styled from 'styled-components'
import media from 'utils/mediaQuery'

export const ButtonContainer = styled.div`
  > * {
    margin: 10px;
  }
`

export const CommunitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`

export const CommunityContainer = styled.div`
  width: 100%;
  margin-bottom: 5px;

  > a {
    height: 100%;

    > div {
      height: 100%;
    }
  }
  
  ${() => media.lessThan('medium')`
    &:not(:nth-child(1)) {
      margin-top: 5px;
    }
  `}

  ${() => media.greaterThan('large')`
    width: calc(33.3% - 5px);
  
    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
      margin-top: 5px;
    }
  
    &:nth-child(3n) {
      margin-left: 5px;
    }
    &:nth-child(3n + 1) {
      margin-right: 5px;
    }
    &:nth-child(3n + 2) {
      margin-left: 2.5px;
      margin-right: 2.5px;
    }
  `}
`
export const CommunityImage = styled.div`
  height: 90px;
  width: 90px;
  min-height: 90px;
  min-width: 90px;

  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 64px;
    width: 64px;
    font-size: 12px;
    object-fit: cover;
  }
`

export const CommunityContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 10px;
`
