import React from 'react'
import PropTypes from 'prop-types'
import DateSelect from 'components/DateSelect'
import FormGroupSelect from 'components/FormGroupSelect'
import Select from 'components/Select'
import 'styles/AgendaHeader.css'

export default function AgendaHeader (props) {
  return (
    <div className='AgendaHeader'>
      <div className='AgendaHeader-date'>
        <DateSelect
          value={props.selectedDate}
          onChange={props.onDateChange}
        />
      </div>
      <div className='AgendaHeader-filter'>
        <FormGroupSelect id='filter' label='Filtrar por concurso:'>
          <Select
            id='filter'
            name='filtro'
            value={props.selectedConcourse}
            onChange={({ value }) => props.onConcourseChange(value)}
            disabled={props.isFetching}
            options={[
              { value: 0, label: 'Todos' },
              ...props.concourses.map(c => ({ value: c.id, label: c.titulo }))
            ]}
          />
        </FormGroupSelect>
      </div>
    </div>
  )
}

AgendaHeader.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  onDateChange: PropTypes.func.isRequired,
  concourses: PropTypes.array.isRequired,
  selectedConcourse: PropTypes.number.isRequired,
  onConcourseChange: PropTypes.func.isRequired
}
