import styled from 'styled-components'

export const Content = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  background: #000000;
  padding: 0 0 60px 0;
`

export const Texto = styled.span`
  float: left;
  width: 100%;
  text-align: center;
  color: #D59A04;
  margin-bottom: 15px;
`

export const ContainerBotoes = styled.span`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button:first-child {
    border-radius: 2px 0 0 2px;
  }

  button:last-child {
    border-radius: 0 2px 2px 0;
  }

  .active {
    color: #000000;
    background: #D59A04;
  }
`

export const Botao = styled.button`
  background-color: #000000;
  border: 0.5px solid #D59A04;
  padding: 15px 30px;
  color: #D59A04;
  outline: none;
  transition: all .2s linear 0s;

  @media only screen and (max-width: 900px) {
    padding: 15px 15px;
    font-size: 13px;
  }
`
