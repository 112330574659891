import React, { useState } from 'react'
import { BoxAviso, Icone, Texto, IconeClose } from './Styled'
import icAviso from 'images/icons/ic-aviso-renovacao.svg'
import icFecha from 'images/icons/ic-close-aviso.svg'

export default function Aviso ({ link, pacote = false }) {

    const [aviso, setAviso] = useState(true)
    const [linkLDI] = useState(pacote ? link.ldi_url : link.props.course.ldi_url)

    const validaLink = () => {
        return linkLDI ? true : false
    }
 
    const fechaAviso = () => {
        setAviso(false)
    }

    const returnHtml = () => (
        <BoxAviso pacote={pacote}>
            <Icone src={icAviso} />
            <Texto>A versão interativa deste curso está disponível na nova plataforma. <a class="linkPlataformaECJ" href={linkLDI} target='_blank' rel='noopener noreferrer'>Acesse aqui.</a></Texto>
            <IconeClose src={icFecha} onClick={fechaAviso} />
        </BoxAviso>
    )

    return validaLink() && aviso ? returnHtml() : null
}
