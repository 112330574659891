import React from 'react'
import { BoxCertificates, ContainerPaginacao } from './Styled'
import Pagination from 'components/Pagination'
import CardItem from 'components/CardItem'
import Spinner from 'components/Spinner'
import { formatDateHour } from 'utils/formatters'

export default function Listagem ({ certificados, loading, pagination, queryCoursesPage }) {

    if (loading) {
        return <Spinner />
    }

    return (
        <BoxCertificates>
            {certificados.map(certificate => (
                <CardItem
                    key={certificate.id}
                    label={formatDateHour(certificate.data)}
                    title={certificate.produto}
                    buttonLabel='Imprimir'
                    href={certificate.link}
                    renderWith='a'
                    target='_blank'
                    download
                />
            ))}

            <ContainerPaginacao>
                <Pagination
                    center
                    current={pagination.current_page}
                    total={pagination.last_page}
                    onChange={queryCoursesPage}
                />
            </ContainerPaginacao>
        </BoxCertificates>
    )
}
