import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import { fetchOne } from 'store/ducks/conteudo/ultimasAulas'
import * as selectors from 'store/ducks/conteudo/ultimasAulas'

class EstrategiaUltimaAula extends Component {

  componentWillMount () {
    if (this.props.fetch) {
      this.props.fetchAulas()
    }
  }

  render () {
    if (this.props.isFetching) {
      return this.props.showSpinner
        ? <Spinner text='Carregando aulas...' />
        : null
    }

    if (!this.props.data) {
      return 'Aulas não encontradas!'
    }

    return this.props.children({
      data: this.props.data,
      isSubmitting: this.props.isSubmitting
    })
  }

  static propTypes = {
    // From component
    children: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
    fetch: PropTypes.bool,

    // From connect (state)
    isFetching: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    data: PropTypes.array,
    isListLoaded: PropTypes.bool.isRequired,

    // From connect (dispatch)
    fetchAulas: PropTypes.func.isRequired
  }

  static defaultProps = {
    showSpinner: true,
    fetch: true
  }

}

const mapStateToProps = (state) => ({
  isFetching: selectors.isFetching(state),
  isSubmitting: selectors.isSubmitting(state),
  data: selectors.data(state),
  isListLoaded: selectors.isListLoaded(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchAulas () {
    dispatch(fetchOne())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstrategiaUltimaAula)
