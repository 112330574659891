import { Component } from 'react'
import { SITE_LOGOUT_URL } from 'config/constants'
import { deleteCookie } from 'utils/helpers'

export default class LogoutScreen extends Component {
  componentWillMount () {
    deleteCookie('dados_sessao')
    window.location = SITE_LOGOUT_URL
  }
}
