import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 100%;
  color: #FF6A58;
  margin-top: 30px;
`

export const PrecoDe = styled.span`
  float: left;
  width: 100%;
  color: #A9ABBD;
  font-size: 16px;
  text-decoration: line-through;
`

export const PrecoPor = styled.span`
  float: left;
  width: 100%;
  color: #fff;
  font-size: 22px;
  margin: 7px 0;
  font-weight: bold;
`

export const Link = styled.a`
  float: left;
  background-color: ${props => props.tipo === '1' ? '#FF6A58' : '#F09C00'};
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  span {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
    text-align: left;
    line-height: 16px;

    small {
      font-size: 12px;
    }
  }
`
