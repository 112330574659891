import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import { fetchOne, updateRating, sendDiscursive } from 'store/ducks/conteudo/cursos'
import * as selectors from 'store/ducks/conteudo/cursos'

class Course extends Component {
  componentWillMount () {
    if (this.props.fetch) {
      this.props.fetchCourse(this.props.id)
    }
  }

  render () {
    if (this.props.isFetching) {
      return this.props.showSpinner
        ? <Spinner text='Carregando curso...' />
        : null
    }

    if (!this.props.hasDetailedCourse) {
      return 'Curso não encontrado!'
    }

    if (!this.props.course) {
      return 'Curso não encontrado!'
    }

    return this.props.children({
      course: this.props.course,
      lessons: this.props.lessons,
      isSubmitting: this.props.isSubmitting,
      onRatingChange: this.props.onRatingChange,
      sendDiscursive: this.props.sendDiscursive
    })
  }

  static propTypes = {
    // From component
    id: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
    fetch: PropTypes.bool,

    // From connect (state)
    isFetching: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    course: PropTypes.object,
    lessons: PropTypes.array.isRequired,
    hasDetailedCourse: PropTypes.bool.isRequired,

    // From connect (dispatch)
    fetchCourse: PropTypes.func.isRequired,
    onRatingChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    showSpinner: true,
    fetch: true
  }
}

const mapStateToProps = (state, { id }) => ({
  isFetching: selectors.isFetching(state),
  isSubmitting: selectors.isSubmitting(state),
  course: selectors.getDetailedCourse(state, id),
  lessons: selectors.getCourseLessons(state, id),
  hasDetailedCourse: selectors.hasDetailedCourse(state, id)
})

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchCourse (id) {
    dispatch(fetchOne(id))
  },

  onRatingChange (courseId, rating, currentRating) {
    dispatch(updateRating(courseId, rating, currentRating))
  },

  sendDiscursive (data) {
    dispatch(sendDiscursive(id, data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Course)
