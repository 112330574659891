/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { actionTypes } from 'store/actions/resources/alerts'
import { actionTypes as authActionTypes } from 'store/ducks/auth'
import createResourceReducer from 'store/utils/createResourceReducer'

/**
 * Alerts Reducer.
 */

export default createResourceReducer({
  actionTypes,
  state: {
    unread: 0
  },
  handlers: {
    [authActionTypes.LOGIN_FULFILLED]: (state, { payload }) =>
      update(state, {
        unread: { $set: payload.recados }
      }),

    [actionTypes.READ_ALERT]: (state, { payload }) =>
      update(state, {
        records: {
          $set: state.records.map(alert => {
            if (alert.id === payload.id) {
              alert.lido = true
            }
            return alert
          })
        },
        unread: {
          $set: state.unread > 0 ? state.unread - 1 : 0
        }
      })
  }
})
