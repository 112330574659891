import React from 'react'
import Screen from 'containers/Screen'
import InviteFriend from 'containers/InviteFriend'

export default function IndicarAmigoScreen () {
  return (
    <Screen title='Indique um Amigo' setHeadTitle>
      <InviteFriend />
    </Screen>
  )
}
