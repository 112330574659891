export default {
  login: '/dashboard/login',
  cursos: '/dashboard/cursos',
  curso: '/dashboard/cursos/:courseId/aulas',
  aula: '/dashboard/cursos/:courseId/aulas/:lessonId',
  video: '/dashboard/cursos/:courseId/aulas/:lessonId/videos/:videoId',
  assinaturas: '/dashboard/assinaturas',
  promocao: '/dashboard/promocao',
  promocaoAssinatura: '/dashboard/promocao-assinatura',
  renovacao: '/dashboard/renovacao-antecipada',
  renovacaoBlackFriday: '/dashboard/renovacao-black-friday',
  renovacaoAssinatura: '/dashboard/renovacao-assinatura',
  trilhas: '/dashboard/trilhas',
  trilhasEcj: '/dashboard/trilhas-ecj',
  comunidades: '/dashboard/comunidades',
  comunidadesEcj: '/dashboard/comunidades-ecj',
  monitorias: '/dashboard/monitorias',
  simulados: '/dashboard/simulados',
  estudeEmGrupo: '/dashboard/estude-em-grupo',
  cursosExclusivos: '/dashboard/cursos-exclusivos',
  forum: '/dashboard/cursos/:courseId/aulas/:lessonId/forum',
  meusDados: '/dashboard/meus-dados',
  meusDownloads: '/dashboard/meus-downloads',
  metodosDePagamento: '/dashboard/metodos-de-pagamento',
  agenda: '/dashboard/agenda',
  historicoDeCompras: '/dashboard/historico',
  certificados: '/dashboard/certificados',
  ingressos: '/dashboard/ingressos',
  meusChamados: '/dashboard/meus-chamados',
  mensagens: '/dashboard/mensagens',
  indicarAmigo: '/dashboard/indique-um-amigo',
  salaVip: '/dashboard/sala-vip/:salaId',
  suporte: '/dashboard/suporte',
  alertas: '/dashboard/alertas',
  logout: '/dashboard/logout',
  promoMaior: '/dashboard/promocao-maior',
  VemParaOMaiorComprovante: '/dashboard/vem-para-o-maior-comprovante',
  promoMaiorLoja: '/dashboard/promocao-maior-loja',
  coaching: '/dashboard/coaching',
  estrategiaCast: '/dashboard/estrategia-cast',
  indiqueAmigo: '/dashboard/indique-amigo',
  cursosExclusivosECJ: '/dashboard/cursos-exclusivos-ecj',
  cursosExtensao: '/dashboard/cursos-extensao',
  estudoEstrategico: '/dashboard/estudo-estrategico',
  cursosOrais: '/dashboard/cursos-orais',
  simuladosECJ: '/dashboard/simulados-ecj',
  rodadas: '/dashboard/rodadas',
  retaFinalEcj: '/dashboard/reta-final-ecj',
  informativosEstrategicos: '/dashboard/informativos-estrategicos',
  vadeMecum: '/dashboard/vade-mecum',
  series: '/dashboard/series',
  painelPontuacao: '/dashboard/painel-pontuacao',
  blogEcj: '/dashboard/blog-ecj',
  pacote: '/dashboard/pacote/:pacoteId',
  ultimasAulas: '/dashboard/ultimas-aulas',
  temasEspeciaisOab: '/dashboard/temas-especiais-oab',
  livraoOab: '/dashboard/livrao-oab',
  simuladosOab: '/dashboard/simulados-oab',
  retaFinalOab: '/dashboard/reta-final-oab',
  monitoriasOab: '/dashboard/monitorias-oab',
  salaVipOab: '/dashboard/sala-vip-oab',
  vmDigitalOab: '/dashboard/vm-digital-oab',
  trilhasOab: '/dashboard/trilhas-oab',
  blackFriday: '/dashboard/black-friday',
  assinaturaVitalicia: '/dashboard/assinatura-vitalicia'
}
