import React from 'react'
import 'styles/Title.css'
import 'styles/Subtitle.css'

export default function Title ({ Component = 'h1', children = '', isSubtitle = null, isPrimary = false, ...props }) {
  let classes = ''
  classes += ` ` + (!isSubtitle ? 'Title' : 'Subtitle')
  classes += ` ` + (!isPrimary ? 'is-primary' : '')
  classes += ` ` + (props.classesName ? props.classesName : '')

  return (
    <Component className={classes} {...props}>
      {children}
    </Component>
  )
}
