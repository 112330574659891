import React, { useEffect } from 'react'
import objectPath from 'object-path'
import OrderCollapse from 'components/OrderCollapse'
import withResourceList from 'store/utils/withResourceList'

function Orders ({ records, pedidoId }) {
  useEffect(() => {
    const ids = records.map(r => r.id)
    if (ids.includes(pedidoId)) {
      const el = document.getElementById(`pedido-${pedidoId}`)
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }, [ records, pedidoId ])

  return records.map(order => (
    <OrderCollapse
      key={order.id}
      activeOrderId={pedidoId}
      orderId={order.id}
      date={order.data}
      value={parseFloat(order.valor_cobrado)}
      paymentType={(order.tipo_cobranca && order.tipo_cobranca.nome) || 'Desconhecido'}
      status={order.status}
      products={order.cursos}
      gerenciar={order.gerenciar}
      tipoCobrancaId={objectPath.get(order, 'tipo_cobranca.tipo_cobranca_grupo_id', 0)}
      order={order}
      pedidoRecorrente={order.pedido_recorrente ? order.pedido_recorrente : false}
    />
  ))
}

export default withResourceList({
  resource: 'orders',
  spinnerText: 'Carregando histórico de pedidos'
})(Orders)
