import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Notifications from 'containers/Notifications'
import FormForumQuestion from 'forms/FormForumQuestion'
import * as forumMessages from 'store/ducks/forumMessages'

class ForumQuestionNew extends Component {
  render () {
    return (
      <div>
        <Notifications notificationsId='formForumQuestion' />
        <FormForumQuestion
          selectedLesson={this.props.selectedLesson}
          isSubmitting={this.props.isSubmitting}
          isFetching={this.props.isFetching}
          onSubmit={values =>
            this.props.create(values.aula, values, 'formForumQuestion')
          }
        />
      </div>
    )
  }

  static propTypes = {
    /** Aula selecionada */
    selectedLesson: PropTypes.number.isRequired,

    isSubmitting: PropTypes.bool.isRequired,

    create: PropTypes.func.isRequired
  }
}

const mapStateToProps = (state, { selectedLesson }) => ({
  isSubmitting: forumMessages.isSubmitting(state)
})

const mapDispatchToProps = {
  create: forumMessages.create
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForumQuestionNew)
