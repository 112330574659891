import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'styles/Player/Player.css'
import SourcesControl from './SourcesControl'
import coverPadrao from '../../images/cover-video.png'
import 'styles/Player/VideoReact.css'
import { Player, ControlBar, BigPlayButton, VolumeMenuButton, PlaybackRateMenuButton, ReplayControl, ForwardControl } from 'video-react'

export default class PlayerVideo extends Component {

	state = {
		src: this.props.defaultSource,
		resolution: this.props.resolution,
		initialPosition: this.props.initialPosition,
		autoplay: false,
		error: null,
		thumbnail: this.props.thumbnail !== null ? this.props.thumbnail : coverPadrao,
		muted: false,
		volume: 1,
		preload: 'auto',
	}

	componentDidMount () {
		this.player.subscribeToStateChange(this.handleStateChange.bind(this));
	}

	handleStateChange(state, prevState) {
		
		if (state.error === null) {
			this.setState({
				player: state,
				currentTime: state.currentTime,
			});
		}

		this.onPositionChanged(state.currentTime, this.state.resolution)
		
	}

	onSourceChange = (src, resolution) => {
		const {player} = this.player.getState();
		this.setState({
			src,
			resolution,
			autoplay: true,
			muted: player.muted,
			initialPosition: player.currentTime,
			volume: player.volume
		})
	}

	onPositionChanged = (playedSeconds, resolution) => {		
		const seconds = Math.round(playedSeconds)
		const condition = seconds % 5 === 0 &&
		seconds >= 5 &&
		seconds !== this.props.initialPosition &&
		this.props.onPositionChanged
		if (condition) {
			this.props.onPositionChanged(seconds, resolution)
		}
	}

	renderPlayer () {

		return (
			<React.Fragment>
				<Player
					src={this.state.src}
					ref={(player) => { this.player = player }}
					poster={this.state.thumbnail}
					preload={this.state.preload}
					startTime={this.state.initialPosition}
					autoPlay={this.state.autoplay}
					muted={this.state.muted}
				>
					<ControlBar>
						<VolumeMenuButton vertical order={2} />
						<PlaybackRateMenuButton order={2} rates={[2.5, 2.25, 2, 1.75, 1.5, 1.25, 1, 0.75, 0.5, 0.25]} />
						<ReplayControl seconds={5} order={2.1} />
						<ForwardControl seconds={5} order={3.1} />
						<SourcesControl sources={this.props.sources} currentSource={this.state.src} onSourceChange={this.onSourceChange} order={2} />
					</ControlBar>
					<BigPlayButton position="center" />
				</Player>
			</React.Fragment>
		)
		
	}

	renderError () {
		return (
			<div className='Player-error'>Opa! Erro ao reproduzir vídeo :(</div>
		)
	}

	render () {
		return (
			<div className={`Player`} >
				{ !this.state.error ? this.renderPlayer() : this.renderError() }
			</div>
		)
	}

	static propTypes = {
		sources: PropTypes.array.isRequired,
		defaultSource: PropTypes.string.isRequired,
		initialPosition: PropTypes.number.isRequired,
		onPositionChanged: PropTypes.func,
	}

	static defaultProps = {
		initialPosition: 0
	}

}