import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { required, cardNumberRequired, cardCVCRequired } from 'utils/forms'
import { range } from 'range'
import zeroFill from 'zero-fill'
import SectionTitle from 'components/SectionTitle'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import CustomField from 'components/CustomField'
import 'styles/FormCreditCard.css'
import ReCAPTCHA from "react-google-recaptcha";

const currentYear = (new Date()).getFullYear()

const Captcha = (props) => (
  <div>
    <ReCAPTCHA
      sitekey="6Le_ugETAAAAAM4hsMiL8mxpd7eDfjC5JGN_rHrR"
      hl="pt-BR" 
      onChange={props.input.onChange}
    />
  </div>
);

export default function FormCreditCard ({ onSubmit, isSubmitting }) {
  return (
    <Form onSubmit={onSubmit} className='FormCreditCard'>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SectionTitle>Incluir novo cartão</SectionTitle>
          <FormGroup>
            <Field
              name='numero'
              type='tel'
              label='Número do cartão'
              component={CustomField}
              {...cardNumberRequired}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name='nome'
              type='text'
              label='Nome do portador'
              component={CustomField}
              {...required}
            />
          </FormGroup>
          <FormGroup>
            <div className='FormCreditCard-fields'>
              <div className='FormCreditCard-fields-column'>
                <div className='FormCreditCard-fields-column-expiry'>
                  <div className='FormCreditCard-fields-column-expiry-item'>
                    <FormGroup>
                      <Field
                        name='validade_mes'
                        type='select'
                        label='Mês'
                        component={CustomField}
                        renderWith='select'
                        {...required}
                      >
                        <option value='' disabled>Mês</option>
                        {range(1, 13).map(month => (
                          <option key={month} value={zeroFill(2, month)}>
                            {zeroFill(2, month)}
                          </option>
                        ))}
                      </Field>
                    </FormGroup>
                  </div>
                  <div className='FormCreditCard-fields-column-expiry-item'>
                    <FormGroup>
                      <Field
                        name='validade_ano'
                        type='select'
                        label='Ano'
                        component={CustomField}
                        renderWith='select'
                        {...required}
                      >
                        <option value='' disabled>Ano</option>
                        {range(currentYear, currentYear + 20).map(year => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Field>
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className='FormCreditCard-fields-column'>
                <FormGroup>
                  <Field
                    name='verificacao'
                    type='tel'
                    label='Código de segurança'
                    component={CustomField}
                    {...cardCVCRequired}
                  />
                </FormGroup>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Field name="g-recaptcha-response" component={Captcha} />
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Incluir cartão' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormCreditCard.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired
}
