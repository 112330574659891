import styled from 'styled-components'
import media from 'utils/mediaQuery'

export const PaginationContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100% !important;
`

export const Container = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: -20px;
  `}

  ${media.greaterThan('huge')`
    > * {
      width: calc(33.33% - 20px);
      margin: 0 10px;
      margin-bottom: 20px;
    }
  `}

  ${media.between('medium', 'huge')`
    > * {
      width: calc(50% - 20px);
      margin: 0 10px;
      margin-bottom: 20px;
    }
  `}

  ${media.lessThan('medium')`
    > * {
      width: 100%;
    }

    > * + * {
      margin-top: 20px;
    }
  `}
`
