import { schema } from 'normalizr'

/**
 * Schemas.
 */

export const video = new schema.Entity('videos')

export const aula = new schema.Entity('aulas', {
  videos: [ video ]
})

export const curso = new schema.Entity('cursos', {
  aulas: [ aula ]
})

export const concurso = new schema.Entity('concursos', {
  cursos: [ curso ]
})
