import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistNonReactStatics from 'hoist-non-react-statics'
import getDisplayName from 'react-display-name'
import Spinner from 'components/Spinner'
import actions from 'store/actions/resources/index'
import selectors from 'store/selectors/resources/index'
import LoadMore from 'components/LoadMore'

const defaults = {
  resource: '',
  spinnerText: 'Carregando',
  handleEmptyRecords: true,
  displaySpinner: true
}

export default function withResourceList (config) {
  const options = Object.assign({}, defaults, config)
  const { resource, spinnerText } = options

  const mapStateToProps = state => ({
    records: selectors[resource].getRecords(state),
    pagination: selectors[resource].getPagination(state),
    loadedPages: selectors[resource].getLoadedPages(state),
    isFetching: selectors[resource].isFetching(state),
    isSubmitting: selectors[resource].isSubmitting(state),
    error: selectors[resource].getError(state)
  })

  const mapDispatchToProps = dispatch => ({
    dispatch,
    fetchAll(params) {
      dispatch(actions[resource].actionCreators.fetchAll(params))
    }
  })

  return WrappedComponent => {
    class EnhancedComponent extends Component {
      componentWillMount() {
        this.props.fetchAll(this.props.params)
      }

      fetchNextPage = () => {
        this.props.fetchAll({
          page: this.props.pagination.current_page + 1,
          ...this.props.params
        })
      }

      hasMore() {
        const { pagination } = this.props
        return pagination && pagination.current_page < pagination.last_page
      }

      isLoading () {
        return !this.props.loadedPages.length && this.props.isFetching
      }

      shouldHandleEmptyRecords () {
        return !this.props.records.length && options.handleEmptyRecords
      }

      render() {
        if (this.isLoading()) {
          return options.displaySpinner
            ? <Spinner text={spinnerText}/>
            : null
        }

        if (this.shouldHandleEmptyRecords()) {
          return (
            <div>
              Nenhum registro encontrado!
            </div>
          )
        }

        return [
          <WrappedComponent key={0} {...this.props} />,
          this.hasMore() && (
            <LoadMore
              key={1}
              onClick={this.fetchNextPage}
              isLoading={this.props.isFetching}
            />
          )
        ]
      }

      static displayName = `withResource(${getDisplayName(WrappedComponent)})`

      static propTypes = {
        params: PropTypes.object
      }

      static defaultProps = {
        params: {}
      }
    }

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(hoistNonReactStatics(EnhancedComponent, WrappedComponent))
  }
}
