import Client from 'modules/Client'

export default {
  fetchAll (params) {
    return Client.get('api/aluno/mensagem', { params })
  },

  read (id) {
    return Client.put(`api/aluno/mensagem/${id}`)
  }
}
