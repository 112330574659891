import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Alert from 'components/Alert'
import {
  removeNotification,
  getNotifications,
  clear
} from 'store/ducks/notifications'

class Notifications extends Component {
  componentDidUpdate (prevProps) {
    const shouldScrollToTop = this.props.scrollToTop &&
      prevProps.notifications.length < this.props.notifications.length
    if (shouldScrollToTop) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
  }

  componentWillUnmount () {
    if (this.props.notifications.length) {
      this.props.clear(this.props.notificationsId)
    }
  }

  render () {
    return (
      <div>
        {this.props.notifications.map((notification, index) => (
          <Alert
            key={index}
            type={notification.type}
            children={typeof notification.message === 'string' ? notification.message : notification.message.label}
            items={Array.isArray(notification.message.items) ? notification.message.items : null}
            onCloseRequest={() => {
              this.props.remove(index, this.props.notificationsId)
            }}
          />
        ))}
      </div>
    )
  }
}

Notifications.propTypes = {
  notificationsId: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  remove: PropTypes.func.isRequired,
  scrollToTop: PropTypes.bool
}

Notifications.defaultProps = {
  notificationsId: 'ALL'
}

const mapStateToProps = (state, { notificationsId }) => ({
  notifications: getNotifications(state, notificationsId)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    remove: removeNotification,
    clear
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
