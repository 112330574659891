import React, { useState } from 'react'
// import Button from 'components/Button'
import Box from 'components/Box';
import Title from 'components/Title';
import 'styles/EstrategiaCast.css';
import imageApp1 from 'images/img-estrategia-cast-app1.png';
import imageApp2 from 'images/img-estrategia-cast-app2.png';
import imageAppStore from 'images/img-estrategia-cast-app-store.png';
import imageAppGooglePlay from 'images/img-estrategia-cast-google-play.png';
import imageRegular from 'images/ic-regular.svg';
import imageRetaFinal from 'images/ic-reta-final.svg';
import imageVantagem1 from 'images/img-estrategia-cast-vantagens-1.png';
import imageVantagem2 from 'images/img-estrategia-cast-vantagens-2.png';
import imageVantagem3 from 'images/img-estrategia-cast-vantagens-3.png';
import imageVantagem4 from 'images/img-estrategia-cast-vantagens-4.png';
import imageVantagem5 from 'images/img-estrategia-cast-vantagens-5.png';

export default function EstrategiaCast({
  planos
}) {
  const [vantagens] = useState([
    { id: 1, short: 'Acesse mais de 300 cursos em áudio', title: 'Milhares de cursos em áudio na sua mão', description: 'Aproveite todos os momentos para estudar com o Estratégia Cast. São cursos completos e exclusivos, além de áudios para turbinar seus estudos. Tudo com o Cast, onde você estiver.', img: imageVantagem1 },
    { id: 2, short: 'Baixe os álbuns e ouça onde quiser', title: 'Liberdade para estudar onde quiser', description: 'Baixe aulas ou álbuns, inteiros para escutar offline, onde e quando quiser.', img: imageVantagem2 },
    { id: 3, short: 'Tenha novos conteúdos toda semana', title: 'Novos conteúdos toda semana', description: 'Cursos exclusivos, leis em áudio, informativos, motivacionais, dicas de estudo: tudo com o Cast, onde você estiver.', img: imageVantagem3 },
    { id: 4, short: 'Controle a velocidade de reprodução', title: 'Pensado para você não perder tempo', description: 'Com o Cast você tem o controle: selecione a velocidade de reprodução de acordo com a sua necessidade e vá no seu tempo.', img: imageVantagem4 },
    { id: 5, short: 'Busque por faixa, álbum ou professor', title: 'Uma busca inteligente', description: 'Vá direto ao ponto e procure por faixa específicas, álbuns ou professores.', img: imageVantagem5 },
  ]);
  const [vantagemSelected, setVantagemSelected] = useState(vantagens[0]);

  return (
    <React.Fragment>
      <div className='container'>
        <div style={{ marginTop: '-70px' }}>
          <Box isTransparent isMargined >
            <h1 className='titleCast'>Estratégia Cast</h1>
          </Box>
          <Box padded isMargined>
            <div className='EstrategiaCast BoxAproveite'>
              <div className='EstrategiaCast-column'>
                <Title isSubtitle className="titleSub">Aproveite todos os momentos</Title>
                <div style={{ marginTop: 50, maxWidth: 439 }}>
                  <span className="descriptionCast is-dark">O Estratégia Cast possui áudios gravados exclusivamente para esse formato. Ou seja, os professores já realizam gravações pensando na percepção auditiva do aluno. Isso certamente é um grande diferencial da metodologia didática adotada.</span>
                </div>
              </div>
              <div className="EstrategiaCast-column">
                <div className="EstrategiaCastApp">
                  <div className="AppOne">
                    <img src={imageApp1} alt="EstrategiaCast" />
                  </div>
                  <div className="AppTwo">
                    <img src={imageApp2} alt="EstrategiaCast" />
                  </div>
                </div>
              </div>
            </div>
          </Box>
          <Box isTransparent padded isMargined>
            <div className='EstrategiaCast'>
              <div className='MobileApp'>
                <div className="EstrategiaCastMobileApp">
                  <div className="AppOne">
                    <img src={imageApp1} alt="EstrategiaCast" />
                  </div>
                  <div className="AppTwo">
                    <img src={imageApp2} alt="EstrategiaCast" />
                  </div>
                </div>
                <div className='EstrategiaCast-full-column'>
                  <div className='lnk-loja-download'>
                    <h1 className="is-dark" style={{ fontSize: 16 }}><b>Baixe agora e experimente de graça</b></h1>
                    <img src={imageAppGooglePlay} alt="EstrategiaCast" />
                    <img src={imageAppStore} alt="EstrategiaCast" />
                  </div>

                </div>
              </div>

            </div>
          </Box>
          <div className='EstrategiaCast-full-column' style={{ marginTop: 80 }}>
            <Title className="titleSub is-dark is-centered">Distribuição dos cursos no aplicativo</Title>
          </div>
          <Box isTransparent isMargined>
            <div className={'EstrategiaCast-full-column CardsList'}>
              <div className='ItemDistribuicaoCurso'>
                <img src={imageRetaFinal} alt="Reta Final" />
                <p>
                  <Title isSubtitle className="sub-titulo is-centered">Curso Reta Final</Title>
                </p>
                <p>
                  <span className="distribuicao-cursos-description">
                    São gravações focadas em determinados concursos quentes ou mais relevantes.
                    Possuem álbuns (matérias) com carga horária média total de 1-2 horas, divididas em
                    faixas de 5-15 minutos.
                  </span>
                  <br />
                  <br />
                  <small style={{ color: '#666666' }}>Exemplos: Retas Finais PCDF, TCM SP, DEPEN, ISS Aracaju, etc.</small>
                </p>
              </div>
              <div className='ItemDistribuicaoCurso'>
                <img src={imageRegular} alt="Curso Regular" />
                <p>
                  <Title isSubtitle className="sub-titulo is-centered">Curso Regular</Title>
                </p>
                <p>
                  <span className="distribuicao-cursos-description">
                    São gravações focadas em determinadas matérias mais recorrentes e com maior relevância para concursos.
                    Por serem regulares, possuem carga horária média total por álbum (matéria) de 10-20 horas,
                    trazendo um conteúdo mais detalhado para o aluno.
                  </span>
                  <br />
                  <br />
                  <small style={{ color: '#666666' }}>
                    Exemplos: Legislação Tributária Municipal, Controle Emocional para Concursos,
                    Lei em áudio – 8.666, Direito Tributário (ciclo básico - área Fiscal), etc.
                    </small>
                </p>
              </div>
            </div>
          </Box>


          <Box isTransparent isMargined>
            <p className="sub-titulo is-dark preVantagens">
              Vale lembrar que o foco principal do Estratégia Cast é trazer um conteúdo objetivo e conciso,
              proporcionando ao aluno uma percepção do que é mais importante dentro daquele respectivo assunto,
              ou seja, o que não pode deixar de saber de jeito nenhum!
              Assim, pensem em utilizá-lo de forma estratégia, como mais um instrumento que irá acrescentar
              bastante nos seus estudos, aliado aos PDFs, videoaulas, mapas mentais, resumos e todas as demais
              ferramentas que fornecemos.
            </p>
          </Box>
          <div className='EstrategiaCast-full-column'>
            <Title className="titleSub is-centered">Conheça as vantagens</Title>
          </div>

          {/* {###################### VANTAGENS ############################# } */}
          <Box isMargined>
            <div>
              <div style={{ position: 'absolute', margin: '70px 50px' }}>
                <Box isTransparent isMargined isPadded>
                  <div>
                    <Title className='itemCastTile'>{vantagemSelected.title}</Title>
                    <p className="itemCastTitleDescription" style={{ maxWidth: 500, marginTop: 60 }}>
                      {vantagemSelected.description}
                    </p>
                  </div>
                </Box>
              </div>
              <div className='EstrategiaCast-full-column' style={
                {
                  backgroundImage: `url(${vantagemSelected.img})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  height: '360px',
                  width: '100%',
                }}>
              </div>
            </div>
          </Box>
          <Box isTransparent isMargined>
            <div className={'EstrategiaCast-full-column CardsList'} style={{ marginTop: '-30px' }}>
              {vantagens.map((van) => {
                return (
                  <div
                    className={`sub-titulo ItemCast ItemCastSmall ${!(van.id === vantagemSelected.id) || 'ItemCastSelected'}`}
                    onClick={() => { setVantagemSelected(van); }}>
                    {van.short}
                  </div>
                )
              })}
            </div>
          </Box>

          <Box isTransparent isMargined>
            <div>
              <div className='EstrategiaCast-full-column'>
                <Title className="titleSub is-dark is-centered">Assine o Premium para aproveitar todo o conteúdo</Title>
              </div>
              <div className='EstrategiaCast-full-column' style={{ marginTop: 32, textAlign: 'center' }}>
                <a style={
                  {
                    'display': 'inline-block',
                    'color': '#ffffff',
                    borderRadius: '3px',
                    'border': 'none',
                    'padding': '20px 40px',
                    'cursor': 'pointer',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    backgroundColor: '#FF6A58'
                  }} className='EstrategiaCastLink' href={planos[0].combos.filter(f => f.nome === "Assinatura Premium")[0].produtos[0].link.replace("'\"/", "/").replace("'\"/", "/")} color="special">Assinar 1 ano</a>
                <a style={
                  {
                    'display': 'inline-block',
                    'color': '#ffffff',
                    borderRadius: '3px',
                    'border': 'none',
                    'padding': '20px 40px',
                    'cursor': 'pointer',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    backgroundColor: '#FF6A58',
                    marginLeft: 30
                  }} className='EstrategiaCastLink' href={planos[1].combos.filter(f => f.nome === "Assinatura Premium")[0].produtos[0].link.replace("'\"/", "/").replace("'\"/", "/")} color="special">Assinar 2 anos</a>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </React.Fragment >
  )
}