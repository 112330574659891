import React, { Component } from 'react'
import { TabPanels, TabList } from './index'
import 'styles/Tabs/Tabs.css'

export default class Tabs extends Component {
  state = {
    activeIndex: this.props.activeIndex || 0
  }

  render () {
    const { children, activeIndex,onActivate, ...props } = this.props
    const compoundChildren = React.Children.map(children, child => {
      if (child.type === TabPanels) {
        return React.cloneElement(child, {
          activeIndex: this.state.activeIndex
        })
      }

      if (child.type === TabList) {
        return React.cloneElement(child, {
          activeIndex: this.state.activeIndex,
          onActivate: activeIndex => {this.setState({
            activeIndex
          }); onActivate(activeIndex);}
        })
      }
    })

    return (
      <div className='Tabs' {...props}>
        {compoundChildren}
      </div>
    )
  }
}
