import styled from 'styled-components'

// Container
export const ContainerGeral = styled.section`
	float: left;
	width: 100%;
	padding: 100px 50px 150px 50px;
	background: url(${props => props.bg}) center center #19102D no-repeat;
	background-size: auto 100%;
	position: relative;

	@media only screen and (max-width: 900px) {
		padding: 80px 40px 650px 40px;
		background: url(${props => props.bgMobile}) bottom center #19102D no-repeat;
		background-size: 100% auto;
	}

	@media only screen and (max-width: 700px) {
		padding: 50px 20px 500px 20px;
	}

	@media only screen and (max-width: 500px) {
		padding: 50px 20px 350px 20px;
	}

	@media only screen and (max-width: 400px) {
		padding: 50px 10px 300px 10px;
	}

`

// Box Fechar 
export const BoxFechar = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
`

// Imagem Botão
export const ImagemBotao = styled.img``
