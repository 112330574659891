import { componentFromProp } from 'react-recompose'
import PropTypes from 'prop-types'

const Base = componentFromProp('component')

Base.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired
}

Base.defaultProps = {
  component: 'div'
}

export default Base
