import React from 'react'
import Section from 'components/Section'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { ButtonContainer } from './styles'

export default function Download() {
  return (
    <Section title='Baixe o Telegram e participe você também' titleOptions={{isSubtitle: true}}>
      <ButtonContainer>

        {/* <Button renderWith='a' href='https://telegram.org' target='_blank' size='medium'>
          <Icon name='windows8' color='red' />
          {' '}
          Windows
        </Button> */}
        <Button outlined='true' renderWith='a' href='https://macos.telegram.org/' target='_blank' size='medium'>
          <Icon name='appleinc' color='red' />
          {' '}
          Mac
        </Button>
        <Button outlined='true' renderWith='a' href='https://itunes.apple.com/br/app/telegram-messenger/id686449807?mt=8' target='_blank' size='medium'>
          <Icon name='appleinc' color='red' />
          {' '}
          iOS
        </Button>
        <Button outlined='true' renderWith='a' href='https://play.google.com/store/apps/details?id=org.telegram.messenger&hl=pt_BR' target='_blank' size='medium'>
          <Icon name='android' color='red' />
          {' '}
          Android
        </Button>
      </ButtonContainer>
    </Section>
  )
}