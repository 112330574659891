import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'styles/Header.css'
import UserDetails from 'containers/UserDetails'
import MenuButton from 'components/MenuButton'
import LinkMenuButton from 'components/LinkMenuButton'
// import UnreadMessages from 'containers/UnreadMessages'
import routes from 'config/routes'
import { SITE_URL } from 'config/constants'
import { toMoney } from 'utils/helpers'
import Logo from 'components/Logo'
import icMinhaConta from 'images/icons/ic-minha-conta.svg'
import icClose from 'images/icons/ic-close.svg'

const HeaderDropdownMenuItem = ({ onClick, route, label, external = false }) => {
  return (<LinkMenuButton onClick={onClick} to={route} extraClassNames={{ 'Header-dropdown-menu-item': true }} external={external}>{label}</LinkMenuButton>)
}

export default function Header({ onMenuButtonClick, displayMenu = true }) {
  const zendeskFaleConosco = 'https://estrategiaeducacional.zendesk.com/hc/pt-br/requests/new?ticket_form_id=360004855973&email='
  const zendeskSolicitarCancelamento = 'https://estrategiaeducacional.zendesk.com/hc/pt-br/requests/new?ticket_form_id=360004866734&email='
  const [menuOpen, setMenuOpen] = useState(false)
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const extraClassNames = menuOpen ? { 'Header-Button-active': true } : {}

  return (
    <header className='Header'>
      <Logo onlyDesktop isNew />
      {displayMenu ? (
        <nav className='Header-menu' ref={wrapperRef}>
          <UserDetails>
            {details => (
              <React.Fragment>
                <div className='Header-menu-item Header-menu-details is-only-desktop' style={{ position: 'relative', marginRight: 0 }}>
                  <MenuButton extraClassNames={extraClassNames} onClick={toggleMenu}>
                    {details.name}
                  </MenuButton>
                  {menuOpen && (
                    <div className='Header-dropdown'>
                      <div className='Gamification-details'>
                        <div className='Gamification-item -is-total'>Total de pontos <span>{details.score}</span></div>
                        <div className='Gamification-item'>Desconto <span>{toMoney(details.discount)}</span></div>
                      </div>
                      <div className='Header-dropdown-menu'>
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.ultimasAulas} label='Últimas Aulas' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.meusDados} label='Meus dados' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.meusDownloads} label='Meus downloads' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.agenda} label='Agenda' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.historicoDeCompras} label='Histórico de compras' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.certificados} label='Certificados' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.ingressos} label='Ingressos' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.meusChamados} label='Meus Chamados' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.alertas} label='Alertas' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.mensagens} label='Mensagens' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.indicarAmigo} label='Indique um amigo' />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={SITE_URL} label='Ir para o Site' external />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={zendeskFaleConosco + details.email} label='Fale Conosco' external />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={zendeskSolicitarCancelamento + details.email} label='Solicitar cancelamento' external />
                        <HeaderDropdownMenuItem onClick={(menuOpen) => setMenuOpen(false)} route={routes.logout} label='Sair' />
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
          </UserDetails>
          <div className='Header-menu-item Header-menu-user is-only-desktop'>
            <div className={`Header-lonely-icon ${menuOpen ? 'btnActive' : ''}`}>
              {menuOpen
                ? <LinkMenuButton customIcon={icClose} external onlyDesktop onClick={toggleMenu} />
                : <LinkMenuButton customIcon={icMinhaConta} external onlyDesktop onClick={toggleMenu} />
              }
            </div>
          </div>

          {/* <div className='Header-menu-item is-only-desktop'>
            <div className='Header-lonely-icon'>
              <UnreadMessages>
                {count => (
                  <LinkMenuButton to={routes.mensagens} icon='bell' count={count} onlyDesktop />
                )}
              </UnreadMessages>
            </div>
          </div> */}

          <div className='Header-menu-item'>
            <MenuButton type='button' icon='bars' onlyMobile onClick={onMenuButtonClick}>Menu</MenuButton>
          </div>
        </nav>
      )
        : (
          <nav className='Header-menu'>
            <div className='Header-menu-item'>
              <MenuButton href={SITE_URL} icon='home' renderWith='a'>Voltar</MenuButton>
            </div>
          </nav>
        )}
    </header>
  )
}

Header.propTypes = {
  onMenuButtonClick: PropTypes.func.isRequired,
  displayMenu: PropTypes.bool
}
