import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/symbol'
import 'core-js/modules/_array-includes'

if (!global.requestAnimationFrame) {
  global.requestAnimationFrame = function (callback) {
    setTimeout(callback, 0)
  }
}
