/**
 * Dependencies.
 */

 import update from 'immutability-helper'
 import createReducer from 'store/utils/createReducer'
 import Downloads from 'api/Downloads'
 import createActionTypes from 'store/utils/createActionTypes'
 
 /**
  * ActionTypes.
  */
 export const actionTypes = createActionTypes('DOWNLOADS', [

  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED'

 ])
 
 /**
  * Initial State.
  */
 const initialState = {
   isFetching: false,
   data: {},
   total: {},
   error: null
 }
 
 /**
  * Reducer.
  */
 
 export default createReducer(initialState, {
 
  /* Fetch ALL */
 
  [actionTypes.FETCH_ALL_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ALL_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      total: { $set: payload }
    })
  },

  [actionTypes.FETCH_ALL_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  },

   /* Fetch One */
 
  [actionTypes.FETCH_ONE_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },
 
  [actionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      data: { $set: payload }
    })
  },
 
  [actionTypes.FETCH_ONE_REJECTED] (state, { payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  }
 
})
 
/* Action Creators */

export const fetchAll = () => (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ALL,
    meta: {
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar dados!'
    },
    payload: Downloads.fetchAll()
  })
}
 
export const fetchOne = () => (dispatch) => {
   dispatch({
     type: actionTypes.FETCH_ONE,
     meta: {
       handleError: true,
       defaultErrorMessage: 'Erro ao carregar dados!'
     },
     payload: Downloads.fetchOne()
   })
}
 
 /* Selectors */
 
 export const isFetching = state => state.downloads.isFetching
 export const isSubmitting = state => state.downloads.isSubmitting
 export const data = state => state.downloads.data
 export const total = state => state.downloads.total
 