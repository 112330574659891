import React from 'react'
import Screen from 'containers/Screen'
import UserProfile from 'containers/UserProfile'
import SectionCardTokens from 'components/SectionCardTokens'
import SectionUserNotifications from 'components/SectionUserNotifications'
import SectionUserInterests from 'components/SectionUserInterests/SectionUserInterests'

export default function MeusDadosScreen () {
  return (
    <Screen title='Meus Dados' setHeadTitle>
      <UserProfile />
      <SectionCardTokens />
      <SectionUserNotifications />
      <SectionUserInterests />
    </Screen>
  )
}
