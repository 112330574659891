import React from 'react'
import PropTypes from 'prop-types'
import Collapse from 'react-smooth-collapse'
import 'styles/Calendar/CalendarCollapse.css'

export default function CalendarCollapse ({ isOpened, concourses, getLessonsByIds }) {
  return (
    <div className='CalendarCollapse-container'>
      <Collapse expanded={isOpened}>
        {concourses.length > 0 && (
          <div className='CalendarCollapse'>
            {concourses.map(concourse => (
              <section key={concourse.id} className='CalendarCollapse-block'>
                <h3 className='CalendarCollapse-block-title'>
                  {concourse.titulo}
                </h3>
                {getLessonsByIds(concourse.aulas).map(lesson => (
                  <span key={lesson.id} className='CalendarCollapse-block-item'>
                    {lesson.nome}
                  </span>
                ))}
              </section>
            ))}
          </div>
        )}
      </Collapse>
    </div>
  )
}

CalendarCollapse.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  concourses: PropTypes.array.isRequired,
  getLessonsByIds: PropTypes.func.isRequired
}
