import React from 'react'
import Input from 'components/Input'
import Label from 'components/Label'
import FormError from 'components/FormError'

export default function CustomField ({ input, meta, label, SubComponent, small, ...props }) {
  return (
    <React.Fragment>
      {label && (
        <Label htmlFor={props.id} SubComponent={SubComponent} isActive={meta.active} small={small}>
          {label}
        </Label>
      )}
      <Input
        {...input}
        {...props}
      />
      {meta.touched && meta.error && (
        <FormError>{meta.error}</FormError>
      )}
    </React.Fragment>
  )
}
