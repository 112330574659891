import React from 'react'
import PropTypes from 'prop-types'
import Title from 'components/Title'
import 'styles/ScreenHeader.css'

export default function ScreenHeader ({ title, Side, isSubtitle = false, isPrimary = false, titleStyles = {} }) {
  
  if (!title && !Side) return (<React.Fragment></React.Fragment>)

  return (
    <div className='GridHeader'>
      <div className='ScreenHeader'>
        {title && (
        <div className='ScreenHeader-title'>
          <Title isSubtitle={isSubtitle} isPrimary={isPrimary} style={titleStyles}>{title}</Title>
        </div>
        )}
        {Side && (
          <div className='ScreenHeader-side'>
            <Side />
          </div>
        )}
      </div>
    </div>
  )
}

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  Side: PropTypes.func
}
