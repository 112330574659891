import { combineReducers } from 'redux'
import conteudo from 'store/ducks/conteudo'
import resources from './resources'
import auth from 'store/ducks/auth'
import user from 'store/ducks/user'
import interests from 'store/ducks/interests'
import calendar from 'store/ducks/calendar'
import forumMessages from 'store/ducks/forumMessages'
import invite from 'store/ducks/invite'
import notifications from 'store/ducks/notifications'
import researches from 'store/ducks/researches'
import courseEvaluations from 'store/ducks/course-evaluations'
import promo2020 from 'store/ducks/promo2020'
import downloads from 'store/ducks/downloads'

export default combineReducers({
  conteudo,
  resources,
  auth,
  user,
  interests,
  notifications,
  invite,
  calendar,
  forumMessages,
  researches,
  courseEvaluations,
  promo2020,
  downloads
})
