import React from 'react'
import 'styles/LessonButtonList.css'

export default function LessonButtonList ({ children }) {
  return (
    <div className='LessonButtonList'>
      {children}
    </div>
  )
}
