import styled from 'styled-components'

export const Container = styled.div`
  float: left;
  width: 100%;
  background: #000000;
  padding: 30px 30px 80px 30px;
  position: relative;

  @media only screen and (max-width: 900px) {
    padding: 30px 30px 80px 30px;
  }

`
export const Texto = styled.span`
  float: left;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  line-height: 170%;

  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
`

export const Borda = styled.div`
  position: absolute;
  height: 1px;
  background-color: #444444;
  left: 30px;
  right: 30px;
  bottom: 40px;
`
