import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from 'components/Icon'
import 'styles/MenuButton.css'

export default function MenuButton ({ newItem, sidebar, onlyDesktop, disableHover, onlyMobile, icon, customIcon, count, children, renderWith, textProps = {}, extraClassNames = {}, ...props }) {
  const className = classNames({
    MenuButton: true,
    '-sidebar': sidebar,
    '-disable-hover': disableHover,
    '-only-desktop': onlyDesktop,
    '-only-mobile': onlyMobile,
    'newItem': newItem,
    ...extraClassNames
  })

  const Component = renderWith

  return (
    <Component className={className} {...props}>
      {icon && (
        <span className='MenuButton-icon'>
          <Icon name={icon} count={count} />
        </span>
      )}

      {customIcon && (
        <span className='MenuButton-icon'>
          <img src={customIcon} alt='' />
        </span>
      )}

      <span className='MenuButton-text' {...textProps}>
        {children}
      </span>
    </Component>
  )
}

MenuButton.propTypes = {
  icon: PropTypes.string,
  count: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.Component
  ]),
  sidebar: PropTypes.bool,
  onlyDesktop: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  renderWith: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired
}

MenuButton.defaultProps = {
  renderWith: 'button'
}
