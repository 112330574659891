/**
 * Dependencies.
 */

import { fit } from 'modules/msk'
import { format, parse } from 'date-fns'

/**
 * Phone.
 */

export const phone = (value = '') => value.length === 11
  ? fit(value, '(99) 9-9999-9999')
  : fit(value, '(99) 9999-9999')

/**
 * CPF.
 */

export const cpf = (value = '') =>
  fit(value, '999.999.999-99')

/**
 * CEP.
 */

export const cep = (value = '') =>
  fit(value, '99999-999')

/**
 * Date.
 */

export const date = (value = '') => {
  const date = /\d{4}-\d{2}-\d{2}/.test(value)
    ? format(parse(value), 'DD/MM/YYYY')
    : value

  return fit(date, '99/99/9999')
}

/**
 * Date and Hour
 */

 export const formatDateHour = (date) => {
  date = new Date(date)
  var year = date.getFullYear(),
      month = ("00" + (date.getMonth() + 1)).slice(-2),
      day = ("00" + date.getDate()).slice(-2),
      hour = ("00" + date.getHours()).slice(-2),
      minute = ("00" + date.getMinutes()).slice(-2),
      second = ("00" + date.getSeconds()).slice(-2)

  return day + "/" + month + "/" + year + " " + hour + ":" + minute + ':' + second;
}


/**
 * Date - Not Year
 */

export const dateNotYear = (value = '') => {
  const date = /\d{4}-\d{2}-\d{2}/.test(value)
    ? format(parse(value), 'DD/MM/YYYY')
    : value

  return fit(date, '99/99')
}

/**
 * Card Number.
 */

export const cardNumber = (value = '') => {
  const isAmex = /^(37|34)/.test(value)
  return isAmex
    ? fit(value, '9999 999999 99999')
    : fit(value, '9999 9999 9999 9999 9999 9999')
}

/**
 * Card CVC.
 */

export const cardCVC = (value = '') =>
  fit(value, '9999')
