import React from 'react'
import PropTypes from 'prop-types'
import VerticalSpacer from 'components/VerticalSpacer'
import ConcursoSection from 'components/ConcursoSection'

export default function Cursos ({ data, inativar, ativar }) {
  return (
    <VerticalSpacer spacing={45}>
      {data.map(concurso => (
        <ConcursoSection
          key={concurso.id}
          concurso={concurso}
          inativar={inativar}modalidades
          ativar={ativar}
        />
      ))}
    </VerticalSpacer>
  )
}

Cursos.propTypes = {
  /** Lista de cursos ordenados dentro de concursos */
  data: PropTypes.array.isRequired
}
