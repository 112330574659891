import React from 'react'
import 'styles/Icon.css'

export default function Icon ({ name, count, styles = {}, ...props }) {
  return (
    <div className='Icon' style={styles}>
      {!isNaN(count) && count > 0 && (
        <span className='Icon-count'>
          {count > 9 ? '+9' : count}
        </span>
      )}
      <i className={`icon-${name}`} {...props} />
    </div>
  )
}
