/**
 * Dependencies.
 */

import UserNotifications from 'api/UserNotifications'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const {
  actionTypes,
  actionCreators
} = createResourceActions('userNotifications', UserNotifications, 'Notificações')
