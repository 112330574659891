import React from 'react'
import classNames from 'classnames'
import 'styles/Box.css'

export default function Box ({ disabled, padded, isTransparent = false, isMargined = false, classes = '',  ...props }) {
  const className = classNames({
    Box: !isTransparent,
    isMargined: isMargined,
    isDisabled: disabled,
    isPadded: padded
  })

  return (
    <div className={className + ` ` + classes} {...props} />
  )
}
