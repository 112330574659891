import React from 'react'
import 'styles/Tabs/TabList.css'

export default function TabList ({ children, activeIndex, onActivate, ...props }) {
  const filteredChildren = React.Children.toArray(children).filter(c => !!c)
  const compoundChildren = filteredChildren.map((child, index) => {
    return React.cloneElement(child, {
      isActive: index === activeIndex,
      onClick: () => onActivate(index)
    })
  })

  return (
    <div className='TabList' {...props}>
      {compoundChildren}
    </div>
  )
}
