/**
 * Dependencies.
 */

import Messages from 'api/Messages'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('messages', Messages, 'Mensagem')

/**
 * Action Types.
 */

actionTypes.READ_MESSAGE = 'MESSAGES/READ_MESSAGE'

/**
 * `Read Message` action creator.
 */

actionCreators.readMessage = id => {
  Messages.read(id)
  return {
    type: actionTypes.READ_MESSAGE,
    payload: { id }
  }
}
