import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from 'theme'

const SubtitleStyled = styled.span`
  display: inline-block;
  font-family: ${theme.fonts.primary};
  color: ${props => props.disabled ? 'rgba(127, 127, 127, 0.6)' : '#FF6A58'};
  font-size: 12px;
  font-weight: bold;
`

export default function Subtitle (props) {
  return <SubtitleStyled {...props} />
}

Subtitle.propTypes = {
  /** Deixa o subtítulo cinza e levemente transparente */
  disabled: PropTypes.bool
}

Subtitle.defaultProps = {
  disabled: false
}
