import React from 'react'
import Screen from 'containers/Screen'
import VerticalSpacer from 'components/VerticalSpacer'
import { TelegramDownload, TelegramCommunitiesList } from 'components/Telegram'

export default function ComunidadesScreen () {
  return (
    <Screen title='Comunidades no Telegram' setHeadTitle>
      <VerticalSpacer>
        <TelegramDownload />
        <TelegramCommunitiesList
          tipo={'estrategia concursos'}
        />
      </VerticalSpacer>
    </Screen>
  )
}
