import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import LessonCollapse from 'components/LessonCollapse'
import 'styles/LessonList.css'

export default class LessonList extends Component {
  state = {
    lessons: [],
    courseId: 0
  }

  componentWillMount() {
    const { lessons, courseId } = this.props;
    var lessonsMAp = lessons.map(m => {
      return ({
        ...m,
        nome: m.nome.replace('  ', ' ').replace('   ', ' ')
      })
    });

    const ordenado = lessonsMAp.sort((a, b) => {
      return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
    })

    this.setState({
      lessons: ordenado,
      courseId
    })

  }

  componentDidMount() {
    this.scrollParam()
  }

  scrollParam () {
    let url = window.location.search
    const urlParams = new URLSearchParams(url);
    let idAula = urlParams.get('aula')

    if(document.querySelector('#aula' + idAula)){
      let topBox = document.querySelector('#aula' + idAula).offsetTop
      setTimeout(() => {
        window.scroll({
          top: topBox - 20, 
          left: 0, 
          behavior: 'smooth'
        })
      }, 1000)
    }
  }

  render() {
    const { lessons, courseId } = this.state
    return (
      <Route>
        {({ match: { params } }) => (
          <div className='LessonList'>
            {lessons.map((lesson, index) => (
              <div key={lesson.id} className='LessonList-item' id={`aula${lesson.id}`}>
                <LessonCollapse
                  index={index}
                  lesson={lesson}
                  courseId={courseId}
                  isActive={parseInt(params.lessonId, 10) === lesson.id}
                />
              </div>
            ))}
          </div>
        )}
      </Route>
    )
  }
}

LessonList.propTypes = {
  lessons: PropTypes.array.isRequired,
  courseId: PropTypes.number.isRequired
}
