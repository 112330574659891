/**
 * Dependencies.
 */

import parse from 'date-fns/parse'
import { format } from 'modules/Date'
import formatNumber from 'format-number'

/**
 * Pluralize a word.
 * @param count
 * @param singular
 * @param plural
 * @returns {*}
 */

export function pluralize (count, singular, plural) {
  if (count <= 1) {
    return singular
  }

  if (plural) {
    return plural
  }

  return singular + 's'
}

/**
 * Converts YYYY-MM-DD to DD/MM/YYYY
 * @param date
 * @returns {string} brDate
 */

export function dateToBR (date) {
  return format(parse(date))
}


/**
 * Converts DD-MM-AAAA to YYYY/MM/DD
 * @param date
 * @returns {string} amDate
 */

 export function dateToAM (date) {
  date = date.split('/')
  return date[2] + '-' + date[1] + '-' + date[0]
}

/**
 * Filter List with regex
 * @param date
 * @returns {string} brDate
 */
export function filterList (q, list, propName) {
  if (!q || q === '') {
    return list
  }

  function escapeRegExp (s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  }
  const words = q.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, ' ')
    .split(/\s+/g)
    .map(s => s.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    .filter(s => !!s)
  const searchRegex = new RegExp(
    words
      .map((word, i) => `(?=.*\\b${escapeRegExp(word)})`)
      .join('') + '.+',
    'gi'
  )
  return list.filter(item => {
    let p = item[propName].normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return searchRegex.test(p) || p.toLowerCase().includes(q.toLowerCase())
  })
}

export function setCookie (cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie (cname) {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function deleteCookie (name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

export const ALL_COURSES_KEY = 'SubscriptionManager.allCourses'
export const SUBSCRIPTIONS_KEY = 'SubscriptionManager.subscriptions'

export function clearAllListCache () {
  localStorage.removeItem(ALL_COURSES_KEY)
  localStorage.removeItem(SUBSCRIPTIONS_KEY)
}

// Seta item na local Storage
export function setLocalStorage (name, value) {
  localStorage.setItem(name, value)
}

// Pega item da local Storage
export function getLocalStorage (name) {
  return localStorage.getItem(name)
}

// Pega item da local Storage
export function removeLocalStorage (name) {
  localStorage.removeItem(name)
}

// Valida Itens do Menu
export function validaItemMenu (item, lista) {
  return lista.includes(item)
}

// Pega o primeiro nome
export function firstName (nome) {
  let primeiroNome = nome.split(' ')
  primeiroNome = primeiroNome[0]
  return primeiroNome
}

// Pega o sobrenome
export function lastName (nome) {
  let nomeCompleto = nome.split(' ')
  let novoNome = ''
  nomeCompleto.map((texto, index) => {
    return index > 0 ? novoNome = novoNome + ' ' + texto : null
  })
  return novoNome
}

/**
 * Formats the given value to currency
 * @param value
 * @returns {*}
 */

export const toMoney = formatNumber({
  prefix: 'R$ ',
  decimalsSeparator: '.',
  integerSeparator: '.',
  decimal: ',',
  padRight: 2
})

/**
 * An empty function.
 */

export const noop = () => {}