import React from 'react'
import UserDetails from 'containers/UserDetails'
import { ContainerGeral } from './Styled'
import Aviso from './Components/Aviso/Aviso'
import Cabecalho from './Components/Cabecalho/Cabecalho'
import Planos from './Components/Planos/Planos'

export default function RenovacaoAntecipada () { 
  return (
    <UserDetails showSpinner>
      {details => {

        return (
          <ContainerGeral padding={false}>
            <Aviso dados={details.renovar_assinatura_antecipada} />
            <Cabecalho dados={details.renovar_assinatura_antecipada} />
            { details.renovar_assinatura_antecipada !== false ? <Planos dados={details.renovar_assinatura_antecipada} /> : null }
          </ContainerGeral>
        )
        
      }}
    </UserDetails>
  )
}
