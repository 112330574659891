/**
 * Dependencies.
 */

import updateObj from 'immutability-helper'
import createActionTypes from 'store/utils/createActionTypes'
import createReducer from 'store/utils/createReducer'
import { actionTypes as authActionTypes } from 'store/ducks/auth'
import User from 'api/User'

/**
 * Action Types
 */

export const actionTypes = createActionTypes('USER', [
  'FETCH_DETAILS',
  'FETCH_DETAILS_PENDING',
  'FETCH_DETAILS_FULFILLED',
  'FETCH_DETAILS_REJECTED',

  'FETCH_PROFILE',
  'FETCH_PROFILE_PENDING',
  'FETCH_PROFILE_FULFILLED',
  'FETCH_PROFILE_REJECTED',

  'UPDATE_USER',
  'UPDATE_USER_PENDING',
  'UPDATE_USER_FULFILLED',
  'UPDATE_USER_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  details: {},
  profile: null,
  isFetching: false,
  isSubmitting: false,
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Login Fulfilled.
   */

  [authActionTypes.LOGIN_FULFILLED]: (state, { payload }) =>
    updateObj(state, {
      details: {
        $merge: {
          name: payload.usuario.nome,
          email: payload.usuario.email,
          id: payload.usuario.id,
          dataCriacao: payload.usuario.data_criacao,
          score: payload.pontos,
          discount: payload.desconto,
          avatar: payload.imagem,
          email_confirmed: payload.email_confirmado,
          mostrar_assinatura: payload.mostrar_assinatura,
          tem_sistema_de_questoes: payload.tem_sistema_de_questoes,
          tem_renovacao: payload.tem_renovacao,
          renovar_assinatura: payload.renovar_assinatura ? payload.renovar_assinatura : false,
          tem_promocao: payload.tem_promocao,
          assinaturas: payload.total_assinaturas,
          vem_para_maior_2021: payload.vem_para_maior_2021,
          vem_para_maior_expire_2021: payload.vem_para_maior_expire_2021,
          ja_enviou_promocao_maio_2020: false,
          verticais: payload.verticais,
          blackfriday: payload.blackfriday,
          renovar_assinatura_antecipada: payload.renovar_assinatura_antecipada,
          ldi_url: payload.ldi_url,
          tem_vitalicia: payload.tem_vitalicia,
          percentual_desconto: payload.percentual_desconto
        }
      }
    }),

  /**
   * Fetch Profile.
   */

  [actionTypes.FETCH_PROFILE_PENDING]: state =>
    updateObj(state, {
      isFetching: { $set: true }
    }),

  [actionTypes.FETCH_PROFILE_FULFILLED]: (state, { payload }) =>
    updateObj(state, {
      $merge: {
        isFetching: false,
        profile: payload.data,
        error: null
      },
      details: {
        name: { $set: payload.data.nome }
      }
    }),

  [actionTypes.FETCH_PROFILE_REJECTED]: (state, { payload }) =>
    updateObj(state, {
      $merge: {
        isFetching: false,
        error: payload
      }
    }),

  /**
   * Update.
   */

  [actionTypes.UPDATE_USER_PENDING]: state =>
    updateObj(state, {
      isSubmitting: { $set: true }
    }),

  [actionTypes.UPDATE_USER_FULFILLED]: (state, { payload }) =>
    updateObj(state, {
      $merge: {
        isSubmitting: false,
        profile: payload.data,
        error: null
      },
      details: {
        name: { $set: payload.data.nome }
      }
    }),

  [actionTypes.UPDATE_USER_REJECTED]: (state, { payload }) =>
    updateObj(state, {
      $merge: {
        isSubmitting: false,
        error: payload
      }
    })
})

/**
 * Action Creators.
 */

export const fetchDetails = () => ({
  type: actionTypes.FETCH_DETAILS,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao receber informações do perfil'
  },
  payload: User.getDetails()
})

export const fetchProfile = () => ({
  type: actionTypes.FETCH_PROFILE,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao receber dados do usuário'
  },
  payload: User.getProfile()
})

const updateUser = (request, data, notificationsId) => ({
  type: actionTypes.UPDATE_USER,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao atualizar dados do usuário!',
    handleSuccess: true,
    defaultSuccessMessage: 'Dados atualizados com sucesso!',
    notificationsId
  },
  payload: request(data)
})

export const updateProfile = data =>
  updateUser(User.updateProfile, data)

export const updateEmail = (data, notificationsId) =>
  updateUser(User.updateEmail, data, notificationsId)

export const updatePassword = (data, notificationsId) =>
  updateUser(User.updatePassword, data, notificationsId)

/**
 * Selectors.
 */

export const isFetching = state =>
  state.user.isFetching

export const isSubmitting = state =>
  state.user.isSubmitting

export const getUserProfile = state =>
  state.user.profile

export const getUserDetails = state =>
  state.user.details

export const getError = state =>
  state.user.error
