/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import CourseEvaluation from 'api/CourseEvaluation'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('COURSE-EVALUATIONS', [
  'CREATE',
  'CREATE_PENDING',
  'CREATE_FULFILLED',
  'CREATE_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isSubmitting: false,
  error: null
}

/**
 * Invite Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.CREATE_PENDING]: state =>
    update(state, {
      isSubmitting: { $set: true }
    }),

  [actionTypes.CREATE_FULFILLED]: state =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: null }
    }),

  [actionTypes.CREATE_REJECTED]: (state, action) =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: action.payload }
    })
})

/**
 * Action creators.
 */

export const create = (id, data) => ({
  type: actionTypes.CREATE,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao enviar avaliação do curso!',
    handleSuccess: true,
    defaultSuccessMessage: 'Avaliação enviada com sucesso!',
    notificationsId: 'courseEvaluation'
  },
  payload: CourseEvaluation.create(id, data)
})

/**
 * Selectors.
 */

export const isSubmitting = state =>
  state.courseEvaluations.isSubmitting

export const getError = state =>
  state.courseEvaluations.error
