import React from 'react'
import PropTypes from 'prop-types'
import Box from 'components/Box'
import SectionTitle from 'components/SectionTitle'
import 'styles/Section.css'

export default function Section ({ title, children, boxOptions = {}, titleOptions = {}, padding = 30, ...props }) {

  return (
    <section className='Section' {...props}>
      <Box style={{ padding }} {...boxOptions}>
        { title ? <SectionTitle {...titleOptions}>{title}</SectionTitle> : null }
        {children}
      </Box>
    </section>
  )

}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}
