import React, { Component } from 'react'
import ControlledCollapse from './ControlledCollapse'

export default class Collapse extends Component {
  state = {
    isOpened: this.props.initialIsOpened
  }

  render () {
    return (
      <ControlledCollapse
        {...this.props}
        isOpened={this.state.isOpened}
        onToggle={() => {
          this.setState({ isOpened: !this.state.isOpened })
        }}
      />
    )
  }

  componentDidUpdate (prevProps) {
    if (prevProps.initialIsOpened !== this.props.initialIsOpened) {
      this.setState({
        isOpened: this.props.initialIsOpened
      })
    }
  }

  static defaultProps = {
    initialIsOpened: false
  }
}
