import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'styles/Calendar/CalendarCell.css'
import CalendarCellCourse from './CalendarCellCourse'
import Icon from 'components/Icon'
import { pluralize } from 'utils/helpers'
import { format } from 'modules/Date'

export default function CalendarCell ({ isAdjacentMonth, concourses, date, onClick, isActive }) {
  const className = classNames({
    CalendarCell: true,
    isActive,
    isAdjacentMonth,
    hasConcourses: concourses.length > 0
  })

  const count = concourses.length

  return (
    <div className='CalendarCell-wrapper'>
      <div className={className} onClick={concourses ? onClick : null}>
        <div className='CalendarCell-content'>
        <span className='CalendarCell-day'>
          {format(date, 'D')}
        </span>
          {count > 0 && (
            <div className='CalendarCell-bottom'>
              <CalendarCellCourse course={concourses[0]} />
            </div>
          )}
          {count > 1 && (
            <span className='CalendarCell-label'>
            Mais {count - 1} {pluralize(count - 1, 'curso')}
          </span>
          )}
        </div>
        {count > 0 && (
          <div className='CalendarCell-arrow'>
            <Icon name='angle-down' />
          </div>
        )}
      </div>
    </div>
  )
}

CalendarCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isAdjacentMonth: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  concourses: PropTypes.array
}
