import React from 'react'
import classNames from 'classnames'
import 'styles/SquaredItem.css'

export default function SquaredItem ({children, small = null, ...props}) {
  const className = classNames({
    SquaredItem: true,
    '-small': small,
  })

  return (
    <div className={className} {...props}>{children}</div>)
}
