import styled from 'styled-components'

export const BoxRegras = styled.section`
    display: inline-block;
    width: 100%;
`

export const BoxRegrasTitulo = styled.strong`
    display: inline-block;
    width: 100%;
    font-weight: bold;
`

export const BoxRegrasLista = styled.ul`
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    margin: 15px 0 0 0;
`

export const BoxRegrasListaItem = styled.li`
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    & + li {
        margin-top: 5px;
    }
`

export const BoxAtualizar = styled.section`
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media(max-width: 600px) {
        flex-direction: column;
    }

    & img {
        width: 30px;
        margin: 0 0 0 20px;

        @media(max-width: 600px) {
            margin: 20px 0 0 0;
        }

    }
`

export const BotaoAtualizar = styled.button`
    display: inline-block;
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    background-color: #FF6A58;
    text-transform: uppercase;
    padding: 10px 30px;
    cursor: pointer;
    text-align: center;

    &.carregando {
        opacity: 0.7;
        cursor: not-allowed;
    }
`

export const AvisoDaAtualizacao = styled.span`
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 0 20px;

    @media(max-width: 600px) {
        margin: 20px 0 0 0;
    }
`

export const SemResultados = styled.span`
    display: inline-block;
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0 0 0;
    width: 100%;
    text-align: center;
`
