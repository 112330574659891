import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import media from 'utils/mediaQuery'

export const Control = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  height: 40px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  overflow: hidden;

  ${ifProp('center', css`
    margin-left: auto;
    margin-right: auto;
  `)}

  ${media.lessThan('small')`
    width: 100%;
  `}
`

export const Button = styled.button.attrs({ type: 'button' })`
  min-width: 35px;
  padding: 3px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  color: #666;
  line-height: 0;

  &:not([disabled]):hover {
    background-color: ${prop('theme.colors.grayLight')};
  }

  &[disabled] {
    cursor: not-allowed;
    color: #666;
  }
`

export const Info = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`
