/**
 * Dependencies.
 */

import CardTokens from 'api/CardTokens'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('cardTokens', CardTokens, 'Cartão')
