import React from 'react'
import styled from 'styled-components'

const SelectOptions = styled.select`
  position: absolute;
  right: 40px;
  margin-top: -58px;
  max-width: 300px;

  @media only screen and (max-width: 800px) {
    position: relative;
    margin: 0 20px 20px 40px;
    max-width: 100%;
  }
`

export default function Select (props) {
  const changeSelect = (event) => {
    props.changeFiltro(event.target.value === 'true')
  }

  return (
    <SelectOptions className={'Input'} onChange={changeSelect}>
      <option value={'false'}>Não Arquivados</option>
      <option value={'true'}>Arquivados</option>
    </SelectOptions>
  )
}
