import React from 'react'

export default function Terms ({ text }) {
    return (
    text === "agreed" ?
    <React.Fragment>
        <ol>
        <li><strong><em> Do Objeto</em></strong></li>
        </ol>
        <p><em><span>1.1. A Estrat&eacute;gia Educacional Participa&ccedil;&otilde;es S/A, CNPJ 28.222.443/0001-46, e as empresas do grupo, doravante denominadas &ldquo;CONTRATADA&rdquo;, fornecer&atilde;o a voc&ecirc;, &ldquo;CONTRATANTE&rdquo;, por meio da plataforma de ensino dispon&iacute;vel no&nbsp; site&nbsp;</span></em><a href="http://questoes.estrategiaconcursos.com.br/"><em><span>questoes.estrategiaconcursos.com.br</span></em></a><em><span>,&nbsp;da CONTRATADA, o SISTEMA DE QUEST&Otilde;ES, abrangendo quest&otilde;es e coment&aacute;rios a quest&otilde;es, al&eacute;m de outros conte&uacute;dos que visam auxiliar os alunos no preparo para exames em geral, de acordo com as condi&ccedil;&otilde;es dispostas neste instrumento, ao qual denomina-se &ldquo;TERMOS DE USO&rdquo;.</span></em></p>
        <p><em><span>1.1.1. O CONTRATANTE &eacute; a PESSOA F&Iacute;SICA que vier a adquirir um produto da CONTRATADA.</span></em></p>
        <p><em><span>1.1.2. Para aquisi&ccedil;&atilde;o de um produto, o CONTRATANTE dever&aacute; se identificar por meio do preenchimento completo dos dados cadastrais exigidos pela CONTRATADA em seu site.</span></em></p>
        <p><em><span>1.1.3. A CONTRATADA, a seu exclusivo crit&eacute;rio, poder&aacute; solicitar dados adicionais para identifica&ccedil;&atilde;o do CONTRATANTE tanto como pr&eacute;-requisito para formaliza&ccedil;&atilde;o da compra quanto para a manuten&ccedil;&atilde;o do acesso &agrave; &Aacute;REA DO ALUNO.</span></em></p>
        <p><em><span>1.1.4. Realizado o cadastramento e realizada a aquisi&ccedil;&atilde;o do produto, o CONTRATANTE ter&aacute; acesso &agrave; plataforma de ensino por meio de seu nome de usu&aacute;rio (login) e de sua respectiva senha, que ser&atilde;o de uso EXCLUSIVO do CONTRATANTE.</span></em></p>
        <p><em><span>1.1.5. O CONTRATANTE, ap&oacute;s finalizar o cadastramento, apenas poder&aacute; alterar seus dados pessoais por meio de solicita&ccedil;&atilde;o para&nbsp;</span></em><a href="mailto:contato@estrategiaconcursos.com.br"><em><span>contato@estrategiaconcursos.com.br</span></em></a><em><span>, podendo a CONTRATADA solicitar informa&ccedil;&otilde;es adicionais para as efetivar.</span></em></p>
        <p><em><span>1.1.6. N&atilde;o se aplica a restri&ccedil;&atilde;o do subitem 1.1.5. para altera&ccedil;&atilde;o da senha e/ou dos dados de cart&atilde;o de cr&eacute;dito, que poder&atilde;o ser realizadas diretamente pelo CONTRATANTE no site da CONTRATADA.</span></em></p>
        <p><em><span>1.1.7. &Eacute; terminantemente vedado ao CONTRATANTE ceder a terceiros o uso de seu usu&aacute;rio (login) e senha, seja de forma gratuita ou onerosa.</span></em></p>
        <p><em><span>1.1.8. A CONTRATADA, caso verifique, por meio do uso de ferramentas de Tecnologia da Informa&ccedil;&atilde;o e Comunica&ccedil;&atilde;o &ndash; TIC, o descumprimento do subitem 1.1.7., poder&aacute;:</span></em></p>
        <ol>
        <li><em><span>a) suspender preventivamente o acesso do CONTRATANTE &agrave; plataforma de ensino, requerendo esclarecimentos para o fato constatado; e,</span></em></li>
        <li><em><span>b) n&atilde;o sendo apresentado ou sendo insuficientes os esclarecimentos prestados, cancelar definitivamente o acesso do CONTRATANTE &agrave; plataforma de ensino, sem a devolu&ccedil;&atilde;o de quaisquer valores.</span></em></li>
        </ol>
        <p><em><span>1.2. Ao adquirir qualquer produto da CONTRATADA, o CONTRATANTE concorda em se vincular a todas as cl&aacute;usulas do presente TERMOS DE USO, bem como com a Pol&iacute;tica de Privacidade da CONTRATADA.</span></em></p>
        <p><em><span>1.2.1. A aceita&ccedil;&atilde;o deste TERMOS DE USO pelo CONTRATANTE se dar&aacute; por meio de clique no bot&atilde;o &ldquo;Li e aceito os termos de uso&rdquo; e ser&aacute; condi&ccedil;&atilde;o para a aquisi&ccedil;&atilde;o de qualquer produto da CONTRATADA.</span></em></p>
        <p><em><span>1.2.2. Caso o potencial CONTRATANTE n&atilde;o concorde com o presente TERMOS DE USO, n&atilde;o poder&aacute; realizar a aquisi&ccedil;&atilde;o de nenhum produto, devendo se abster de acessar e utilizar a plataforma de ensino por n&atilde;o figurar como CONTRATANTE.</span></em></p>
        <ol start="2">
        <li><strong><em> Do Conte&uacute;do</em></strong></li>
        </ol>
        <p><em><span>2.1. O CONTE&Uacute;DO oferecido pela CONTRATADA &eacute; obtido mediante contratos de cess&atilde;o de direitos autorais e/ou de presta&ccedil;&atilde;o de servi&ccedil;os firmados com os professores, sendo estes&nbsp;respons&aacute;veis pelo material produzido.</span></em></p>
        <p><em><span>2.1.1. Todo CONTE&Uacute;DO oferecido pela CONTRATADA &eacute; protegido pelas leis de direitos autorais e outras correlatas existentes ou que vierem a existir no ordenamento jur&iacute;dico.</span></em></p>
        <p><em><span>2.1.2. A CONTRATADA tem autoriza&ccedil;&atilde;o dos professores para utilizar sua imagem e os materiais produzidos, raz&atilde;o pela qual qualquer reprodu&ccedil;&atilde;o, cess&atilde;o ou divulga&ccedil;&atilde;o indevidas, pelo CONTRATANTE, do CONTE&Uacute;DO a ele disponibilizado constitui, al&eacute;m da viola&ccedil;&atilde;o de direitos de propriedade intelectual da CONTRATADA, viola&ccedil;&atilde;o dos direitos de imagem dos professores, ensejando responsabilidade exclusiva do CONTRATANTE por eventual repara&ccedil;&atilde;o civil, sem preju&iacute;zo quanto &agrave; responsabiliza&ccedil;&atilde;o penal, se o caso.</span></em></p>
        <p><em><span>2.2. O CONTE&Uacute;DO ser&aacute; disponibilizado exclusivamente via plataforma de ensino, no site&nbsp;</span></em><a href="http://questoes.estrategiaconcursos.com.br/"><em><span>questoes.estrategiaconcursos.com.br</span></em></a><em><span>, da CONTRATADA (&ldquo;&Aacute;REA DO ALUNO&rdquo;).</span></em></p>
        <p><em><span>2.2.1. A CONTRATADA n&atilde;o disponibiliza seu CONTE&Uacute;DO em DVDs, pendrives ou qualquer tipo de m&iacute;dia de armazenamento, devendo o acesso ao CONTE&Uacute;DO ocorrer apenas na &Aacute;REA DO ALUNO.</span></em></p>
        <p><em><span>2.3. O acesso ao CONTE&Uacute;DO &eacute; de uso pessoal e intransfer&iacute;vel do CONTRATANTE, sendo ilegal ou contr&aacute;rio ao presente TERMOS DE USO sua distribui&ccedil;&atilde;o, venda, rateio, compartilhamento ou redistribui&ccedil;&atilde;o por qualquer modalidade, bem como a participa&ccedil;&atilde;o do CONTRATANTE em grupos, canais, sites ou plataformas que assim atuem.</span></em></p>
        <p><em><span>2.3.1. &Eacute; contr&aacute;rio ao presente TERMOS DE USO, ensejando o cancelamento do acesso do CONTRATANTE &agrave; &Aacute;REA DO ALUNO, sem qualquer devolu&ccedil;&atilde;o do valor da compra, tanto a participa&ccedil;&atilde;o quanto &agrave; efetiva redistribui&ccedil;&atilde;o - por meio de c&oacute;pias reprogr&aacute;ficas f&iacute;sicas ou c&oacute;pias digitais - de forma gratuita ou onerosa, entre outros, pelos seguintes meios:</span></em></p>
        <ol>
        <li><em><span>a) sites de rateio;</span></em></li>
        <li><em><span>b) sites de ofertas de compra e venda de produtos diversos;</span></em></li>
        <li><em><span>c) e-mail, independentemente do dom&iacute;nio utilizado;</span></em></li>
        <li><em><span>d) aplicativos mobile, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo;</span></em></li>
        <li><em><span>e) bibliotecas virtuais;</span></em></li>
        <li><em><span>f) pastas compartilhadas, independentemente de ser em aparelho local ou em nuvem;</span></em></li>
        <li><em><span>g) plataformas de compartilhamentos de v&iacute;deo, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo; e</span></em></li>
        <li><em><span>h) qualquer m&iacute;dia ou rede social, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo.</span></em></li>
        </ol>
        <p><em><span>2.4. Al&eacute;m da licen&ccedil;a limitada de uso estabelecida neste TERMOS DE USO, o CONTRATANTE n&atilde;o tem qualquer outro direito, t&iacute;tulo ou propriedade sobre o CONTE&Uacute;DO a ele disponibilizado&nbsp;ou produzido por ele na plataforma, reconhecendo que, em quaisquer circunst&acirc;ncias, os seus direitos quanto ao CONTE&Uacute;DO ser&atilde;o limitados por este TERMOS DE USO, pelos direitos autorais da CONTRATADA, pelas leis de propriedade intelectual e, ainda, por toda a legisla&ccedil;&atilde;o aplic&aacute;vel.</span></em></p>
        <p><em><span>2.4.1. Ao fazer coment&aacute;rios ou quaisquer intera&ccedil;&otilde;es na plataforma, o CONTRATANTE cede, a t&iacute;tulo gratuito e definitivo, todos os direitos autorais sobre referido conte&uacute;do e se compromete a seguir todas as demais obriga&ccedil;&otilde;es previstas neste TERMOS DE USO.</span></em></p>
        <p><em><span>2.4.2. Apenas para que n&atilde;o haja qualquer d&uacute;vida, ainda que o CONTRATANTE deixe de ser assinante do SISTEMA DE QUEST&Otilde;ES, os coment&aacute;rios e intera&ccedil;&otilde;es feitos por ele na plataforma permanecer&atilde;o nela dispon&iacute;veis, a crit&eacute;rio da CONTRATADA.</span></em></p>
        <p><em><span>2.5. O CONTRATANTE declara ter conhecimento da descri&ccedil;&atilde;o do produto disponibilizado pela CONTRATADA, estando ciente de que, em fun&ccedil;&atilde;o de suas especificidades, o produto pode n&atilde;o conter quest&otilde;es voltadas &agrave; prepara&ccedil;&atilde;o para todas as provas de admiss&atilde;o.</span></em></p>
        <p><em><span>2.6. A CONTRATADA se reserva no direito de efetuar eventuais modifica&ccedil;&otilde;es nos CONTE&Uacute;DOS disponibilizados, a fim de preservar a boa qualidade do material fornecido.</span></em></p>
        <p><em><span>2.7. A CONTRATADA n&atilde;o poder&aacute; ser responsabilizada por falhas ou atrasos na disponibiliza&ccedil;&atilde;o dos CONTE&Uacute;DOS que decorrerem de caso fortuito ou for&ccedil;a maior.</span></em></p>
        <ol start="3">
        <li><strong><em> Dos Produtos</em></strong></li>
        </ol>
        <p><em><span>3.1. O CONTRATANTE poder&aacute; adquirir da CONTRATADA os seguintes produtos:</span></em></p>
        <ol>
        <li><em><span>a) assinatura anual (&ldquo;ASSINATURA ANUAL&rdquo;);</span></em></li>
        <li><em><span>b) assinatura bianual (&ldquo;ASSINATURA BIANUAL&rdquo;);</span></em></li>
        <li><em><span>c) assinatura vital&iacute;cia (&ldquo;ASSINATURA VITAL&Iacute;CIA&rdquo;).</span></em></li>
        </ol>
        <p><em><span>3.2. A CONTRATADA fornecer&aacute; ao CONTRATANTE acesso ao&nbsp;produto&nbsp;adquirido, de acordo com a sua dura&ccedil;&atilde;o e valores estabelecidos no momento da contrata&ccedil;&atilde;o, por meio de CONTE&Uacute;DO disponibilizado, via internet, na &Aacute;REA DO ALUNO.</span></em></p>
        <p><em><span>3.3. Os PRODUTOS ficar&atilde;o dispon&iacute;veis pelo tempo de vig&ecirc;ncia de sua contrata&ccedil;&atilde;o, estando ciente o CONTRATANTE de que tais datas s&atilde;o improrrog&aacute;veis.</span></em></p>
        <p><em><span>3.4. Na hip&oacute;tese de ASSINATURA ANUAL ou BIANUAL, o CONTRATANTE declara estar ciente de que haver&aacute; renova&ccedil;&atilde;o autom&aacute;tica da assinatura ao final de 1 (um) ano ou 2 (dois) anos, respectivamente, podendo, ainda, haver reajuste do valor do produto, segundo a varia&ccedil;&atilde;o no per&iacute;odo do IGP-M (&Iacute;ndice Geral de Pre&ccedil;os do Mercado) ou outro &iacute;ndice que venha substitu&iacute;-lo.</span></em></p>
        <p><em><span>3.4.1. Caso a varia&ccedil;&atilde;o do IGP-M no per&iacute;odo seja negativa, n&atilde;o haver&aacute; altera&ccedil;&atilde;o do valor.</span></em></p>
        <p><em><span>3.4.2. A crit&eacute;rio exclusivo da CONTRATADA, a aplica&ccedil;&atilde;o do &iacute;ndice de reajuste poder&aacute; ser dispensada.</span></em></p>
        <p><em><span>3.5. A renova&ccedil;&atilde;o da ASSINATURA depende do pagamento antecipado do novo valor do per&iacute;odo a ser adquirido, seja por cart&atilde;o - &agrave; vista ou parcelado em at&eacute; 12 (doze) vezes - ou por boleto - este exclusivamente &agrave; vista.</span></em></p>
        <p><em><span>3.6. Na hip&oacute;tese de ASSINATURA VITAL&Iacute;CIA, a CONTRATADA fornecer&aacute; ao CONTRATANTE, por tempo indeterminado, mediante pagamento de um valor &uacute;nico, acesso &agrave; plataforma.</span></em></p>
        <p><em><span>3.6.1. A ASSINATURA VITAL&Iacute;CIA inclui as funcionalidades dispon&iacute;veis para acesso no momento da compra, ficando a crit&eacute;rio da CONTRATADA a disponibiliza&ccedil;&atilde;o de outras lan&ccedil;adas ap&oacute;s a aquisi&ccedil;&atilde;o da ASSINATURA VITAL&Iacute;CIA pelo CONTRATANTE.</span></em></p>
        <p><em><span>3.6.2. Apenas para que n&atilde;o se tenha qualquer d&uacute;vida, aplica-se &agrave; ASSINATURA VITAL&Iacute;CIA o disposto nos itens 1.1.7 e 1.1.8, sendo expressamente vedado, sob qualquer justificativa, o compartilhamento de usu&aacute;rio e senha.</span></em></p>
        <p><em><span>3.6.3. A CONTRATADA, caso verifique, por meio do uso de ferramentas de Tecnologia da Informa&ccedil;&atilde;o e Comunica&ccedil;&atilde;o &ndash; TIC, acessos utilizando o usu&aacute;rio e senha do CONTRATADO feitos por 3 (tr&ecirc;s) ou mais IPs ou, ainda, por IPs localizados em estados diversos daquele informado pelo CONTRATANTE como sendo o de sua resid&ecirc;ncia, poder&aacute;:</span></em></p>
        <ol>
        <li><em><span>a) suspender preventivamente o acesso do CONTRATANTE &agrave; plataforma de ensino, requerendo esclarecimentos para o fato constatado; e,</span></em></li>
        <li><em><span>b) n&atilde;o sendo apresentado ou sendo insuficientes os esclarecimentos prestados, cancelar definitivamente o acesso do CONTRATANTE &agrave; plataforma de ensino, sem a devolu&ccedil;&atilde;o de quaisquer valores.</span></em></li>
        <li><strong><em> Do acesso &agrave; &Aacute;REA DO ALUNO</em></strong></li>
        </ol>
        <p><em><span>4.1. O acesso &agrave; &Aacute;REA DO ALUNO ocorre com a utiliza&ccedil;&atilde;o de login e senha cadastrados pelo CONTRATANTE, os quais s&atilde;o liberados ap&oacute;s a aprova&ccedil;&atilde;o do cadastro e a confirma&ccedil;&atilde;o do pagamento, podendo ocorrer somente enquanto a ASSINATURA adquirida estiver ativa.</span></em></p>
        <p><em><span>4.2. O CONTRATANTE &eacute; respons&aacute;vel pelas informa&ccedil;&otilde;es fornecidas no momento de seu cadastro, n&atilde;o se responsabilizando a CONTRATADA por informa&ccedil;&otilde;es incorretas ou inver&iacute;dicas por ele apresentadas.</span></em></p>
        <p><em><span>4.3. O CONTRATANTE &eacute; respons&aacute;vel por manter todas as informa&ccedil;&otilde;es pessoais e de contato fornecidas devidamente atualizadas, realizando-as por meio do&nbsp;</span></em><a href="mailto:contato@estrategiaconcursos.com.br"><em><span>contato@estrategiaconcursos.com.br</span></em></a><em><span>.</span></em></p>
        <p><em><span>4.4. O CONTRATANTE declara estar ciente de que, caso forne&ccedil;a informa&ccedil;&otilde;es incorretas ou inver&iacute;dicas no momento de seu cadastro, seu acesso &agrave; &Aacute;REA DO ALUNO poder&aacute; ser cancelado, independentemente de qualquer formalidade e sem que haja qualquer reembolso de valores pela CONTRATADA.</span></em></p>
        <p><em><span>4.5. O acesso &agrave; &Aacute;REA DO ALUNO &eacute; oneroso e personal&iacute;ssimo, sendo proibido ao CONTRATANTE compartilhar ou revender, por qualquer meio, seu login e senha, sob pena de ter seu acesso ao site cancelado, sem a devolu&ccedil;&atilde;o do valor da compra.</span></em></p>
        <p><em><span>4.6. &Eacute; responsabilidade do CONTRATANTE o sigilo do seu login e de sua senha, para evitar o acesso n&atilde;o autorizado de terceiros &agrave; &Aacute;REA DO ALUNO, sendo ele o &uacute;nico respons&aacute;vel por eventual uso inadequado decorrente de acessos indevidos.</span></em></p>
        <p><em><span>4.7. Caso o CONTRATANTE suspeite que seu usu&aacute;rio e senha sejam de conhecimento de terceiro, dever&aacute; imediatamente solicitar a altera&ccedil;&atilde;o do usu&aacute;rio por meio do&nbsp;</span></em><a href="mailto:contato@estrategiaconcursos.com.br"><em><span>contato@estrategiaconcursos.com.br</span></em></a><em><span>&nbsp;e alterar sua senha diretamente na plataforma de ensino.</span></em></p>
        <p><em><span>4.8. &Eacute; vedado o acesso &agrave; &Aacute;REA DO ALUNO com o mesmo login e senha por 2 (dois) ou mais dispositivos eletr&ocirc;nicos diferentes simultaneamente, podendo a CONTRATADA realizar o logoff autom&aacute;tico do dispositivo que havia iniciado a primeira sess&atilde;o.</span></em></p>
        <p><em><span>4.8.1. Eventual tentativa de dois ou mais acessos simult&acirc;neos com o mesmo login e senha &agrave; &Aacute;REA DO ALUNO poder&aacute; ensejar o bloqueio de seu acesso.</span></em></p>
        <p><em><span>4.8.2. A CONTRATADA, caso verifique, por meio do uso de ferramentas de Tecnologia da Informa&ccedil;&atilde;o e Comunica&ccedil;&atilde;o &ndash; TIC, que o CONTRATANTE tenha cedido a terceiros o uso de seu login e senha ou mesmo que n&atilde;o tenha tomado as precau&ccedil;&otilde;es necess&aacute;rias para a manuten&ccedil;&atilde;o do sigilo destes, poder&aacute;:</span></em></p>
        <ol>
        <li><em><span>a) suspender preventivamente o acesso do CONTRATANTE, titular do aludido login e senha, &agrave; plataforma de ensino, requerendo esclarecimentos para o fato constatado; e</span></em></li>
        <li><em><span>b) n&atilde;o sendo apresentado ou sendo insuficientes os esclarecimentos prestados, cancelar definitivamente o acesso do CONTRATANTE &agrave; plataforma de ensino, sem a devolu&ccedil;&atilde;o de quaisquer valores.</span></em></li>
        </ol>
        <p><em><span>4.9. O servi&ccedil;o disponibilizado na &Aacute;REA DO ALUNO depende da funcionalidade de diversos fatores, alguns alheios ao controle da CONTRATADA, tais como a intera&ccedil;&atilde;o de servidores e servi&ccedil;os de telecomunica&ccedil;&otilde;es de terceiros e a adequa&ccedil;&atilde;o dos equipamentos do CONTRATANTE, competindo a este observar os requisitos m&iacute;nimos para acesso ao servi&ccedil;o almejado.</span></em></p>
        <p><em><span>4.10. A CONTRATADA adotar&aacute; todas as medidas cab&iacute;veis para manter no ar o sistema pelo maior tempo poss&iacute;vel, mas, devido &agrave; natureza da arquitetura web, o CONTRATANTE declara estar ciente de que problemas podem ocorrer.</span></em></p>
        <p><em><span>4.11. O CONTRATANTE declara estar ciente de que o sistema da CONTRATADA pode ter funcionalidades atualizadas, adicionadas, removidas ou alteradas a qualquer instante e sem aviso pr&eacute;vio, sem que a ele seja devida qualquer devolu&ccedil;&atilde;o de valores ou indeniza&ccedil;&atilde;o a qualquer t&iacute;tulo.</span></em></p>
        <p><em><span>4.12. A CONTRATADA se reserva no direito de fazer interrup&ccedil;&otilde;es programadas, suspens&otilde;es t&eacute;cnicas e/ou operacionais ou, ainda, paradas para manuten&ccedil;&atilde;o nos servi&ccedil;os online, emergenciais ou n&atilde;o.</span></em></p>
        <ol start="5">
        <li><strong><em> Das Responsabilidades</em></strong></li>
        </ol>
        <p><em><span>5.1. Cabe &agrave; CONTRATADA disponibilizar o CONTE&Uacute;DO ao CONTRATANTE, via &Aacute;REA DO ALUNO, ap&oacute;s a confirma&ccedil;&atilde;o do respectivo pagamento e aprova&ccedil;&atilde;o de seu cadastro.</span></em></p>
        <p><em><span>5.2. Cabe ao CONTRATANTE a completa e fidedigna identifica&ccedil;&atilde;o no seu ato de cadastro no site, reservando-se a CONTRATADA o direito de, a qualquer tempo, solicitar informa&ccedil;&otilde;es e/ou comprovantes adicionais para a libera&ccedil;&atilde;o do acesso, para a efetiva&ccedil;&atilde;o da compra e para a manuten&ccedil;&atilde;o do acesso &agrave; &Aacute;REA DO ALUNO.</span></em></p>
        <p><em><span>5.2.1. A CONTRATADA poder&aacute;, inclusive, solicitar c&oacute;pias de documentos que contenham identifica&ccedil;&atilde;o com digital e foto para, junto aos &oacute;rg&atilde;os competentes, realizar a confirma&ccedil;&atilde;o biom&eacute;trica ou facial.</span></em></p>
        <p><em><span>5.3. Cabe ao CONTRATANTE a observ&acirc;ncia dos direitos autorais que resguardam as obras intelectuais objeto do presente contrato, nos termos da Lei n&ordm; 9.610, de 19 de fevereiro de 1998, e de outras normas correlatas existentes ou que vierem a ser introduzidas no ordenamento jur&iacute;dico.</span></em></p>
        <p><em><span>5.3.1. Em caso de desrespeito ao previsto no item 5.3. ou a qualquer cl&aacute;usula deste TERMOS DE USO, a CONTRATADA se reserva o direito de cancelar o acesso do CONTRATANTE ao site, sem a devolu&ccedil;&atilde;o de qualquer valor, al&eacute;m das provid&ecirc;ncias legais cab&iacute;veis na defesa dos direitos da CONTRATADA, de seus professores e dos seus leg&iacute;timos alunos.</span></em></p>
        <p><em><span>5.4. O CONTRATANTE se responsabiliza por toda e qualquer intera&ccedil;&atilde;o que fizer na plataforma, sendo expressamente proibida a publica&ccedil;&atilde;o de conte&uacute;do il&iacute;cito, abusivo, preconceituoso ou que viole direitos de terceiros. Ademais, &eacute; vedada a publica&ccedil;&atilde;o de propaganda comercial, pol&iacute;tica, religiosa ou ideol&oacute;gica, bem como de links externos.</span></em></p>
        <p><em><span>5.5. A CONTRATADA n&atilde;o se responsabiliza por quaisquer danos ou preju&iacute;zos ocasionados por outros usu&aacute;rios da plataforma ou por suas publica&ccedil;&otilde;es.</span></em></p>
        <p><em><span>5.6. A CONTRATADA tamb&eacute;m n&atilde;o est&aacute; obrigada a rever, corrigir, editar ou excluir conte&uacute;dos postados pelos usu&aacute;rios, independentemente de sua natureza.</span></em></p>
        <p><em><span>5.7. Caso o CONTRATANTE seja identificado como participante de grupos ou sites de rateio, compartilhamento ou (re)distribui&ccedil;&atilde;o de qualquer CONTE&Uacute;DO da CONTRATADA, esta cancelar&aacute; o seu acesso &agrave; &Aacute;REA DO ALUNO, sem qualquer devolu&ccedil;&atilde;o do valor da compra e sem preju&iacute;zo quanto &agrave;s provid&ecirc;ncias legais cab&iacute;veis na defesa dos direitos da CONTRATADA, de seus professores e dos seus leg&iacute;timos alunos.</span></em></p>
        <p><em><span>5.8. O acesso ao CONTE&Uacute;DO &eacute; de uso pessoal e intransfer&iacute;vel do CONTRATANTE, sendo ilegal ou contr&aacute;rio ao presente TERMOS DE USO sua distribui&ccedil;&atilde;o, venda, rateio, compartilhamento ou redistribui&ccedil;&atilde;o por qualquer modalidade, bem como a participa&ccedil;&atilde;o do CONTRATANTE em grupos, canais, sites ou plataformas que assim atuem.</span></em></p>
        <p><em><span>5.8.1. &Eacute; contr&aacute;rio ao presente TERMOS DE USO, ensejando o cancelamento do acesso do CONTRATANTE &agrave; &Aacute;REA DO ALUNO, sem qualquer devolu&ccedil;&atilde;o do valor da compra, tanto a participa&ccedil;&atilde;o quanto &agrave; efetiva redistribui&ccedil;&atilde;o - por meio de c&oacute;pias reprogr&aacute;ficas f&iacute;sicas ou c&oacute;pias digitais - de forma gratuita ou onerosa, entre outros, pelos seguintes meios:</span></em></p>
        <ol>
        <li><em><span>a) sites de rateio;</span></em></li>
        <li><em><span>b) sites de ofertas de compra e venda de produtos diversos;</span></em></li>
        <li><em><span>c) e-mail, independentemente do dom&iacute;nio utilizado;</span></em></li>
        <li><em><span>d) aplicativos mobile, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo;</span></em></li>
        <li><em><span>e) bibliotecas virtuais;</span></em></li>
        <li><em><span>f) pastas compartilhadas, independentemente de ser em aparelho local ou em nuvem;</span></em></li>
        <li><em><span>g) plataformas de compartilhamentos de v&iacute;deo, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo; e</span></em></li>
        <li><em><span>h) qualquer m&iacute;dia ou rede social, independentemente da tecnologia utilizada e de a redistribui&ccedil;&atilde;o ocorrer de forma individual ou em grupo.</span></em></li>
        </ol>
        <p><em><span>5.9. Ao CONTRATANTE &eacute; proibido manipular ou adulterar, de qualquer forma, o conte&uacute;do do material fornecido pela CONTRATADA.</span></em></p>
        <p><em><span>5.9.1. Caso a CONTRATADA identifique o CONTRATANTE respons&aacute;vel pela aquisi&ccedil;&atilde;o do material que veio a ser adulterado, aquela cancelar&aacute; o acesso deste &agrave; &Aacute;REA DO ALUNO, sem qualquer devolu&ccedil;&atilde;o do valor da compra e sem preju&iacute;zo quanto &agrave; ado&ccedil;&atilde;o das provid&ecirc;ncias cab&iacute;veis em &acirc;mbito civil e/ou penal, se o caso.</span></em></p>
        <p><em><span>5.9.2. O cancelamento de que trata o item 5.5.1. independe de quem tenha efetivamente realizado a adultera&ccedil;&atilde;o no material.</span></em></p>
        <p><em><span>5.10. O CONTRATANTE dever&aacute; utilizar o CONTE&Uacute;DO a ele disponibilizado de acordo com o ordenamento jur&iacute;dico brasileiro, com a moral e os bons costumes geralmente aceitos, abstendo-se de usar, explorar, reproduzir ou divulgar, indevidamente, por qualquer meio, o conte&uacute;do disponibilizado na &Aacute;REA DO ALUNO.</span></em></p>
        <p><em><span>5.10.1. Os CONTE&Uacute;DOS disponibilizados n&atilde;o podem ser repassados, copiados, reproduzidos, distribu&iacute;dos, transmitidos, difundidos, exibidos, vendidos, licenciados, adaptados ou, de outro modo, explorados para quaisquer fins, sem o consentimento pr&eacute;vio e por escrito da CONTRATADA.</span></em></p>
        <p><em><span>5.11. O CONTRATANTE responder&aacute; civil e criminalmente pela utiliza&ccedil;&atilde;o inadequada do sistema, por qualquer interfer&ecirc;ncia no seu funcionamento que venha a prejudicar a funcionalidade do ambiente virtual da CONTRATADA, por qualquer infra&ccedil;&atilde;o aos direitos autorais e patrimoniais da CONTRATADA em rela&ccedil;&atilde;o ao CONTE&Uacute;DO fornecido, objeto deste contrato, e por qualquer infra&ccedil;&atilde;o ao presente TERMOS DE USO.</span></em></p>
        <ol start="6">
        <li><strong><em> Do Cancelamento</em></strong></li>
        </ol>
        <p><em><span>6.1. Em caso de descumprimento pelo CONTRATANTE de qualquer cl&aacute;usula deste TERMOS DE USO, poder&aacute; a CONTRATADA cancelar, de imediato e sem aviso pr&eacute;vio, seu acesso &agrave; &Aacute;REA DO ALUNO e ao CONTE&Uacute;DO nela disponibilizado, sem a devolu&ccedil;&atilde;o de qualquer valor.</span></em></p>
        <p><em><span>6.2. A CONTRATADA praticar&aacute; a pol&iacute;tica de "GARANTIA DE SATISFA&Ccedil;&Atilde;O OU DINHEIRO DE VOLTA", pela qual o CONTRATANTE poder&aacute; solicitar o cancelamento da compra e a restitui&ccedil;&atilde;o do valor investido, exclusivamente na 1&ordf; (primeira) aquisi&ccedil;&atilde;o do SISTEMA DE QUEST&Otilde;ES, at&eacute; o 30&ordm; (trig&eacute;simo) dia ap&oacute;s a aquisi&ccedil;&atilde;o.</span></em></p>
        <p><em><span>6.3. Fora das condi&ccedil;&otilde;es descritas no item 6.2., n&atilde;o haver&aacute; qualquer devolu&ccedil;&atilde;o do valor da compra.</span></em></p>
        <p><em><span>6.4. As solicita&ccedil;&otilde;es de cancelamento, em qualquer caso, dever&atilde;o ocorrer pelo e-mail&nbsp;</span></em><a href="mailto:cancelamento@estrategiaconcursos.com.br"><em><span>cancelamento@estrategiaconcursos.com.br</span></em></a><em><span>.</span></em></p>
        <p><em><span>6.5. Em se tratando de pagamento por meio de boleto banc&aacute;rio, a restitui&ccedil;&atilde;o dos valores ocorrer&aacute; em at&eacute; 20 (vinte) dias &uacute;teis, a contar do aceite pela CONTRATADA quanto ao fornecimento dos dados banc&aacute;rios corretos pelo CONTRATANTE.</span></em></p>
        <p><em><span>6.6. Tratando-se de pagamento por meio de cart&atilde;o de cr&eacute;dito, a restitui&ccedil;&atilde;o depender&aacute; da data de fechamento da fatura do CONTRATANTE e da data do reconhecimento da solicita&ccedil;&atilde;o de cancelamento pelo Banco Emissor do CONTRATANTE.</span></em></p>
        <ol start="7">
        <li><em><span> Das condi&ccedil;&otilde;es gerais</span></em></li>
        </ol>
        <p><em><span>7.1. D&uacute;vidas e casos omissos devem ser encaminhados &agrave; CONTRATADA pelo e-mail&nbsp;</span></em><a href="mailto:contato@estrategiaconcursos.com.br"><em><span>contato@estrategiaconcursos.com.br</span></em></a><em><span>&nbsp;ou por outros meios de contato disponibilizados em seu site.</span></em></p>
        <p><em><span>7.2. Mesmo que qualquer parte deste TERMOS DE USO seja considerada inv&aacute;lida ou inexequ&iacute;vel, as demais disposi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</span></em></p>
        <p><em><span>7.3. A toler&acirc;ncia ou o n&atilde;o exerc&iacute;cio de quaisquer direitos assegurados neste TERMOS DE USO ou na lei em geral n&atilde;o importar&aacute; em nova&ccedil;&atilde;o ou em ren&uacute;ncia a qualquer desses direitos, podendo a CONTRATADA e o CONTRATANTE exerc&ecirc;-los durante a vig&ecirc;ncia deste TERMOS DE USO.</span></em></p>
        <p><em><span>7.4. Este TERMOS DE USO poder&aacute; ser alterado, a qualquer momento, pela CONTRATADA.</span></em></p>
        <p><em><span>7.5. Fica eleito o foro da comarca da cidade de S&atilde;o Paulo - SP, com expressa ren&uacute;ncia a qualquer outro, por mais privilegiado que seja, para dirimir as quest&otilde;es oriundas da interpreta&ccedil;&atilde;o e execu&ccedil;&atilde;o do presente TERMOS DE USO.</span></em></p>
    </React.Fragment>
    :
    <React.Fragment>
        <p><strong>DECLARA&Ccedil;&Atilde;O DE VERACIDADE DE INFORMA&Ccedil;&Otilde;ES</strong></p>
        <p>&nbsp;</p>
        <ol>
        <li><span>AO CLICAR EM &ldquo;LI E CONCORDO&rdquo; COM ESSA DECLARA&Ccedil;&Atilde;O DE VERACIDADE DE INFORMA&Ccedil;&Otilde;ES, VOC&Ecirc; DECLARA, PARA TODOS OS FINS, QUE AS INFORMA&Ccedil;&Otilde;ES PRESTADAS NO MOMENTO DA AQUISI&Ccedil;&Atilde;O DO PRODUTO &ldquo;SISTEMA DE QUEST&Otilde;ES&rdquo;, DA EMPRESA ESTRAT&Eacute;GIA EDUCACIONAL, CNPJ 13.877.842/0001-78, </span><strong>ESPECIALMENTE AS RELACIONADAS &Agrave; CONCESS&Atilde;O DE BENEF&Iacute;CIOS ESPECIAIS PARA AQUISI&Ccedil;&Atilde;O DO REFERIDO PRODUTO</strong><span>, S&Atilde;O </span><strong>VERDADEIRAS</strong><span>.</span></li>
        <li><span>A CONCORD&Acirc;NCIA COM A PRESENTE DECLARA&Ccedil;&Atilde;O </span><strong>N&Atilde;O</strong><span> O (A) EXIMIR&Aacute; DA ENTREGA DE TODOS OS DOCUMENTOS NECESS&Aacute;RIOS &Agrave; COMPROVA&Ccedil;&Atilde;O DAS INFORMA&Ccedil;&Otilde;ES ORA DECLARADAS.</span></li>
        <li><span>ENTENDE-SE POR DOCUMENTOS COMPROBAT&Oacute;RIOS:</span></li>
        <ol>
        <li><span>RECIBOS DE PAGAMENTOS EFETUADOS PARA A AQUISI&Ccedil;&Atilde;O DE SISTEMAS DE QUEST&Otilde;ES CUJAS SOMAS SEJAM MAIORES QUE R$ 100,00, COM DATAS A PARTIR DE JUNHO DE 2019 E/OU;</span></li>
        <li><span>PRINTS DAS TELAS DE SISTEMAS DE QUEST&Otilde;ES, CONTENDO O VALOR PAGO E O NOME DO CLIENTE, COM DATA DE AQUISI&Ccedil;&Atilde;O SUPERIOR A JUNHO 2019.</span></li>
        </ol>
        <li><span>CASO SUA OP&Ccedil;&Atilde;O SEJA POR ENVIAR OS DOCUMENTOS COMPROBAT&Oacute;RIOS EM MOMENTO POSTERIOR &Agrave; COMPRA, VOC&Ecirc; DEVER&Aacute; CLICAR EM &ldquo;QUERO ENVIAR O COMPROVANTE DEPOIS&rdquo;, E RECEBER&Aacute;, AP&Oacute;S A CONFIRMA&Ccedil;&Atilde;O DE PAGAMENTO, UM E-MAIL COM O PROCEDIMENTO PARA ENVIO DA DOCUMENTA&Ccedil;&Atilde;O. NESSE CASO, VOC&Ecirc; TER&Aacute; O </span><strong>PRAZO DE 3 (TR&Ecirc;S) DIAS &Uacute;TEIS PARA ENVIAR A DOCUMENTA&Ccedil;&Atilde;O REQUERIDA</strong><span>, </span><strong>SOB PENA DE CANCELAMENTO DA COMPRA</strong><span>.</span></li>
        <li><span>EM QUALQUER DOS CASOS &ndash; ENVIO DOS DOCUMENTOS COMBROBAT&Oacute;RIOS NO MOMENTO DA COMPRA OU EM MOMENTO POSTEIOR &Agrave; COMPRA -, A AN&Aacute;LISE DA DOCUMENTA&Ccedil;&Atilde;O SER&Aacute; REALIZADA EM AT&Eacute; 20 (VINTE) DIAS &Uacute;TEIS AP&Oacute;S O ENVIO E, CASO OS DOCUMENTOS ENVIADOS N&Atilde;O SEJAM SUFICIENTES PARA COMPROVAR A DECLARA&Ccedil;&Atilde;O FEITA NO MOMENTO DA AQUISI&Ccedil;&Atilde;O DO PRODUTO, O ESTRAT&Eacute;GIA PODER&Aacute; SOLICITAR DOCUMENTOS ADICIONAIS, CONCEDENDO PRAZO RAZO&Aacute;VEL PARA O ENVIO DESSES.</span></li>
        <li><span>N&Atilde;O SENDO ATENDIDA A SOLICITA&Ccedil;&Atilde;O DE COMPLEMENTA&Ccedil;&Atilde;O E/OU N&Atilde;O SENDO SEQUER ATENDIDO O PEDIDO INICIAL DE ENVIO DOS DOCUMENTOS, O ESTRAT&Eacute;GIA SE RESERVA NO DIREITO DE CANCELAR A COMPRA REALIZADA, COM A DEVOLU&Ccedil;&Atilde;O INTEGRAL DO VALOR PAGO, </span><strong>PERDENDO O DECLARANTE O DIREITO DE REIVINDICAR, POSTERIORMENTE, A CONCESS&Atilde;O DOS BENEF&Iacute;CIOS ESPECIAIS ORA CONCEDIDOS</strong><span>.</span></li>
        <li><span>D&Uacute;VIDAS E CASOS OMISSOS DEVEM SER ENCAMINHADOS AO ESTRAT&Eacute;GIA PELO E-MAIL:&nbsp;</span><em><span>contato@estrategiaconcursos.com.br</span></em></li>
        </ol>
    </React.Fragment>
    )
}