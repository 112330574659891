import React from 'react'
import InputFile from 'components/InputFile'
import FormError from 'components/FormError'

export default function FormFieldFile ({ input, meta, disabled = false, multiple = false }) {
  return (
    <div>
      <InputFile {...input} disabled={disabled} multiple={multiple} />
      {meta.touched && meta.error && (
        <FormError>{meta.error}</FormError>
      )}
    </div>
  )
}
