import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import 'styles/BlockInfo.css'

export default function BlockInfo ({ icon, label, value }) {
  return (
    <div className='BlockInfo'>
      <div className='BlockInfo-icon'>
        <Icon name={icon} />
      </div>
      <div className='BlockInfo-content'>
        <span className='BlockInfo-content-label'>
          {label}
        </span>
        <span className='BlockInfo-content-value'>
          {value}
        </span>
      </div>
    </div>
  )
}

BlockInfo.propTypes = {
  // icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
}
