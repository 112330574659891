import React from 'react'
import 'styles/Tabs/TabPanels.css'

export default function TabPanels ({ children, activeIndex, ...props }) {
  return (
    <div className='TabPanels' {...props}>
      {React.Children.toArray(children).filter(c => !!c)[activeIndex]}
    </div>
  )
}
