import React from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'
import Icon from 'components/Icon'
import Notifications from 'containers/Notifications'

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.width};
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 20px;
  margin: 30px 0;
  max-height: 100vh;

  ${props => mediaQuery.lessThan(props.width)`
    width: 100%;
  `}
`

const ButtonClose = styled.button`
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
  padding-bottom: 10px;
  
  &:hover {
    color: #d83f33;
  }
`

const Content = styled.div`
  width: 100%;
  border-radius: 3px;
  padding: ${props => props.padding};
  background-color: #fff;
`

export default function Modal ({ children, withNotifications, width = '600px', padding = '20px', ...props }) {
  return (
    <ReactModal
      ariaHideApp={false}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          paddingTop: 20,
          backgroundColor: 'rgba(11, 11, 11, 0.8)',
          zIndex: 1000,
          overflow: 'auto'
        },
        content: {
          border: 'none',
          padding: 0,
          position: 'static'
        }
      }}
      {...props}
    >
      <Wrapper width={width}>
        <div style={{ textAlign: 'right' }}>
          <ButtonClose
            type='button'
            aria-label='Fechar Modal'
            onClick={props.onRequestClose}
          >
            <Icon name='close' />
          </ButtonClose>
        </div>
        <Content padding={padding}>
          {withNotifications && <Notifications />}
          {children}
        </Content>
      </Wrapper>
    </ReactModal>
  )
}
