/**
 * Dependencies.
 */

import getDigits from 'utils/getDigits'
import { isValid, format } from 'date-fns'
import { parseDate } from 'modules/Date'

/**
 * Phone.
 */

export const phone = getDigits

/**
 * CPF.
 */

export const cpf = getDigits

/**
 * CEP.
 */

export const cep = getDigits


/**
 * Card Number.
 */

export const cardNumber = getDigits

/**
 * Card CVC.
 */

export const cardCVC = getDigits

/**
 * Date.
 */

export const date = value => {
  if (getDigits(value).length === 8) {
    const date = parseDate(value)
    return date && isValid(date)
      ? format(date, 'YYYY-MM-DD')
      : value
  }

  return value
}
