import styled from 'styled-components'

export const Box = styled.div`
  float: left;
  width: 10%;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;

  @media only screen and (max-width: 900px) {
    height: auto;
    margin-bottom: 40px;
    width: 100%;
  }

`

export const Imagem = styled.img`
  display: inline-block;

  @media only screen and (max-width: 900px) {
    transform: rotate(90deg);
  }
`
