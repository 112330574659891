import styled from 'styled-components'

export const Lista = styled.ul`
  float: left;
  width: 100%;
  padding: 0;
  margin: 20px 0 5px 0;
`

export const Item = styled.li`
  list-style: none;
  float: left;
  width: 100%;

  + li {
    margin-top: 8px;
  }
`

export const Texto = styled.span`
  font-size: 16px;
  color: #0057EE;
  font-weight: bold;
`