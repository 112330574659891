import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import 'styles/Alert.css'

export default function Alert ({ type, children, items, onCloseRequest }) {
  return (
    <div className={`Alert -${type}`}>
      <div>
        {process(children)}
        {items && (
          <ul style={{ margin: 0, marginTop: 10, paddingLeft: 25 }}>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      {onCloseRequest && (
        <button
          type='button'
          className='Alert-close'
          onClick={onCloseRequest}
          children='×'
        />
      )}
    </div>
  )
}

Alert.propTypes = {
  type: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,
  onCloseRequest: PropTypes.func
}

Alert.defaultProps = {
  type: 'default'
}

const process = text => {
  if (typeof text !== 'string') return text

  const re = /\[link ([^[]+)\]([^[]+)\[\/link\]/g
  const match = text.match(re)

  if (!match) return text

  for (let i = 0; i < match.length; i++) {
    const link = match[i]
    const [ before, after ] = text.split(link)
    const [ , route, label ] = re.exec(link)
    return (
      <React.Fragment>
        {before}
        {' '}
        {route.includes("://") ? (<a href={route} target="_blank">{label}</a>) : (<Link to={route}>{label}</Link>)}
        {' '}
        {after}
      </React.Fragment>
    )
  }

  return text
}
