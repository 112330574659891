import React from 'react'
import Screen from 'containers/Screen'
import Blog from 'containers/Blog'
import 'styles/Blog.css'

export default function BlogEcjScreen () {
  return (
    <Screen title='Blog' setHeadTitle>
      <Blog />
    </Screen>
  )
}
