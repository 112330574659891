import update from 'immutability-helper'
import createReducer from './createReducer'
import getFromKeypath from 'lodash/get'

export default function createResourceReducer ({ actionTypes, state, handlers }) {
  const initialState = {
    records: [],
    pagination: {},
    loadedPages: [],
    isFetching: false,
    isSubmitting: false,
    isRemoving: false,
    ...state
  }

  return createReducer(initialState, {
    /**
     * Fetch All.
     */

    [actionTypes.FETCH_ALL_PENDING] (state) {
      return update(state, {
        isFetching: { $set: true }
      })
    },

    [actionTypes.FETCH_ALL_FULFILLED] (state, { payload }) {
      return update(state, {
        records: { $push: payload.data },
        loadedPages: { $push: [ getFromKeypath(payload, 'meta.current_page', 1) ] },
        $merge: {
          isFetching: false,
          pagination: payload.meta,
          error: null
        }
      })
    },

    [actionTypes.FETCH_ALL_REJECTED] (state, { payload }) {
      return update(state, {
        $merge: {
          isFetching: false,
          error: payload
        }
      })
    },

    /**
     * Create.
     */

    [actionTypes.CREATE_PENDING] (state) {
      return update(state, {
        isSubmitting: { $set: true }
      })
    },

    [actionTypes.CREATE_FULFILLED] (state, { payload }) {
      const isArray = Array.isArray(payload.data)
      return update(state, {
        isSubmitting: { $set: false },
        records: {
          [isArray ? '$set' : '$unshift']: isArray ? payload.data : [ payload.data ]
        },
        error: { $set: false }
      })
    },

    [actionTypes.CREATE_REJECTED] (state, { payload }) {
      return update(state, {
        isSubmitting: { $set: false },
        error: { $set: payload }
      })
    },

    /**
     * Update.
     */

    [actionTypes.UPDATE_PENDING] (state) {
      return update(state, {
        isSubmitting: { $set: true }
      })
    },

    [actionTypes.UPDATE_FULFILLED] (state, { meta, payload }) {
      if (!Array.isArray(payload.data)) {
        // TODO: Update a single record by id.
        throw new Error('Update by id is not implemented yet.')
      }

      return update(state, {
        records: { $set: payload.data },
        isSubmitting: { $set: false },
        error: { $set: null }
      })
    },

    [actionTypes.UPDATE_REJECTED] (state, { payload }) {
      return update(state, {
        isSubmitting: { $set: false },
        error: { $set: payload }
      })
    },

    /**
     * Remove.
     */

    [actionTypes.REMOVE_PENDING] (state) {
      return update(state, {
        isRemoving: { $set: true }
      })
    },

    [actionTypes.REMOVE_FULFILLED] (state, { payload: { data } }) {
      return update(state, {
        isRemoving: { $set: false },
        records: { $apply: records => records.filter(record => record.id !== data.id) },
        error: { $set: null }
      })
    },

    [actionTypes.REMOVE_REJECTED] (state) {
      return update(state, {
        isRemoving: { $set: false },
        error: { $set: null }
      })
    },

    /**
     * Custom Handlers.
     */

    ...handlers
  })
}
