import React from 'react'
import classNames from 'classnames'
import 'styles/Button.css'

export default function Button ({ renderWith = 'button', size, color, outlined = false, ...props }) {
  const Component = renderWith
  const className = classNames({
    Button: true,
    '-small': size === 'small',
    '-medium': size === 'medium',
    '-danger': color === 'danger',
    '-special': color === 'special',
    '-fit': !!props.fit,
    '-outlined': outlined
  })

  return (
    <Component
      className={className}
      {...props}
    />
  )
}
