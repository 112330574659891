import styled from 'styled-components'
import theme from 'theme'

export const Container = styled.div`
  margin: 20px 0;

  @media only screen and (max-width: 600px) {
    & + div {
      margin-bottom:40px;
    }
  }  
`

export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  height: 56px;
  background-color: ${theme.colors.secondary};
  border-radius: 4px;
  color: #fff;
  min-width: 245px;
  transition: all 0.2s linear 0s;
  &:hover {
    background-color: ${theme.colors.secondaryDark};
  }
`

export const ActionButtonIcon = styled.div`
  font-size: 20px;
  margin-right: 15px;
`

export const ActionButtonContent = styled.div`
  
`

export const ActionButtonContentTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
`

export const ActionButtonContentLabel = styled.div`
  font-size: 14px;
`

export const PrecoAntigo = styled.div`
    color: #A9A9A9;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    font-family: Roboto,sans-serif;
    text-decoration: line-through;
    float: left;
    width: 100%;
      
`

export const Parcelamento = styled.strong`
  color: #333333;
  font-family: Roboto,sans-serif;
  font-size: 22px;
  line-height: 40px;
  text-align: left;
  float: left;
  width: 100%;
  font-weight: 600;
  margin-bottom: 5px;
`
