import styled from 'styled-components'

export const ContainerBusca = styled.div`
    display: block;
    width: 100%;
    margin: 15px 0 15px 0;

    & input {
        display: block;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #D8D8D8;
        font-family: 'Roboto', sans-serif;
        color: #333333;
        font-size: 16px;
        outline: none;
        height: 50px;
        padding: 0 13px;
    }
`