import React, {useState} from 'react'
import Section from 'components/Section'
import UserInterests from 'containers/UserInterests'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { Interesses, TextoInicial, TituloSessao, BoxInteresses, TituloTipo, ListaInteresses, ItemInteresse, ItemInteresseText, Mensagem } from './style'
import Client from 'modules/Client'

export default function SectionUserInterests () {
  
  const [preferencias, setPreferencias] = useState([])
  const [send, setSend] = useState(false)
  const [mensagem, setMensagem] = useState({
    'msg': '',
    'ativo': false,
    'tipo': ''
  })

  const mudaPreferencia = (indexVertical, indexItem) => {
    let newArray = [...preferencias]
    // Caso seja experiência
    if (preferencias[indexVertical][0] === 'Experiência') {
      preferencias[indexVertical][1].map((interesse, index) => {
        interesse.id === newArray[indexVertical][1][indexItem].id ? newArray[indexVertical][1][index].marcado = true : newArray[indexVertical][1][index].marcado = false 
        return true
      })
    // Caso não seja experiência
    } else {
      newArray[indexVertical][1][indexItem].marcado = !newArray[indexVertical][1][indexItem].marcado
    }
    setPreferencias(newArray)
  }

  const listaInteresses = (data) => {
    // Atualiza o state de listagem
    setPreferencias(data)
    if (data) {
      let boxInteresse = preferencias.map((vertical, indexVertical) => (
        vertical.map((interesse, index) => (
          <ListaInteresses key={index}>
            { index === 0
              ? <TituloTipo>{ interesse }</TituloTipo>
              : <React.Fragment>
                { interesse.map((item, indexItem) => {
                  if(item.descricao !== ''){
                    return (
                      <Tooltip key={item.id} placement='top' overlay={item.descricao}>
                        <ItemInteresse className={item.marcado ? 'ativo' : ''} onClick={() => mudaPreferencia(indexVertical, indexItem)}>
                          <ItemInteresseText>{item.nome}</ItemInteresseText>
                        </ItemInteresse>
                      </Tooltip>
                    )
                  } else {
                    return (
                      <ItemInteresse key={item.id} className={item.marcado ? 'ativo' : ''} onClick={() => mudaPreferencia(indexVertical, indexItem)}>
                        <ItemInteresseText>{item.nome}</ItemInteresseText>
                      </ItemInteresse>
                    )
                  }
                })}
              </React.Fragment>
            }
          </ListaInteresses>
        ))
      ))
      return boxInteresse
    }
  }

  const pegaDados = () => {
    let itens = []
    preferencias.map((vertical) => {
      vertical.map((interesse, index) => {
        if (index === 1) { 
          interesse.map((item) => { 
            itens.push(item)
            return true
          }) 
        }
        return true
      })
      return true
    })
    return itens
  }

  const enviaDados = (data) => {
    setSend(true)
    setMensagem({
      'msg': '',
      'ativo': false,
      'tipo': ''
    })
    Client.post(`api/aluno/minhas-preferencias`, { data })
      .then(response => {
        setSend(false)
        if (response.success) {
          setMensagem({
            'msg': 'Preferências atualizadas com sucesso!',
            'ativo': true,
            'tipo': '-success'
          })
        } else {
          setMensagem({
            'msg': 'Erro ao atualizar as preferências!',
            'ativo': true,
            'tipo': '-error'
          })
        }
      })
      .catch(err => {
        setSend(false)
        setMensagem({
          'msg': 'Erro ao atualizar as preferências!',
          'ativo': true,
          'tipo': '-error'
        })
      })
  }

  return (
    <Section title='Minhas Preferências'>
      <TextoInicial>Ao preencher as informações abaixo, você passará a receber informações, materiais de estudo e recomendações alinhadas ao seu perfil.</TextoInicial>
      <UserInterests>
        {({ data }) => (
          <Interesses>
            <BoxInteresses>
              <TituloSessao>Quais são as suas Áreas de Interesse?</TituloSessao>
              { listaInteresses(data) }
            </BoxInteresses>
            <FormGroup right>
              <Button onClick={ () => enviaDados(pegaDados()) } type='submit' disabled={false}>{ send ? 'Salvando...' : 'Salvar' }</Button>
            </FormGroup>
          </Interesses>
        )}
      </UserInterests>
      { mensagem.ativo ? <Mensagem className={ `Alert ${mensagem.tipo}` }>{ mensagem.msg }</Mensagem> : null }
    </Section>
  )
}
