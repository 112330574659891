import React, { Component } from 'react'
import { omit } from 'ramda'
import styled from 'styled-components'
import theme from 'theme'
import mediaQuery from 'utils/mediaQuery'
import Button from 'components/Button'

const Container = styled.div`
  font-family: ${theme.fonts.primary};
  padding: 5px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  ${mediaQuery.greaterThan('small')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
`

const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${mediaQuery.greaterThan('small')`
    white-space: nowrap;
    flex-grow: 1;
  `}
`

const ButtonContainer = styled.div`
  ${mediaQuery.greaterThan('small')`
    min-width: 0;
    flex-shrink: 0;
    margin-left: 5px;
  `}

  ${mediaQuery.lessThan('small')`
    margin-top: 5px;  
  `}
`

export default class InputFile extends Component {
  state = {
    filename: ''
  }

  onChange = e => {
    const file = e.target.files[0]
    this.setState({ filename: file.name })
    this.props.onChange && this.props.onChange(file)
  }

  openFileDialog = () => {
    this.input.click()
  }

  render () {
    const props = omit(['onChange', 'value'], this.props)
    return (
      <div>
        <Container>
          <FileName onClick={this.openFileDialog}>
            {this.state.filename || 'Selecione um arquivo'}
          </FileName>
          <ButtonContainer>
            <Button type='button' size='small' onClick={this.openFileDialog} disabled={props.disabled}>
              Escolher arquivo
            </Button>
          </ButtonContainer>
        </Container>
        <Input
          type='file'
          onChange={this.onChange}
          ref={input => this.input = input}
          {...props}
        />
      </div>
    )
  }
}
