import { normalize, schema } from 'normalizr'
import Client from 'modules/Client'

const aula = new schema.Entity('aulas')

const concurso = new schema.Entity('concursos', {
  aulas: [ aula ]
})

const dia = new schema.Entity('dias', { concursos: [ concurso ] }, {
  idAttribute: 'dia'
})

export default {
  query ({ startDate, endDate }) {
    return Client.get('api/aluno/agenda', {
      params: {
        inicio: startDate,
        fim: endDate
      }
    })
    .then(response => ({
      data: normalize(response.data, [ dia ]),
      range: `${startDate}-${endDate}`
    }))
  }
}
