import styled from 'styled-components'

export const Interesses = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0;

    & button {
        margin-top: 20px;
    }
`

export const TextoInicial = styled.p`
    float: left;
    width: 100%;
    margin: 0 0 25px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 24px;
`

export const BoxInteresses = styled.div`
    display: inline-block;
    width: 100%;
    margin: 0;
`

export const TituloSessao = styled.h2`
    width: 100%;
    display: inline-block;
    padding: 0;
    font-size: 18px;
    margin: 0 0 25px 0;
    font-family: 'Roboto', sans-serif;
    line-height: 26px;
`

export const TituloTipo = styled.h3`
    width: 100%;
    display: inline-block;
    padding: 0;
    font-size: 15px;
    margin: 0 0 10px 0;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
`

export const ListaInteresses = styled.ul`
    width: 100%;
    display: inline-block;
    padding: 0;
    margin: 0 0 15px 0;
`

export const ItemInteresse = styled.li`
    list-style: none;
    float: left;
    margin: 10px;
    padding: 10px 20px;
    background: #F1F1F1;
    cursor: pointer;
    border: solid 2px #dfdfdf;
    transition: all 0.2s linear 0s;
    border-radius: 4px;

    &:hover {
        background: #dfdfdf;
    }

    &.ativo {
        border: solid 2px #FF6A58;
    }

    @media only screen and (max-width: 768px) {
        padding: 10px;
    }
`

export const ItemInteresseText = styled.span`
    font-size: 15px;
`

export const Mensagem = styled.span`
    margin: 20px 0 0 0;
`
