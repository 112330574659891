import styled from 'styled-components'
import theme from 'theme'
import mediaQuery from 'utils/mediaQuery'

export const Title = styled.h1`
  color: #333333;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;

  & strong {
    color: #FF6A58;
  }
`

export const SubTitle = styled.h2`
  color: #333333;
  font-size: 15px;
  font-weight: normal;
  margin: 0;
  padding-bottom: 25px;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 25px;
`

export const Info = styled.div`
  margin-bottom: 25px;
  float: left;
  width: 100%;
`

export const InfoText = styled.span`
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  float: left;
  width: 100%;
  line-height: 150%;

  & strong {
    color: #FF6A58;
  }

  & + span {
    margin-top: 10px;
  }
`

export const Payment = styled.div`
  background-color: #fff;
  border: 1px solid #D8D8D8;
  padding: 30px;
  font-size: 14px;
  margin-bottom: 25px;
  float: left;
  width: 100%;

  ${mediaQuery.greaterThan('medium')`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const PaymentTexts = styled.div``

export const PaymentText = styled.p`
  font-family: Roboto,sans-serif;
  margin: 0;
  font-size: 15px;
  color: #333333;
  padding-right: 30px;
  line-height: 150%;

  & + p {
    margin: 10px 0 0 0;
  }

  @media only screen and (max-width: 600px) {
    padding-right: 0px;
  }
`

export const PaymentLink = styled.a`
  line-height: 1.5;
  text-decoration: underline;
  font-family: Roboto,sans-serif;
  color: #FF6A58;
  font-weight: bold;
  & :hover {
    color: ${theme.colors.primary};
  }
`

export const PaymentDetails = styled.div`
  display: flex;
  align-items: center;

  ${mediaQuery.greaterThan('medium')`
    flex-shrink: 0;
  `}

  ${mediaQuery.lessThan('medium')`
    margin-top: 20px;
  `}
`

export const PaymentDetailsText = styled.div`
  margin-left: 15px;
  margin-right: 40px;
  line-height: 150%;
`

export const CardBrandName = styled.span`
  text-transform: capitalize;  
`
