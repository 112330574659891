import React, { Component } from 'react'
import Link from 'components/Link'
import FormLogin from 'forms/FormLogin'
import logoEstrategia from 'images/logo.svg'
import { SITE_URL, CLIENT_SECRET, CLIENT_ID, GRANT_TYPE } from 'config/constants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login, isAuthenticated, isProcessing } from 'store/ducks/auth'
import { Wrapper, Content, Logo, LinkContainer} from './styled.js'
import Notifications from 'containers/Notifications'
import { removeLocalStorage } from 'utils/helpers'
 
class Login extends Component {

  realizaLogin (data) {

    let dados = {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: data.username,
      password: data.password,
      grant_type: GRANT_TYPE
    }
    this.props.requestLogin(this.props.path.location.pathname, dados)
    removeLocalStorage('menuSidebar')
  }

  render () {
    return (
      <Wrapper>
        <Content>
          <Logo src={ logoEstrategia } />
          <FormLogin
            onSubmit={data => this.realizaLogin(data)}
            isSubmitting={false}
          />
          <Notifications scrollToTop />
          <LinkContainer>
            <Link href={SITE_URL}>
              Voltar ao site
            </Link>
          </LinkContainer>
        </Content>
      </Wrapper>
    )
  }

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    requestLogin: PropTypes.func.isRequired
  }

}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  isProcessing: isProcessing(state)
})

const mapDispatchToProps = dispatch => ({
  requestLogin(pathname, dados) {
    dispatch(login(pathname, dados, true))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)