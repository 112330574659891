import React from 'react';
import Screen from 'containers/Screen';
import IndiqueAmigo from 'containers/IndiqueAmigo';

export default function IndiqueAmigoScreen() {
  return (
    <Screen title='' setHeadTitle>
      <IndiqueAmigo />
    </Screen>
  )
}
