import styled from 'styled-components'
import media from 'utils/mediaQuery'

export const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.gray};
  padding: 15px;
`

export const Files = styled.div`
  display: flex;
  margin-bottom: 15px;
  overflow: auto;
  position: relative;

  > * + * {
    margin-left: 5px;
  }

  > * {
    flex-shrink: 0;
  }

  ${media.lessThan('medium')`
    > * {
      width: 80%
    }
  `}
`
