import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import * as dateFns from 'date-fns'
import Media from 'react-media'
import Countdown from 'components/Countdown'
import LoteProduto from 'components/LoteProduto'
import Stories from 'components/Stories'
import mq from 'utils/mediaQuery'

export default function Lote ({ lote, index, disabled: disableButtons, date }) {
  const start = dateFns.parse(lote.data_inicio)
  const end = dateFns.parse(lote.data_fim)

  const isFuture = dateFns.isFuture(start)
  const isPast = dateFns.isPast(end)
  const disabled = isFuture || isPast

  const lotes = lote.produtos.map((produto, index) => (
    <LoteProduto
      key={index}
      index={index}
      disabled={disabled}
      isFuture={isFuture}
      isPast={isPast}
      disableButtons={disableButtons || !produto.ativo}
      produto={{
        ...produto,
        nome: ['1 ano de assinatura', '2 anos de assinatura'][index]
      }}
    />
  ))

  return (
    <Container>
      <Section id={`lote-${index}`}>
        <Header
          disabled={disabled}
          isFuture={isFuture}
          isPast={isPast}
        >
          <HeaderTitle
            disabled={disabled}
            isFuture={isFuture}
            isPast={isPast}
          >
            <HeaderTitleName>{lote.nome}</HeaderTitleName>
            <HeaderTitleDuration>
              {dateFns.isSameDay(start, end) ? (
                `Das ${dateFns.format(start, 'H')}h às ${Number(dateFns.format(end, 'H')) + 1}h do dia ${dateFns.format(end, 'D/MM')}`
              ) : (
                `De ${dateFns.format(start, 'D/MM')} a ${dateFns.format(end, 'D/MM')}`
              )}
            </HeaderTitleDuration>
          </HeaderTitle>
          {!disabled && (
            <HeaderCountdown>
              <Countdown
                date={date}
                to={end}
                onFinish={() => window.location.reload() }
              />
            </HeaderCountdown>
          )}
        </Header>
        <Media query='(max-width: 700px)'>
          {matches => matches ? (
            <Stories slides={lotes} />
          ) : (
            <Body>
              {lotes}
            </Body>
          )}
        </Media>
      </Section>
{/*
      {!disabled && (
        <InfoText>
          <InfoTextLink href='https://www.estrategiaconcursos.com.br/pagina/termos-de-uso/'>
            Ver regulamento completo
          </InfoTextLink>
        </InfoText>
      )}
*/}
    </Container>
  )
}

Lote.propTypes = {
  disabled: PropTypes.bool.isRequired,
  lote: PropTypes.shape({
    data_inicio: PropTypes.string.isRequired,
    data_fim: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    produtos: PropTypes.arrayOf(PropTypes.shape({
      nome: PropTypes.string.isRequired,
      cartao_url: PropTypes.string.isRequired,
      boleto_url: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
}

const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 45px;
  }
`

const Section = styled.section`
  box-shadow: 0 2px 8px 5px rgba(138, 138, 138, 0.5);
  position: relative;
  overflow: hidden;
`

const Header = styled.header`
  background-color: #2e2efd;
  color: #fff;
  padding: 30px;
  ${mq.lessThan('700px')`padding: 15px;`}

  ${props => props.disabled && css`
    background-color: ${ifProp('isFuture', '#eceff0', '#f5f5f5')};
  `}

  ${mq.greaterThan('700px')`
    display: flex;
    align-items: center;
    justify-content: space-between;  
  `}
`

const HeaderTitle = styled.div`
  color: #fff;
  ${props => props.disabled && css`
    color: ${ifProp('isFuture', '#757575', '#9e9e9e')};
  `}
`

const HeaderTitleName = styled.h1`
  display: block;
  font-size: 24px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  ${mq.lessThan('700px')`font-size: 16px;`}
`

const HeaderTitleDuration = styled.div`
  font-weight: bold;
  font-size: 32px;
  ${mq.lessThan('700px')`font-size: 24px;`}
`

const HeaderCountdown = styled.div`
  display: inline-flex;

  ${mq.lessThan('700px')`margin-top: 15px;`}
`

const Body = styled.div`
  display: flex;
  background-color: #fff;
  
  > * {
    width: 50%;
    padding-bottom: 40px;
    
    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }
`

// const InfoText = styled.div`
//   color: #424242;
//   font-size: 12px;
//   margin-top: 25px;
//
//   > div + div {
//     margin-top: 5px;
//   }
// `
//
// const InfoTextLink = styled.a`
//   display: inline-block;
//   margin-top: 10px;
//   font-weight: bold;
//   text-decoration: underline;
// `
