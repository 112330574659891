import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { required, emailRequired } from 'utils/forms'
import CustomField from 'components/CustomField'
import FormGroup from 'components/FormGroup'
import Section from 'components/Section'
import SubmissionContainer from 'components/SubmissionContainer'
import Button from 'components/Button'
import Link from 'components/Link'
import Notifications from 'containers/Notifications'
import { SITE_URL } from 'config/constants'

export default function FormLogin ({ onSubmit, isSubmitting }) {
  return (
    <Section title="">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Notifications
              notificationsId='LOGIN'
            />
            <FormGroup>
              <Field
                name='username'
                type='email'
                label='E-mail'
                component={CustomField}
                {...emailRequired}
              />
            </FormGroup>
            <FormGroup>
              <Field
                name='password'
                type='password'
                label='Senha'
                component={CustomField}
                {...required}
              />
            </FormGroup>
            <FormGroup>
              <SubmissionContainer>
                <Link href={`${SITE_URL}/loja/entrar/esqueciMinhaSenha`}>
                  Esqueci minha senha
                </Link>
                <Button type='submit' disabled={isSubmitting}>
                  {!isSubmitting ? 'Login' : 'Processando...'}
                </Button>
              </SubmissionContainer>
            </FormGroup>
          </form>
        )}
      </Form>
    </Section>
  )
}

FormLogin.propTypes = {
  /** Callback do form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired
}
