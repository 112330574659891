import React from 'react'
import classNames from 'classnames'
import 'styles/SectionTitle.css'

export default function Title ({ Component = 'h2', children = '', noMargin, isSubtitle = false, ...props }) {
  const className = classNames({
    SectionTitle: true,
    '-no-margin': noMargin,
    'is-secondary': isSubtitle,
  })

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  )
}
