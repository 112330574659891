import React from 'react'
import { Container, BoxConteudo, TituloConteudo, BoxPrecos, BoxPreco, TituloPreco, DescricaoPreco, PrecoDe, PrecoPor, LinkPreco } from './Styled'

export default function Conteudo ({ planos }) {

	return (
		<Container>
			<BoxConteudo>

				<TituloConteudo>Use agora antes que expire!</TituloConteudo>

				<BoxPrecos>

					{/* Pix ou Boleto */}
					<BoxPreco>
						<TituloPreco>Pix ou boleto</TituloPreco>
						<DescricaoPreco>+10% OFF</DescricaoPreco>
						<PrecoDe>{ planos.boleto.preco_de }</PrecoDe>
						<PrecoPor>{ planos.boleto.preco_por }</PrecoPor>
						<LinkPreco href={ planos.boleto.link }>Assine agora</LinkPreco>
					</BoxPreco>

					{/* Cartão */}
					<BoxPreco>
						<TituloPreco>Cartão</TituloPreco>
						<DescricaoPreco></DescricaoPreco>
						<PrecoDe>{ planos.cartao.preco_de.replace('de', '') }</PrecoDe>
						<PrecoPor>{ planos.cartao.preco_por.replace('por', '') }</PrecoPor>
						<LinkPreco href={ planos.cartao.link }>Assine agora</LinkPreco>
					</BoxPreco>

				</BoxPrecos>

			</BoxConteudo>
		</Container>
	)

}
