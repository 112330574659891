import styled from 'styled-components'

export const Header = styled.section`
  float: left;
  width: 100%;
`
export const ImagemTopo = styled.img`
  float: left;
  width: 100%;
  max-width: 100%;
`
