import React from 'react'
import PropTypes from 'prop-types'
import 'styles/Avatar.css'
import defaultAvatar from 'images/avatar.svg'

export default function Avatar ({ src, name, ...props }) {
  const { width, height, ...rest } = props
  return (
    <div className='Avatar'>
      <img
        className='Avatar-image'
        src={src || defaultAvatar}
        alt={name}
        style={{ width, height }}
        {...rest}
      />
    </div>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string
}
