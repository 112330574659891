import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { required, fileType, fileSize } from 'utils/validators'
import SectionTitle from 'components/SectionTitle'
import FormGroup from 'components/FormGroup'
import FormFieldFile from 'components/FormFieldFile'
import Text from 'components/Text'
import Button from 'components/Button'
import './style.css'

const FILE_SUPPORTED_TYPES = [
  'application/pdf',
  'image/bmp',
  'image/jpg',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'text/plain',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const MAX_FILE_SIZE = (1024 ** 2) * 5 // 5 megabytes

const validate = value =>
  required(value) ||
  fileType(FILE_SUPPORTED_TYPES)(value) ||
  fileSize(MAX_FILE_SIZE)(value)

export default function FormCourseDiscursive ({ onSubmit, isSubmitting }) {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className='formRedacao'>
          <SectionTitle>Enviar Redação</SectionTitle>
          <div className='boxFormRedacao'>
            <FormGroup>
              <Text>O simulado deve ser enviado compilado.</Text>
            </FormGroup>
            <FormGroup>
              <Field
                type='file'
                name='discursiva'
                component={FormFieldFile}
                validate={validate}
              />
            </FormGroup>
          </div>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Confirmar Envio' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormCourseDiscursive.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired
}
