import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from 'components/Spinner'
import { fetchOne } from 'store/ducks/conteudo/pacotes'
import * as selectors from 'store/ducks/conteudo/pacotes'

class Pacote extends Component {

  componentWillMount () {
    if (this.props.fetch) {
      this.props.fetchPacote(this.props.id)
    }
  }

  render () {
    if (this.props.isFetching) {
      return this.props.showSpinner
        ? <Spinner text='Carregando pacote...' />
        : null
    }

    if (!this.props.data) {
      return 'Pacote não encontrado!'
    }

    return this.props.children({
      data: this.props.data,
      isSubmitting: this.props.isSubmitting
    })
  }

  static propTypes = {
    // From component
    id: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
    showSpinner: PropTypes.bool,
    fetch: PropTypes.bool,

    // From connect (state)
    isFetching: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    data: PropTypes.object,
    isListLoaded: PropTypes.bool.isRequired,

    // From connect (dispatch)
    fetchPacote: PropTypes.func.isRequired
  }

  static defaultProps = {
    showSpinner: true,
    fetch: true
  }

}

const mapStateToProps = (state, { id }) => ({
  isFetching: selectors.isFetching(state),
  isSubmitting: selectors.isSubmitting(state),
  data: selectors.data(state, id),
  isListLoaded: selectors.isListLoaded(state, id)
})

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchPacote (id) {
    dispatch(fetchOne(id))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pacote)
