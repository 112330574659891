import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modal'
import Notifications from 'containers/Notifications'

export default class WithModal extends Component {
  state = {
    isOpened: !!this.props.isOpened
  }

  toggleModal = () => {
    this.setState({
      isOpened: !this.state.isOpened
    })
  }

  render () {
    const {
      children,
      modal,
      isOpened,
      notifications,
      notificationsId,
      ...props
    } = this.props

    return (
      <React.Fragment>
        {children({
          toggleModal: this.toggleModal,
          isOpened: this.state.isOpened
        })}
        <Modal
          isOpen={this.state.isOpened}
          onRequestClose={this.toggleModal}
          {...props}
        >
          {notifications && (
            <Notifications notificationsId={notificationsId} />
          )}
          {modal}
        </Modal>
      </React.Fragment>
    )
  }

  static propTypes = {
    /** Conteúdo do modal */
    modal: PropTypes.any.isRequired,

    /** Render function com a função para togglear o modal */
    children: PropTypes.func,

    /** Exibe as noticações no topo do modal */
    notifications: PropTypes.bool
  }

  static defaultProps = {
    notifications: false
  }
}
