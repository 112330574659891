import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SmoothCollapse from 'react-smooth-collapse'
import isFunction from 'is-function'

export default class CollapseBody extends Component {
  state = {
    closed: !this.props.isOpened
  }

  onChangeEnd = () => {
    if (this.props.onOpen && this.props.isOpened) {
      this.props.onOpen()
    }

    if (!this.props.isOpened) {
      this.setState({ closed: true })
    }
  }

  componentWillReceiveProps (props) {
    if (props.isOpened) {
      this.setState({ closed: false })
    }
  }

  renderBody () {
    if (this.state.closed && this.props.unmountedClosed) {
      return null
    }

    return isFunction(this.props.children)
      ? this.props.children({ isOpened: this.props.isOpened })
      : this.props.children
  }

  render () {
    if (this.props.disabled) return null

    return (
      <SmoothCollapse
        expanded={this.props.isOpened}
        onChangeEnd={this.onChangeEnd}
      >
        <div className='Collapse-body'>
          {this.renderBody()}
        </div>
      </SmoothCollapse>
    )
  }

  static propTypes = {
    isOpened: PropTypes.bool,
    unmountedClosed: PropTypes.bool,
    disabled: PropTypes.bool,
    onOpen: PropTypes.func
  }
}
