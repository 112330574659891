import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'styles/LessonVideos.css'
import PlayerVideo from 'components/Player/PlayerVideo'
import ListVideos from 'components/ListVideos'
import LessonPlayer from 'components/LessonPlayer'
import Checkbox from 'components/Checkbox'

export default class LessonVideos extends Component {
  markVideoView = () => {
    this.props.viewVideo(
      this.props.lessonId,
      this.props.video.id,
      !this.props.video.visualizado
    )
  }

  render () {
    const { videos, courseId, lessonId, video } = this.props
    const sources = Object.keys(video.resolucoes).map(name => ({
      name,
      url: video.resolucoes[name]
    }))
    const defaultSource = sources.length > 0
      ? sources[0].url
      : ''

    return (
      <div className='LessonVideos'>
        <div className='LessonVideos-player'>
          {video.sambatech_player_key ? (
            <LessonPlayer
              sambatechPlayerKey={video.sambatech_player_key}
              sambatechMediaId={video.sambatech_media_id}
            />
          ) : (
            <PlayerVideo
              key={video.id}
              sources={sources}
              defaultSource={video.resolucoes[video.resolucao] || defaultSource}
              resolution={video.resolucao}
              initialPosition={video.posicao}
              onPlay={this.markVideoView}
              onPositionChanged={(ms, resolution) => {
                this.props.saveVideoLastPosition(lessonId, video.id, ms, resolution)
              }}
              onVideoDownload={this.markVideoView}
              audio={video.audio} 
              thumbnail={video.thumbnail ? video.thumbnail : null}
            />
          )}
          <div className="boxVisualizado">
            <Checkbox
              type='checkbox'
              checked={video.visualizado}
              onChange={this.markVideoView}
              label='Visualizado'
              disabled={video.alterando_visualizacao}
              inversed
              small
            />
          </div>
        </div>
        <div className='LessonVideos-playlist'>
          <ListVideos
            videos={videos}
            lessonId={lessonId}
            courseId={courseId}
          />
        </div>
      </div>
    )
  }

  static propTypes = {
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.number.isRequired,
    videos: PropTypes.array.isRequired,
    video: PropTypes.object.isRequired,
    viewVideo: PropTypes.func.isRequired,
    saveVideoLastPosition: PropTypes.func.isRequired,
    selectedVideo: PropTypes.number.isRequired
  }
}
