import Client from 'modules/Client'

export default {
  fetchAll () {
    return Client.get('api/aluno/oportunidades')
  },

  remove (id, { makeRequest }) {
    if (makeRequest) {
      return Client.delete(`api/aluno/oportunidades/${id}`)
    } else {
      return new Promise(resolve => resolve({ data: { id } }))
    }
  }
}
