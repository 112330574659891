import React from 'react';
import Screen from 'containers/Screen';
import EstrategiaCast from 'containers/EstrategiaCast';
import FetchApi from 'components/FetchApi';
import { SITE_URL } from 'config/constants';

export default function EstrategiaCastScreen() {
  return (
    <Screen title='' setHeadTitle>
      <FetchApi
        baseURL={SITE_URL}
        url='assinaturas/main/carregarajax/'
        spinner
      >
        {({ data }) => {
          if (data && data.planos.length > 0) {
            return <EstrategiaCast
              planos={data.planos}
            />
          }
          return 'Estrategia Cast não disponivel no momento.'
        }}
      </FetchApi>
    </Screen>
  )
}
