import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Subtitle from 'components/Subtitle/Subtitle'
import Button from 'components/Button'
import Link from 'components/Link'
import iconAlert from 'images/ic-alert-course.svg'
import { Card, Wrapper, Content, SubtitleContainer, Title, Label, AlertLabel } from './styles'
import Tooltip from 'components/Tooltip'

function CardItemCurso ({ title, subtitle, subscribed, onClick, disabled, url, list, withLink, bottomLabel, agrupador, id, status }) {
  return (
    <Card>
      <Wrapper>
        <Content isList={list}>
          {subtitle && (
            <SubtitleContainer isList={list}>
              <Subtitle>{subtitle}</Subtitle>
            </SubtitleContainer>
          )}
          {withLink ? (
            <Title>
              <Link to={agrupador ? '/dashboard/pacote/' + id : '/dashboard/cursos/' + id + '/aulas'} renderWith={RouterLink} >
                {title}
              </Link>
            </Title>
          ) : (
            <Title>{title}</Title>
          )}
          {url ? (
            <Button
              renderWith='a'
              href={url}
              size='medium'
              children='Detalhes'
              disabled={disabled}
            />
          ) : (
            <Button
              size='medium'
              children={subscribed ? 'Desmatricular' : 'Matricular'}
              outlined={subscribed}
              onClick={onClick}
              disabled={disabled}
            />
          )}
          {bottomLabel && (
            <Label padding={!status}>
              {!status ? <Tooltip placement='bottom' overlay={'Este produto não está disponível em Minhas Matrículas porque esta assinatura' +
              ' está fora da validade. Se você tem outra assinatura válida, podem tentar se desmatricular e se matricular novamente.'}><AlertLabel src={iconAlert} /></Tooltip> : null}
              {bottomLabel}
            </Label>
          )}
        </Content>
      </Wrapper>
    </Card>
  )
}

export default CardItemCurso
