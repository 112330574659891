import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-recompose'
import Notifications from 'containers/Notifications'
import Text from 'components/Text'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import Modal from 'components/Modal'
import CardToken from 'components/CardToken'
import FormCreditCard from 'forms/FormCreditCard'
import withResourceList from 'store/utils/withResourceList'
import withResourceActions from 'store/utils/withResourceActions'

class CardTokens extends Component {
  state = {
    isModalOpen: false
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  render () {
    return (
      <div>
        <FormGroup>
          <Text>
            A Estratégia Concursos não armazena as informações do seu cartão de crédito como número, portador, vencimento e código de segurança. Nossa adquirente, autorizada pelas operadoras de cartão e munida dessas informações, gera um número truncado do cartão e um token de uso exlusivo na Estratégia Concursos.
          </Text>
          <Text>
            O número truncado é utilizado para facilitar a identificação do cartão de crédito e token pode ser utilizado, exclusivamente na Estratégia Concursos, para facilitar novas compras mediante solicitação do aluno.
          </Text>
        </FormGroup>
        <FormGroup>
          {this.props.records.map(card => (
            <CardToken
              key={card.id}
              brand={card.bandeira}
              token={card.numero}
              isActive={card.ativo}
              disabled={this.props.isSubmitting || this.props.isRemoving}
              onActiveClick={() => this.props.update(card.id)}
              onExcludeClick={() => this.props.remove(card)}
            />
          ))}
        </FormGroup>
        <FormGroup right>
          <Button type='button' onClick={this.toggleModal}>
            Incluir novo cartão
          </Button>
        </FormGroup>
        <Modal withNotifications isOpen={this.state.isModalOpen} onRequestClose={this.toggleModal}>
          <Notifications notificationsId='formCreditCard' />
          <FormCreditCard
            onSubmit={values => this.props.create(values, null, 'formCreditCard')}
            isSubmitting={this.props.isSubmitting}
          />
        </Modal>
      </div>
    )
  }

  static propTypes = {
    records: PropTypes.array.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isRemoving: PropTypes.bool.isRequired
  }
}

export default compose(
  withResourceActions({
    resource: 'cardTokens'
  }),
  withResourceList({
    resource: 'cardTokens',
    spinnerText: 'Carregando métodos de pagamento...',
    handleEmptyRecords: false
  })
)(CardTokens)
