import React from 'react'
import 'styles/Input.css'

export default function Input ({ renderWith = 'input', ...props }) {
  const Component = renderWith

  return (
    <Component
      className='Input'
      {...props}
    />
  )
}
