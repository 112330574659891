import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Botao from '../Botao'
import imgD from '../../../images/ic-disponivel.svg'
import imgM from '../../../images/ic-nao-disponivel.svg'
import {
  Container, Planos, PlanoItem, PlanoItemCard, PlanosVantagens, PlanoVantagemItem, PlanoVantagemItemPrimary,
  PlanoItemCardText, OfertasMobile, CompativelComSeuPlano, CardInfoTextBotton, Rectangle, BotaoPlano,
  TextIncluso, ItemInclusoLista, ItemInclusoListaTexto, ItemInclusoListaHr, IconeTipo, PlanoItemCima,
  SelectPlanosMobile, BotaoPlanoMobile
} from './styles'

export default function PlanoVantagens ({ planos }) {
  const [planSelected, setplanSelected] = useState(planos[0])
  const [tipoPlano, setTipoPlano] = useState(0)
  const [listaVantagens, setListaVantagens] = useState(getVantagens(planos[0].ofertas[planos[0].ofertas.length - 1]))

  function getVantagens (ofertas) {
    let lista = []
    if (ofertas) {
      lista = Object.entries(ofertas.vantagens)
    }
    return lista
  }

  function changePlan (plan) {
    setTipoPlano(plan.nome === 'Planos de 1 Ano' ? 0 : 1)
    setplanSelected(plan)
    setListaVantagens(getVantagens(planSelected.ofertas[planSelected.ofertas.length - 1]))
  }

  function quebraTexto (texto) {
    let novoTexto = texto.split(' ')
    return novoTexto[0] + '<br>' + novoTexto[1]
  }

  function completaVantagens (qtd) {
    let itens = []
    for (let i = 0; i < qtd; i++) {
      itens.push(
        <IconeTipo key={i + 'complete'}>
          <img src={imgM} alt={'Não possúi'} />
        </IconeTipo>
      )
    }
    return itens
  }

  function completaVantagensMobile (qtd, index) {
    let itens = []
    for (let i = 0; i < qtd; i++) {
      itens.push(
        <tr key={index}>
          <td align='center'>
            <IconeTipo key={i + 'complete'}>
              <img src={imgM} alt={'Não possúi'} />
            </IconeTipo>
          </td>
          <td>{listaVantagens[index][0]}</td>
        </tr>
      )
      index++
    }
    return itens
  }

  return (
    <div>
      {planSelected !== null &&
        <Container>
          <Planos>

            <PlanoItemCima style={{ marginTop: 40 }}>
              {planos.map((plano, index) => {
                return (
                  <BotaoPlano key={index} onClick={() => { changePlan(planos[index]) }}>
                    <strong style={{ color: plano.nome === planSelected.nome ? '#FF6A58' : null }}>{plano.nome} {plano.nome === planSelected.nome ? <Rectangle /> : null}</strong>
                  </BotaoPlano>
                )
              })}
            </PlanoItemCima>

            {planSelected.ofertas.map((oferta, index) => {
              return (
                <PlanoItemCard key={index}>
                  {oferta.compativel ? <CompativelComSeuPlano>Compatível com seu plano atual</CompativelComSeuPlano> : null }
                  <PlanoItemCardText dangerouslySetInnerHTML={{ __html: quebraTexto(oferta.nome) }} />
                  <CardInfoTextBotton>{tipoPlano === 0 ? 'Plano anual' : 'Plano bienal'}</CardInfoTextBotton>
                </PlanoItemCard>
              )
            })}

          </Planos>

          <PlanosVantagens>

            <PlanoVantagemItemPrimary>
              <TextIncluso>Veja o que está Incluso</TextIncluso>
              {getVantagens(planSelected.ofertas[planSelected.ofertas.length - 1]).map((vantagem, index) => {
                return (
                  <ItemInclusoLista key={index}>
                    <ItemInclusoListaTexto>{vantagem[0]}</ItemInclusoListaTexto>
                    {index !== (getVantagens(planSelected.ofertas[planSelected.ofertas.length - 1]).length - 1) && <ItemInclusoListaHr />}
                  </ItemInclusoLista>
                )
              })}
            </PlanoVantagemItemPrimary>

            {planSelected.ofertas.map((oferta, index) => {
              let totalVantagens = getVantagens(planSelected.ofertas[planSelected.ofertas.length - 1]).length - getVantagens(oferta).length
              return (
                <PlanoVantagemItem key={index}>
                  {getVantagens(oferta).map((vantagem, index) => (
                    <IconeTipo key={(vantagem.id + '-' + index)}>
                      <img src={vantagem[1] ? imgD : imgM} alt={vantagem.id} />
                    </IconeTipo>
                  ))}
                  {completaVantagens()}
                  {totalVantagens > 0 ? completaVantagens(totalVantagens) : null}
                </PlanoVantagemItem>
              )
            })}

          </PlanosVantagens>

          <Planos>

            <PlanoItem />

            <PlanoItem />

            {planSelected.ofertas.map((botao, index) => {
              return (
                <PlanoItem key={index}>
                  {botao.combos.map((pag, index) => (
                    <Botao
                      key={index}
                      precoDe={pag.preco_de}
                      precoPor={pag.preco_por}
                      icon={pag.forma_pagamento === 'credito' ? 'card' : 'barcode'}
                      title={pag.forma_pagamento === 'credito' ? 'RENOVAR COM CARTÃO' : 'RENOVAR COM BOLETO'}
                      label={pag.forma_pagamento === 'boleto' ? '10% de desconto à vista' : null}
                      urlRedirec={pag.url}
                      forma={pag.forma_pagamento}
                    />
                  ))}
                </PlanoItem>
              )
            })}

          </Planos>

          <OfertasMobile>

            <SelectPlanosMobile>
              {planos.map((plano, index) => {
                return (
                  <BotaoPlanoMobile key={index} onClick={() => { changePlan(planos[index]) }}>
                    <strong style={{ color: plano.nome === planSelected.nome ? '#FF6A58' : null }}>{plano.nome} {plano.nome === planSelected.nome ? <Rectangle /> : null}</strong>
                  </BotaoPlanoMobile>
                )
              })}
            </SelectPlanosMobile>

            {planSelected.ofertas.map((oferta, index) => {
              let totalVantagens = getVantagens(planSelected.ofertas[planSelected.ofertas.length - 1]).length - getVantagens(oferta).length
              return (
                <React.Fragment key={index}>

                  {/* Card */}
                  <PlanoItemCard>
                    {oferta.compativel ? <CompativelComSeuPlano>Compatível com seu plano atual</CompativelComSeuPlano> : null }
                    <PlanoItemCardText dangerouslySetInnerHTML={{ __html: quebraTexto(oferta.nome) }} />
                    <CardInfoTextBotton>{tipoPlano === 0 ? 'Plano anual' : 'Plano bienal'}</CardInfoTextBotton>
                  </PlanoItemCard>

                  {/* Lista Vantagens */}
                  <PlanoVantagemItem>
                    <table>
                      <tbody>
                        {getVantagens(oferta).map((vantagem, index) => {
                          return (
                            <tr key={index}>
                              <td align='center'>
                                <IconeTipo>
                                  <img src={vantagem[1] ? imgD : imgM} alt={vantagem.id} />
                                </IconeTipo>
                              </td>
                              <td>{vantagem[0]}</td>
                            </tr>
                          )
                        })}
                        {totalVantagens > 0 ? completaVantagensMobile(totalVantagens, getVantagens(oferta).length) : null}
                      </tbody>
                    </table>
                  </PlanoVantagemItem>

                  {/* Botões Renovar */}
                  <PlanoItem>
                    {oferta.combos.map((pag, index) => (
                      <Botao
                        key={index}
                        precoDe={pag.preco_de}
                        precoPor={pag.preco_por}
                        icon={pag.forma_pagamento === 'credito' ? 'card' : 'barcode'}
                        title={pag.forma_pagamento === 'credito' ? 'RENOVAR COM CARTÃO' : 'RENOVAR COM BOLETO'}
                        label={pag.forma_pagamento === 'boleto' ? '10% de desconto à vista' : null}
                        urlRedirec={pag.url}
                        forma={pag.forma_pagamento}
                      />
                    ))}
                  </PlanoItem>

                </React.Fragment>
              )
            })}

          </OfertasMobile>

        </Container>
      }
    </div>
  )
}

PlanoVantagens.propTypes = {
  planos: PropTypes.array.isRequired
}
