import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import CustomField from 'components/CustomField'
import InputFile from 'components/InputFile'
import FormGroup from 'components/FormGroup'
import { required } from 'utils/forms'
import { fileSize, fileType } from 'utils/validators'

const FILE_SUPPORTED_TYPES = [
  'image/jpg',
  'image/png',
  'image/jpeg'
]

const MAX_FILE_SIZE = 5000000 // 5 megabytes

export default function FormForumQuestion ({ onSubmit, isSubmitting, selectedLesson, ...props }) {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        aula: selectedLesson
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SectionTitle>Enviar Pergunta</SectionTitle>
          <FormGroup>
            <Field
              name='pergunta'
              label='Faça sua pergunta*'
              component={CustomField}
              renderWith='textarea'
              rows={10}
              {...required}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name='imagem'
              label='Imagem em anexo'
              component={CustomField}
              renderWith={InputFile}
              validate={value => fileType(FILE_SUPPORTED_TYPES)(value) || fileSize(MAX_FILE_SIZE)(value)}
            />
          </FormGroup>
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Salvar alterações' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormForumQuestion.propTypes = {
  /** Callback para o form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired,

  /** ID da aula selecionada */
  selectedLesson: PropTypes.number.isRequired
}
