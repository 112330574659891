import React from 'react'
import Screen from 'containers/Screen'
import AssinaturaVitalicia from 'components/AssinaturaVitalicia'

export default function AssinaturaVitaliciaScreen () {
  return (
    <Screen title={''}>
      <AssinaturaVitalicia />
    </Screen>
  )
}
