import styled from 'styled-components'

export const BoxCertificates = styled.div`
    display: inline-block;
    width: 100%;
`

export const ContainerPaginacao = styled.div`
    margin-top: 40px;
    display: flex;
`
