/**
 * Dependencies.
 */

import getFromKeypath from 'lodash/get'
import { pushNotification } from 'store/ducks/notifications'
import isEmpty from 'lodash/isEmpty'

/**
 * A global middleware that handles promises.
 */

export default function globalFeedbackMiddleware (store) {
  return next => action => {
    if (!action.meta || !action.payload) {
      return next(action)
    }

    if (action.error && action.meta.handleError) {
      const errorsList = getFromKeypath(action.payload, 'response.data.errors')

      // Verifica se o status code corresponde a um erro de validação (422)
      const errorMessage = getFromKeypath(action.payload, 'response.status') === 422
        ? getFromKeypath(
          action.payload,
          'response.data.message',
          'Erro de validação'
        ) : getFromKeypath(
          action.payload,
          'response.data.message',
          action.meta.defaultErrorMessage || 'Ops! Ocorreu um erro!'
        )

      if (!isEmpty(errorsList)) {
        const message = {
          label: errorMessage,
          items: Object.values(errorsList)
        }
        store.dispatch(pushNotification(message, 'error', action.meta.notificationsId))
      } else {
        store.dispatch(pushNotification(errorMessage, 'error', action.meta.notificationsId))
      }

      return next(action)
    }

    if (action.meta.handleSuccess) {
      const successMessage = getFromKeypath(
        action.payload,
        'response.data.message',
        action.meta.defaultSuccessMessage || 'Processo realizado com sucesso!'
      )

      store.dispatch(pushNotification(successMessage, 'success', action.meta.notificationsId))

      return next(action)
    }

    return next(action)
  }
}
