import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { 
  addDays, differenceInDays, format, getMonth, subMonths,
  addMonths, isSameMonth, isSameDay, setDate, getDaysInMonth
} from 'date-fns'
import { range } from 'range'
import { Link } from 'react-router-dom'
import Box from 'components/Box'
import SectionTitle from 'components/SectionTitle'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import {
  Container, Calendar, Events, Header, Control, SelectedDate, SelectedMonth,
  SelectedYear, Weekdays, Weekday, Grid, Cell, EventList, EventContainer
} from './styles'
import getDateRange from 'utils/getDateRange'
import { MONTHS } from 'config/constants'
import scrollIntoView from 'scroll-into-view-if-needed'
import getRoute from 'utils/getRoute'
import routes from 'config/routes'

class CalendarEvents extends Component {
  state = {
    selectedDate: new Date()
  }

  componentDidMount () {
    this.requestData()
  }

  requestData () {
    const { selectedDate } = this.state
    const start = format(setDate(selectedDate, 1), 'YYYY-MM-DD')
    const end = format(setDate(selectedDate, getDaysInMonth(selectedDate)), 'YYYY-MM-DD')

    this.props.onFetchData({ start, end })
  }

  handleNext = e => {
    this.setState({
      selectedDate: addMonths(this.state.selectedDate, 1)
    }, () => this.requestData())
  }

  handlePrev = e => {
    this.setState({
      selectedDate: subMonths(this.state.selectedDate, 1)
    }, () => this.requestData())
  }

  getMonthEvents () {
    return this.props.events.filter(event => isSameMonth(
      this.state.selectedDate,
      event.date
    ) && event.events.length > 0)
  }

  renderCells () {
    const { startDate, endDate } = getDateRange(this.state.selectedDate)
    const selectedDateMonth = getMonth(this.state.selectedDate)
    const data = range(0, differenceInDays(endDate, startDate) + 1).map(i => {
      const date = addDays(startDate, i)
      return {
        date,
        key: format(date, 'YYYY-MM-DD'),
        hasEvents: this.props.events.find(event => isSameDay(
          date,
          event.date
        )),
        isAdjacentMonth: getMonth(date) !== selectedDateMonth
      }
    })

    return data.map(date => (
      <Cell
        key={date.key}
        isAdjacentMonth={date.isAdjacentMonth}
        hasEvents={!date.isAdjacentMonth && date.hasEvents}
        onClick={() => {
          const item = document.getElementById(date.date.toLocaleDateString())
          if (item) {
            scrollIntoView(item, {
              behavior: 'smooth',
              inline: 'center',
              block: 'center',
              boundary: item.parentElement,
            })
          }
        }}
      >
        {date.date.getDate()}
      </Cell>
    ))
  }

  render () {
    const events = this.getMonthEvents()
    const { loading } = this.props
    return (
      <Box padded>
        <SectionTitle>Calendário</SectionTitle>
        <Container>
          <Calendar style={{ opacity: loading ? 0.6 : 1 }}>
            <Header>
              <Control title='Voltar' onClick={this.handlePrev}>
                <Icon name='chevron-left' />
              </Control>
              <SelectedDate>
                <SelectedMonth>
                  {MONTHS[this.state.selectedDate.getMonth()]}
                </SelectedMonth>
                <SelectedYear>
                  {this.state.selectedDate.getFullYear()}
                </SelectedYear>
              </SelectedDate>
              <Control title='Próximo' onClick={this.handleNext}>
                <Icon name='chevron-right' />
              </Control>
            </Header>
            <Weekdays>
              <Weekday>Dom</Weekday>
              <Weekday>Seg</Weekday>
              <Weekday>Ter</Weekday>
              <Weekday>Qua</Weekday>
              <Weekday>Qui</Weekday>
              <Weekday>Sex</Weekday>
              <Weekday>Sáb</Weekday>
            </Weekdays>
            <Grid>
              {this.renderCells()}
            </Grid>
          </Calendar>
          {loading && (
            <Events center>
              <Spinner />
            </Events>
          )}
          {!loading && (
            <Events center={events.length === 0}>
              {events.length > 0 ? (
                <EventList>
                  {events.map((event, index) => (
                    <EventContainer key={index} id={event.date.toLocaleDateString()}>
                      <div className='dateTopCalendar'>
                        {format(event.date, 'DD/MM/YYYY')}
                      </div>
                      {event.events.map((data, index) => (
                        <div key={index} className='boxItemCalendario'>
                          <p>{data.title}</p>
                          {data.items.length > 0 && (
                            <ul>
                              {data.items.map((item, index) => (
                                <li key={index}>
                                  {item.hora && <strong>{item.hora.substr(0, 5)} - </strong>}
                                  <Link to={getRoute(routes.aula, { courseId: item.produto_id, lessonId: item.id })}>
                                    {item.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </EventContainer>
                  ))}
                </EventList>
              ) : (
                <p className='semRegistrosDataCalendario'>Nenhum registro disponível nesse mês</p>
              )}
            </Events>
          )}
        </Container>
      </Box>
    )
  }
  static propTypes = {
    events: PropTypes.array.isRequired
  }
}

export default CalendarEvents
