import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import 'styles/VideoItem.css'
import Icon from 'components/Icon'
import classNames from 'classnames'
import routes from 'config/routes'
import getRoute from 'utils/getRoute'

export default function VideoItem ({ courseId, lessonId, videoId, ...props }) {
  const className = classNames({
    VideoItem: true,
    '-watched': props.visualizado
  })

  const focusVideo = () => {
    setTimeout(() => {
      if (document.querySelector('.video-react-big-play-button') !== null) {
        document.querySelector('.video-react-big-play-button').focus();
      }
    }, 1000)
  }

  return (
    <NavLink
      to={getRoute(routes.video, { courseId, lessonId, videoId })}
      className={className}
      activeClassName='isSelected' 
      onClick={focusVideo()}
    >
      <span className='VideoItem-icon'>
        <Icon
          name={props.visualizado ? 'check' : 'play'}
        />
      </span>
      <span className='VideoItem-info'>
        <span className='VideoItem-info-index'>
          Vídeo {props.index + 1}
        </span>
        <span className='VideoItem-info-title'>
          {props.titulo}
        </span>
      </span>
    </NavLink>
  )
}

VideoItem.propTypes = {
  index: PropTypes.number.isRequired,
  titulo: PropTypes.string.isRequired,
  visualizado: PropTypes.bool.isRequired,
  videoId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired
}

VideoItem.defaultProps = {
  isSelected: false
}
