import React from 'react'
import Box from 'components/Box'
import Image from 'components/Image'
import Text from 'components/Text'
import 'styles/PromoMaior.css'
import UserDetails from 'containers/UserDetails'

function Featured() {
  return (
    <React.Fragment>
      <Box padded classes={`-headPadding`}>
        <div className='PromoMaior'>
          <div className='PromoMaior-column'>
            <UserDetails>
              {details => (
                <Text style={{ color: '#fff', fontWeight: 600 }}>
                  Envie os comprovantes de pagamento do curso da concorrência até {details.vem_para_maior_expire_2021} para confirmar o desconto extra na sua assinatura
                </Text>
              )}
            </UserDetails>
          </div>
          <div className='PromoMaior-column'>
            <Image
              src={require('images/conserte-erro.png')}
              alt='Promoção #VemParaOMaior'
              center
            />
          </div>
        </div>
      </Box>
    </React.Fragment>
  )
}
export default Featured