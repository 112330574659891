import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'
import Text from 'components/Text'

const CheckboxStyled = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 120px;
  justify-content: ${props => props.alignLeft ? 'unset' : 'flex-end'};

  ${props => props.disabled && 'opacity: 0.7;'}
`

const CheckboxControl = styled.div`
  ${props => !props.inversed && 'margin-right: 15px;'}
  ${props => props.inversed && `
    margin-left: 10px;
    order: 1;
  `}
`

const CheckboxReplacer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid ${props => props.checked ? 'transparent' : '#c1c1c1'};
  width: 23px;
  height: 23px;
  background-color: ${props => props.checked ? props.color : 'transparent'};

  > :first-child {
    display: ${props => props.checked ? 'block' : 'none'};
    color: #fff;
  }
`

const CheckboxInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
`

export default function Checkbox ({ label, Component = null, inversed, alignLeft = false, small, color = '#1352aa', labelStyles = {}, textColor, ...props }) {
  return (
    <CheckboxStyled disabled={props.disabled} alignLeft={alignLeft}>
      <CheckboxControl inversed={inversed}>
        <CheckboxInput
          type='checkbox'
          {...props}
        />
        <CheckboxReplacer checked={props.checked} color={color}>
          <Icon name='check' />
        </CheckboxReplacer>
      </CheckboxControl>
      { Component ? <Component {...props} /> : (
        <Text
          component='span'
          small={small}
          color={textColor}
          style={labelStyles}
        >{label}</Text>
      )}
    </CheckboxStyled>
  )
}
