import styled from 'styled-components'

export const Content = styled.div`
  float: left;
  width: 100%;
  padding: 30px 40px 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    padding: 20px 0;
  }

`

export const Texto = styled.span`
  color: #0F1A2A;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    font-size: 12px;
    text-align: center;
  }
`

export const ContainerBotoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  border: solid 3px #156EF3;
  border-radius: 50px;
  padding: 3px;

  .active {
    background: #156EF3;
  }

  @media only screen and (max-width: 900px) {
    margin: 0 5px;
  }
`

export const Botao = styled.button`
  width: 15px;
  height: 15px;
  transition: all .2s linear 0s;
  border-radius: 50px;
  border: 0;
  background: none;

  @media only screen and (max-width: 900px) {
    padding: 8px 8px;
  }
`
