import React from 'react'
import Button from 'components/Button'
import FormGroup from 'components/FormGroup'
import { API_URL } from 'config/constants'
import Icon from 'components/Icon'
import { DebounceInput } from 'react-debounce-input'
import Spinner from 'components/Spinner'

export default function CoursesSearch({ doFetch, url, term, onTermChange, quickSearch, baseURL, loading }) {
  baseURL = baseURL || API_URL;
  function toggleQuickSearch() {
    var qsc = document.getElementById("quickSearchContainer");
    if (qsc.style.display === 'none')
      qsc.style.display = 'block';
    else
      qsc.style.display = 'none';
  }

  function timmingSubmit(e) {
    var q = (e && e.target && e.target.value) || term;
    doFetch({
      url: `${baseURL}/${url}?nome=${window.encodeURIComponent(q)}`
    })
  }

  return (
    <div style={{ marginBottom: 30 }}>
      <form id="formPesquisa"
        onSubmit={e => {
          timmingSubmit(e);
          e.preventDefault();
        }}>
        <FormGroup>
          <DebounceInput
            minLength={1}
            value={term}
            debounceTimeout={1500}
            className='Input'
            style={{ float: "left", width: ((quickSearch && "90%") || "100%") }}
            onKeyDown={e => {
              let vlr = e.target.value;
              if (vlr && vlr.length === 1) {
                vlr = "";
              }
              onTermChange(vlr);
            }}
            onChange={e =>
              timmingSubmit(e)
            }
            placeholder='Pesquisar...' />
          {quickSearch && quickSearch.length > 0
            && <Button onClick={() => {
              toggleQuickSearch()
            }}
              type="button" style={{ fontSize: "14px", padding: "12px 5px", fontWeight: "300", width: "10%", background: "transparent", color: "gray" }}><Icon name='chevron-down' /> Mais</Button>}
        </FormGroup>
        <br style={{ clear: "both" }} />
        <div id="quickSearchContainer" style={{ textAlign: "center", display: "block" }}>
          {quickSearch &&
            quickSearch.length > 0 && quickSearch.map((row, i) => {
              return (
                <div key={'div' + i}>
                  {row.map((col, i) => {
                    return (<Button type="button" style={{ margin: "0", padding: "3px 5px", fontSize: "10px", fontWeight: "300", width: "12%", background: "transparent", color: "gray" }}
                      size="small" onClick={(e) => { term = col; onTermChange(col); timmingSubmit(e); }} key={i}>{col}</Button>)
                  })}
                </div>
              )
            })}
        </div>
        {loading && <Spinner />}
      </form>
    </div>
  )
}
