import React, { useState } from 'react';
import PropTypes from 'prop-types'
import HeadTitle from 'components/HeadTitle'
import CourseInfo from 'components/CourseInfo'
import LessonList from 'components/LessonList'
import Spinner from 'components/Spinner'
import CourseDiscursive from 'components/CourseDiscursive'
import Client from 'modules/Client'

export default function CourseLessons({ course, lessons, isSubmitting, sendDiscursive }) {
  const [loading, setloading] = useState(false);
  const downloads = [];

  if (course.raio_x) {
    downloads.push({
      title: 'Raio-X Estratégico',
      url: course.raio_x
    })
  }

  if (course.mapa_da_lei) {
    downloads.push({
      title: 'Mapa da Lei',
      url: course.mapa_da_lei
    })
  }

  const emitirCertificado = (event) => {
    event.preventDefault()

    return Promise.resolve()
      .then(() => {
        setloading(true);
        return Client.get(`api/aluno/certificado/gerar/${course.id}`)
      })
      .then(data => {
        if (data) {
          if (!data.success && data.message) {
            alert(data.message);
          }

          if (data.data && data.data.length > 0) {
            document.location.reload(true);
          }
        }
      })
      .catch(err => alert(err))
      .finally(() => setloading(false));
  }

  return (
    <div>
      {loading && <Spinner text={'Emitindo Certificado'} />}

      {!loading && <HeadTitle title={course.nome} />}

      {!loading && <CourseInfo
        title={course.nome}
        type={course.modalidade}
        certificado_pode_emitir={course.certificado_pode_emitir}
        certificado_link={course.certificado_link}
        teachers={course.professores}
        downloads={downloads}
        totalAulas={course.total_aulas ? course.total_aulas : 0}
        aulasRealizadas={course.total_aulas_visualizadas ? course.total_aulas_visualizadas : 0}
        onClickEmitir={(event) => { emitirCertificado(event) }}
      />}

      {!loading &&
        course.discursivas && (
          <CourseDiscursive
            course={course}
            isSubmitting={isSubmitting}
            onSubmit={sendDiscursive}
          />
        )}

      {!loading &&
        <LessonList
          lessons={lessons}
          courseId={course.id}
        />
      }
    </div>
  )
}

CourseLessons.propTypes = {
  course: PropTypes.object.isRequired,
  lessons: PropTypes.array.isRequired
}
