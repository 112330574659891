/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import ForumMessages from 'api/ForumMessages'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('FORUM_MESSAGES', [
  'REGISTER_FORUM',

  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'CREATE',
  'CREATE_PENDING',
  'CREATE_FULFILLED',
  'CREATE_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  forums: {},
  isSubmitting: false,
  isFetching: false,
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  /**
   * Register Forum.
   */

  [actionTypes.REGISTER_FORUM] (state, { payload: { id } }) {
    return update(state, {
      forums: {
        [id]: {
          $set: {
            messages: [],
            loadedPages: [],
            pagination: {}
          }
        }
      }
    })
  },

  /**
   * Fetch All.
   */

  [actionTypes.FETCH_ALL_PENDING] (state, { meta: { id } }) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_ALL_FULFILLED] (state, { meta: { id }, payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      forums: {
        [id]: {
          loadedPages: { $push: [ payload.meta.current_page ] },
          messages: { $push: payload.data },
          pagination: { $set: payload.meta }
        }
      }
    })
  },

  [actionTypes.FETCH_ALL_REJECTED] (state, { meta: { id }, payload }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Create.
   */

  [actionTypes.CREATE_PENDING] (state) {
    return update(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.CREATE_FULFILLED] (state, { meta: { id }, payload }) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: false },
      forums: {
        [id]: {
          messages: { $unshift: [ payload.data ] }
        }
      }
    })
  },

  [actionTypes.CREATE_REJECTED] (state, payload) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  }
})

/**
 * Action Creators.
 */

const registerForum = (lessonId, dispatch, state) => {
  if (!isForumRegistered(state, lessonId)) {
    dispatch({
      type: actionTypes.REGISTER_FORUM,
      payload: {
        id: lessonId
      }
    })
  }
}

export const fetchAll = (lessonId, params) => (dispatch, getState) => {
  const state = getState()

  registerForum(lessonId, dispatch, state)

  if (isPageLoaded(state, lessonId, params.page || 1)) {
    return
  }

  dispatch({
    type: actionTypes.FETCH_ALL,
    meta: {
      id: lessonId,
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar mensagens do fórum'
    },
    payload: ForumMessages.fetchAll(lessonId, params)
  })
}

export const create = (lessonId, data, notificationsId) => (dispatch, getState) => {
  registerForum(lessonId, dispatch, getState())

  dispatch({
    type: actionTypes.CREATE,
    meta: {
      id: lessonId,
      handleError: true,
      defaultErrorMessage: 'Erro ao salvar mensagem no fórum!',
      handleSuccess: true,
      defaultSuccessMessage: 'Mensagem enviada com sucesso!',
      notificationsId
    },
    payload: ForumMessages.create(lessonId, data)
  })
}

/**
 * Selectors.
 */

export const isForumRegistered = (state, lessonId) =>
  state.forumMessages.forums.hasOwnProperty(lessonId)

export const getForum = (state, lessonId) =>
  state.forumMessages.forums[lessonId] || {}

export const isPageLoaded = (state, lessonId, page) => {
  const forum = getForum(state, lessonId)
  return forum.loadedPages && forum.loadedPages.includes(page)
}

export const getForumMessages = (state, lessonId) =>
  getForum(state, lessonId).messages || []

export const isFetching = state =>
  state.forumMessages.isFetching

export const isSubmitting = state =>
  state.forumMessages.isSubmitting

export const getPagination = (state, lessonId) =>
  getForum(state, lessonId).pagination || {}
