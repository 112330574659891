import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from 'components/Icon'
import 'styles/Player/PlayerControl.css'

export default function PlayerControl (props) {
  const {
    icon,
    label,
    children,
    renderWith,
    onlyDesktop,
    ...rest
  } = props

  const Component = renderWith
  const className = classNames({
    PlayerControl: true,
    '-only-desktop': onlyDesktop,
    Inverse: props.classe
  })

  return (
    <div className={className }>
      <Component
        type='button'
        className='PlayerControl-button'
        aria-label={label}
        {...rest}
      >
        <Icon
          name={icon}
        />
      </Component>
      {children && (
        <div className='PlayerControl-options'>
          {children}
        </div>
      )}
    </div>
  )
}

PlayerControl.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  onlyDesktop: PropTypes.bool,
  renderWith: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]).isRequired
}

PlayerControl.defaultProps = {
  renderWith: 'button'
}
