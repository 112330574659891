import React from 'react'
import PropTypes from 'prop-types'
import 'styles/VideosTitle.css'
import Icon from 'components/Icon'

export default function VideosTitle ({ count }) {
  return (
    <div className='VideosTitle'>
      <div className='VideosTitle-icon'>
        <Icon name='camera' />
      </div>
      <h2 className='VideosTitle-text'>
        Vídeos
      </h2>
      <span className='VideosTitle-count'>
        {count}
      </span>
    </div>
  )
}

VideosTitle.propTypes = {
  count: PropTypes.number.isRequired
}
