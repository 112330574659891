import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'
import { ifProp } from 'styled-tools'

const DURATION = 10 // in seconds

export default function Stories ({ slides }) {
  const [ index, setIndex ] = useState(0)

  useEffect(() => {
    if (index == null) {
      return
    }

    const interval = window.setInterval(() => {
      setIndex((index + 1) % slides.length)
    }, DURATION * 1000)

    return () => window.clearInterval(interval)
  }, [index])

  return (
    <Container onClick={() => setIndex((index + 1) % slides.length)}>
      <Navigation>
        {!isNaN(index) && slides.map((slide, i) => (
          <Navigator
            key={i}
            active={i === index}
            past={i < index}
          />
        ))}
      </Navigation>
      <SliderContainer>
        <Slider index={index}>
          {slides.map((slide, index) => (
            <Slide key={index}>
              {slide}
            </Slide>
          ))}
        </Slider>
      </SliderContainer>
    </Container>
  )
}

Stories.propTypes = {
  slides: PropTypes.array.isRequired
}

const Container = styled.div`
  background-color: #fff;
`

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 20px 0;
`


const Slider = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  transform: translateX(-${props => props.index * 100}%);
  transition: all 0.2s ease;
`

const Slide = styled.div`
  width: 100%;
  flex: 1 0 auto;
`

const Navigation = styled.div`
  display: flex;
  padding: 15px 3px;
  padding-bottom: 0;
`

const animation = keyframes`
  from {
    width: 0;
  }
  
  to {
    width: 100%;
  }
`

const Navigator = styled.div`
  width: 100%;
  height: 4px;
  margin: 0 5px;
  background-color: #cccccc;
  border: none;
  position: relative;

  ${ifProp('past', 'background-color: #2e2efd;')}

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    transition: all ${DURATION}s linear;
    width: 0;
    ${props => props.active && css`animation: ${animation} ${DURATION}s linear;`}
    height: 100%;
    background-color: #2e2efd;
  }
`
