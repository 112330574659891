import styled from 'styled-components'

export const Container = styled.section`
  float: left;
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: initial;
  justify-content: center;

  @media only screen and (max-width: 1100px) {
    display: inline-block;
    padding: 0;
    margin: 0 0 15px 0px;
  }
`
