import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import CollapseContent from 'components/CollapseContent'
import Button from 'components/Button'
import media from 'utils/mediaQuery'

const Buttons = styled.div`
  margin-top: 5px;

  ${media.greaterThan('medium')`
    display: flex;
    > * + * {
      margin-left: 5px;
    }
  `}

  ${media.lessThan('medium')`
    > * + * {
      margin-top: 5px;
    }  
  `}
`

export default function VideoDownloads ({ video }) {
  return (
    <CollapseContent
      title='Opções de download'
      label='Vídeo e áudio disponíveis'
      icon='download'
    >
      <div style={{ marginBottom: 20 }}>
        <span>Resoluções de vídeo disponíveis:</span>
        <Buttons>
          {Object.keys(video.resolucoes).map(res => (
            <Button
              key={res} 
              download 
              renderWith='a'
              href={video.resolucoes[res]}
              size='small'
              target='_blank'
            >
              {res}
            </Button>
          ))}
        </Buttons>
      </div>
      {video.audio && (
        <div>
          <span>Áudio:</span>
          <Buttons>
            <Button
              renderWith='a'
              href={video.audio}
              size='small'
              download
            >
              Baixar Áudio
            </Button>
          </Buttons>
        </div>
      )}
    </CollapseContent>
  )
}

VideoDownloads.propTypes = {
  /** Video object */
  video: PropTypes.object.isRequired
}
