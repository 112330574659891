import React from 'react'
import UserDetails from 'containers/UserDetails'
import { ContainerGeral } from './Styled'
import Aviso from './Components/Aviso/Aviso'
import Cabecalho from './Components/Cabecalho/Cabecalho'
import Planos from './Components/Planos/Planos'
import Countdown from 'components/Countdown'

export default function PromocaoAssinatura () {

  return (
    <UserDetails showSpinner>
      {details => {

        return (
          <ContainerGeral padding={false}>
            <Aviso dados={details.renovar_assinatura_antecipada} />
            <Cabecalho lote={details.renovar_assinatura_antecipada.lote} />
            <Countdown
                date={new Date()}
                to={details.renovar_assinatura_antecipada.data_fim}
                onFinish={() => window.location.reload() }
              />
            { details.renovar_assinatura_antecipada !== false ? <Planos dados={details.renovar_assinatura_antecipada} lote={details.renovar_assinatura_antecipada.lote} /> : null }
          </ContainerGeral>
        )
        
      }}
    </UserDetails>
  )
}
