import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import VideoWrapper from 'components/VideoWrapper'
import loadScript from 'load-script2'
import Spinner from 'components/Spinner'

const SCRIPT_URL = 'https://player.sambatech.com.br/v3/samba.player.api.js'

let isSambaJSLoaded = false

export default class LessonPlayer extends PureComponent {
  state = {
    loaded: isSambaJSLoaded,
    error: null
  }

  componentDidMount () {
    if (isSambaJSLoaded) {
      return this.setState({ loaded: true }, () => this.executeVideo())
    }

    if (!this.state.loaded) {
      return loadScript(SCRIPT_URL, err => {
        if (err) {
          this.setState({ error: err, loaded: false }, () => {
            isSambaJSLoaded = false
          })
        } else {
          this.setState({ loaded: true }, () => {
            isSambaJSLoaded = true
          })
        }
      })
    }
  }

  componentDidUpdate () {
    this.executeVideo()
  }

  executeVideo () {
    if (!this.state.loaded) return

    if (this.player) {
      this.player = null
      document.getElementById('lesson-player').innerHTML = ''
    }

    this.player = new window.SambaPlayer('lesson-player', {
      width: '100%',
      height: '100%',
      ph: this.props.sambatechPlayerKey,
      m: this.props.sambatechMediaId,
      events: {},
      playerParams: {
        resume: true
      }
    })
  }

  render () {
    if (this.state.error) {
      return (
        <div>
          Ocorreu um erro ao carregar o vídeo!
        </div>
      )
    }

    if (!this.state.loaded) {
      return <Spinner text='Carregando vídeo'/>
    }

    return (
      <VideoWrapper id='lesson-player' />
    )
  }

  static propTypes = {
    sambatechPlayerKey: PropTypes.string.isRequired,
    sambatechMediaId: PropTypes.string.isRequired
  }
}
