import React from 'react'
import 'styles/FormGroupSelect.css'

export default function FormGroupSelect ({ id, label, children }) {
  return (
    <div className='FormGroupSelect'>
      <label htmlFor={id} className='FormGroupSelect-label'>
        {label}
      </label>
      <div className='FormGroupSelect-select'>
        {children}
      </div>
    </div>
  )
}
