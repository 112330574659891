import React from 'react'
import Screen from 'containers/Screen'
import Messages from 'containers/Messages'

export default function MensagensScreen () {
  return (
    <Screen title='Mensagens' setHeadTitle>
      <Messages />
    </Screen>
  )
}
