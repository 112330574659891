import React from 'react'
import Screen from 'containers/Screen'
import SectionCardTokens from 'components/SectionCardTokens'

export default function MetodosDePagamentoScreen () {
  return (
    <Screen title='Métodos de pagamento' setHeadTitle>
      <SectionCardTokens />
    </Screen>
  )
}
