import { combineReducers } from 'redux'
import concursos from './concursos'
import cursos from './cursos'
import aulas from './aulas'
import videos from './videos'
import pacotes from './pacotes'
import ultimasAulas from './ultimasAulas'
import blackFriday from './blackFriday'

export default combineReducers({
  concursos,
  cursos,
  aulas,
  videos,
  pacotes,
  ultimasAulas,
  blackFriday
})
