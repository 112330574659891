import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { clearAll } from 'store/ducks/notifications'
import HeadTitle from 'components/HeadTitle'
import ScreenHeader from 'components/ScreenHeader'
import theme from 'theme'
import AvisoplataformaECJ from 'components/AvisoplataformaECJ'
import AvisoLDI from 'components/AvisoLDI'

class Screen extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.clearNotifications()
  }

  render() {

    const {
      title,
      Side,
      setHeadTitle,
      setAsSubtitle,
      setPrimary,
      displayHeader,
      padded,
      horizontalPadded,
      children,
      titleStyles = {},
      course
    } = this.props

    const styles = (horizontalPadded) ? { padding: `${theme.dimensions.smallerContentPadding}px ${theme.dimensions.contentPadding}px` } : { padding: padded ? theme.dimensions.contentPadding : 0 }

    return (
      <div style={styles} id={'boxConteudo'}>
        <AvisoplataformaECJ />
        {course ? <AvisoLDI link={children} /> : null }
        {displayHeader && (
          <ScreenHeader
            title={title}
            Side={Side}
            isSubtitle={setAsSubtitle}
            isPrimary={setPrimary}
            titleStyles={titleStyles}
          />
        )}
        {setHeadTitle && <HeadTitle title={title} />}
        <div>
          {children}
        </div>
      </div>
    )
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    displayHeader: PropTypes.bool,
    Side: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.array,
      PropTypes.string
    ]).isRequired,
    clearNotifications: PropTypes.func.isRequired,
    setHeadTitle: PropTypes.bool,
    setAsSubtitle: PropTypes.bool,
    padded: PropTypes.bool
  }

  static defaultProps = {
    displayHeader: true,
    padded: true
  }
}

const mapDispatchToProps = dispatch => ({
  clearNotifications() {
    dispatch(clearAll())
  }
})

export default connect(
  null,
  mapDispatchToProps
)(Screen)
