import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'components/Checkbox'
import FormGroup from 'components/FormGroup'
import Button from 'components/Button'
import StyledScrollbars from 'components/StyledScrollbars'

export default class FormUserNotifications extends Component {
  state = {
    selectedOptions: this.props.notifications
      .filter(notification => notification.habilitado)
      .map(notification => notification.id)
  }

  onSubmit = e => {
    e.preventDefault()
    this.props.onSubmit(this.state.selectedOptions)
  }

  toggleOption (id) {
    const { selectedOptions } = this.state
    if (selectedOptions.includes(id)) {
      return this.setState({
        selectedOptions: selectedOptions.filter(option => option !== id)
      })
    }
    this.setState({
      selectedOptions: selectedOptions.concat(id)
    })
  }

  render () {
    return (
      <form onSubmit={this.onSubmit}>
        <FormGroup>
          <StyledScrollbars style={{ maxHeight: 400, position: 'relative' }}>
            {this.props.notifications.map(notification => (
              <FormGroup key={notification.id}>
                <Checkbox
                  label={notification.titulo}
                  alignLeft
                  checked={this.state.selectedOptions.includes(notification.id)}
                  onChange={() => this.toggleOption(notification.id)}
                  value={notification.id}
                />
              </FormGroup>
            ))}
          </StyledScrollbars>
        </FormGroup>
        <FormGroup right>
          <Button type='submit' disabled={this.props.isSubmitting}>
            {!this.props.isSubmitting ? 'Salvar' : 'Processando...'}
          </Button>
        </FormGroup>
      </form>
    )
  }

  static propTypes = {
    /** Callback do form submit */
    onSubmit: PropTypes.func.isRequired,

    /** Form sendo processado */
    isSubmitting: PropTypes.bool.isRequired,

    /** Lista de notificações para serem habilitadas ou desabilitadas */
    notifications: PropTypes.array.isRequired
  }
}
