import React from 'react'
import PropTypes from 'prop-types'
import CursosList from 'components/CursosList'
import SectionTitle from 'components/SectionTitle'
import Button from 'components/Button'
import { Container, Files } from './styles'

export default function CargoSection ({cargo, tipo, inativar, ativar}) {
  const cursos = cargo.cursos.filter(curso => curso.tipo === tipo)

  if (!cursos.length) {
    return null
  }

  const anexos = cargo.anexos || []

  return (
    <Container>
      <SectionTitle style={{ fontSize: 20, marginBottom: 15 }}>
        {cargo.nome}
      </SectionTitle>
      {anexos.length > 0 && (
        <Files>
          {anexos.map(file => (
            <Button
              key={file.id}
              href={file.caminho}
              renderWith='a'
              size='small'
              target='_blank'
              download
            >
              {file.nome}
            </Button>
          ))}
        </Files>
      )}
      <CursosList
        inativar={inativar}
        ativar={ativar}
        cursos={cursos}
      />
    </Container>
  )
}

CargoSection.propTypes = {
  /** Objeto do cargo */
  cargo: PropTypes.object.isRequired,

  /** Tipo de cursos */
  tipo: PropTypes.string.isRequired
}
