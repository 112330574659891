import React from 'react'
import Screen from 'containers/Screen'

export default function SalaVipScreen ({match}) {
  const salaId = match.params.salaId;
  const appLink = 'https://estrategia-concursos.whereby.com/' + salaId + '?iframeSource=estrategiaconcursos';
  return (
    <Screen title='Sala VIP' setHeadTitle>
      <iframe style={{width: "100%", heigth:"100%", minHeight: "600px", border: "6px solid gray" }} allow="microphone, camera" 
      title="estudo em grupo" src={appLink}></iframe>
    </Screen>
  )
}