import React from 'react'
import Client from 'modules/Client'
import FetchApi from 'components/FetchApi'

export default function FetchAuth ({ headers = {}, hasBearer = true, ...props }) {
  if (hasBearer) headers['Authorization'] = `Bearer ${Client.token}`

  return (
    <FetchApi
      headers={headers}
      {...props}
    />
  )
}
