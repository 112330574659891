import React from 'react'
import MessageCollapse from 'components/MessageCollapse'
import withResourceList from 'store/utils/withResourceList'
import { actionCreators } from 'store/actions/resources/messages'

function Messages ({ records, dispatch }) {
  return records.map(message => (
    <MessageCollapse
      key={message.mensagem.id}
      authorName={message.mensagem.professor ? message.mensagem.professor.nome : 'Sistema'}
      authorAvatar={message.mensagem.professor ? message.mensagem.professor.imagem : require('../images/avatar.svg')}
      date={message.mensagem.data}
      body={message.mensagem.conteudo}
      subject={message.mensagem.assunto}
      unread={!message.lido}
      product={message.mensagem.produto ? message.mensagem.produto : null}
      onOpen={() => message.data_visualizada !== null ? null : dispatch(actionCreators.readMessage(message.mensagem.id))}
    />
  ))
}

export default withResourceList({
  resource: 'messages',
  spinnerText: 'Carregando mensagens'
})(Messages)
