import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 0;
`

export const Padded = styled.div`
  padding: 0 20px;
`

export const Warning = styled.div`
  padding: 15px 20px;
  background-color: #fffde4;
`
