/**
 * Dependencies.
 */

import update from 'immutability-helper'
import { actionTypes } from './cursos'
import createReducer from 'store/utils/createReducer'

/**
 * Initial State.
 */

const initialState = {
  entities: {},
  result: []
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.FETCH_ALL_FULFILLED] (state, { payload }) {
    return update(state, {
      entities: { $set: payload.entities.concursos },
      result: { $set: payload.result }
    })
  }
})

/**
 * Selectors.
 */

export const getConcoursesList = state =>
  state.conteudo.concursos.result.map(id => (
    state.conteudo.concursos.entities[id]
  ))
