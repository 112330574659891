import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'styles/CardToken.css'

export default function CardToken (props) {
  const className = classNames({
    'CardToken-item': true,
    isActive: props.isActive
  })

  return (
    <div className='CardToken'>
      <div className='CardToken-item'>
        {props.token}
      </div>
      <button
        disabled={props.disabled}
        type='button'
        className={className}
        onClick={props.isActive ? null : props.onActiveClick}
      >
        {props.isActive ? 'Ativo' : 'Ativar'}
      </button>
      <button
        disabled={props.disabled}
        type='button'
        className='CardToken-item'
        onClick={props.onExcludeClick}
      >
        Excluir
      </button>
    </div>
  )
}

CardToken.propTypes = {
  token: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  brand: PropTypes.string,
  onActiveClick: PropTypes.func.isRequired,
  onExcludeClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
