import Client from 'modules/Client'

export default {
    
  fetchAll (lessonId, params = {}) {
    return Client.get(`api/aluno/aula/${lessonId}/forum`, { params })
  },

  create (lessonId, { pergunta, imagem }) {
 
    const data = new window.FormData()

    if (pergunta) data.set('pergunta', pergunta)
    if (imagem) data.set('imagem', imagem)

    return Client.post(`api/aluno/aula/${lessonId}/forum`, {
      data,
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }
}
