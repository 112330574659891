import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Modal from 'components/Modal'
import SectionTitle from 'components/SectionTitle'
import { Container, Padded, Warning } from './styles'
import FormConfirmacaoPalavra from 'forms/FormConfirmacaoPalavra'

export default function MatriculaModal (props) {
  const {
    produtoSelecionado,
    onSubmit,
    onCloseModal,
    limite,
    inscricoes
  } = props

  const restante = limite - inscricoes - 1

  return (
    <Modal
      isOpen={!isEmpty(produtoSelecionado)}
      onRequestClose={onCloseModal}
      width='1000px'
      padding='0'
    >
      <Container>
        <Padded>
          <SectionTitle style={{ fontSize: 20 }}>
            Matrícula no curso: {produtoSelecionado.nome}
          </SectionTitle>
        </Padded>
        {inscricoes < limite ? (
          <Warning>
            <p>
              Você tem direito a{' '}
              <strong>{limite} matrículas simultâneas</strong> em cursos e
              pacotes.
            </p>
            <p>
              Depois de atingido este limite, para que seja possível se
              matricular em novo curso/pacote, será necessário se desmatricular
              de um dos seus cursos/pacotes previamente matriculados.
            </p>
            <p>
              Isso é importante porque os professores são remunerados com base
              nessas matrículas. O Estratégia enxerga os professores como parte
              essencial do seu sucesso. Para valorizar o trabalho deles,
              matricule-se somente nos cursos que você está{' '}
              <strong>realmente estudando</strong>.
            </p>
          </Warning>
        ) : (
          <Warning>
            <p>
              Você já está matriculado em 3 pacotes e/ou cursos. Para se
              matricular em um novo pacote ou curso, você precisa, previamente,
              desmatricular-se de algum pacote e/ou curso que atualmente esteja
              em sua estante virtual.
            </p>
          </Warning>
        )}

        {inscricoes < limite && (
          <Padded>
            <p>
              Você tem certeza que deseja se matricular em{' '}
              <strong>{produtoSelecionado.nome}</strong>? Após confirmar sua
              matrícula, sobrarão {restante} matrículas.
            </p>
            <FormConfirmacaoPalavra word='CORUJA' onSubmit={data => data && onSubmit(produtoSelecionado)} />
          </Padded>
        )}
      </Container>
    </Modal>
  )
}

MatriculaModal.propTypes = {
  produtoSelecionado: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  limite: PropTypes.number.isRequired,
  inscricoes: PropTypes.number.isRequired
}
