import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import 'styles/LessonButton.css'

export default function LessonButton ({ renderWith: Component, icon, label, children, novo, ...props }) {
  return (
    <Component className='LessonButton' {...props}>
      <div className='LessonButton-inner'>
        <span className='LessonButton-icon'>
          <Icon name={icon} />
        </span>
        <span className='LessonButton-text'>
          {children}
        </span>
      </div>
      {label && (
        <span className={`LessonButton-label ${label === 'Baixado' ? 'baixado' : ''}`}>
          {label}
        </span>
      )}
      {novo && (
        <span className={`novo`}>novo</span>
      )}
    </Component>
  )
}

LessonButton.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  label: PropTypes.string,
  renderWith: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
}

LessonButton.defaultProps = {
  renderWith: 'button'
}
