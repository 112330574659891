import React from 'react'
import styled from 'styled-components'
import { Collapse, CollapseHeader, CollapseBody } from 'components/Collapse'
import Icon from 'components/Icon'

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-top: 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
  position: relative;
`

const HeaderIcon = styled.div`
  display: block;
  font-size: 26px;
  color: #1352aa;
  margin-right: 10px;
  line-height: 0;
`

const Title = styled.strong`
  display: block;
  font-size: 14px;
  color: #2b2b2b;
  margin-bottom: 3px;
`

const Label = styled.span`
  display: block;
  font-size: 11px;
  color: #7f7f7f;
`

const Body = styled.div`
  padding-top: 5px;
`

export default function CollapseContent ({ children, icon, title, label }) {
  return (
    <Container>
      <Collapse>
        <CollapseHeader arrow>
          <Header>
            <HeaderIcon>
              <Icon name={icon} />
            </HeaderIcon>
            <div>
              <Title>
                {title}
              </Title>
              <Label>
                {label}
              </Label>
            </div>
          </Header>
        </CollapseHeader>
        <CollapseBody>
          <Body>
            {children}
          </Body>
        </CollapseBody>
      </Collapse>
    </Container>
  )
}
