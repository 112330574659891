import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section'
import CardItemCurso from 'components/CardItemCurso'
import FormAssinaturaCursosFilter from 'forms/FormAssinaturaCursosFilter'
import VerticalSpacer from 'components/VerticalSpacer'
import Matriculas from 'components/Matriculas'
import { Container } from 'components/CursosList/styles'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs'
import Box from 'components/Box'
import Spinner from 'components/Spinner'
import './index.css'

export default function Subscriptions (props) {

  const {
    subscribedCourses,
    onSubscribe,
    onUnsubscribe,
    queryCourses,
    queryCoursesPage,
    disabled,
    courses,
    packages,
    teatchers,
    subjects,
    assinaturasItems,
    loadingFilter
  } = props

  const vagas = assinaturasItems.map(a => `${a.limite_inscricao} matrículas na "${a.nome}"`)
  const vagasLast = vagas.pop()
  const vagasText = [
    vagas.join(', '),
    vagasLast
  ].join(' e ')

  function onActivate (index) {
    switch (index) {
      case 1:
        queryCourses('a', 'curso', 0)
        break
      case 2:
        queryCourses('a', 'teatchers', 0)
        break
      case 3:
        queryCourses('a', 'subjects', 0)
        break
      default:
        queryCourses('pacote', 'pacote', 0)
        break
    }
  }

  function onPageChange (page) {
    queryCoursesPage(page)
  }

  return (
    <VerticalSpacer>
      <Section title='Produtos matriculados' padding={'0 0 20px 0'} boxOptions={{isTransparent: true}}>
        {subscribedCourses.length > 0 ? (
          <div>
            <Container>
              {subscribedCourses.map(course => (
                <CardItemCurso
                  key={course.produto.id}
                  title={course.produto.nome}
                  subscribed
                  disabled={disabled}
                  onClick={() => onUnsubscribe(course)}
                  bottomLabel={course.assinatura.nome}
                  agrupador={course.produto.agrupador}
                  id={course.produto.id}
                  withLink
                  status={course.ativa}
                />
              ))}
            </Container>
          </div>
        ) : (
          <Box padded style={{ marginTop: '20px' }}>
            <p className='textoMatriculas'>Você não está matriculado em nenhum produto. Increva-se nos produtos abaixo:</p>
          </Box>
        )}

        <Box padded style={{ marginTop: '20px' }}>
          <p className='textoMatriculas'>
            Você tem direito a {vagasText}.
            Depois de atingido este limite, para que seja possível se matricular em novo curso/pacote,
            será necessário se desmatricular de um dos seus cursos/pacotes previamente matriculados.
          </p>
        </Box>

      </Section>
      <Section title='Matricular em novos produtos' padding={0} boxOptions={{isTransparent: true}}>
        <Tabs activeIndex={0} onActivate={onActivate}>
          <TabList >
            <Tab>Pacotes</Tab>
            <Tab>Cursos</Tab>
            <Tab>Professores</Tab>
            <Tab>Matérias</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FormAssinaturaCursosFilter searchList={data => queryCourses(data ? data : 'pacote', 'pacote', 0)} />
              { loadingFilter ? <Spinner /> : <Matriculas
                  produtos={packages.data}
                  pagination={packages.pagination}
                  disabled={disabled}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  onPageChange={onPageChange}
                />
              }
            </TabPanel>
            <TabPanel>
              <FormAssinaturaCursosFilter searchList={data => queryCourses(data ? data : 'a', 'curso', 0)} />
              { loadingFilter ? <Spinner /> : <Matriculas
                  produtos={courses.data}
                  pagination={courses.pagination}
                  disabled={disabled}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  onPageChange={onPageChange}
                />
              }
            </TabPanel>
            <TabPanel>
              <FormAssinaturaCursosFilter searchList={data => queryCourses(data ? data : 'a', 'teatchers', 0)} />
              { loadingFilter ? <Spinner /> : <Matriculas
                  produtos={teatchers.data}
                  pagination={teatchers.pagination}
                  disabled={disabled}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  onPageChange={onPageChange}
                />
              }
            </TabPanel>
            <TabPanel>
              <FormAssinaturaCursosFilter searchList={data => queryCourses(data ? data : 'a', 'subjects')} />
              { loadingFilter ? <Spinner /> : <Matriculas
                  produtos={subjects.data}
                  pagination={subjects.pagination}
                  disabled={disabled}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  onPageChange={onPageChange}
                />
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
    </VerticalSpacer>
  )
}

Subscriptions.propTypes = {
  subscribedCourses: PropTypes.array.isRequired,
  courses: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func.isRequired,
  onUnsubscribe: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  fetchCourses: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired
}
