import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SectionTitle from 'components/SectionTitle'
import FormCourseEvaluation from 'forms/FormCourseEvaluation'
import { isSubmitting, create } from 'store/ducks/course-evaluations'

function CourseEvaluation ({ courseId, isSubmitting, create }) {
  return (
    <section>
      <SectionTitle>Dê sua opinião</SectionTitle>
      <FormCourseEvaluation
        onSubmit={(data, form) => {
          create(courseId, data).then(() => form.reset())
        }}
        isSubmitting={isSubmitting}
      />
    </section>
  )
}

CourseEvaluation.propTypes = {
  /** ID do curso */
  courseId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state)
})

const mapDispatchToProps = { create }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEvaluation)
