import React from 'react'
import { Container, ColumnInfo, InfoTitle, InfoText, InfoDestaque, ColumnPicture, PictureImage, InfoMain } from './styles'

function PlanoDisponivel () {
  return (
    <Container>
      <InfoMain>
        <ColumnInfo>
          <div>
            <InfoTitle>Não fique sem conteúdo!</InfoTitle>
            <InfoText>Aproveite o <InfoDestaque>desconto para assinantes</InfoDestaque> <br /> e faça a renovação da sua assinatura agora.</InfoText>
          </div>
        </ColumnInfo>
        <ColumnPicture>
          <PictureImage />
        </ColumnPicture>
      </InfoMain>
    </Container>
  )
}

export default PlanoDisponivel
