/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import Cursos from 'api/Cursos'
import { isEmpty } from 'ramda'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('RESEARCHES', [
  'FETCH_RESEARCH',
  'FETCH_RESEARCH_PENDING',
  'FETCH_RESEARCH_FULFILLED',
  'FETCH_RESEARCH_REJECTED',

  'SEND_RESEARCH',
  'SEND_RESEARCH_PENDING',
  'SEND_RESEARCH_FULFILLED',
  'SEND_RESEARCH_REJECTED',

  'IGNORE_RESEARCH',
  'IGNORE_RESEARCH_PENDING',
  'IGNORE_RESEARCH_FULFILLED',
  'IGNORE_RESEARCH_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  items: {},
  isFetching: false,
  isSubmitting: false,
  isIgnoring: false,
  error: null
}

/**
 * Researches Reducer.
 */

export default createReducer(initialState, {
  /**
   * Fetch Research
   */

  [actionTypes.FETCH_RESEARCH_PENDING] (state) {
    return update(state, {
      isFetching: { $set: true }
    })
  },

  [actionTypes.FETCH_RESEARCH_FULFILLED] (state, { payload, meta }) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: null },
      items: {
        [meta.courseId]: {
          $set: {
            perguntas: payload.perguntas,
            professores: payload.professores
          }
        }
      }
    })
  },

  [actionTypes.FETCH_RESEARCH_REJECTED] (state, payload) {
    return update(state, {
      isFetching: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Send Research.
   */

  [actionTypes.SEND_RESEARCH_PENDING] (state) {
    return update(state, {
      isSubmitting: { $set: true }
    })
  },

  [actionTypes.SEND_RESEARCH_FULFILLED] (state) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: null }
    })
  },

  [actionTypes.SEND_RESEARCH_REJECTED] (state, payload) {
    return update(state, {
      isSubmitting: { $set: false },
      error: { $set: payload }
    })
  },

  /**
   * Ignore Research.
   */

  [actionTypes.IGNORE_RESEARCH_PENDING] (state) {
    return update(state, {
      isIgnoring: { $set: true }
    })
  },

  [actionTypes.IGNORE_RESEARCH_FULFILLED] (state) {
    return update(state, {
      isIgnoring: { $set: false },
      error: { $set: null }
    })
  },

  [actionTypes.IGNORE_RESEARCH_REJECTED] (state, payload) {
    return update(state, {
      isIgnoring: { $set: false },
      error: { $set: payload }
    })
  }
})

/**
 * Action creators.
 */

export const fetchResearch = courseId => (dispatch, getState) => {
  if (!isEmpty(getResearch(getState(), courseId))) return

  dispatch({
    type: actionTypes.FETCH_RESEARCH,
    payload: Cursos.fetchResearch(courseId),
    meta: {
      courseId,
      handleError: true,
      defaultErrorMessage: 'Erro ao carregar pesquisa',
      formName: 'research'
    }
  })
}

export const sendResearch = (courseId, data, notificationsId) => ({
  type: actionTypes.SEND_RESEARCH,
  payload: Cursos.sendResearch(courseId, data),
  meta: {
    courseId,
    handleError: true,
    defaultErrorMessage: 'Erro ao enviar respostas da pesquisa',
    notificationsId
  }
})

export const ignoreResearch = courseId => ({
  type: actionTypes.IGNORE_RESEARCH,
  payload: Cursos.ignoreResearch(courseId),
  meta: {
    courseId
  }
})

/**
 * Selectors.
 */

export const isFetching = state =>
  state.researches.isFetching

export const isSubmitting = state =>
  state.researches.isSubmitting

export const isIgnoring = state =>
  state.researches.isIgnoring

export const getResearch = (state, id) =>
  state.researches.items[id] || {}

export const getError = state =>
  state.researches.error
