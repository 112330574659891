import React from 'react'
import {
  Content,
  Texto,
  ContainerBotoes,
  Botao
} from './Styled'

export default function Switch ({ status, changeSwitch }) {
  return (
    <Content>
      <Texto onClick={() => changeSwitch(1)}>Planos de 1 ano</Texto>
      <ContainerBotoes>
        <Botao className={status === 1 ? 'active' : null} onClick={() => changeSwitch()} />
        <Botao className={status === 2 ? 'active' : null} onClick={() => changeSwitch()} />
      </ContainerBotoes>
      <Texto onClick={() => changeSwitch(2)}>Planos de 2 anos</Texto>
    </Content>
  )
}
