import styled from 'styled-components'

export const Header = styled.section`
  float: left;
  width: 100%;
  background: #000;
  position: relative;
  padding: 30px;

  @media only screen and (max-width: 560px) {
    padding: 30px;
  }
`

// Imagem
export const BoxImagem = styled.figure`
  margin: 0;
  float: left;
  text-align: center;
  background: #000000;
  width: 100%;

  img {
    max-width: 100%;
  }
`
