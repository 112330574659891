import React from 'react'
import '../../styles.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { totalSlides, verificaAutoPlay } from '../../scripts'
import Slide from './Componentes/Slide'

export default function SliderBox (props) {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    arrows: false,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          centerPadding: '15px'
        }
      }
    ]
  }

  return (
    <div className={`banner-slider-thumbs`}>
      <Slider {...settings} infinite={totalSlides(props.data.data.items)} autoplay={verificaAutoPlay(props.data.data.items)}>
          {props.data.data.items.map((item, index) => (
            <Slide key={index} index={index} item={item} />
          ))}
      </Slider>
    </div>
  )

}