import React from 'react'
import Screen from 'containers/Screen'
import Orders from 'containers/Orders'
import { clearResponseCache } from 'react-request'

export default function HistoricoScreen ({ location: { search } }) {
  clearResponseCache();
  localStorage.removeItem("SubscriptionManager.allCourses");
  localStorage.removeItem("SubscriptionManager.subscriptions");

  return (
    <Screen title='Histórico de Compras' setHeadTitle>
      <Orders
        pedidoId={search ? parseInt(search.replace(/\D/g, ''), 10) : null}
      />
    </Screen>
  )
}
