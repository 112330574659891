/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import PromoMaior2020 from 'api/VemParaoMaior2021'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('PROMO2020', [
  'PROMO2020',
  'PROMO2020_PENDING',
  'PROMO2020_FULFILLED',
  'PROMO2020_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isSubmitting: false,
  error: null,
  isDone: false
}

/**
 * Invite Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.PROMO2020_PENDING]: state =>
    update(state, {
      isSubmitting: { $set: true }
    }),

  [actionTypes.PROMO2020_FULFILLED]: state =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: null },
      isDone: { $set: true }
    }),

  [actionTypes.PROMO2020_REJECTED]: (state, action) =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: action.payload },
      isDone: { $set: false }
    })
})

/**
 * Action creators.
 */

export const Promo2020 = data => ({
  type: actionTypes.PROMO2020,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao enviar dados!',
    handleSuccess: true,
    defaultSuccessMessage: 'Dados enviados com sucesso!',
    formName: 'promo2020'
  },
  payload: PromoMaior2020.sendProof(data)
})

/**
 * Selectors.
 */

export const isSubmitting = state =>
  state.promo2020.isSubmitting

export const isDone = state =>
  state.promo2020.isDone