import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Base from 'components/Base'

const TextStyled = styled(({ small, color, ...props }) => <Base {...props} />)`
  font-family: ${theme.fonts.primary};
  color: ${props => props.color};
  font-size: 18px;
  line-height: 1.5;
  margin: 0;

  ${props => props.small && 'font-size: 14px;'}

  & + & {
    margin-top: 20px;
  }
`

export default function Text (props) {
  return (
    <TextStyled
      component='p'
      color={theme.colors.text}
      {...props}
    />
  )
}
