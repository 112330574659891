import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'styles/CourseInfo.css'
import Box from 'components/Box'
import TeachersList from 'components/TeachersList'
import SubTitle from 'components/Subtitle'
import Button from 'components/Button'
import mediaQuery from 'utils/mediaQuery'

export default function CourseInfo({ certificado_link, certificado_pode_emitir, type, title, teachers, downloads, totalAulas, aulasRealizadas, onClickEmitir }) {
  return (
    <Box className="CourseInfoRelative Box isPadded">
      <section className='CourseInfo'>

        {/* Botão emitir certificado */}
        {certificado_pode_emitir &&
          onClickEmitir &&
          <ContadorPorcentagem>
            <Button size='small' onClick={onClickEmitir}>Emitir <br />Certificado</Button>
          </ContadorPorcentagem>
        }

        {/* Botão já emitido anteriormente */}
        {certificado_link &&
          <ContadorPorcentagem>
            <Button renderWith='a' size='small' href={certificado_link} target='_blank'>Visualizar <br/>Certificado</Button>
          </ContadorPorcentagem>}

        <ContadorPorcentagem>
          <span className="numeroPorcentagem">{aulasRealizadas && totalAulas ? ((aulasRealizadas / totalAulas) * 100).toFixed(0) : 0}%</span>
          <span className="textoPorcentagem">das aulas baixadas</span>
        </ContadorPorcentagem>

        <div className='CourseInfo-content'>
          {type && (
            <SubTitle>{type}</SubTitle>
          )}
          <h2 className='CourseInfo-content-title'>
            {title}
          </h2>
        </div>
        <div className='CourseInfo-teachers'>
          <TeachersList teachers={teachers} />
        </div>
      </section>

      {downloads.lenght > 0 ?
        <ButtonsContainer>
          {downloads.map((file, index) => (
            <Button
              key={index}
              renderWith='a'
              href={file.url}
              download
              target='_blank'
              size='small'
            >
              {file.title}
            </Button>
          ))}
        </ButtonsContainer>
        : null
      }

      <BarraPorcentagem>
        <Porcentagem
          width={totalAulas && aulasRealizadas ? (aulasRealizadas / totalAulas) * 100 : 0}
          color={(totalAulas && aulasRealizadas ? (aulasRealizadas / totalAulas) * 100 : 0) === 100 ? '#1717CE' : '#FF6A58'}
        />
      </BarraPorcentagem>

    </Box>
  )
}

CourseInfo.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  teachers: PropTypes.array.isRequired,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  )
}

CourseInfo.defaultProps = {
  downloads: []
}

const ButtonsContainer = styled.div`
  margin-top: 15px;

  > * + * {
    ${mediaQuery.greaterThan('medium')`
      margin-left: 5px;
    `}

    ${mediaQuery.lessThan('medium')`
      margin-top: 5px;
    `}
  }
`

const ContadorPorcentagem = styled.div`
  margin-right: 20px;
  border-right: solid 1px #f1f1f1;
  padding-right: 20px;
  text-align: center;

  @media(max-width: 700px) {
    text-align: left;
    margin-bottom: 15px;
  }

  & .numeroPorcentagem {
    text-align: center;
    font-size: 24px;
    color: #F98273;
    margin-bottom: 4px;
    font-weight: bold;
    display: inline-flex;
  }

  & .textoPorcentagem {
    font-size: 12px;
    clear: both;
    color: #999;
    display: block;
  }
`

const BarraPorcentagem = styled.div`
  width: calc(100% + 2px);
  height: 8px;
  background: #e2e2e2;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: -1px;
  overflow: hidden;
`

const Porcentagem = styled.div`
  height: 8px;
  position: absolute;
  left: 0;
  background: ${props => props.color ? `${props.color}` : null};
  width: ${props => props.width ? `${props.width}%` : '0'};
`
