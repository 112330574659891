import React from 'react'
import UserDetails from 'containers/UserDetails'
import { ContainerGeral, BoxFechar, ImagemBotao } from './Styled'
import Cabecalho from './Components/Cabecalho/Cabecalho'
import Conteudo from './Components/Conteudo/Conteudo'
import routes from 'config/routes'
import { NavLink, Redirect } from 'react-router-dom'
import imgFechar from 'images/ic-fechar-promocao.svg'
import bgContainer from 'images/bg-vitalicia.jpg'
import bgContainerMobile from 'images/bg-vitalicia-mobile.jpg'

export default function AssinaturaVitalicia () { 
  return (
    <UserDetails showSpinner>
      {details => (!details.tem_vitalicia) 
        ? (<Redirect to={routes.cursos} />)
        : (
          <ContainerGeral bg={bgContainer} bgMobile={bgContainerMobile}>

            <BoxFechar>
              <NavLink to={routes.cursos}>
                <ImagemBotao src={imgFechar} />
              </NavLink>
            </BoxFechar>

            <Cabecalho desconto={details.renovar_assinatura_antecipada.cashback} nameUser={details.name} />

            <Conteudo planos={details.renovar_assinatura_antecipada.planos[0].assinaturas[0]} />

          </ContainerGeral>
        )
        
      }
    </UserDetails>
  )
}
