import React from 'react'
import PropTypes from 'prop-types'
import { parse } from 'date-fns'
import CursoCard from 'components/CursoCard'
import Pagination from 'components/Pagination'
import { PaginationContainer, Container } from './styles'

export default function CursosList ({ cursos, type, pagination, onPageChange, inativar, ativar }) {
  return (
    <Container>
      { pagination && pagination.last_page > 1
        ? <small style={{width: '100%'}}>Mostrando {cursos.length} de {(pagination && pagination.total) || 0} resultados</small>
        : null
      } 
      {cursos.map(curso => (
        <CursoCard
          key={curso.id}
          id={parseInt(curso.id, 10)}
          title={curso.nome || curso.name}
          type={curso.tipo || type}
          startDate={parse(curso.data_inicio)}
          expiryDate={parse(curso.data_retirada)}
          icon={curso.icone}
          redirectLink={curso.redirect_area_aluno}
          inativar={inativar}
          ativar={ativar}
          favorito={curso.favorito} 
          totalAulas={curso.total_aulas && curso.total_aulas !== null ? curso.total_aulas : 0}
          aulasRealizadas={curso.total_aulas_visualizadas && curso.total_aulas_visualizadas !== null ? curso.total_aulas_visualizadas : 0}
        />
      ))}
      {
        pagination && pagination.last_page > 1 && (
          <PaginationContainer>
            <Pagination
              center
              current={pagination.current_page}
              total={pagination.last_page}
              onChange={onPageChange}
            />
          </PaginationContainer>
        )
      }
    </Container>
  )
}

CursosList.propTypes = {
  /** Lista de cursos */
  cursos: PropTypes.array.isRequired
}
