import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'styles/Link.css'

export default function Link ({ renderWith, size, ...props }) {
  const className = classNames({
    Link: true,
    '-small': size === 'small',
    '-large': size === 'large'
  })

  const Component = renderWith

  return (
    <Component
      className={className}
      {...props}
    />
  )
}

Link.propTypes = {
  renderWith: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  size: PropTypes.oneOf(['small', 'large'])
}

Link.defaultProps = {
  renderWith: 'a'
}
