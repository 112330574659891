import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, CollapseHeader, CollapseBody } from 'components/Collapse'
import Box from 'components/Box'
import BlockInfo from 'components/BlockInfo'
import Button from 'components/Button'
import FetchAuth from 'components/FetchAuth'
import WithModal from 'components/WithModal'
import FormConfirmacaoPalavra from 'forms/FormConfirmacaoPalavra'
import { dateToBR, toMoney } from 'utils/helpers'
import { SITE_URL } from 'config/constants'
import 'styles/OrderCollapse.css'

export default function OrderCollapse (props) {

  const getIcon = (tipo) => {

    switch (tipo) {
      case 'Boleto Bancário':
          return 'barcode'
      case 'Vale-desconto':
        return 'ticket'
      case 'Pix':
        return 'qrcode fa'
      default:
        return 'card'
    }

  }

  return (
    <div className='OrderCollapse' id={`pedido-${props.orderId}`}>
      <Box>
        <Collapse initialIsOpened={props.orderId === props.activeOrderId}>
          <CollapseHeader arrow>
            <div className='OrderCollapse-header'>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='cart'
                  label='Número do pedido'
                  value={props.orderId}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='clock'
                  label='Data da compra'
                  value={dateToBR(props.date)}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='wallet'
                  label='Valor'
                  value={toMoney(props.value)}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon={getIcon(props.paymentType)}
                  label='Forma de pagamento'
                  value={props.paymentType}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  label='Status'
                  value={props.status}
                />
              </div>
            </div>
          </CollapseHeader>
          <CollapseBody>
            <div className='OrderCollapse-body'>
              <ul className='OrderCollapse-body-list'>
                {props.products.map(product => (
                  <li key={product.id}>
                    <span>{product.nome}</span>
                  </li>
                ))}
                { props.pedidoRecorrente ? <li><span>Vigência de assinatura começando em {dateToBR(props.pedidoRecorrente.validade_inicio)} e terminando em {dateToBR(props.pedidoRecorrente.validade_fim)}</span></li> : null }
                {props.order.cobrancas && props.order.cobrancas.map((cobranca, index) => (
                  <li key={index}>
                    <span>
                      {cobranca.numero_parcelas}x no {cobranca.tipo_cobranca.nome}{cobranca.status ? ` (${ cobranca.status === 'aguardando_pagamento' ? 'Aguardando Pagamento' : cobranca.status })` : ''}
                      { props.order.url ? <span> - <a href={ props.order.url } target="_blank">{ props.paymentType === 'Pix' ? 'Realizar pagamento com Pix' : 'Baixar Boleto' }</a></span> : '' }
                    </span>
                  </li>
                ))}
              </ul>
              {props.gerenciar && props.tipoCobrancaId !== 1 && (
                <Button
                  renderWith='a'
                  href={`${SITE_URL}/assinaturas`}
                >
                  Renovar assinatura
                </Button>
              )}
              {props.gerenciar && props.tipoCobrancaId === 1 && (
                <FetchAuth
                  method='post'
                  url={`api/aluno/historico/${props.orderId}/cancelarRenovacao`}
                  successfulMessage={() => 'Renovação cancelada com sucesso'}
                  lazy
                >
                  {({ doFetch, loading, data }) => data ? '' : (
                    <React.Fragment>
                      <WithModal
                        modal={(
                          <div>
                            <FormConfirmacaoPalavra
                              word='CORUJA'
                              label={<React.Fragment>Para confirmar o cancelamento de renovação automática da assinatura, digite <strong>CORUJA</strong>:</React.Fragment>}
                              onSubmit={() => doFetch()}
                            />
                          </div>
                        )}
                      >
                        {({ toggleModal }) => (
                          <Button
                            type='button'
                            disabled={loading}
                            onClick={() => toggleModal()}
                          >
                            {loading ? 'Processando...' : 'Cancelar Renovação'}
                          </Button>
                        )}
                      </WithModal>
                    </React.Fragment>
                  )}
                </FetchAuth>
              )}
            </div>
          </CollapseBody>
        </Collapse>
      </Box>
    </div>
  )
}

OrderCollapse.propTypes = {
  orderId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  paymentType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.any.isRequired
}
