import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import StarRating from 'components/StarRating'
import Course from 'containers/Course'
import WithModal from 'components/WithModal'
import CourseEvaluation from 'containers/CourseEvaluation'
import mediaQuery from 'utils/mediaQuery'

const Container = styled.div`
  ${mediaQuery.greaterThan('medium')`
    display: flex;
    align-items: center;
  `}
`

const ButtonStyled = styled.button`
  padding: 10px 15px;
  border-radius: 3px;
  background-color: transparent;
  border: 2px solid #000000;
  color: #000;
  margin-left: 5px;

  &:hover {
    background-color: #d2d2d2;
  }

  ${mediaQuery.lessThan('medium')`
    display: block;
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
  `}
`

export default function CourseRating ({ id }) {
  return (
    <Course id={id} showSpinner={false} fetch={false}>
      {({ course, onRatingChange }) => (
        <Container>
          <StarRating
            label='Avalie este curso:'
            value={course.nota}
            onChange={rating => {
              if (rating !== course.nota) {
                onRatingChange(id, rating, course.nota)
              }
            }}
          />
          <div>
            <WithModal
              modal={<CourseEvaluation courseId={id} />}
              notifications
              notificationsId='courseEvaluation'
            >
              {({ toggleModal }) => (
                <ButtonStyled onClick={() => toggleModal()}>
                  Dê sua opinião
                </ButtonStyled>
              )}
            </WithModal>
          </div>
        </Container>
      )}
    </Course>
  )
}

CourseRating.propTypes = {
  id: PropTypes.number.isRequired
}
