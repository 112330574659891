import {
  setDate,
  getDay,
  subDays,
  addDays,
  getDaysInMonth
} from 'date-fns'

export default function getDateRange (date) {
  const selectedDate = setDate(date, 1)
  const daysInMonth = getDaysInMonth(date)
  const weekday = getDay(selectedDate)
  const startDate = subDays(selectedDate, weekday)
  const days = Math.ceil((weekday + daysInMonth) / 7) * 7 - 1
  const endDate = addDays(startDate, days)

  return {
    startDate,
    endDate
  }
}
