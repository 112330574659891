import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'styles/Badge.css'

export default function Badge ({ children, dark }) {
  const className = classNames({
    Badge: true,
    '-dark': dark
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  dark: PropTypes.bool
}
