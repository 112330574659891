import React from 'react'
import PropTypes from 'prop-types'
import 'styles/ListVideos.css'
import VideosTitle from 'components/VideosTitle'
import VideoItem from 'components/VideoItem'
import StyledScrollbars from 'components/StyledScrollbars'

export default function ListVideos ({ videos, courseId, lessonId }) {
  return (
    <div className='ListVideos'>
      <div className='ListVideos-title'>
        <VideosTitle count={videos.length} />
      </div>
      <StyledScrollbars className='ListVideos-items'>
        {videos.map((video, index) => (
          <div key={index} className='ListVideos-items-video'>
            <VideoItem
              index={index}
              videoId={video.id}
              lessonId={lessonId}
              courseId={courseId}
              {...video}
            />
          </div>
        ))}
      </StyledScrollbars>
    </div>
  )
}

ListVideos.propTypes = {
  videos: PropTypes.array.isRequired,
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired
}
