import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Box from 'components/Box'
import Text from 'components/Text'
import FormInviteFriend from 'forms/FormInviteFriend'
import { inviteFriend, isSubmitting } from 'store/ducks/invite'
import 'styles/InviteFriend.css'

function InviteFriend ({ isSubmitting, onSubmit }) {
  return (
    <Box padded>
      <div className='InviteFriend'>
        <div className='InviteFriend-column'>
          <Text>
            O Indique um amigo é uma forma de você convidar amigos para fazer parte do melhor time de concurseiros.
          </Text>
          <Text>
            Ao indicar um amigo, ele receberá um email avisando que você o indicou. Quando seu amigo entrar para o time, você receberá outro aviso.
          </Text>
          <Text>
            Você pode indicar quantos amigos quiser e quanto mais melhor! Só fique atento ao limite de cinco indicações por dia. Indique já um amigo. Temos certeza que ele vai gostar.
          </Text>
        </div>
        <div className='InviteFriend-column'>
          <FormInviteFriend
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </Box>
  )
}

InviteFriend.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state)
})

const mapDispatchToProps = dispatch => ({
  onSubmit (data) {
    dispatch(inviteFriend(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteFriend)
