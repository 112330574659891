import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Wrapper from 'containers/Wrapper'
import Dashboard from 'containers/Dashboard'
import { clearResponseCache } from 'react-request'

export default function App () {

  var clearCache = null
  var expirationTime = 3600000

  if (!clearCache) {
    clearCache = setInterval(clearResponseCache, expirationTime)
  }

  return (
    <Wrapper>
      <Switch>
        <Route path='/dashboard' render={props => <Dashboard {...props} />} />
        <Route render={() => <Redirect to='/dashboard' />} />
      </Switch>
    </Wrapper>
  )
}
