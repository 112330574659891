import React from 'react'
import classNames from 'classnames'
import 'styles/FormGroup.css'

export default function FormGroup ({ children, right = false }) {
  const className = classNames({
    FormGroup: true,
    '-right': right
  })

  return (
    <div className={className}>
      {children}
    </div>
  )
}
