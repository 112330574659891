/**
 * Dependencies.
 */

import Tickets from 'api/Tickets'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('tickets', Tickets, 'Ingresso')
