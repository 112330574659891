import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  ${props => props.center && `
    margin-left: auto;
    margin-right: auto;
  `}
  ${props => props.right && `
    margin-left: auto;
    margin-right: 0;
  `}
`

export default function Image (props) {
  return <StyledImage {...props} />
}

Image.propTypes = {
  /** URL da imagem */
  src: PropTypes.string.isRequired,

  /** Alt da imagem */
  alt: PropTypes.string.isRequired,

  /** Deixa a imagem centralizada */
  center: PropTypes.bool,

  /** Deixa a imagem à direita */
  right: PropTypes.bool
}

Image.defaultProps = {
  center: false,
  right: false,
}
