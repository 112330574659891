import styled from 'styled-components'

export const Lista = styled.ul`
  float: left;
  width: 100%;
  padding: 0;
  margin: 20px 0 5px 0;
  min-height: ${props => props.produto === 'Concursos' ? '50px' : '0'};

  @media only screen and (max-width: 1450px) {
    min-height: 1px;
  }
`

export const Item = styled.li`
  list-style: none;
  float: left;
  width: 100%;

  + li {
    margin-top: 8px;
  }
`

export const Texto = styled.span`
  font-size: 18px;
  color: #0057EE;
`