import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'styles/TeachersListSlider.css'
import Avatar from 'components/Avatar'
import Tooltip from 'components/Tooltip'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  centerMode: false,
  infinite: false
}

export default class TeachersListSlider extends Component {

  renderTeachers () {
    return this.props.teachers.map((teacher, index) => (
      <Tooltip className="tooltipSlider" placement='bottom' overlay={teacher.nome} key={index}>
        <a href={teacher.url}>
          <Avatar name={teacher.nome} src={teacher.imagem} width={60} height={60} />
        </a>
      </Tooltip>
    ))
  }

  render () {
    return (
      <div className={`TeachersListSlider`}>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {this.renderTeachers()}
        </Slider>
      </div>
    )
  }

  static propTypes = {
    teachers: PropTypes.array.isRequired
  }
}
