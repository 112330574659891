import React from 'react'
import { Header, BoxFechar, BoxConteudo, BoxTexto, PreTitulo, Titulo, Tag, Texto } from './Styled'
import imgFechar from 'images/ic-fechar-promocao-ecj.svg'
import imgCabecalho from 'images/imagem-renovacao-antecipada.jpg'
import routes from 'config/routes'
import { NavLink } from 'react-router-dom'
import { toMoney } from 'utils/helpers'

export default function Cabecalho ({ dados }) {

  let texto = dados.cashback !== "" && dados.cashback !== null ? 'Thiago, você tem ' + toMoney(dados.cashback) + ' de cashback disponível para usar na Assinatura Vitalícia! <br> Use agora antes que expire!' : "Thiago, você não tem cashback disponível, mas ainda pode garantir a Vitalícia com uma condição imperdível!<br> Aproveite antes que seja tarde!"

  let textos = {
    'preTitulo': 'Nunca mais gaste um centavo sequer para ser aprovado',
    'titulo': 'Assinatura Vitalícia',
    'tag': 'ela voltou, mas não por muito tempo.',
    'texto': texto
  };

  return (
    <Header>

      <BoxFechar>
        <NavLink to={routes.cursos}>
          <img src={imgFechar} alt="Fechar" />
        </NavLink>
      </BoxFechar>

      <BoxConteudo>

        {/* Texto */}
        <BoxTexto>
          <PreTitulo dangerouslySetInnerHTML={{ __html: textos.preTitulo }} />
          <Titulo dangerouslySetInnerHTML={{ __html: textos.titulo }} />
          <Tag dangerouslySetInnerHTML={{ __html: textos.tag }} />
          <Texto dangerouslySetInnerHTML={{ __html: textos.texto }} />
        </BoxTexto>

        <img src={imgCabecalho} alt="Cabecalho" />

      </BoxConteudo>

    </Header>
  )
}
