import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { diff } from 'deep-object-diff'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import Modal from 'components/Modal'
import Notifications from 'containers/Notifications'
import FormUserProfile from 'forms/FormUserProfile'
import FormUserEmail from 'forms/FormUserEmail'
import { UPDATE_PASSWORD_URL } from 'config/constants'
import {
  fetchProfile,
  updateProfile,
  updateEmail,
  updatePassword,
  isFetching,
  isSubmitting,
  getUserProfile,
  getError
} from 'store/ducks/user'

class UserProfile extends Component {
  state = {
    isModalFormUserEmailOpen: false
  }

  componentDidMount () {
    if (!this.props.userProfile) {
      this.props.fetchUserProfile()
    }
  }

  toggleFormUserEmailModal = () => {
    this.setState({
      isModalFormUserEmailOpen: !this.state.isModalFormUserEmailOpen
    })
  }

  linkUpdatePassword = () => {
    window.open(UPDATE_PASSWORD_URL);
  }

  render () {
    if (this.props.isFetching) {
      return (
        <Section title='Informações pessoais'>
          <Spinner text='Carregando dados do usuário' />
        </Section>
      )
    }

    return (
      <Section title='Informações pessoais'>
        <FormUserProfile
          initialValues={this.props.userProfile}
          onSubmit={values => {
            const data = diff(this.props.userProfile, values)
            this.props.onUserProfileSubmit(data)
          }}
          toggleFormUserEmailModal={this.toggleFormUserEmailModal}
          linkUpdatePassword={this.linkUpdatePassword}
          isSubmitting={this.props.isSubmitting}
        />
        <Modal
          isOpen={this.state.isModalFormUserEmailOpen}
          onRequestClose={this.toggleFormUserEmailModal}
        >
          <Notifications notificationsId='formUserEmail' />
          <FormUserEmail
            onSubmit={values => {
              this.props.onUserEmailSubmit(values, 'formUserEmail')
            }}
            isSubmitting={this.props.isSubmitting}
          />
        </Modal>
      </Section>
    )
  }

  static propTypes = {
    /** Action para carregar os dados do usuário */
    fetchUserProfile: PropTypes.func.isRequired,

    /** Action para atualizar os dados do usuário */
    onUserProfileSubmit: PropTypes.func.isRequired,

    /** Action para atualizar o e-mail do usuário */
    onUserEmailSubmit: PropTypes.func.isRequired,

    /** Action para atualizar a senha do usuário */
    onUserPasswordSubmit: PropTypes.func.isRequired,

    /** Dados do usuário estão sendo carregados */
    isFetching: PropTypes.bool.isRequired,

    /** Dados estãos sendo submetidos à API para serem atualizados */
    isSubmitting: PropTypes.bool.isRequired,

    /** Dados do usuário */
    userProfile: PropTypes.object,

    /** Possível erro oriundo da atualização do usuário */
    error: PropTypes.any
  }
}

const mapStateToProps = state => ({
  isFetching: isFetching(state),
  isSubmitting: isSubmitting(state),
  userProfile: getUserProfile(state),
  error: getError(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchUserProfile: fetchProfile,
    onUserProfileSubmit: updateProfile,
    onUserEmailSubmit: updateEmail,
    onUserPasswordSubmit: updatePassword
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile)
