import Client from 'modules/Client'
import { REQUEST_TOKEN_URL, API_URL} from 'config/constants'
import axios from 'axios'
import { setCookie, getCookie } from 'utils/helpers'

export default {

  getToken () {
    return Promise.resolve()
      .then(() => {
        if (getCookie('dados_sessao') != null && getCookie('dados_sessao') !== '') {
          let dadosSessao = JSON.parse(getCookie('dados_sessao'))
          return {
            token_type: dadosSessao.token_type,
            access_token: dadosSessao.access_token
          }
        } else {
          return Client.get(REQUEST_TOKEN_URL, { withCredentials: true })
        }
      })
      .then(data => {
        Client.token = data.access_token
        Client.setHeader('Session', data.session_id)
        Client.setHeader('Authorization', `${data.token_type} ${data.access_token}`)
        Client.setHeader('Personificado', String(data.personificado))
        Client.setBaseURL(API_URL)
      })
      .then(() => Client.get('api/aluno/perfil/detalhes'))
  },

  getTokenInterno (dados) {
    return axios
      .post(API_URL + '/api/oauth/token', dados)
        .then(response => {
          return response.data
        })
        .then(data => {
          setCookie('dados_sessao', JSON.stringify(data, data.expires_in))
          Client.token = data.access_token
          Client.setHeader('Session', '')
          Client.setHeader('Authorization', `${data.token_type} ${data.access_token}`)
          Client.setHeader('Personificado', false)
          Client.setBaseURL(API_URL)
        })
        .then(() => Client.get('api/aluno/perfil/detalhes'))
  }

}
