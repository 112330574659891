 import React from 'react';

class Wootric extends React.Component {
  componentDidMount() {
    let setupScript = document.createElement('script');
    setupScript.type = 'text/javascript';
    setupScript.id = 'wootric-settings';
    setupScript.async = true;

     /* 
      Comente as linhas wootric_survey_immediately = true e wootric_no_surveyed_cookie = true quando estiver pronto para produção. 
      Como alternativa, deixe a linha no código para fins de teste ou para pesquisar o cliente a cada visita a uma página específica. 
    */
    // setupScript.innerHTML = `wootric_no_surveyed_cookie = true; `;
    // setupScript.innerHTML += `wootric_survey_immediately = true; `;

    var created_at = parseInt(new Date(this.props.user_created_at).getTime() / 1000);
    setupScript.innerHTML = `
      window.wootricSettings = {
        external_id: '${this.props.id}',
        email: '${this.props.email}',
        created_at: '`+created_at+`',
        account_token: 'NPS-c4b5f369',
        product_name: '${this.props.title}',
        properties: {
          segment: 'Concursos (Legado)',
          horizontais: '${this.props.item}'
        }
      };
    `;

    if (document.getElementById('wootric-settings') == null) {
      document.body.appendChild(setupScript);
    }

    // Beacon
    let beacon = document.createElement('script');
    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.async = true;

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
    beacon.onload = function() {
      window.wootric('run');
    };
    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon)
    };
  }

  render() {
    return (
      <div/>
    );
  }
}

export default Wootric;
