import React from 'react'
import Screen from 'containers/Screen'
import PromocaoBox from 'components/PromocaoAssinatura/PromocaoAssinatura'

export default function PromocaoAssinaturaScreen () {
  return (
    <Screen title={''}>
      <PromocaoBox />
    </Screen>
  )
}
