import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AgendaHeader from 'components/AgendaHeader'
import Calendar from 'components/Calendar/Calendar'
import * as calendarDuck from 'store/ducks/calendar'

class Agenda extends Component {
  componentDidMount () {
    this.props.selectDate(new Date())
  }

  render () {
    return (
      <div>
        <AgendaHeader
          selectedDate={this.props.selectedDate}
          isFetching={this.props.isFetching}
          onDateChange={this.props.selectDate}
          concourses={this.props.concoursesWithinSelectedRange}
          selectedConcourse={this.props.selectedConcourseForFilter}
          onConcourseChange={this.props.setConcourseForFilter}
        />
        <Calendar
          selectedDate={this.props.selectedDate}
          days={this.props.days}
          concourses={this.props.concourses}
          lessons={this.props.lessons}
          isLoading={this.props.isFetching}
          getConcoursesByIds={this.props.getConcoursesByIds}
          getLessonsByIds={this.props.getLessonsByIds}
          selectedConcourse={this.props.selectedConcourseForFilter}
        />
      </div>
    )
  }

  static propTypes = {
    // State
    isFetching: PropTypes.bool.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    days: PropTypes.object.isRequired,
    concourses: PropTypes.object.isRequired,
    lessons: PropTypes.object.isRequired,
    concoursesWithinSelectedRange: PropTypes.array.isRequired,
    selectedConcourseForFilter: PropTypes.number.isRequired,
    getConcoursesByIds: PropTypes.func.isRequired,
    getLessonsByIds: PropTypes.func.isRequired,

    // Dispatch
    selectDate: PropTypes.func.isRequired,
    setConcourseForFilter: PropTypes.func.isRequired
  }
}

const mapStateToProps = state => ({
  isFetching: calendarDuck.isFetching(state),
  selectedDate: calendarDuck.getSelectedDate(state),
  days: calendarDuck.getDays(state),
  concourses: calendarDuck.getConcourses(state),
  lessons: calendarDuck.getLessons(state),
  concoursesWithinSelectedRange: calendarDuck.getConcoursesWithinSelectedRange(state),
  selectedConcourseForFilter: calendarDuck.getSelectedConcourseForFilter(state),
  getConcoursesByIds: calendarDuck.getConcoursesByIds(state),
  getLessonsByIds: calendarDuck.getLessonsByIds(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    selectDate: calendarDuck.selectDate,
    setConcourseForFilter: calendarDuck.setConcourseForFilter
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agenda)
