import React from 'react'
import PropTypes from 'prop-types'
import 'styles/Spinner.css'
import ImgSpinner from 'images/spinner.gif'

export default function Spinner ({ text }) {
  return (
    <div className='Spinner'>
      <img src={ImgSpinner} className='Spinner-image' alt={text || 'Spinner'} />
      {text && <span className='Spinner-text'>{text}</span>}
    </div>
  )
}

Spinner.propTypes = {
  text: PropTypes.string
}
