import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import Box from 'components/Box'
import { ControlledCollapse, CollapseHeader, CollapseBody } from 'components/Collapse'
import LessonCollapseHeader from 'components/LessonCollapseHeader'
import Lesson from 'containers/Lesson'
import Checkbox from 'components/Checkbox'
import { updateLessonView, isLessonViewUpdating, isLessonChecked } from 'store/ducks/conteudo/aulas'
import { format } from 'modules/Date'
import { parse } from 'date-fns'
import routes from 'config/routes'
import getRoute from 'utils/getRoute'
import theme from 'theme'
import styled from 'styled-components'

function LessonCollapse({ lesson, courseId, isActive, dispatch, isLessonViewUpdating, isLessonChecked }) {
  const publishDate = parse(lesson.data_publicacao)
  const isAvailable = lesson.is_disponivel
  const link = lesson.videos.length > 0
    ? getRoute(routes.video, { courseId, lessonId: lesson.id, videoId: lesson.videos[0] })
    : getRoute(routes.aula, { courseId, lessonId: lesson.id })
  const to = isActive
    ? getRoute(routes.curso, { courseId })
    : link
  const isDisabled = !isAvailable

  return (
    <section>
      <Box disabled={isDisabled}>
        <ControlledCollapse
          disabled={isDisabled}
          unmountedClosed
          isOpened={isActive}
        >
          <CollapseHeader
            component={Link}
            to={isDisabled ? '#' : to}
            disabled={isDisabled}
            SideContent={() => {
              if (!isAvailable) {
                return (
                  <span>Disponível em {format(publishDate)}</span>
                )
              }

              return (
                <Fragment>
                  <Checkbox
                    disabled={isLessonViewUpdating}
                    label={isLessonChecked ? 'Estudei' : 'Não estudei'}
                    checked={isLessonChecked}
                    onChange={() => dispatch(updateLessonView(lesson.id, !isLessonChecked))}
                    color={theme.colors.success}
                    textColor={isLessonChecked ? theme.colors.success : theme.colors.text}
                    inversed
                    small
                  />
                  {lesson.baixado && <Baixado>baixado</Baixado>}
                </Fragment>
              )
            }}
          >
            {({ isOpened }) => (
              <LessonCollapseHeader
                isOpened={isOpened}
                lesson={lesson}
                isAvailable={isAvailable}
                subject={lesson.conteudo}
              />
            )}
          </CollapseHeader>
          <CollapseBody>
            <Lesson isActive={isActive} lessonId={lesson.id} courseId={courseId} />
          </CollapseBody>
        </ControlledCollapse>
      </Box>
    </section>
  )
}

LessonCollapse.propTypes = {
  lesson: PropTypes.object.isRequired,
  courseId: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired
}

const Baixado = styled.div`
  background: #FF6A58;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #fff;
  margin-left: 12px;
  width: 61px;
  margin-top: 3px;
`

const mapStateToProps = (state, { lesson }) => ({
  isLessonViewUpdating: isLessonViewUpdating(state, lesson.id),
  isLessonChecked: isLessonChecked(state, lesson.id)
})

export default withRouter(connect(mapStateToProps)(LessonCollapse))
