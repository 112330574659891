import React from 'react'
import { Header, TextoHeader } from './Styled'
import { firstName } from 'utils/helpers'
import { toMoney } from 'utils/helpers'

export default function Cabecalho ({ desconto, nameUser }) {

	const getMessage = () => {
		return desconto > 0 
		? `<strong>${firstName(nameUser)}</strong>, você tem <strong>${toMoney(desconto)}</strong> de cashback disponível para usar na<br> <strong>Assinatura Vitalícia</strong>!`
		: `${firstName(nameUser)}, você não tem cashback disponível, mas ainda pode<br> garantir a <strong>Vitalícia com uma condição imperdível</strong>!`
	}

	return (
		<Header>
			<TextoHeader dangerouslySetInnerHTML={{__html: getMessage()}} />
		</Header>
	)

}
