import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import Spinner from 'components/Spinner'
import SalesForceChat from 'components/SalesForceChat'
import Notifications from 'containers/Notifications'
import Opportunities from 'containers/Opportunities'
import mediaQuery from 'utils/mediaQuery'
import { getCookie, deleteCookie, clearAllListCache, firstName, lastName } from 'utils/helpers'
import routes from 'config/routes'
import * as Screens from 'screens'
import { login, isAuthenticated, isProcessing } from 'store/ducks/auth'
import UserDetails from 'containers/UserDetails'
import { clearResponseCache } from 'react-request'
import { Helmet } from "react-helmet"
import Wootric from 'components/Wootric'

// Componentes
import CursosPorTipoScreen from 'screens/CursosPorTipoScreen'
import AssinaturasScreen from 'screens/AssinaturasScreen'
import CursosScreen from 'screens/CursosScreen'

class Dashboard extends Component {
  state = {
    isSidebarOpen: false
  }

  componentWillMount() {
    if (!this.props.isAuthenticated) {
      this.props.requestLogin(this.props.location.pathname)
    }
  }

  componentDidUpdate(prevProps) {
    const prevPathname = prevProps.location.pathname
    const currentPathname = this.props.location.pathname
    const { isSidebarOpen } = this.state
    if (isSidebarOpen && prevPathname !== currentPathname) {
      this.setState({ isSidebarOpen: false })
    }
  }

  render() {

    if (this.props.isProcessing) {
      if (getCookie('loggedUser').length > 0) {
        deleteCookie('loggedUser');
        //Limpa o cache para personificação
        clearResponseCache()
        clearAllListCache()
      }
      return <Spinner text='Autenticando...' />
    }

    // Exibe a página de promoções para usuários não logados!
    // if (!this.props.isAuthenticated && this.props.location.pathname === routes.promocao) {
    //   return (
    //     <div>
    //       <Header
    //         displayMenu={false}
    //         onMenuButtonClick={() => {
    //           this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
    //         }}
    //       />
    //       <div style={{ maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto', paddingTop: 71 }}>
    //         <Route component={Screens.PromocaoScreen} />
    //       </div>
    //     </div>
    //   )
    // }

    // Verifica  se não está logado e a rota é login
    if (!this.props.isAuthenticated && this.props.location.pathname === routes.login) {
      return (
        <Route path={routes.login} component={Screens.Login} />
      )
    }

    // Se logado e estiver na página de login
    if (this.props.isAuthenticated && this.props.location.pathname === routes.login) {
      return (
        <Redirect to={routes.cursos} />
      )
    }

    // Se não estiver logado, mostra mensagem que deve estar logado
    if (!this.props.isAuthenticated) {
      return <h1 style={{ textAlign: 'center' }}>Você precisa estar logado!</h1>
    }

    if (this.props.isAuthenticated) {
      return (
        <div>
          <UserDetails>
            {details => (
              <div>
                <Helmet>
                  <script>
                    {`
                    var beamer_config = {
                      'product_id': 'QUXJzwyL29024',
                      'user_firstname': '${firstName(details.name)}',
                      'user_lastname': '${lastName(details.name)}',
                      'user_email': '${details.email}',
                      'user_id': ${details.id},
                      'user_created_at': '${details.dataCriacao}'
                    }
                  `}
                  </script>
                </Helmet>
                <SalesForceChat>
                  {salesForce => (
                    <div>
                      <Header
                        salesForce={salesForce}
                        onMenuButtonClick={() => {
                          this.setState({ isSidebarOpen: !this.state.isSidebarOpen })
                        }}
                      />
                      <Sidebar
                        salesForce={salesForce}
                        isSidebarOpen={this.state.isSidebarOpen}
                      />
                    </div>
                  )}
                </SalesForceChat>
                <Content>
                  <Notifications scrollToTop />
                  <Opportunities />
                  <Switch>
                    <Route exact path={routes.cursos} component={() =>
                      <div>
                        <CursosScreen />
                        <Wootric title="encontrar os cursos de seu interesse" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Cursos'/>
                      </div>
                    } />
                    <Route exact path={routes.video} component={Screens.CursoScreen} />
                    <Route exact path={routes.aula} component={Screens.CursoScreen} />
                    <Route exact path={routes.curso} component={Screens.CursoScreen} />
                    <Route exact path={routes.forum} component={Screens.ForumScreen} />
                    {/* <Route exact path={routes.promocao} component={Screens.PromocaoScreen} /> */}
                    <Route exact path={routes.promocaoAssinatura} component={Screens.PromocaoAssinaturaScreen} />
                    <Route exact path={routes.renovacao} component={Screens.RenovacaoAntecipadaScreen} />
                    <Route exact path={routes.assinaturaVitalicia} component={Screens.AssinaturaVitaliciaScreen} />
                    {/* <Route exact path={routes.renovacaoBlackFriday} component={Screens.RenovacaoBlackFridayScreen} /> */}
                    <Route exact path={routes.renovacaoAssinatura} component={Screens.RenovacaoAssinaturaScreen} />
                    <Route exact path={routes.assinaturas} component={() =>
                      <div>
                        <AssinaturasScreen />
                        <Wootric title="se matricular em novos produtos" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Catálogo de cursos'/>
                      </div>
                    } />
                    <Route exact path={routes.monitorias} component={() =>
                      <div>
                        <CursosPorTipoScreen
                          titulo={'Monitorias'}
                          tipo={'MONITORIA'}
                          agenda={true} />
                        <Wootric title="participar de uma monitoria de seu interesse" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Monitorias' />
                      </div>
                    } />
                    <Route exact path={routes.trilhas} component={() =>
                      <div>
                        <CursosPorTipoScreen
                          titulo={'Trilhas Estratégicas'}
                          tipo={'trilha_estrategica'}
                          agenda={false} />
                        <Wootric title="encontrar a trilha de seu interesse" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Trilhas Estratégicas' />
                      </div>
                    } />
                    <Route exact path={routes.comunidades} component={Screens.ComunidadesScreen} />
                    <Route exact path={routes.comunidadesEcj} component={Screens.ComunidadesEcjScreen} />
                    <Route exact path={routes.simulados} component={() =>
                      <div>
                        <CursosPorTipoScreen
                          titulo={'Simulados'}
                          tipo={'SIMULADO'}
                          agenda={true} />
                        <Wootric title="encontrar um simulado de seu interesse" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Simulados' />
                      </div>
                    } />
                    <Route exact path={routes.cursosExclusivos} component={() => <CursosPorTipoScreen
                      titulo={'Cursos Exclusivos'}
                      tipo={'EXCLUSIVO'}
                      agenda={true} />}
                    />
                    <Route path={routes.meusDados} component={Screens.MeusDadosScreen} />
                    <Route path={routes.meusDownloads} component={Screens.DownloadsScreen} />
                    <Route path={routes.metodosDePagamento} component={Screens.MetodosDePagamentoScreen} />
                    <Route path={routes.agenda} component={Screens.AgendaScreen} />
                    <Route path={routes.historicoDeCompras} component={Screens.HistoricoScreen} />
                    <Route path={routes.certificados} component={Screens.CertificadosScreen} />
                    <Route path={routes.ingressos} component={Screens.IngressosScreen} />
                    <Route path={routes.meusChamados} component={Screens.MeusChamadosScreen} />
                    <Route path={routes.mensagens} component={Screens.MensagensScreen} />
                    <Route path={routes.indicarAmigo} component={Screens.IndicarAmigoScreen} />
                    <Route path={routes.suporte} component={Screens.SuporteScreen} />
                    <Route path={routes.alertas} component={Screens.AlertasScreen} />
                    <Route path={routes.salaVip} component={Screens.SalaVipScreen} />
                    <Route path={routes.estudeEmGrupo} component={() =>
                      <div>
                        <CursosPorTipoScreen
                          titulo={'Sala Vip'}
                          tipo={'sala_vip'}
                          agenda={true} />
                        <Wootric title="participar de um evento da Sala VIP" email={details.email} id={details.id} user_created_at={details.dataCriacao} item='Sala VIP' />
                      </div>
                    } />
                    <Route path={routes.logout} component={Screens.LogoutScreen} />
                    <Route path={routes.coaching} component={Screens.CoachingScreen} />
                    <Route path={routes.estrategiaCast} component={Screens.EstrategiaCastScreen} />
                    <Route path={routes.indiqueAmigo} component={Screens.IndiqueAmigoScreen} />
                    <Route path={routes.cursosExclusivosECJ} component={() => <CursosPorTipoScreen
                      titulo={'Cursos Exclusivos ECJ'}
                      tipo={'ecj_exclusivo'}
                      agenda={true} />}
                    />
                    <Route path={routes.cursosExtensao} component={() => <CursosPorTipoScreen
                      titulo={'Cursos de Extensão'}
                      tipo={'ecj_extensao'}
                      agenda={true} />}
                    />
                    <Route path={routes.estudoEstrategico} component={() => <CursosPorTipoScreen
                      titulo={'Estudo Estratégico'}
                      tipo={'ecj_estrategico'}
                      agenda={false} />}
                    />
                    <Route path={routes.cursosOrais} component={() => <CursosPorTipoScreen
                      titulo={'Cursos Orais'}
                      tipo={'ecj_orais'}
                      agenda={false} />}
                    />

                    <Route exact path={routes.trilhasEcj} component={() => <CursosPorTipoScreen
                      titulo={'Trilhas Estratégicas'}
                      tipo={'ECJ_TRILHA_ESTRATEGICA'}
                      agenda={false} />}
                    />

                    <Route path={routes.simuladosECJ} component={() => <CursosPorTipoScreen
                      titulo={'Simulados (ECJ)'}
                      tipo={'ecj_simulado'}
                      agenda={true} />}
                    />
                    <Route path={routes.rodadas} component={() => <CursosPorTipoScreen
                      titulo={'Rodadas'}
                      tipo={'ecj_rodadas'}
                      agenda={false} />}
                    />
                    <Route path={routes.retaFinalEcj} component={() => <CursosPorTipoScreen
                      titulo={'Reta Final'}
                      tipo={'ecj_reta_final'}
                      agenda={false} />}
                    />
                    <Route path={routes.informativosEstrategicos} component={() => <CursosPorTipoScreen
                      titulo={'Informativos estratégicos'}
                      tipo={'ecj_info'}
                      agenda={false} />}
                    />
                    <Route path={routes.vadeMecum} component={() => <CursosPorTipoScreen
                      titulo={'Vade-Mécum Estratégico'}
                      tipo={'ecj_vmecum'}
                      agenda={false} />}
                    />

                    {/* Rotas OAB */}

                    <Route path={routes.temasEspeciaisOab} component={() => <CursosPorTipoScreen
                      titulo={'Temas Especiais'}
                      tipo={'OAB_TEMAS_ESPECIAIS'}
                      agenda={true} />}
                    />
                    <Route path={routes.livraoOab} component={() => <CursosPorTipoScreen
                      titulo={'Livrão'}
                      tipo={'OAB_LIVRAO'}
                      agenda={false} />}
                    />
                    <Route path={routes.simuladosOab} component={() => <CursosPorTipoScreen
                      titulo={'Simulados'}
                      tipo={'OAB_SIMULADOS'}
                      agenda={true} />}
                    />
                    <Route path={routes.retaFinalOab} component={() => <CursosPorTipoScreen
                      titulo={'Reta Final'}
                      tipo={'OAB_RETA_FINAL'}
                      agenda={true} />}
                    />
                    <Route path={routes.monitoriasOab} component={() => <CursosPorTipoScreen
                      titulo={'Monitorias'}
                      tipo={'OAB_MONITORIAS'}
                      agenda={true} />}
                    />
                    <Route path={routes.salaVipOab} component={() => <CursosPorTipoScreen
                      titulo={'Salas VIP'}
                      tipo={'OAB_SALAS_VIP'}
                      agenda={true} />}
                    />
                    <Route path={routes.vmDigitalOab} component={() => <CursosPorTipoScreen
                      titulo={'Vade-Mécum Digital'}
                      tipo={'OAB_VM_DIGITAL'}
                      agenda={false} />}
                    />
                    <Route path={routes.trilhasOab} component={() => <CursosPorTipoScreen
                      titulo={'Trilha Estratégica'}
                      tipo={'OAB_TRILHA_ESTRATEGICA'}
                      agenda={false} />}
                    />

                    <Route path={routes.blogEcj} component={Screens.BlogEcjScreen} />
                    <Route path={routes.pacote} component={Screens.PacoteScreen} />
                    <Route path={routes.ultimasAulas} component={Screens.EstrategiaUltimaAulasScreen} />

                    <Route exact path='/dashboard' component={() => (
                      <UserDetails>
                        {details => {

                          let initialRoute = routes.cursos

                          // if (details.renovar_assinatura_antecipada !== false && details.renovar_assinatura_antecipada !== null) {
                          //   initialRoute = routes.renovacao
                          // }

                          // if (details.renovar_assinatura !== false) {
                          //   initialRoute = routes.renovacaoAssinatura
                          // }

                          if (details.tem_vitalicia && details.renovar_assinatura_antecipada !== false && details.renovar_assinatura_antecipada !== null) {
                            initialRoute = routes.assinaturaVitalicia
                          }

                          return (
                            <Redirect
                              to={initialRoute}
                            />
                          )
                        }}
                      </UserDetails>
                    )} />
                    <Route>
                      {() => <Redirect
                        to={routes.cursos}
                      />}
                    </Route>
                  </Switch>
                </Content>
              </div>
            )}
          </UserDetails>
        </div>
      )
    }

  }

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    requestLogin: PropTypes.func.isRequired
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  isProcessing: isProcessing(state)
})

const mapDispatchToProps = dispatch => ({
  requestLogin(pathname) {
    dispatch(login(pathname))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)

const Content = styled.div`
  padding-top: 68px!important;
  ${mediaQuery.greaterThan('1250px')`padding-left: 300px;`}
`
