import React from 'react'
import { isEmpty } from 'ramda'
import FetchAuth from 'components/FetchAuth'
import Cursos from 'components/Cursos'
import SemCursos from 'components/SemCursos'
import Box from 'components/Box'
import Text from 'components/Text/Text'

export default function RequestCursosInativos ({ empty: Empty, ativar, ...props }) {
  return (
    <FetchAuth
      url={`api/aluno/curso?arquivado=true`}
      spinner
      cacheResponse={false}
      {...props}
    >
      {({ data }) => data && data.data && data.data.concursos.length > 0 ? (
        <Cursos ativar={ativar} data={transform(data.data)} />
      ) : (
        <Box style={{ textAlign: 'center', marginTop: 50, display: 'inline-block', width: '100%' }} padded>
          <div style={{ maxWidth: 600, margin: '0 auto' }}>
            <Text>Você não possui nenhum curso arquivado!</Text>
          </div>
        </Box>
      )}
    </FetchAuth>
  )
}

RequestCursosInativos.defaultProps = {
  empty: SemCursos
}

const transform = data => {
  if (isEmpty(data)) {
    return []
  }

  const cargos = data.cargos.reduce((memo, cargo) => {
    memo[cargo.id] = cargo
    return memo
  }, {
    '##': { id: '##', nome: 'Outros', descricao: '' }
  })

  return data.concursos.map(concurso => {
    const cargoCursos = { '##': [] }
    concurso.cursos.forEach(curso => {
      if (curso.id_cargos && curso.id_cargos.length > 0) {
        curso.id_cargos.forEach(id => {
          if (cargoCursos.hasOwnProperty(id)) {
            cargoCursos[id].push(curso)
          } else {
            cargoCursos[id] = [curso]
          }
        })
      } else {
        cargoCursos['##'].push(curso)
      }
    })

    return ({
      id: concurso.id,
      modalidadeActiveIndex: concurso.modalidadeActiveIndex,
      titulo: concurso.titulo,
      modalidades: concurso.modalidades,
      cargos: Object.keys(cargoCursos).map(id => ({
        ...cargos[id],
        cursos: cargoCursos[id]
      }))
    })
  })
}
