import React from 'react'
import PropTypes from 'prop-types'
import 'styles/TeachersList.css'
import { pluralize } from 'utils/helpers'
import TeachersListSlider from 'components/TeachersListSlider'

export default function TeachersList ({ teachers }) {
  const count = teachers.length
  return (
    <div className='TeachersList'>
      <div className='TeachersList-label'>
        <span>
          {pluralize(count, 'Professor', 'Professores')}
        </span>
        {' '}
        <span className='TeachersList-label-count'>
          ({count})
        </span>
      </div>
      <div className='TeachersList-slider'>
        <TeachersListSlider teachers={teachers} />
      </div>
    </div>
  )
}

TeachersList.propTypes = {
  teachers: PropTypes.array.isRequired
}
