import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import hoistNonReactStatics from 'hoist-non-react-statics'
import getDisplayName from 'react-display-name'
import actions from 'store/actions/resources'
import selectors from 'store/selectors/resources'

export default function withResourceActions ({ resource }) {
  const mapStateToProps = state => ({
    isSubmitting: selectors[resource].isSubmitting(state),
    isRemoving: selectors[resource].isRemoving(state),
    error: selectors[resource].getError(state)
  })

  const mapDispatchToProps = dispatch =>
    bindActionCreators(actions[resource].actionCreators, dispatch)

  return WrappedComponent => {
    function EnhancedComponent (props) {
      return (
        <WrappedComponent
          {...props}
        />
      )
    }

    EnhancedComponent.displayName = `withResource(${getDisplayName(WrappedComponent)})`

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(hoistNonReactStatics(EnhancedComponent, WrappedComponent))
  }
}
