import React, { useState, Fragment } from 'react'
import Screen from 'containers/Screen'
import Pacote from 'containers/Pacote'
import CoursePacote from 'components/CoursePacote'
import Box from 'components/Box'
import SectionTitle from 'components/SectionTitle'
import AvisoLDI from 'components/AvisoLDI'
import 'styles/Pacotes.css'

export default function PacoteScreen ({ match }) {
  const [busca, setBusca] = useState('')
  const id = parseInt(match.params.pacoteId, 10)

  const handleOnChange = event => {
    const { value } = event.target
    setBusca(value)
  }

  return (
    <Screen title='Pacote'>
      <Pacote id={id} showSpinner>
        {({ data, isSubmitting }) => (

          <Fragment>
            {data.ldi_url ? <AvisoLDI link={data} pacote={true} /> : null }
            <Box padded>
              <SectionTitle>{data.nome}</SectionTitle>
              <input type={'text'} placeholder={'Pesquisar por curso...'} className={'campoBusca'} value={busca} onChange={handleOnChange} />
            </Box>
            <CoursePacote
              showSpinner
              data={data}
              isSubmitting={isSubmitting}
              filtro={busca}
            />
          </Fragment>

        )}
      </Pacote>
    </Screen>
  )
}
