import React from 'react'
import Section from 'components/Section'
import FormUserNotifications from 'forms/FormUserNotifications'
import UserNotifications from 'containers/UserNotifications'

export default function SectionUserNotifications () {
  return (
    <Section title='Notificações por e-mail'>
      <UserNotifications>
        {({ records, isSubmitting, update }) => (
          <FormUserNotifications
            notifications={records}
            isSubmitting={isSubmitting}
            onSubmit={data => update(data)}
          />
        )}
      </UserNotifications>
    </Section>
  )
}
