import React from 'react'
import Screen from 'containers/Screen'
import { API_URL } from 'config/constants'
import FetchAuth from 'components/FetchAuth'
import ChamadoCollapse from 'components/ChamadoCollapse'
// import ZendeskChat from 'containers/ZendeskChat'

export default function MeusChamadosScreen() {
  return (
    <Screen title='Meus Chamados' setHeadTitle>
      <FetchAuth
        url={`api/aluno/zendesk/tickets`}
        baseURL={API_URL}
        spinner
      >
        {({ data }) => data && data.records &&  data.records.length > 0 ? (
          <div>
          {data.records.sort((a ,b) => a.id < b.id ? 1 : -1).map(ticket => 
            (
              <ChamadoCollapse
                description={ticket.description}
                activeOrderId={ticket.id}
                date={ticket.created_at}
                status={ticket.status}
                title={ticket.subject}
                orderId={ticket.id}
                key={ticket.id}
              />
            )
          )} 
{/*
          <ZendeskChat record={data} />
*/}
          </div>
          ) : (
          <p>
            Nenhum chamadoo disponivel.
          </p>
        )}
      </FetchAuth>
    </Screen>
  )
}
