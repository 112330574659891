import React from 'react'
import 'styles/List.css'

export default function List ({items = [], ...props}) {
  return (
    <ul class="List">
        {items.map((item, key) => {
            return (<li key={key}>{item}</li>)
        })}
    </ul>)
}
