import React, { Component } from 'react'
import { connect } from 'react-redux'
import objectPath from 'object-path'
import Subscriptions from 'components/Subscriptions'
import Spinner from 'components/Spinner'
import Text from 'components/Text'
import MatriculaModal from 'components/MatriculaModal'
import DesmatriculaModal from 'components/DesmatriculaModal'
import MatriculasDisponiveis from 'components/MatriculasDisponiveis'
import Client from 'modules/Client'
import { pushNotification } from 'store/ducks/notifications'
import { clearAllListCache } from 'utils/helpers'
import { clearResponseCache } from 'react-request'

const TEMPO_EXPIRAR = 1000 * 60 * 5

class SubscriptionsManager extends Component {
  state = {
    loading: true,
    loadingFilter: true,
    error: false,
    disabled: false,
    courses: [],
    subscribedCourses: [],
    pagination: {},
    query: '',
    limit: 0,
    assinaturasItems: [],
    assinaturas: 0,
    selectedUnsubscribeCourse: {},
    selectedCourse: {},
    page: 0
  }

  componentDidMount () {
    this.loadData()
  }

  async loadData () {
    try {
      
      let cursos = {data: [], meta: {}}
      let assinaturas = await Promise.resolve(Client.get('/api/aluno/assinatura'));
      let matriculas = await Promise.resolve(Client.get('/api/aluno/assinaturaInscricao'));
      
      setTimeout(() => { clearAllListCache(); }, TEMPO_EXPIRAR);

      this.setState({
        loading: false,
        loadingFilter: false,
        assinaturas: assinaturas.data.length,
        assinaturasItems: assinaturas.data,
        limit: assinaturas.data.reduce((memo, value) => memo + value.limite_inscricao, 0),
        subscribedCourses: matriculas.data,
        courses: {data: [], pagination: {}},
        packages: {data: [], pagination: {}},
        teatchers: {data: [], pagination: {}},
        subjects: {data: [], pagination: {}},
        pagination: cursos.meta,
        page: 0
      })
      this.queryCourses('pacote', 'pacote');
    } catch (err) {
      this.setState({ loading: false, error: true })
      this.setState({ loadingFilter: false, error: true })
    }
  }

  pushError (message) {
    this.props.push(message, 'error')
  }

  handleSubscribe = course => {
    this.setState({ selectedCourse: course });
    clearResponseCache();
  }

  handleFormSubmission = selectedCourse => {
    this.setState({ selectedCourse: {} }, () => {
      this.subscribeCourse(selectedCourse)
    })
  }

  subscribeCourse = course => {
    if (this.state.subscribedCourses.length >= this.state.totalLimit) {
      return window.alert('Você já atingiu o limite de cursos matriculados. Desmatricule-se em outro curso para poder se matricular neste.')
    }
    this.updateCourseSubscription(course, true)
  }

  handleUnsubscribe = course => {
    this.setState({ selectedUnsubscribeCourse: course });
    clearResponseCache();
  }

  handleUnsubscribeFormSubmission = _ => {
    const { selectedUnsubscribeCourse } = this.state
    this.updateCourseSubscription(selectedUnsubscribeCourse, false)
    this.setState({ selectedUnsubscribeCourse: {} })
  }

  updateCourseSubscription = async (course, newSubscriptionStatus) => {
    try {
      this.setState({ disabled: true })

      const response = newSubscriptionStatus ? await Client.post(`/api/aluno/assinaturaInscricao?produto_id=${course.id}`, {
        data: {
          inscrito: newSubscriptionStatus
        }
      }) : await Client.delete(`/api/aluno/assinaturaInscricao/${course.id}`)

      // const newCourses = {...this.state.courses, data: ((this.state.courses || {}).data || []).map(c => {
      //   if (c.id === course.id) {
      //     return { 
      //       'id': String(response.data.produto.id),
      //       'nome': response.data.produto.nome
      //     }
      //   }
      //   return c
      // })}

      this.setState({
        disabled: false,
        subscribedCourses: newSubscriptionStatus
          ? this.state.subscribedCourses.concat(response.data)
          : this.state.subscribedCourses.filter(c => c.id !== course.id),
        // courses: newCourses
      })

    } catch (err) {
      const message = objectPath.get(
        err,
        'response.data.message',
        'Um erro aconteceu'
      )
      this.setState({ disabled: false })
      this.pushError(message)
    }
  }

  queryCourses = (query, type, page) => {
    this.setState({ query, type, page }, () => {
      this.fetchCourses()
    })
  }

  queryCoursesPage = (page) => {
    this.setState({ page }, () => {
      this.fetchCourses()
    })
  }

  fetchCourses = async params => {

    this.setState({ loadingFilter: true })

    try {
      let q = this.state.query;
      let t = this.state.type;
      let page = this.state.page || 1;
      let boost = 'curso';
      if(t === 'teatchers' || t === 'subjects') {
        boost = t;
        t = 'curso';
      }
      
      let subs = this.state.assinaturasItems.filter(function (ojb) { return ojb.limite_inscricao > 0}).map(a => a.id).join(',')
      let filteredData = await Promise.resolve(Client.get(`/api/assinatura/curso/search?size=51&subscriptions=${subs}&q=${q}&type=${t}&boost=${boost}&page=${page}`));

      filteredData = { pagination: filteredData.meta, data: filteredData.data, meta: {} };

      if(this.state.type === 'curso')
      {
        this.setState({
          courses: filteredData,
        })
      }

      if(this.state.type === 'pacote')
      {
        this.setState({
          packages: filteredData,
        })
      }

      if(this.state.type === 'teatchers')
      {
        this.setState({
          teatchers: filteredData,
        })
      }

      if(this.state.type === 'subjects')
      {
        this.setState({
          subjects: filteredData,
        })
      }

      this.setState({ loadingFilter: false })

    } catch (err) {
      this.pushError('Ocorreu um erro')
      this.setState({ disabled: false })
    }
  }

  render () {
    if (this.state.error) {
      return <Text>Ops! Ocorreu um erro!</Text>
    }

    if (this.state.loading) {
      return <Spinner />
    }

    if (this.state.assinaturas === 0) {
      return <MatriculasDisponiveis />
    }

    return (
      <div>
        <Subscriptions
          subscribedCourses={this.state.subscribedCourses}
          courses={this.state.courses}
          packages={this.state.packages}
          teatchers={this.state.teatchers}
          subjects={this.state.subjects}
          pagination={this.state.pagination}
          onSubscribe={this.handleSubscribe}
          onUnsubscribe={this.handleUnsubscribe}
          fetchCourses={this.fetchCourses}
          queryCourses={this.queryCourses}
          queryCoursesPage={this.queryCoursesPage}
          limit={this.state.limit}
          disabled={this.state.disabled}
          assinaturasItems={this.state.assinaturasItems} 
          loadingFilter={this.state.loadingFilter}
        />
        <MatriculaModal
          produtoSelecionado={this.state.selectedCourse}
          inscricoes={this.state.subscribedCourses.length}
          limite={this.state.limit}
          onSubmit={this.handleFormSubmission}
          onCloseModal={() => this.setState({ selectedCourse: {} })}
        />
        <DesmatriculaModal
          produtoSelecionado={this.state.selectedUnsubscribeCourse}
          inscricoes={this.state.subscribedCourses.length}
          limite={this.state.limit}
          onSubmit={this.handleUnsubscribeFormSubmission}
          onCloseModal={() => this.setState({ selectedUnsubscribeCourse: {} })}
        />
      </div>
    )
  }
}

export default connect(
  null,
  { push: pushNotification }
)(SubscriptionsManager)
