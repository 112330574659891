import React from 'react'
import 'styles/Player/PlayerControlOptions.css'
import classNames from 'classnames'

export function PlayerControlOptions ({ children }) {
  return (
    <div className='PlayerControlOptions'>
      {children}
    </div>
  )
}

export function Button ({ children, isActive, onClick, renderWith = 'button', ...props }) {
  const Component = renderWith
  const className = classNames({
    'PlayerControlOptions-button': true,
    isActive
  })

  return (
    <Component
      className={className}
      onClick={onClick}
      {...props}
    >
      {children}
    </Component>
  )
}
