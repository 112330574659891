import React from 'react'
import IconSeta from 'images/icons/ic-seta-renovacao-ecj.svg'
import {
  Box,
  Imagem
} from './Styled'

export default function Seta () {
  return (
    <Box>
      <Imagem src={IconSeta} alt='Seta' />
    </Box>
  )
}
