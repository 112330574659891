import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'styles/CourseDiscursive.css'
import Button from 'components/Button'
import FormGroup from 'components/FormGroup'
import Modal from 'components/Modal'
import FormCourseDiscursive from 'forms/FormCourseDiscursive'
import { format, formatHour } from 'modules/Date'
import Tooltip from 'components/Tooltip'
import IcDownload from 'images/ic-download.svg'
import IcEdit from 'images/ic-edit.svg'

export default class CourseDiscursive extends Component {
  state = {
    isModalOpen: false,
    open: false,
    filtro: false
  }

  componentWillUpdate ({ isSubmitting }) {
    if (!isSubmitting && this.props.isSubmitting) {
      this.setState({ isModalOpen: false })
    }
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      filtro: false
    })
  }

  toggleList = () => {
    this.setState({
      open: !this.state.open,
      filtro: false
    })
  }

  filterList = (tipo, lista) => {
    let newList = []

    switch (tipo.value) {
      case '1':
        lista.map((item) => {
          return item.arquivo_professor ? newList.push(item) : null
        })
        this.setState({ filtro: newList })
        return
      case '2':
        lista.map((item) => {
          return !item.arquivo_professor ? newList.push(item) : null
        })
        this.setState({ filtro: newList })
        return
      default:
        this.setState({ filtro: false })
        return null
    }
  }

  render () {
    const respostasGeral = this.props.course.discursivas.respostas
    const respostas = this.state.filtro !== false ? this.state.filtro : this.props.course.discursivas.respostas
    const podeEnviar = this.props.course.discursivas.pode_enviar

    return (
      <div className='CourseDiscursive'>
        <FormGroup>

          {/* Header */}
          <div className='headerBox'>

            <div className='redacoes'>
              <span>Redações ({respostas.length})</span>
            </div>

            <div className='conteudo'>

              { !this.state.open
                ? 
                <div className='ultimaEnviada'>
                  { respostas.length > 0 
                    ?
                    <React.Fragment>

                      <div className='item'>
                        <span>Última enviada</span>
                        <strong>
                          { respostas[respostas.length - 1].arquivo_aluno ?
                            <React.Fragment>{format(respostas[respostas.length - 1].data_envio)} - {formatHour(respostas[respostas.length - 1].data_envio)}</React.Fragment>
                            : '-'
                          }
                        </strong>
                      </div>

                      <div className={`item status ${respostas[respostas.length - 1].data_devolucao ? 'corrigido' : 'aguardando'}`}>
                        <span>Situação</span>
                        <strong>{respostas[respostas.length - 1].data_devolucao ? 'Corrigido' : 'Aguardando'}</strong>
                      </div>

                      { respostas[respostas.length - 1].data_devolucao_prevista
                        ?
                        <div className='item'>
                          <span>Prazo p/ correção</span>
                          <strong>
                            { respostas[respostas.length - 1].arquivo_aluno ?
                              <React.Fragment>{format(respostas[respostas.length - 1].data_devolucao_prevista)} - {formatHour(respostas[respostas.length - 1].data_devolucao_prevista)}</React.Fragment>
                              : '-'
                            }
                          </strong>
                        </div>
                        : null
                      }
                      
                    </React.Fragment>
                    : 'Nenhuma redação enviada.'
                  }

                </div>
                : 
                <div className='filtro'>
                  <select onChange={(e) => this.filterList(e.target, respostasGeral)}>
                    <option value='0'>Todas Situações</option>
                    <option value='1'>Corrigidas</option>
                    <option value='2'>Aguardando correção	</option>
                  </select>
                </div>
              }

            </div>

            <div className='botoes'>
              { podeEnviar
                ?
                <Button type='button' onClick={this.toggleModal}>
                  Enviar Redação
                </Button>
                : null
              }

              { respostasGeral.length > 0 
                ?
                <Button type='button' onClick={this.toggleList}>
                  { this.state.open ? 'Ocultar' : 'Ver Todas' }
                </Button>
                : null
              }

            </div>

          </div>

          {/* Listagem */}
          {this.state.open && respostas.length > 0 ? (
            <div className='tableListagem'>
              <table cellSpacing='0' cellPadding='0'>
                <thead>
                  <tr>
                    <th>Data da Redação</th>
                    <th>Redação</th>
                    <th>Situação</th>
                    <th>Prazo para Correção</th>
                    <th>Data da Correção</th>
                    <th>Correção</th>
                    <th>Áudio sobre <br /> Aspectos Linguísticos</th>
                    <th>Áudio sobre <br /> Conteúdo</th>
                    <th>Professor</th>
                  </tr>
                </thead>
                <tbody>
                {respostas.map(resposta => (
                  <tr key={resposta.id}>

                    {/* Data do Envio */}
                    <td>
                      { resposta.arquivo_aluno ? 
                        <React.Fragment>
                          <span>{format(resposta.data_envio)} - {formatHour(resposta.data_envio)}</span>
                        </React.Fragment>
                        : '--'
                      }
                    </td>

                    {/* Arquivo da Redação */}
                    <td>
                    { resposta.arquivo_aluno ? 
                        <React.Fragment>
                          <Tooltip placement='bottom' overlay={'Baixar arquivo'}>
                            <a href={resposta.arquivo_aluno} target='_blank'>
                              <img src={IcDownload} alt='Download' />
                            </a>
                          </Tooltip>
                          { resposta.permite_reenvio 
                            ? 
                            <Tooltip placement='bottom' overlay={'Editar arquivo'}>
                              <a href={resposta.arquivo_aluno} target='_blank'>
                                <img src={IcEdit} alt='Download' />
                              </a>
                            </Tooltip>
                            : null
                          }
                        </React.Fragment>
                        : '--'
                      }
                    </td>

                    {/* Situação */}
                    <td className={`status ${resposta.data_devolucao ? 'corrigido' : 'aguardando'}`}>
                      <span>{resposta.data_devolucao ? 'Corrigido' : 'Aguardando'}</span>
                    </td>

                    {/* Prazo para Correção */}
                    <td>
                      { resposta.data_devolucao_prevista
                        ? <React.Fragment>
                          {format(resposta.data_devolucao_prevista)} - {formatHour(resposta.data_devolucao_prevista)}
                        </React.Fragment>
                        : '--'
                       }
                    </td>

                    {/* Data da Correção */}
                    <td>
                    {resposta.arquivo_professor ? (
                        <React.Fragment>
                          <span>{format(resposta.data_devolucao)} - {formatHour(resposta.data_devolucao)}</span>
                        </React.Fragment>
                      ) : '--'
                      }
                    </td>

                    {/* Arquivo da Correção */}
                    <td>
                      {resposta.arquivo_professor ? (
                        <React.Fragment>
                          <Tooltip placement='bottom' overlay={'Baixar arquivo'}>
                            <a href={resposta.arquivo_professor} target='_blank'>
                              <img src={IcDownload} alt='Download' />
                            </a>
                          </Tooltip>
                        </React.Fragment>
                      ) : '--'
                      }
                    </td>

                    {/* Áudio sobre Aspectos Linguísticos */}
                    <td>
                      { resposta.audio_linguistico
                        ? <React.Fragment>
                          <audio controls style={{ maxWidth: '250px' }}>
                            <source src={resposta.audio_linguistico} type="audio/ogg" />
                            <source src={resposta.audio_linguistico} type="audio/mpeg" />
                          </audio>
                        </React.Fragment>
                        : '--'
                      }
                    </td>

                    {/* Áudio sobre Conteúdo */}
                    <td>
                      { resposta.audio_conteudo
                        ? <React.Fragment>
                            <audio controls style={{ maxWidth: '250px' }}>
                              <source src={resposta.audio_conteudo} type="audio/ogg" />
                              <source src={resposta.audio_conteudo} type="audio/mpeg" />
                            </audio>
                          </React.Fragment>
                        : '--'
                      }
                    </td>

                    {/* Professor */}
                    <td>
                      <span>{resposta.professor ? resposta.professor.nome : '--'}</span>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          ) : null
          }
        </FormGroup>
        {podeEnviar && (
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.toggleModal}
          >
            <FormCourseDiscursive
              onSubmit={this.props.onSubmit}
              isSubmitting={this.props.isSubmitting}
            />
          </Modal>
        )}
      </div>
    )
  }

  static propTypes = {
    course: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  }
}
