import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, CollapseHeader, CollapseBody } from 'components/Collapse'
import Box from 'components/Box'
import BlockInfo from 'components/BlockInfo'
import { dateToBR } from 'utils/helpers'
import 'styles/ChamadoCollapse.css'

export default function ChamadoCollapse(props) {
  return (
    <div className='OrderCollapse' id={`zendesk-${props.id}`}>
      <Box>
        <Collapse initialIsOpened={props.id === props.activeOrderId}>
          <CollapseHeader arrow>
            <div className='OrderCollapse-header'>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='info'
                  label='Titulo'
                  value={props.title}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='ticket'
                  label='Número do Protocolo'
                  value={props.orderId}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                  icon='clock'
                  label='Data da Abertura'
                  value={dateToBR(props.date)}
                />
              </div>
              <div className='OrderCollapse-header-item'>
                <BlockInfo
                   icon=""
                  label='Status'
                  value={props.status}
                />
              </div>
            </div>
          </CollapseHeader>
          <CollapseBody>
            <div className='OrderCollapse-body'>
              <ul className='OrderCollapse-body-list'>
                {props.description}
              </ul>
            </div>
          </CollapseBody>
        </Collapse>
      </Box>
    </div>
  )
}

ChamadoCollapse.propTypes = {
  orderId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}
