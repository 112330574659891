import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'is-function'
import Icon from 'components/Icon'

export default function CollapseHeader ({ isOpened, disabled, onClick, children, arrow, component = 'div', SideContent, ...props }) {
  const Header = component

  return (
    <div className='Collapse-header-container'>
      <Header
        className='Collapse-header'
        tabIndex={0}
        onClick={!disabled ? onClick : null}
        {...props}
      >
        {
          isFunction(children) ? (
            children({ isOpened, disabled })
          ) : (
            children
          )
        }
        {arrow && (
          <div className={`Collapse-header-arrow ${isOpened ? 'isOpened' : ''}`}>
            <Icon name='angle-down' />
          </div>
        )}
      </Header>
      {SideContent && (
        <div className='Collapse-header-side'>
          <SideContent />
        </div>
      )}
    </div>
  )
}

CollapseHeader.propTypes = {
  isOpened: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  SideContent: PropTypes.func
}
