import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import rootReducer from 'store/reducers'
import globalFeedbackMiddleware from 'store/middlewares/feedback'

export default createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    applyMiddleware(promise),
    applyMiddleware(globalFeedbackMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : value => value
  )
)
