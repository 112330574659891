/**
 * Dependencies.
 */

import Opportunities from 'api/Opportunities'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions(
  'opportunities',
  Opportunities,
  'Oportunidade',
  false
)
