import React, { useState } from 'react'
import { connect } from 'react-redux'
import Screen from 'containers/Screen'
import RequestCursos from 'components/RequestCursos'
import RequestCursosInativos from 'components/RequestCursosInativos'
import { SITE_URL } from 'config/constants'
import { getUserDetails } from 'store/ducks/user'
import SelectArquivados from 'components/SelectArquivados'
import { CarrosselConcursos, CarrosselEcj, CarrosselOab } from 'components/Carrossel'
import { getLocalStorage } from 'utils/helpers'

function CursosScreen ({ isEmailConfirmed, ...props }) {
  const [filtro, setFiltro] = useState(false)

  if (!isEmailConfirmed) {
    window.location.href = `${SITE_URL}/aluno/confirmarEmail/`
    return <p>Confirme seu e-mail</p>
  }

  const changeFiltro = (value) => {
    setFiltro(value)
  }

  const retornaCarrossel = () => {
    switch (getLocalStorage('menuSidebar')) {
      case 'concursos':
        return <CarrosselConcursos tipo={'concursos'} />
      case 'ecj':
        return <CarrosselEcj tipo={'ecj'} />
      case 'oab':
        return <CarrosselOab tipo={'oab'} />
      default:
        return <CarrosselConcursos tipo={'concursos'} />
    }
  }

  return (
    <Screen title={filtro ? 'Meus Cursos - Arquivados' : 'Meus Cursos'} setHeadTitle >
      { retornaCarrossel() }
      { <SelectArquivados changeFiltro={(value) => changeFiltro(value)} /> }
      { !filtro ? <RequestCursos inativar /> : <RequestCursosInativos ativar buttonFavorite /> }
    </Screen>
  )
}

const mapStateToProps = state => ({
  isEmailConfirmed: getUserDetails(state).email_confirmed
})

export default connect(mapStateToProps)(CursosScreen)
