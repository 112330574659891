import React from 'react'
import PropTypes from 'prop-types'
import 'styles/ForumHeader.css'
import FormGroupSelect from 'components/FormGroupSelect'
import Select from 'components/Select'
import Button from 'components/Button'
import WithModal from 'components/WithModal'
import ForumQuestionNew from 'containers/ForumQuestionNew'

export default function ForumHeader ({ lessons, selectedLesson, onLessonChange, isForumOpen, ...props }) {
  return (
    <WithModal
      modal={
        <ForumQuestionNew selectedLesson={selectedLesson} />
      }
    >
      {({ toggleModal }) => (
        <div className='ForumHeader'>
          <div className='ForumHeader-button'>
            <Button type='button' onClick={toggleModal} disabled={!isForumOpen}>
              {isForumOpen ? 'Enviar pergunta' : 'Fechado para perguntas'}
            </Button>
          </div>
          <div className='ForumHeader-filter'>
            <FormGroupSelect id='lessonsFilter' label='Filtrar perguntas:'>
              <Select
                id='lessonsFilter'
                value={selectedLesson}
                options={lessons.map(lesson => ({
                  label: lesson.nome,
                  value: lesson.id
                }))}
                onChange={onLessonChange}
              />
            </FormGroupSelect>
          </div>
        </div>
      )}
    </WithModal>
  )
}

ForumHeader.propTypes = {
  lessons: PropTypes.array.isRequired,
  onLessonChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,

  /** Fórum aberto para perguntas */
  isForumOpen: PropTypes.bool.isRequired
}
