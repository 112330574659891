import { combineReducers } from 'redux'
import messages from './messages'
import alerts from './alerts'
import orders from './orders'
import certificates from './certificates'
import tickets from './tickets'
import userNotifications from './userNotifications'
import cardTokens from './cardTokens'
import opportunities from './opportunities'

export default combineReducers({
  messages,
  alerts,
  orders,
  certificates,
  tickets,
  userNotifications,
  cardTokens,
  opportunities
})
