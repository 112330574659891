import React, { useState } from 'react'
import Box from 'components/Box'
import { DebounceInput } from 'react-debounce-input'
import { ContainerBusca } from './Styled'

export default function Busca ({ busca }) {

    const [termo, setTermo] = useState('')

    return (
        <ContainerBusca>
            <Box padded>
                <DebounceInput
                    minLength={1}
                    value={termo}
                    debounceTimeout={1500}
                    onInput={ e => {
                        let vlr = e.target.value;
                        if (vlr && vlr.length === 1) {
                            vlr = "";
                        }
                        setTermo(vlr)
                    }}
                    onChange={e =>
                        busca(e.target.value)
                    }
                    placeholder='Pesquisar certificados...' 
                />
            </Box>
        </ContainerBusca>
    )
}
