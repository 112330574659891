import styled from 'styled-components'

export const ContainerGeral = styled.section`
  float: left;
  width: 100%;
  padding: ${props => props.padding ? '50px' : '0px' };
  background-image: url('${props => props.bg}');
  background-size: cover;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

`
