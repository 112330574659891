import Client from 'modules/Client'

export default {
  sendProof (data) {
    const formData = new window.FormData()
    if (!!data.comprovante_arquivo) {
      formData.set('comprovante_arquivo', data.comprovante_arquivo)
    }

    Object.entries(data || {}).forEach(([field, value]) => {
      formData.set(field, value)
    })

    return Client.post('api/aluno/promomaior', {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(data => data || true)
  }
}
