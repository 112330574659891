import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import Title from 'components/Title';
import 'styles/IndiqueAmigo.css';
import imageIndiqueAmigo from "images/ic-indique-amigo.svg";
import imageProduto from "images/ic-produto.svg";
import imageAssinale from "images/ic-assinale.svg";
import imageDesconto from "images/ic-desconto.svg";
import routes from '../config/routes';

export default function IndiqueAmigo() {
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
  };

  return (
    <React.Fragment>
      <div>
        <Box isTransparent id="box-desconto">
          <div className='pontos'>
            <div className='pontos-acumulados'>
              Pontos Acumulados <span style={{ fontSize: 32, color: '#FF6A58', marginRight: 20, marginLeft: 15 }}>600</span>
            </div>
            <div>
              <Link className='Links' to={routes.painelPontuacao}>ver detalhes</Link>
            </div>
          </div>
        </Box>

        <Box isTransparent>
          <div className='IndiqueAmigo'>
            <div className='IndiqueAmigo-column' style={{ maxWidth: 450 }}>
              <Title className="titleSub">Convide amigos e ganhe descontos em produtos.</Title>
              <div style={{ maxWidth: 370, marginTop: 50 }}>
                <span className="description">Você ganha 10%* do valor da compra que o seu amigo fizer em pontos e o seu amigo 10%* de desconto.</span>
                <br />
                <br />
                <span className="description">*confira a <a href={'/'} className="Links">tabela de pontos</a> e o <a href={'/'} className="Links">regulamento.</a></span>
              </div>
            </div>
            <div className="IndiqueAmigo-column">
              <img src={imageIndiqueAmigo} alt="Indique um amigo" />
            </div>
          </div>
        </Box>

        <Box isTransparent id="box-desconto">
          <div className='IndiqueAmigo-full-column'>
            <div className='selo-cliente'>
              <div className='selo-cliente-box' style={{ backgroundColor: '#1717CE', marginRight: '-13px', zIndex: 1 }}>
                <div style={{ marginTop: 20, fontSize: 30 }}>10%</div>
                <div>para você</div>
              </div>
              <div className='selo-cliente-box' style={{ backgroundColor: '#FF6A58' }}>
                <div style={{ marginTop: 20, fontSize: 30 }}>10%</div>
                <div>pra seu amigo</div>
              </div>
            </div>
          </div>
        </Box>

        <Box padded id='box-desconto' style={{ marginTop: '-65px' }}>
          <div className='IndiqueAmigo-full-column'>
            <Title className="titleSub" style={{ textAlign: 'center', marginTop: '80px' }}>Convide seus amigos agora!</Title>
            <div style={{ textAlign: 'center' }}>
              <span className="description">Compartilhe o seu link com amigos e familiares.</span>
              <br />
              <span className="description">Eles ganharão desconto comprando com o seu link e você acumulará pontos</span>
            </div>
            <div className='area-copiar'>
              <input ref={textAreaRef} value="https://www.estrategiaconcursos.com.br/i/thiagb" className='input-copiar' />
              <div className='btn-copiar' onClick={copyToClipboard}>
                <span>{copySuccess ? copySuccess : 'COPIAR'}</span>
              </div>
            </div>
          </div>
        </Box>

        <Box isTransparent id='box-pontos'>
          <div>
            <div className='IndiqueAmigo-full-column'>
              <Title className="titleSub is-centered">Como utilizar os seus pontos acumulados</Title>
            </div>
            <div className='IndiqueAmigo-full-column CardsList'>
              <div className='cardItem'>
                <div className='cardCircleItem'><img src={imageProduto} alt="Produto" /></div>
                <div className="description cardItemText">Escolha uma assinatura, ou um pacote desejável</div>
              </div>
              <div className='cardItem'>
                <div className='cardCircleItem'><img src={imageAssinale} alt="Assine" /></div>
                <div className="description cardItemText">Marque a opção de utilização de créditos no carrinho</div>
              </div>
              <div className='cardItem'>
                <div className='cardCircleItem'><img src={imageDesconto} alt="Desconto" /></div>
                <div className="description cardItemText">Obtenha o seu desconto no produto escolhido</div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </React.Fragment>
  )
}