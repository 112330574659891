import React from 'react';
import Icon from 'components/Icon'
import {
    Container, PrecoAntigo, Parcelamento,
    ActionButton, ActionButtonIcon, ActionButtonContent,
    ActionButtonContentTitle, ActionButtonContentLabel
} from './styles'

export default function Botao ({ precoDe, precoPor, icon, title, label, urlRedirec, forma }) {
  return (
    <Container>

      {precoDe &&
        <PrecoAntigo>
          {forma === 'boleto' ? 'de ' : ''}{precoDe}
        </PrecoAntigo>
      }

      {precoPor &&
        <Parcelamento>
          {forma === 'boleto' ? 'por ' : ''} {precoPor}
        </Parcelamento>
      }

      {urlRedirec &&
        <ActionButton href={urlRedirec} target='_blank'>
          <ActionButtonIcon>
            <Icon name={icon} />
          </ActionButtonIcon>
          <ActionButtonContent>
            <ActionButtonContentTitle>{title}</ActionButtonContentTitle>
            {label && <ActionButtonContentLabel>{label}</ActionButtonContentLabel>}
          </ActionButtonContent>
        </ActionButton>
      }
    </Container>
  )
}
