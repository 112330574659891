import React from 'react'
import Screen from 'containers/Screen'
import SubscriptionsManager from 'containers/SubscriptionsManager'

export default function AssinaturasScreen () {
  return (
    <Screen title=''>
      <SubscriptionsManager />
    </Screen>
  )
}
