import styled from 'styled-components'

export const Header = styled.section`
    float: left;
    width: 100%;
    position: relative;
    padding: 15px 15px 20px 0;
`

// Box Conteúdo
export const TextoHeader = styled.span`
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 80px;

    strong {
        color: #F09C00;
    }

    @media only screen and (max-width: 900px) {

        margin-bottom: 30px;

        br {
            display: none;
        }

	}

    @media only screen and (max-width: 768px) {

        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

    }

`
