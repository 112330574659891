import React from 'react'
import { Header, BoxFechar, BoxConteudo, BoxTexto, PreTitulo, Titulo, Tag, Texto } from './Styled'
import imgFechar from 'images/ic-fechar-promocao-ecj.svg'
import imgCabecalho from 'images/imagem-renovacao-antecipada.jpg'
import routes from 'config/routes'
import { NavLink } from 'react-router-dom'

export default function Cabecalho ({ lote }) {

  return (
    <Header>

      <BoxFechar>
        <NavLink to={routes.cursos}>
          <img src={imgFechar} alt="Fechar" />
        </NavLink>
      </BoxFechar>

      <BoxConteudo>

        {/* Texto */}
        <BoxTexto>
          <PreTitulo>Nunca mais gaste um centavo sequer para ser aprovado</PreTitulo>
          <Titulo>Assinatura Vitalícia</Titulo>
          <Tag>ela voltou, mas não por muito tempo.</Tag>
          <Texto dangerouslySetInnerHTML={{ __html: lote === 1 ? 'Torne-se assinante até o dia 26 de agosto com até <strong>R$ 2,4 mil de desconto <br /> e ganhe 30 dias de acompanhamento com um Coach.</strong>' : 'Torne-se assinante até o dia 02 de setembro com <br /> até <strong>R$ 2,4 mil de desconto.</strong>' }}></Texto>
        </BoxTexto>

        <img src={imgCabecalho} alt="Cabecalho" />

      </BoxConteudo>

    </Header>
  )
}
