import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link, Redirect, withRouter } from 'react-router-dom'
import 'styles/Lesson.css'
import LessonVideos from 'components/LessonVideos'
import LessonButtonList from 'components/LessonButtonList'
import LessonButton from 'components/LessonButton'
import VideoNotes from 'components/VideoNotes'
import VideoDownloads from 'components/VideoDownloads'
import Spinner from 'components/Spinner'
import VideoLivestream from 'components/VideoLivestream'
import { downloadLessonPdf } from 'store/ducks/conteudo/cursos'
import { fetchOne } from 'store/ducks/conteudo/aulas'
import { viewVideo, saveVideoLastPosition, saveNotes } from 'store/ducks/conteudo/videos'
import { isFetching, getDetailedLesson, getLessonVideos } from 'store/ducks/conteudo/aulas'
import { getDetailedCourse } from 'store/ducks/conteudo/cursos'
import ResearchModal from 'containers/ResearchModal'
import Alert from 'components/Alert'
import getRoute from 'utils/getRoute'
import routes from 'config/routes'

class Lesson extends Component {
  state = {
    isSummaryModalOpened: false,
    isModalResearchOpen: false
  }

  toggleSummaryModal = () => {
    this.setState({
      isSummaryModalOpened: !this.state.isSummaryModalOpened
    })
  }

  shouldDisplayResearchForm () {
    const { course: { aulas, aulas_baixadas, pesquisa_habilitada } } = this.props
    return pesquisa_habilitada &&
      aulas_baixadas.length === aulas.length - 1 &&
      !aulas_baixadas.includes(this.props.lessonId)
  }

  shouldBlockPdfDownload () {
    const { course: { aulas_baixadas, downloads_restantes } } = this.props
    return !aulas_baixadas.includes(this.props.lessonId) && downloads_restantes < 1
  }

  shouldEnableDownloads () {
    // return !this.props.course.pertence_assinatura
    return true
  }

  onDownloadPdfClick = e => {
    if (this.shouldDisplayResearchForm()) {
      e.preventDefault()
      return this.setState({ isModalResearchOpen: true })
    }

    if (this.shouldBlockPdfDownload()) {
      e.preventDefault()
      return window.alert('Você atingiu o limite de downloads!')
    }

    this.props.downloadLessonPdf(this.props.courseId, this.props.lessonId)
  }

  componentWillMount () {
    this.props.fetchLesson(this.props.lessonId)
  }

  render () {
    if (this.props.isFetching) {
      return <Spinner text='Carregando aula...' />
    }

    const { lesson, lessonVideos, course, isActive } = this.props

    if (!lesson) return 'Aula não encontrada'

    const videoId = parseInt(this.props.match.params.videoId, 10)

    if (!videoId && lessonVideos.length > 0 && isActive) {
      const to = getRoute(routes.video, {
        courseId: course.id,
        lessonId: lesson.id,
        videoId: lessonVideos[0].id
      })
      return <Redirect to={to} />
    }

    const video = lessonVideos.filter(video => video.id === videoId)[0]
    const selectedVideo = lessonVideos.indexOf(video)
    const label = `Vídeo ${selectedVideo + 1}`

    const PdfHasBeenDownloaded = course.aulas_baixadas.includes(lesson.id)

    return (
      <div className='Lesson'>

        {/* PDFs e Fórum */}
        <div className='Lesson-contentTop'>
          <LessonButtonList>
            {lesson && (
              <React.Fragment>

                {lesson.pdf_simplificado && (
                  <LessonButton
                    icon='file'
                    renderWith='a'
                    href={lesson.pdf_simplificado}
                    target='_blank'
                    disabled={!lesson.pdf_simplificado}
                    novo
                  >
                    Baixar Livro Eletrônico<br /><span>versão simplificada</span>
                  </LessonButton>
                )}

                {lesson.pdf && (
                  <LessonButton
                    icon='file'
                    renderWith='a'
                    href={lesson.pdf}
                    target='_blank'
                    onClick={this.onDownloadPdfClick}
                    disabled={!lesson.pdf}
                    label={PdfHasBeenDownloaded ? 'Baixado' : ''}
                  >
                    Baixar Livro Eletrônico<br /><span>versão original</span>
                  </LessonButton>
                )}

                {lesson.pdf_grifado && (
                  <LessonButton
                    icon='file'
                    renderWith='a'
                    href={lesson.pdf_grifado}
                    target='_blank'
                    onClick={this.onDownloadPdfClick}
                    disabled={!lesson.pdf_grifado}
                    label={course.funcionalidade_pdf_grifado ? '' : 'Bloqueado'}
                  >
                    Baixar Livro Eletrônico<br /><span>marcação dos aprovados</span>
                  </LessonButton>
                )}

                {course.permite_forum && (
                  <LessonButton
                    icon='messages'
                    renderWith={Link}
                    to={getRoute(routes.forum, { courseId: course.id, lessonId: lesson.id })}
                    onClick={e => !course.permite_forum && e.preventDefault()}
                    disabled={!course.permite_forum}
                    label={course.funcionalidade_forum ? '' : 'Bloqueado' }
                  >
                    Fórum de Dúvidas
                  </LessonButton>
                )}

              </React.Fragment>
            )}
          </LessonButtonList>
        </div>

        {lessonVideos && lessonVideos.length > 0 && video && (
          <LessonVideos
            lessonId={lesson.id}
            courseId={course.id}
            videos={lessonVideos}
            video={video}
            viewVideo={this.props.viewVideo}
            saveVideoLastPosition={this.props.saveVideoLastPosition}
            selectedVideo={selectedVideo}
          />
        )}
        <div className='Lesson-content'>

          {/* Outros Botões */}
          <LessonButtonList>
            {video && (
              <React.Fragment>
                {video.resumo && (
                  <LessonButton
                    href={video.resumo}
                    renderWith='a'
                    icon='list'
                    download
                    disabled={!video.resumo}
                    label={course.funcionalidade_resumo ? '' : 'Bloqueado'}
                  >
                    Baixar Resumo
                  </LessonButton>
                )}
                {video.slide && (
                  <LessonButton
                    href={video.slide}
                    renderWith='a'
                    icon='slides'
                    download
                    disabled={!video.slide}
                  >
                    Baixar Slides
                  </LessonButton>
                )}
              </React.Fragment>
            )}
            {lesson && (
              <React.Fragment>
                {video && video.mapa_mental && (
                  <LessonButton
                    icon='file'
                    renderWith='a'
                    href={video.mapa_mental}
                    target='_blank'
                    label={course.funcionalidade_mapa_mental ? '' : 'Bloqueado'}
                  >
                    Baixar Mapa Mental
                  </LessonButton>
                )}
              </React.Fragment>
            )}
            {lesson.tec_concursos && (
              <LessonButton
                icon='external-link'
                renderWith='a'
                href={lesson.tec_concursos}
                target='_blank'
              >
                Caderno de Questões
              </LessonButton>
            )}
            {lesson.conferencia_link && (
              <LessonButton
                icon='users1'
                renderWith='a'
                href={lesson.conferencia_link}
                target='_blank'
              >
                Estude em Grupo
              </LessonButton>
            )}
          </LessonButtonList>

          {this.shouldBlockPdfDownload() && (
            <div style={{ marginTop: 10 }}>
              <Alert type='warning'>
                Você atingiu o limite de downloads!
              </Alert>
            </div>
          )}
          {lesson.livestream_link && (
            <VideoLivestream
              livestream={{
                data: lesson.livestream_data,
                link: lesson.livestream_link,
                senha: lesson.livestream_senha }}
            />
          )}
          {video && (
            <React.Fragment>
              {this.shouldEnableDownloads() && (
                <VideoDownloads video={video} />
              )}
              <VideoNotes
                label={label}
                video={video}
                onSubmit={(data, video) => {
                  const videoId = parseInt(this.props.match.params.videoId, 10)
                  this.props.saveVideoNotes(lesson.id, videoId, data, 'videoNotes')
                }}
                isSubmitting={!!video.isSubmittingNotes}
              />
            </React.Fragment>
          )}
        </div>
        {this.state.isModalResearchOpen && (
          <ResearchModal
            onClose={() => this.setState({ isModalResearchOpen: false })}
            courseId={this.props.courseId}
          />
        )}
      </div>
    )
  }

  static propTypes = {
    fetchLesson: PropTypes.func.isRequired,
    viewVideo: PropTypes.func.isRequired,
    saveVideoLastPosition: PropTypes.func.isRequired,
    lessonId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    lesson: PropTypes.object,
    lessonVideos: PropTypes.array.isRequired,
    saveVideoNotes: PropTypes.func.isRequired,
    downloadLessonPdf: PropTypes.func.isRequired,
    course: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired
  }
}

const mapStateToProps = (state, { lessonId, courseId }) => ({
  isFetching: isFetching(state),
  lesson: getDetailedLesson(state, lessonId),
  lessonVideos: getLessonVideos(state, lessonId),
  course: getDetailedCourse(state, courseId)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    viewVideo,
    saveVideoLastPosition,
    saveVideoNotes: saveNotes,
    fetchLesson: fetchOne,
    downloadLessonPdf
  }, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Lesson))
