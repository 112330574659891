import Client from 'modules/Client'

export default {
  fetchAll () {
    return Client.get('api/aluno/perfil/notificacoes')
  },

  update (areas) {
    return Client.put('api/aluno/perfil/notificacoes', { data: { areas } })
  }
}
