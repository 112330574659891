import React, { Component } from 'react'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import { range } from 'range'
import 'styles/Calendar/Calendar.css'
import CalendarCell from './CalendarCell'
import CalendarCollapse from './CalendarCollapse'
import Spinner from 'components/Spinner'
import getDateRange from 'utils/getDateRange'
import {
  getMonth,
  addDays,
  isEqual,
  differenceInDays,
  format
} from 'date-fns'

export default class Calendar extends Component {
  state = {
    expandedItem: null,
    activeItem: null,
    activeDate: null,
    activeContent: {}
  }

  getCells () {
    const { startDate, endDate } = getDateRange(this.props.selectedDate)
    const selectedDateMonth = getMonth(this.props.selectedDate)
    const data = range(0, differenceInDays(endDate, startDate) + 1).map(i => {
      const date = addDays(startDate, i)
      return {
        date,
        key: format(date, 'YYYY-MM-DD'),
        isAdjacentMonth: getMonth(date) !== selectedDateMonth
      }
    })

    return chunk(data, 7)
  }

  onCellClick = (row, day) => {
    if (isEqual(day.date, this.state.activeDate)) {
      return this.setState({
        activeDate: null,
        activeRow: null
      })
    }

    this.setState({
      activeDate: day.date,
      activeRow: row,
      activeContent: {
        ...this.state.activeContent,
        [row]: this.getConcourses(day.key)
      }
    })
  }

  getConcourses (date) {
    const day = this.props.days[date]
    if (!day) return []

    const concourses = this.props.selectedConcourse
      ? day.concursos.filter(id => id === this.props.selectedConcourse)
      : day.concursos

    return this.props.getConcoursesByIds(concourses)
  }

  componentWillUpdate ({ selectedConcourse, selectedDate }) {
    const shouldResetState = selectedConcourse !== this.props.selectedConcourse ||
      !isEqual(selectedDate, this.props.selectedDate)

    if (shouldResetState) {
      this.setState({ activeContent: {}, activeDate: null })
    }
  }

  renderCells (data, row) {
    return data.map((item, index) => {
      const concourses = this.getConcourses(item.key)
      return (
        <CalendarCell
          key={index}
          date={item.date}
          isAdjacentMonth={item.isAdjacentMonth}
          concourses={concourses}
          onClick={() => concourses.length > 0 ? this.onCellClick(row, item) : null}
          isActive={isEqual(item.date, this.state.activeDate)}
        />
      )
    })
  }

  render () {
    return (
      <div className='Calendar'>
        <div className='Calendar-weekdays'>
          {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map(day => (
            <div key={day} className='Calendar-weekdays-day' data-letter={day[0]}>
              {day}
            </div>
          ))}
        </div>
        <div className='Calendar-grid'>
          {this.getCells().map((data, row) => (
            <div key={row}>
              <div className='Calendar-grid-row'>
                {this.renderCells(data, row)}
              </div>
              <CalendarCollapse
                isOpened={this.state.activeRow === row}
                concourses={this.state.activeContent[row] || []}
                getLessonsByIds={this.props.getLessonsByIds}
              />
            </div>
          ))}
          {this.props.isLoading && (
            <div className='Calendar-spinner'>
              <Spinner text='Carregando...' />
            </div>
          )}
        </div>
      </div>
    )
  }

  static propTypes = {
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    days: PropTypes.object.isRequired,
    concourses: PropTypes.object.isRequired,
    lessons: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getConcoursesByIds: PropTypes.func.isRequired,
    getLessonsByIds: PropTypes.func.isRequired,
    selectedConcourse: PropTypes.number.isRequired
  }
}
