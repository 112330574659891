import styled from 'styled-components'
import mediaQuery from 'utils/mediaQuery'
const BP = '1396px'

export const Container = styled.section`
    padding: 0 25px 25px 25px;
    position: relative;
    margin-top: -90px;
    float: left;
    width: 100%;

    ${mediaQuery.lessThan(BP)`
        padding: 0;
        margin-top: 5px;
    `}
`

export const Planos = styled.section`
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    float: left;
    width: 100%;

    ${mediaQuery.lessThan(BP)`
        display: none;
    `}
`

export const BotaoPlano = styled.button`
    background: none;
    border: 0;
    height: 10px;
    outline: 0;
    margin: 0 10px;
    font-weight: bold;
`

export const PlanoItem = styled.div`
    margin: 5px 20px;
    text-align: left;

    ${mediaQuery.lessThan(BP)`
        margin: 10px 0 0 0;
        float: left;
        width: 100%;
    `}
`

export const PlanoItemCima = styled.div`
    margin: 5px;
    text-align: left;
    grid-column: span 2;
`

export const PlanoItemCard = styled.section`
    text-align: left;    
    margin: 5px 20px;
    border: 1px solid #1717CE;
    background: #fff;
    min-width: 245px;
    height: 150px;
    position: relative;

    ${mediaQuery.lessThan(BP)`
        margin: 0 0 10px 0;
        float: left;
        width: 100%;
    `}
`

export const PlanoItemCardInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
`

export const PlanoItemCardText = styled.span`
    font-family: 'Roboto',sans-serif;
    font-size: 28px;
    color: #1717CE;
    padding-top: 16px;
    width: 100%;
    font-weight: 600;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 15px;
    top: calc(50% - 32px);
`

export const PlanosVantagens = styled.section`
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(4, 1fr);
    float: left;
    width: 100%;

    ${mediaQuery.lessThan(BP)`
        display: none;
    `}
`

export const PlanoVantagemItem = styled.div`
    margin: 5px 20px;
    text-align: left;
    background: #fff;
    border-radius: 4px;
    min-width: 245px;

    ${mediaQuery.lessThan(BP)`
        margin: 0;
        padding: 15px;
        float: left;
        width: 100%;
    `}
`

export const PlanoVantagemItemPrimary = styled.div`
    margin: 5px;
    text-align: left;
    grid-column: span 2;
`

export const InfoPlan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${mediaQuery.lessThan(BP)`
        flex-direction: column;
    `}
`

export const InfoOption = styled.div`
    display: flex;
    align-items: center;
    margin-top: -50px;

    ${mediaQuery.lessThan(BP)`
        display: none;
    `}

    ${mediaQuery.greaterThan(BP)`
        display: block;
    `}
`

export const InfoOptionText = styled.p`
    color: #FF6A58;
    font-weight: bold;
`

export const InfoCardOption = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;    
    margin-top: -70px;

    ${mediaQuery.lessThan(BP)`
        margin-top: 10px;
        flex-direction: column;
    `}
`

export const TextIncluso = styled.p`
    position: absolute;
    margin-top: -45px;
    color: #666666;
    left: 50px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
`

export const ItemInclusoLista = styled.div`
    margin-top: ${props => props.margin ? '70px' : 0};
    font-family: Roboto, sans-serif;
    margin-bottom: 0px;
    font-weight: ${props => props.negrito ? 600 : null}
`

export const ItemInclusoListaTexto = styled.span`
    display: inline-block;
    padding: 10px 0 10px 15px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
`

export const ItemInclusoListaHr = styled.hr`
    margin: 0;
    position: absolute;
    width: 97%;
    height: 1px;
    border-width: 0;
    color: #eee;
    background-color: #D8D8D8
`

export const IconeTipo = styled.p`
    margin-top: ${props => props.margin ? '70px' : 0};
    margin-bottom: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CompativelComSeuPlano = styled.span`
    background-color: #FF6A58;
    border-radius: 2px;
    font-family: Roboto,sans-serif;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    left: 15px;
    top: 15px;
    right: 15px;
`

export const Rectangle = styled.p`
    background-color: #FF6A58;
    width: 100%;
    height: 8px;
    margin-top: 25px;

    ${mediaQuery.lessThan(BP)`
        margin-top: 10px;
    `}
`

export const CardMobile = styled.section`
    border: 1px solid #1717CE;
    background: #fff;
`

export const CardInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
`

export const OfertasMobile = styled.div`
    width: 100%;

    ${mediaQuery.greaterThan(BP)`
        display: none;
    `}
`

export const CardInfoText = styled.span`
    font-size: 30px;
    color: #1717CE;
    padding-top: 16px;
`

export const CardInfoTextBotton = styled.span`
    color: #666666;
    font-family: Roboto,sans-serif;
    font-size: 13px;
    line-height: 14px;
    text-align: left;
    width: 100%;
    padding-top: 10px;
    position: absolute;
    bottom: 15px;
    left: 15px;
`

export const EmptyButton = styled.div`
    min-width: 245px;
`

// Mobile ****************************************

// Select de planos Mobile

export const SelectPlanosMobile = styled.div`
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
`

export const BotaoPlanoMobile = styled.button`
    background: none;
    border: 0;
    height: 10px;
    outline: 0;
    margin: 0 10px;
    font-weight: bold;
`
