import Client from 'modules/Client'

export default {
  fetchAll () {
    return Client.get('api/aluno/cardToken')
  },

  create (data) {
    return Client.post('api/aluno/cardToken', { data })
  },

  update (id) {
    return Client.put(`api/aluno/cardToken/${id}/ativar`)
  },

  remove ({ id }) {
    return Client.delete(`api/aluno/cardToken/${id}`)
  }
}
