/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import Auth from 'api/Auth'
import loginRedirect from 'utils/loginRedirect'
import createActionTypes from 'store/utils/createActionTypes'
import { pushNotification } from 'store/ducks/notifications'
import routes from 'config/routes'
import { parse, format } from 'date-fns'
import axios from 'axios'
import { API_URL, CLIENT_SECRET, CLIENT_ID } from 'config/constants'
import { setCookie, getCookie } from 'utils/helpers'
import Client from 'modules/Client'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('AUTH', [
  'LOGIN',
  'LOGIN_PENDING',
  'LOGIN_FULFILLED',
  'LOGIN_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isAuthenticated: false,
  isProcessing: false,
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.LOGIN_PENDING]: state =>
    update(state, {
      isProcessing: { $set: true }
    }),

  [actionTypes.LOGIN_FULFILLED]: state =>
    update(state, {
      $merge: {
        isAuthenticated: true,
        isProcessing: false,
        error: null
      }
    }),

  [actionTypes.LOGIN_REJECTED]: (state, { payload }) =>
    update(state, {
      $merge: {
        isAuthenticated: false,
        isProcessing: false,
        error: payload
      }
    })
})

/**
 * Action Creators.
 */

export function refreshToken (pathname, dadosSessao) {

  // Monta os dados para o Refresh Token
  let refreshDados = {
    'grant_type': 'refresh_token',
    'refresh_token': dadosSessao.refresh_token,
    'client_id': CLIENT_ID,
    'client_secret': CLIENT_SECRET,
    'scope': ''
  }

  axios.post(API_URL + '/api/oauth/token', refreshDados)
    .then(response => {
      setCookie('dados_sessao', JSON.stringify(response.data, response.data.expires_in))
      Client.token = response.data.access_token
      Client.setHeader('Session', '')
      Client.setHeader('Authorization', `${response.data.token_type} ${response.data.access_token}`)
      Client.setHeader('Personificado', false)
      Client.setBaseURL(API_URL)
    })
    .then(() => Client.get('api/aluno/perfil/detalhes'))
    .then(() => window.location.reload())
    .catch(err => {
      console.log(err)
      if (pathname !== '/dashboard/renovacao' && pathname !== '/dashboard/login') {
        loginRedirect()
      }
    })

}

export const login = (pathname, dados = '', interno = false) => dispatch => {
  
  return dispatch({
    type: actionTypes.LOGIN,
    payload: interno === false ? Auth.getToken() : Auth.getTokenInterno(dados)
  })
    .then(data => {
      if (data.value.desconectou) {
        dispatch(pushNotification('Suas contas em outros dispositivos foram desconectadas', 'success'))
      }

      if (data.value.pedido_renovar_assinatura) {
        const { id: pedidoId, validade_fim, tipo_cobranca_grupo_id, produto: {nome} } = data.value.pedido_renovar_assinatura
        if (tipo_cobranca_grupo_id === 1) {
          dispatch(pushNotification(
            `Sua assinatura "${nome}" irá terminar em ${format(parse(validade_fim), 'DD/MM/YYYY')}. Clique em [link ${routes.historicoDeCompras}?p=${pedidoId}]Histórico de Compras[/link] para gerenciar sua assinatura.`,
            'warning'
          ))
        } else {
          dispatch(pushNotification(
            `Sua assinatura "${nome}" não será renovada automaticamente em ${format(parse(validade_fim), 'DD/MM/YYYY')}. Clique em [link ${routes.historicoDeCompras}?p=${pedidoId}]Histórico de Compras[/link] para renovar e garantir o preço.`,
            'warning'
          ))
        }
      }
    })
    .catch(err => {
      console.log(err)
      dispatch({ type: actionTypes.LOGIN_REJECTED })

      // verifica se existe dados no cookie
      if (getCookie('dados_sessao')) {
        let dadosSessao = JSON.parse(getCookie('dados_sessao'))
        // verifica se existe o Refresh Token
        if (dadosSessao.refresh_token && dadosSessao.refresh_token != null && dadosSessao.refresh_token !== '') {
          refreshToken(pathname, dadosSessao)
        }

      }

      // Mostra mensagem de login inválido
      if (pathname === '/dashboard/login' && interno) {
        dispatch(pushNotification(
          `Erro ao realizar login.`,
          'error'
        ))
      }

      // Redireciona para a página de login no site
      if (pathname !== '/dashboard/renovacao' && pathname !== '/dashboard/login') {
        loginRedirect()
      }

    })
}

/**
 * Selectors.
 */

export const isAuthenticated = state =>
  state.auth.isAuthenticated

export const isProcessing = state =>
  state.auth.isProcessing
