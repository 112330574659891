import PropTypes from 'prop-types'
import { compose } from 'react-recompose'
import withResourceList from 'store/utils/withResourceList'
import withResourceActions from 'store/utils/withResourceActions'

function UserNotifications ({ records, children, update, isSubmitting }) {
  return children({ records, update, isSubmitting })
}

UserNotifications.propTypes = {
  records: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default compose(
  withResourceActions({
    resource: 'userNotifications'
  }),
  withResourceList({
    resource: 'userNotifications',
    spinnerText: 'Carregando notificações...'
  })
)(UserNotifications)
