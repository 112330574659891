import React from 'react'
import 'styles/MenuSwitcher.css'
import UserDetails from 'containers/UserDetails'
import { validaItemMenu } from 'utils/helpers'
import { Link } from 'react-router-dom'
import routes from 'config/routes'

export default function EcjSwitcher ({ onSwitch, isActive }) {
  return (
    <UserDetails>
      {details => (
        <React.Fragment>
          {validaItemMenu('Carreira Jurídica', details.verticais) || validaItemMenu('OAB', details.verticais) ? (
            <div className='EcjSwitcher-container'>
              <div className='EcjSwitcher'>

                {/* Menu Concursos */}
                <Link to={{ pathname: routes.cursos, state: { tipo: 'concursos' } }} className={`EcjSwitcher-item ${isActive === 'concursos' ? 'is-active' : ''}`} onClick={() => onSwitch('concursos')}>
                  <div>Concursos</div>
                </Link>
                
                {/* Menu carreira Jurídica */}
                { validaItemMenu('Carreira Jurídica', details.verticais)
                  ? (
                    <Link to={{ pathname: routes.cursos, state: { tipo: 'ecj' } }}  className={`EcjSwitcher-item ${isActive === 'ecj' ? 'is-active' : ''}`} onClick={() => onSwitch('ecj')}>
                      <div>Carreira Jurídica</div>
                    </Link>
                  )
                  : null
                }

                {/* Menu OAB */}
                { validaItemMenu('OAB', details.verticais)
                  ? (
                    <Link to={{ pathname: routes.cursos, state: { tipo: 'ecj' } }} className={`EcjSwitcher-item ${isActive === 'oab' ? 'is-active' : ''}`} onClick={() => onSwitch('oab')}>
                      <div>OAB</div>
                    </Link>
                  )
                  : null
                }
              </div>
            </div>
           ) : null}
        </React.Fragment>
      )}
    </UserDetails>
  )
}
