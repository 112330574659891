import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'
import Text from 'components/Text'
import {
  Control,
  Button,
  Info
} from './styles'

function Pagination ({ current, total, onChange, disabled, center }) {
  current = current || 1;
  return (
    <Control center={center}>

      <Button
        title='Primeira Página'
        onClick={() => onChange(1)}
        disabled={disabled || current === 1} >
        <Icon name='chevron-left' /><Icon name='chevron-left' />
      </Button>

      <Button
        title='Página anterior'
        onClick={() => onChange(current - 1)}
        disabled={disabled || current === 1} >
        <Icon name='chevron-left' />
      </Button>
      
      <Info>
        <Text>
          Página {current} de {total}
        </Text>
      </Info>

      <Button
        title='Próxima Página'
        onClick={() => onChange(current + 1)}
        disabled={disabled || current === total}>
        <Icon name='chevron-right' />
      </Button>

      <Button
        title='Última página'
        onClick={() => onChange(total)}
        disabled={disabled || current === total}>
        <Icon name='chevron-right' /><Icon name='chevron-right' />
      </Button>
      
    </Control>
  )
}

Pagination.propTypes = {
  /** Total of available pages */
  total: PropTypes.number.isRequired,

  /** Current page */
  current: PropTypes.number.isRequired,

  /** Disables pagination */
  disabled: PropTypes.bool,

  /** Centers the component */
  center: PropTypes.bool
}

export default Pagination
