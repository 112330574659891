import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import WithModal from 'components/WithModal'
import Image from 'components/Image'
import theme from 'theme'

const Button = styled.button`
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  border: 1px solid ${theme.colors.grayBorder};
`

export default function ModalThumb ({ src, alt, ...props }) {
  return (
    <WithModal
      width={1000}
      modal={(
        <Image
          src={src}
          alt={alt}
          center
        />
      )}
    >
      {({ toggleModal }) => (
        <Button type='button' onClick={toggleModal}>
          <Image
            src={src}
            alt={alt}
            width={100}
            {...props}
          />
        </Button>
      )}
    </WithModal>
  )
}

ModalThumb.propTypes = {
  /** Endereço da imagem */
  src: PropTypes.string.isRequired,

  /** Alt da imagem */
  alt: PropTypes.string.isRequired
}
