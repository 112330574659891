import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "ramda";
import Modal from "components/Modal";
import SectionTitle from "components/SectionTitle";
import FormConfirmacaoPalavra from "forms/FormConfirmacaoPalavra";
import { Container, Padded, Warning } from "./styles";
import { parse } from 'date-fns'

export default function DesmatriculaModal(props) {
  const {
    produtoSelecionado,
    onSubmit,
    onCloseModal,
    inscricoes,
    limite
  } = props;


  const restante = limite - inscricoes + 1;
  const foiRetirado = parse(produtoSelecionado.data_retirada) < Date.now()

  return (
    <Modal
      isOpen={!isEmpty(produtoSelecionado)}
      onRequestClose={onCloseModal}
      width="1000px"
      padding="0"
    >
      <Container>
        <Padded>
          <SectionTitle style={{ fontSize: 20 }}>
            ATENÇÃO!!!
          </SectionTitle>
        </Padded>

        <Warning>
          { foiRetirado ? (
            <p>
              Antes de confirmar sua desmatricula no curso <strong>{produtoSelecionado.nome}</strong>,
              informamos que este curso/pacote não está mais disponível para venda em nosso site,
              desta forma, caso se desmatricule o mesmo não aparecerá mais em sua assinatura.
            </p>
          ) : (
            <p>
              Caso confirme a desmatrícula, você poderá acessar este curso após refazer a matrícula.
            </p>
          )}
        </Warning>

        <Padded>
          <p>
            Você tem certeza que deseja se desmatricular de <strong>{produtoSelecionado.nome}</strong>?
            Após a confirmação, sobrarão <strong>{restante}</strong> matrícula(s) do seu total de <strong>{limite}</strong>.
          </p>
          <FormConfirmacaoPalavra word="CORUJA" onSubmit={data => data && onSubmit(produtoSelecionado)} />
        </Padded>
      </Container>
    </Modal>
  );
}

DesmatriculaModal.propTypes = {
  produtoSelecionado: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  limite: PropTypes.number.isRequired,
  inscricoes: PropTypes.number.isRequired
};
