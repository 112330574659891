import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ForumMessage from 'components/ForumMessage'
import Spinner from 'components/Spinner'
import LoadMore from 'components/LoadMore'
import {
  fetchAll,
  isFetching,
  getPagination,
  getForumMessages
} from 'store/ducks/forumMessages'

class ForumMessages extends Component {
  componentDidMount () {
    this.fetch()
  }

  fetch = (params = {}) => {
    this.props.fetchAll(this.props.lessonId, params)
  }

  fetchNextPage = () => {
    this.fetch({ page: this.props.pagination.current_page + 1 })
  }

  hasMore() {
    const { pagination } = this.props
    return pagination && pagination.current_page < pagination.last_page
  }

  render () {
    if (this.props.isFetching && !this.props.messages.length) {
      return <Spinner text='Carregando mensagens' />
    }

    if (!this.props.messages.length) {
      return 'Nenhuma mensagem cadastrada!'
    }

    return [
      ...this.props.messages.map(message => (
        <ForumMessage
          key={message.id}
          message={message}
        />
      )),
      this.hasMore() && (
        <LoadMore
          key='loadMore'
          isLoading={this.props.isFetching}
          onClick={this.fetchNextPage}
        />
      )
    ]
  }

  static propTypes = {
    // From Component
    lessonId: PropTypes.number.isRequired,

    // From State
    isFetching: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired,

    // From Dispatch
    fetchAll: PropTypes.func.isRequired
  }
}

const mapStateToProps = (state, { lessonId }) => ({
  isFetching: isFetching(state),
  pagination: getPagination(state, lessonId),
  messages: getForumMessages(state, lessonId)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchAll }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForumMessages)
