import React from 'react'
import Screen from 'containers/Screen'
import Coaching from 'containers/Coaching'

export default function CoachingScreen() {
  return (
    <Screen title='Assinatura Platinum' setHeadTitle>
      <Coaching />
    </Screen>
  )
}
