/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import Videos from 'api/Videos'
import createActionTypes from 'store/utils/createActionTypes'

/**
 * Action Types.
 */

const aulasActionTypes = createActionTypes('AULAS', [
  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED'
])

export const actionTypes = createActionTypes('VIDEOS', [
  'VIEW_VIDEO',
  'VIEW_VIDEO_PENDING',
  'VIEW_VIDEO_FULFILLED',
  'VIEW_VIDEO_REJECTED',

  'SAVE_VIDEO_LAST_POSITION',

  'SAVE_NOTES',
  'SAVE_NOTES_PENDING',
  'SAVE_NOTES_FULFILLED',
  'SAVE_NOTES_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  entities: {},
  error: null
}

/**
 * Reducer.
 */

export default createReducer(initialState, {
  [aulasActionTypes.FETCH_ONE_FULFILLED] (state, { payload }) {
    return update(state, {
      entities: { $merge: payload.entities.videos || {} }
    })
  },

  [actionTypes.VIEW_VIDEO_PENDING] (state, { meta: { id, value } }) {
    return update(state, {
      entities: {
        [id]: {
          alterando_visualizacao: { $set: true },
          visualizado: { $set: value }
        }
      }
    })
  },

  [actionTypes.VIEW_VIDEO_FULFILLED] (state, { meta: { id, value } }) {
    return update(state, {
      entities: {
        [id]: {
          alterando_visualizacao: { $set: false },
          visualizado: { $set: value }
        }
      }
    })
  },

  [actionTypes.VIEW_VIDEO_REJECTED] (state, { meta: { id, value } }) {
    return update(state, {
      entities: {
        [id]: {
          alterando_visualizacao: { $set: false },
          visualizado: { $set: !value }
        }
      }
    })
  },

  [actionTypes.SAVE_VIDEO_LAST_POSITION] (state, { payload }) {
    return update(state, {
      entities: {
        [payload.videoId]: {
          posicao: { $set: payload.time }
        }
      }
    })
  },

  [actionTypes.SAVE_NOTES_PENDING] (state, { meta: { id } }) {
    return update(state, {
      entities: {
        [id]: {
          isSubmittingNotes: { $set: true }
        }
      }
    })
  },

  [actionTypes.SAVE_NOTES_FULFILLED] (state, { meta: { id, notes }, payload }) {
    return update(state, {
      entities: {
        [id]: {
          anotacoes: { $set: notes },
          isSubmittingNotes: { $set: false }
        }
      },
      error: { $set: null }
    })
  },

  [actionTypes.SAVE_NOTES_REJECTED] (state, { meta: { id }, payload }) {
    return update(state, {
      entities: {
        [id]: {
          isSubmittingNotes: { $set: false }
        }
      },
      error: { $set: payload }
    })
  }
})

/**
 * Action Creators.
 */

export const viewVideo = (aulaId, videoId, value = true) => (dispatch, getState) => {
  const video = getVideos(getState())[videoId]
  if (!video) return

  dispatch({
    type: actionTypes.VIEW_VIDEO,
    payload: Videos.viewVideo(aulaId, videoId, value),
    meta: {
      id: videoId,
      value
    }
  })
}

export const saveVideoLastPosition = (lessonId, videoId, time, resolution) => dispatch => {
  Videos.saveVideoLastPosition(lessonId, videoId, time, resolution)

  dispatch({
    type: actionTypes.SAVE_VIDEO_LAST_POSITION,
    payload: {
      videoId,
      time,
      resolution
    }
  })
}

export const saveNotes = (lessonId, videoId, data, notificationsId) => ({
  type: actionTypes.SAVE_NOTES,
  meta: {
    handleError: true,
    handleSuccess: true,
    defaultErrorMessage: 'Erro ao salvar anotações!',
    defaultSuccessMessage: 'Anotação salva com sucesso!',
    id: videoId,
    notificationsId,
    notes: data.anotacoes
  },
  payload: Videos.saveNotes(lessonId, videoId, data)
})

/**
 * Selectors.
 */

export const getVideos = state =>
  state.conteudo.videos.entities
