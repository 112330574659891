import React from 'react'
import PlayerControl from './PlayerControl'
import { PlayerControlOptions, Button } from './PlayerControlOptions'

export default function SourcesControl ({ sources, currentSource, onSourceChange }) {
  return (
    <PlayerControl
      icon='cog'
      label='Alterar qualidade'
    >
      <PlayerControlOptions>
        {sources.map((source, index) => (
          <Button
            key={index}
            isActive={source.url === currentSource}
            onClick={() => onSourceChange(source.url, source.name)}
            type='button'
          >
            {source.name}
          </Button>
        ))}
      </PlayerControlOptions>
    </PlayerControl>
  )
}
