import React from 'react'
import 'styles/Logo.css'
import classNames from 'classnames'
import { SITE_URL } from 'config/constants'

import oldLogo from 'images/logo.svg'
import newLogo from 'images/logo-completo.png'

export default function Logo ({ isNew = null, onlyDesktop, onlyMobile, ...props }) {

  const url = !!isNew ? newLogo : oldLogo
  const className = classNames({
    Logo: true,
    '-is-new': !!isNew,
    '-only-desktop': onlyDesktop,
    '-only-mobile': onlyMobile
  })

  return (
    <a href={SITE_URL} className={className}>
      <img
        src={url}
        alt='Logo - Estratégia Concursos'
      />
    </a>
  )
}
