import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Screen from 'containers/Screen'
import routes from 'config/routes'
import Forum from 'containers/Forum'
import Link from 'components/Link'

export default function ForumScreen ({ match: { params } }) {
  const lessonId = parseInt(params.lessonId, 10)
  const courseId = parseInt(params.courseId, 10)
  return (
    <Screen
      title='Fórum de dúvidas'
      Side={() => (
        <Link to={routes.cursos} renderWith={RouterLink}>Meus cursos</Link>
      )}
    >
      <Forum lessonId={lessonId} courseId={courseId} />
    </Screen>
  )
}
