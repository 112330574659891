import styled from 'styled-components'
import media from 'utils/mediaQuery'

export const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; 
`

export const Content = styled.div`
    width: 100%;
    padding: 20px;

    ${media.greaterThan('medium')`
        width: 600px;
    `}
`

export const Logo = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 250px;
    height: auto;

    ${media.lessThan('small')`
        width: 180px;
        margin-bottom: 25px;
    `}
`

export const LinkContainer = styled.div`
    margin-top: 30px;
    text-align: center;

    ${media.lessThan('small')`
        margin-top: 20px;
    `}
`