import React from 'react'

export default function Slide (props) {
	return (
		<a key={props.index} href={props.item.evento_url} target={!props.item.evento_url.includes('https://www.estrategiaconcursos.com.br/app/dashboard') ? '_blank' : '_self'}>
			<button type='button' className='banner-slider-thumbs-item'>
				<div className='banner-slider-thumbs-item-image'>
					<img className='banner-img' src={props.item.urlImagem} alt={props.item.titulo} />
				</div>
			</button>
		</a>
	)
}