import React from 'react'
import PropTypes from 'prop-types'
import FormVideoNotes from 'forms/FormVideoNotes'
import Notifications from 'containers/Notifications'
import CollapseContent from 'components/CollapseContent'

export default function VideoNotes ({ label, video, onSubmit, isSubmitting }) {
  return (
    <CollapseContent
      title='Anotações'
      label={label}
      icon='notes'
    >
      <Notifications notificationsId='videoNotes' />
      <FormVideoNotes
        initialValues={{ anotacoes: video.anotacoes }}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </CollapseContent>
  )
}

VideoNotes.propTypes = {
  label: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}
