import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import FormGroup from 'components/FormGroup'
import CustomField from 'components/CustomField'
import Button from 'components/Button'

export default function FormConfirmacaoPalavra ({ word = 'PALAVRA', onSubmit, label }) {
  const checkValue = value => String(value).toLowerCase().trim() === word.toLowerCase()
  const handleFormSubmit = (_, form) => onSubmit(form.getState().valid)

  return (
    <Form onSubmit={handleFormSubmit}>
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup>{label}</FormGroup>
          <FormGroup>
            <Field
              name='word'
              component={CustomField}
              validate={value => checkValue(value) ? null : `Digite a palavra ${word} para confirmar a matrícula`}
              autoFocus
            />
          </FormGroup>
          <FormGroup>
            <Button type='submit' disabled={invalid}>Confirmar</Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormConfirmacaoPalavra.propTypes = {
  /** Callback do form submit */
  onSubmit: PropTypes.func.isRequired
}

FormConfirmacaoPalavra.defaultProps = {
  label: <React.Fragment>Para confirmar, digite <strong>CORUJA</strong>:</React.Fragment>
}
