import React from 'react'
import IconeCheck from 'images/ic-disponivel.svg'
import icClose from 'images/icons/ic-exclude-item.svg'
import { Lista, Item, Icone, Texto } from './Styled'

export default function List ({ itens }) {

  const iconItem = (type) => {
    return type ? IconeCheck : icClose
  }

  const ItensList = () => {
    let listaItens = Object.entries(itens).map((item, index) => (
      <Item key={index}>
        <Icone src={iconItem(item[1])} alt='Item do Plano' />
        <Texto indisponivel={!item[1]}>{item[0]}</Texto>
      </Item>
    ))
    return listaItens
  }

  return (
    <Lista>
      {ItensList()}
    </Lista>
  )
}
