/**
 * Dependencies.
 */

import update from 'immutability-helper'
import createReducer from 'store/utils/createReducer'
import createActionTypes from 'store/utils/createActionTypes'
import Invite from 'api/Invite'

/**
 * Action Types.
 */

export const actionTypes = createActionTypes('INVITATION', [
  'INVITE_FRIEND',
  'INVITE_FRIEND_PENDING',
  'INVITE_FRIEND_FULFILLED',
  'INVITE_FRIEND_REJECTED'
])

/**
 * Initial State.
 */

const initialState = {
  isSubmitting: false,
  error: null
}

/**
 * Invite Reducer.
 */

export default createReducer(initialState, {
  [actionTypes.INVITE_FRIEND_PENDING]: state =>
    update(state, {
      isSubmitting: { $set: true }
    }),

  [actionTypes.INVITE_FRIEND_FULFILLED]: state =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: null }
    }),

  [actionTypes.INVITE_FRIEND_REJECTED]: (state, action) =>
    update(state, {
      isSubmitting: { $set: false },
      error: { $set: action.payload }
    })
})

/**
 * Action creators.
 */

export const inviteFriend = data => ({
  type: actionTypes.INVITE_FRIEND,
  meta: {
    handleError: true,
    defaultErrorMessage: 'Erro ao enviar dados do seu amigo!',
    handleSuccess: true,
    defaultSuccessMessage: 'Dados do amigo enviado com sucesso!',
    formName: 'inviteFriend'
  },
  payload: Invite.inviteFriend(data)
})

/**
 * Selectors.
 */

export const isSubmitting = state =>
  state.invite.isSubmitting
