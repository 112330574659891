import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { format } from 'modules/Date'
import { addMonths, subMonths } from 'date-fns'
import Icon from 'components/Icon'
import 'styles/DateSelect.css'

export default class DateSelect extends Component {
  onPrevClick = () => {
    this.setDate(subMonths(this.props.value, 1))
  }

  onNextClick = () => {
    this.setDate(addMonths(this.props.value, 1))
  }

  setDate (date) {
    this.props.onChange(date)
  }

  render () {
    return (
      <div className='DateSelect'>
        <div className='DateSelect-controls'>
          <button
            type='button'
            className='DateSelect-controls-button'
            aria-label='Mês Anterior'
            onClick={this.onPrevClick}
          >
            <Icon name='angle-left' />
          </button>
          <button
            type='button'
            className='DateSelect-controls-button'
            aria-label='Mês Posterior'
            onClick={this.onNextClick}
          >
            <Icon name='angle-right' />
          </button>
        </div>
        <span className='DateSelect-item'>
          {format(this.props.value, 'MMMM YYYY')}
        </span>
      </div>
    )
  }

  static propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired
  }
}
