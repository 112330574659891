import Client from 'modules/Client'

export default {
  getDetails () {
    return Client.get('api/aluno/detalhes')
  },

  getProfile () {
    return Client.get('api/aluno/perfil')
  },

  updateProfile (data) {
    return Client.put('api/aluno/perfil', { data })
  },

  updateEmail (data) {
    return Client.put('api/aluno/perfil/email', { data })
  },

  updatePassword (data) {
    return Client.put('api/aluno/perfil/senha', { data })
  },

  getMonitor (params) {
    return Client.get('api/aluno/monitor', { params })
  },
  getZendeskToken (params) {
    return Client.get('api/aluno/zendesk/token', { params })
  }
}
