import React, { useState } from 'react'
import { Container } from './Styled'

// Componentes
import Switch from './Components/Switch/Switch'
import Plano from './Components/Plano/Plano'
import Seta from './Components/Seta/Seta'

export default function Planos ({ dados }) {

  console.log(dados);

  const [tipo, setTipo] = useState(dados.produto_atual && dados.produto_atual.assinatura_meses === '12' ? 1 : 2)

  const listaAssinaturas = (tipoPlano, lista) => {
    let listaItens = lista.map((item, index) => (
      <Plano dadosPlano={item} key={index} tipo={tipoPlano} produto={dados.tipo} />
    ))
    return listaItens
  } 

  const changeSwitch = (tipoPlano) => {
    if (tipoPlano === 1) {
      setTipo(2)
    } else {
      setTipo(1)
    }
  }

  return (
    <React.Fragment>
      {/* { dados.tipo === 'Concursos' && dados.planos && dados.planos.length > 1 ? <Switch status={tipo} changeSwitch={(plano) => plano ? setTipo(plano) : changeSwitch(tipo)} planos={dados.planos} /> : null } */}
      <Container>
        {/* { dados.produto_atual ? <Plano dadosPlano={dados.produto_atual} atual={true} tipo={1} /> : null }
        { dados.produto_atual && dados.planos.length > 0 ? <Seta /> : null } */}
        {/* { dados.produto_atual && tipo === 1 && dados.planos && dados.planos.length > 0 ? listaAssinaturas(dados.produto_atual.vertical, dados.planos[0].assinaturas) : null }
        { dados.produto_atual && tipo === 2 && dados.planos && dados.planos.length > 1 ? listaAssinaturas(dados.produto_atual.vertical, dados.planos[1].assinaturas) : null } */}
        
        { !dados.produto_atual && dados.planos && dados.planos.length > 0 ? listaAssinaturas('', dados.planos[0].assinaturas) : null }
        {/* { !dados.produto_atual && dados.planos && dados.planos.length > 0 ? listaAssinaturas('', dados.planos[1].assinaturas) : null } */}

      </Container>
    </React.Fragment>
  )
}
