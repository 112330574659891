/**
 * Dependencies.
 */

import * as formatters from 'utils/formatters'
import * as validators from 'utils/validators'
import * as parsers from 'utils/parsers'

/**
 * createRequired helper.
 */

const createRequired = ({ parse, format, validate }) => ({
  parse,
  format,
  validate: value =>
    validators.required(value) || validate(value)
})

/**
 * Required.
 */

export const required = {
  validate: validators.required
}

/**
 * E-mail.
 */

export const email = {
  validate: validators.email
}

export const emailRequired = createRequired(email)

/**
 * Phone.
 */

export const phone = {
  format: formatters.phone,
  parse: parsers.phone,
  validate: validators.phone
}

export const phoneRequired = createRequired(phone)

/**
 * CPF.
 */

export const cpf = {
  format: formatters.cpf,
  parse: parsers.cpf,
  validate: validators.cpf
}

export const cpfRequired = createRequired(cpf)

/**
 * CEP.
 */

export const cep = {
  format: formatters.cep,
  parse: parsers.cep,
  validate: validators.cep
}

export const cepRequired = createRequired(cep)

/**
 * Date.
 */

export const date = {
  format: formatters.date,
  parse: parsers.date,
  validate: validators.date
}

export const dateRequired = createRequired(date)

/**
 * Card Number.
 */

export const cardNumber = {
  format: formatters.cardNumber,
  parse: parsers.cardNumber
}

export const cardNumberRequired = {
  ...cardNumber,
  validate: validators.required
}

/**
 * Card CVC.
 */

export const cardCVC = {
  format: formatters.cardCVC,
  parse: parsers.cardCVC,
  validate: validators.cardCVC
}

export const cardCVCRequired = createRequired(cardCVC)
