import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import FormGroup from 'components/FormGroup'
import CustomField from 'components/CustomField'
import FormFieldScale from 'components/FormFieldScale'
import Button from 'components/Button'
import { required } from 'utils/forms'
import Label from 'components/Label'

  export default function FormResearch ({ onSubmit, research, isSubmitting }) {
  return (
    <Form
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {research.perguntas.map(question => (
            <FormGroup key={question.id}>
              {question.tipo_pergunta === 'text' && (
                <Field
                  name={`pergunta-${question.id}`}
                  component={CustomField}
                  label={question.pergunta}
                  renderWith={'textarea'}
                  {...required}
                />
              )}
              {question.tipo_pergunta === 'scale' && !question.especifica && (
                <Field
                  name={`pergunta-${question.id}`}
                  component={CustomField}
                  renderWith={FormFieldScale}
                  label={question.pergunta}
                  answers={question.respostas}
                  {...required}
                />
              )}
              {question.especifica && research.professores.length > 0 && (
                <div style={{ marginTop: 15 }}>
                  <Label>
                    {question.pergunta}
                  </Label>
                  {research.professores.map(prof =>
                    <div
                      key={prof.id}
                      style={{ marginTop: 10 }}
                    >
                      <Field
                        name={`pergunta-${question.id}-prof-${prof.id}`}
                        component={CustomField}
                        renderWith={FormFieldScale}
                        label={`Professor(a) ${prof.nome}:`}
                        answers={question.respostas}
                        small
                        {...required}
                      />
                    </div>
                  )}
                </div>
              )}
            </FormGroup>
          ))}
          <FormGroup right>
            <Button type='submit' disabled={isSubmitting}>
              {!isSubmitting ? 'Enviar Pesquisa' : 'Processando...'}
            </Button>
          </FormGroup>
        </form>
      )}
    </Form>
  )
}

FormResearch.propTypes = {
  /** Callback do form submit */
  onSubmit: PropTypes.func.isRequired,

  /** Form sendo processado */
  isSubmitting: PropTypes.bool.isRequired,

  /** Array de perguntas para montar o form de pesquisa */
  research: PropTypes.object.isRequired
}
