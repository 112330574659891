import { connect } from 'react-redux'
import selectors from 'store/selectors/resources/alerts'

const mapStateToProps = state => ({
  count: selectors.getUnreadAlertsCount(state)
})

export default connect(mapStateToProps)(
  function UnreadAlerts ({ count, children }) {
    return children(count)
  }
)
