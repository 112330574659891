import Client from 'modules/Client'

export default {

  fetchOne () {
    return Client.get(`api/aluno/downloads`)
      .then(response => response)
  },

  fetchAll () {
    return Client.get(`api/aluno/totaldownloads`)
      .then(response => response)
  }

}
