import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserDetails } from 'store/ducks/user'

function UserDetails ({ children, details }) {
  return children(details) || null
}

UserDetails.propTypes = {
  details: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  details: getUserDetails(state)
})

export default connect(mapStateToProps)(UserDetails)
