import React from 'react'
import Screen from 'containers/Screen'
import FetchAuth from 'components/FetchAuth'
import Box from 'components/Box'
import CursosList from 'components/CursosList'
import VerticalSpacer from 'components/VerticalSpacer'
import CalendarEvents from 'components/CalendarEvents'
import transformEvents from 'utils/transformEvents'
import SectionTitle from 'components/SectionTitle'
import FetchCourses from '../components/FetchCourses'
import { API_URL } from 'config/constants'

export default function CursosPorTipoScreen (props) {
  const titulo = props.titulo
  const tipo = props.tipo

  return (
    <Screen title={titulo}>
      <FetchCourses
        url={`api/aluno/curso/tipo/${props.tipo}`}
        baseURL={API_URL}
        type={tipo}>
        {({ data, doFetch, search }) =>
          <React.Fragment>
            {
              <VerticalSpacer>
                {props.agenda === true ? <FetchAuth url='api/aluno/agenda' lazy>
                  {({ data, fetching, doFetch }) => (
                    <CalendarEvents
                      onFetchData={date => doFetch({
                        url: `${API_URL}/api/aluno/agenda/tipo/${tipo}?inicio=${date.start}&fim=${date.end}`
                      })}
                      events={transformEvents(data)}
                      loading={fetching}
                    />
                  )}
                </FetchAuth>
                : null
                }
                <Box padded>
                  <SectionTitle>{titulo}</SectionTitle>
                  {search}
                  {data && data.data && <CursosList
                    onPageChange={ page =>
                      doFetch({
                        url: `${API_URL}/api/aluno/curso/tipo/${props.tipo}?page=${page}`
                      })
                    } 
                    pagination={data.meta} 
                    cursos={data.data} 
                    type={titulo} 
                  /> }
                </Box>
              </VerticalSpacer>
            }
          </React.Fragment>
        }
      </FetchCourses>
    </Screen>
  )
}
