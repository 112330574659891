import React from 'react'
import PropTypes from 'prop-types'
import Box from 'components/Box'
import 'styles/CardItem.css'
import Button from 'components/Button'

export default function CardItem ({ label, title, buttonLabel, ...props }) {
  return (
    <section className='CardItem'>
      <Box padded>
        <div className='CardItem-inner'>
          <div className='CardItem-info'>
            {label && (
              <span className='CardItem-info-label'>
                {label}
              </span>
            )}
            <h3 className='CardItem-info-title'>
              {title}
            </h3>
          </div>
          <div className='CardItem-button'>
            <Button {...props}>
              {buttonLabel}
            </Button>
          </div>
        </div>
      </Box>
    </section>
  )
}

CardItem.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired
}
