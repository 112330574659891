/**
 * Dependencies.
 */

import Orders from 'api/Orders'
import createResourceActions from 'store/utils/createResourceActions'

/**
 * Action Types and Creators.
 */

export const { actionTypes, actionCreators } = createResourceActions('orders', Orders, 'Pedido')
