import styled from 'styled-components'
import media from 'utils/mediaQuery'

export default styled.div`
  width: 100%;

  ${media.greaterThan('medium')`
    display: flex;
    align-items: center;
    justify-content: space-between;  
  `}

  ${media.lessThan('medium')`
    > * + * {
      margin-top: 25px;
    }
  `}
`
