import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'
import media from 'utils/mediaQuery'

export const Container = styled.div`
  ${media.greaterThan('medium')`
    display: flex;
  `}
`

export const Calendar = styled.div`
  ${media.greaterThan('medium')`
    width: 50%;
  `}
`

export const Events = styled.div`

  & ::-webkit-scrollbar { 
    width:10px; 
    background:#e3e0d6; 
  }

  & ::-webkit-scrollbar-track { 
    background: rgba(0,0,0,0.1); 
  }

  & ::-webkit-scrollbar-thumb { 
    background:#8b8778; 
    cursor: pointer; 
  }

  ${media.greaterThan('medium')`
    width: 50%;
    border-left: none;  
  `}

  ${media.lessThan('medium')`
    border-top: none;
  `}

  border: 1px solid ${prop('theme.colors.grayForm')};
  position: relative;
  
  ${props => props.center && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  & .semRegistrosDataCalendario {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${prop('theme.colors.grayForm')}
  padding: 5px;
`

export const Control = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: transparent;
  color: ${prop('theme.colors.text')};
  border: none;
  font-size: 20px;

  & i {
    color: #FF6A58;
  }
`

export const SelectedDate = styled.div`
  text-align: center;
`

export const SelectedMonth = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`

export const SelectedYear = styled.div`
  font-size: 14px;
`

export const Weekdays = styled.div`
  display: flex;
  border-left: 1px solid ${prop('theme.colors.grayForm')};
  border-right: 1px solid ${prop('theme.colors.grayForm')};
`

export const Weekday = styled.div`
  flex: 1;
  padding: 5px;
  text-align: center;
  font-weight: bold;

  & + & {
    border-left: 1px solid ${prop('theme.colors.grayForm')}; 
  }
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${prop('theme.colors.grayForm')}
`

export const Cell = styled.div`
  width: calc(1/7 * 100%);
  text-align: center;
  padding: 10px 5px;
  transition: all 0.2s linear 0s;

  ${props => props.isAdjacentMonth && `color: #d1d1d1;`}
  ${props => props.hasEvents && `
    background-color: #FF6A58;
    color: #fff;
    cursor: pointer;
    border: solid 1px #fff;
    font-weight: bold;

    &:hover {
      filter: brightness(.9);
    }
  `}
`

export const EventList = styled.div`
  ${media.greaterThan('medium')`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  `}

  padding: 10px;
`

export const EventContainer = styled.div`
  width: 100%;
  background-color: #F1F1F1;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;

  & .dateTopCalendar {
    background-color: #FF6A58;
    float: left;
    border-radius: 3px;
    padding: 3px 5px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
  }

  & .boxItemCalendario {
    display: inline-block;
    width: 100%;
    margin: 0;

    & + .boxItemCalendario {
      margin-top: 15px;
      padding-top: 5px;
      border-top: solid 1px #c1c1c1;
    }

    & p {
    font-family: 'Roboto', sans-serif;
    float: left;
    width: 100%;
    font-size: 15px;
    margin: 10px 0;
    font-weight: bold;
  }

  & ul {
    padding: 0;
    display: inline-block;
    width: 100%;
    margin: 0;

    & li {
      padding: 5px 0;
      font-size: 13px;
      font-size: 14px;
      color: #FF6A58;
      list-style: none;

      & strong {
        color: #FF6A58;
      }

      & a {
        color: #2b2b2b;
        text-decoration: underline;
      }
    }
  }
  }

`
