import styled from 'styled-components'

export const Box = styled.div`
    float: left;
    width: 30%;
    padding: 0 10px;

    @media only screen and (max-width: 940px) {
        width: 100%;
        margin-bottom: 40px;
        padding: 0;
    }

`

export const BoxSplash = styled.div`
    width: 80px;
    height: 80px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 30px;
    background-image: url(${props => props.bg ? props.bg : ''});

    // @media only screen and (max-width: 1450px) {
    //   margin-left: 10px;
    //   width: 70px;
    // }
`

export const BoxSplashText = styled.span`
    float: left;
    width: 100%;
    font-size: 11px;
    text-align: center;
    color: #156EF3;
    line-height: 100%;
`

export const BoxSplashDiscount = styled.span`
    float: left;
    width: 100%;
    font-size: 22px;
    text-align: center;
    color: #156EF3;
    font-weight: bold;
    margin: 3px 0 0 0;
    line-height: 100%;
`

export const TopoBox = styled.div`
    float: left;
    width: 100%;
    height: 120px;
    background: ${props => props.atual === true ? 'transparent' : '#053EA1'};
    border: solid 1px transparent;
    border-color: ${props => props.atual === true ? '#156EF3' : '#F1F1F1'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 20px;
    position: relative;
`

export const TopoBoxFlex = styled.div`
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const TagPlanoAtual = styled.span`
    background-color: #156EF3;
    color: #fff;
    font-size: 12px;
    padding: 8px 10px;
    line-height: 100%;
    margin-bottom: 13px;
    display: inline-flex;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    display: inline-block;
`

export const TagPlano = styled.span`
    background-color: ${props => props.tipo === 1 ? '#D93E3E' : '#156EF3'};
    color: #fff;
    font-size: 12px;
    padding: 8px 10px;
    line-height: 100%;
    margin-bottom: 13px;
    display: inline-flex;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    display: inline-block;
`

export const TituloPlano = styled.span`
    color: ${props => props.atual === true ? '#0A121F' : props.cor};
    font-size: 20px;
    font-weight: bold;
    line-height: 130%;
    float: left;
    width: 100%;

    @media only screen and (max-width: 1450px) {
        font-size: 18px;
    }

    @media only screen and (max-width: 900px) {
        max-width: 100%;
        font-size: 18px;
        top: 55px;
    }
`

export const TipoPlano = styled.span`
    color: ${props => props.atual === true ? '#0A121F' : props.cor};
    font-size: 13px;
    line-height: 100%;
    float: left;
    width: 100%;
`

export const BonusPlano = styled.span`
    color: #fff;
    font-size: 13px;
    line-height: 100%;
    font-weight: bold;
    background: ${props => props.cor ? props.cor : '#FF6A58'};
    padding: 3px;
    margin-top: 10px;
    display: inline-block;
`

export const TituloLista = styled.span`
    color: #475569;
    float: left;
    width: 100%;
    margin: 20px 0;
    font-size: 14px;
`
