import React from 'react'
import {
  Box,
  TopoBox,
  BoxSplash,
  BoxSplashText,
  BoxSplashDiscount,
  TopoBoxFlex,
  TagPlanoAtual,
  TagPlano,
  TituloPlano,
  TipoPlano,
  TituloLista,
  TextoPosLista,
  TextoCoaching
} from './Styled'

import icSplash from 'images/icons/ic-splash.svg'

/* Componentes */
import Bonus from './Components/Bonus/Bonus'
import List from './Components/List/List'
import Cartao from './Components/Cartao/Cartao'
import Boleto from './Components/Boleto/Boleto'

export default function Plano ({ dadosPlano, atual, tipo, lote }) {

    const editTitle = (title) => {
        let newTitle = title.split(" - ")
        return newTitle[0]
    }

    const tituloPlano =  atual ? dadosPlano : editTitle(dadosPlano.nome)

    return (
        <Box>
        
            {/* Box Topo */}
            <TopoBox atual={atual}>

                <TopoBoxFlex>

                    {/* Tag */}
                    { atual ? <TagPlanoAtual>Este é o seu plano atual</TagPlanoAtual> : <TagPlano tipo={dadosPlano.tag === 'Faça upgrade com a melhor oferta' ? 1 : 2}>Upgrade disponível para você</TagPlano> }

                    {/* Título do Plano */}
                    <TituloPlano cor={ !atual ? '#fff' : '#0A121F' } atual={atual} dangerouslySetInnerHTML={{ __html: !atual ? tituloPlano.replace(' ', '<br>') : tituloPlano }} />

                    {/* Tipo do Plano */}
                    {dadosPlano.plano && !atual ? <TipoPlano cor={ !atual ? '#fff' : '#0A121F' } atual={atual}>{dadosPlano.plano}</TipoPlano> : null}

                </TopoBoxFlex>

                { !atual 
                ? <BoxSplash bg={icSplash}>
                    <BoxSplashText>até</BoxSplashText>
                    <BoxSplashDiscount>50%</BoxSplashDiscount>
                    <BoxSplashText>off</BoxSplashText>
                    </BoxSplash> 
                : null 
                }

            </TopoBox>

            {/* Lista de Bônus */}
            { !atual && lote === 1 ? <Bonus /> : null }
            
            {/* Título das Vantagens */}
            { !atual ? <TituloLista>O que tem na Assinatura Premium?</TituloLista> : null }

            {/* Lista de Vantagens */}
            {dadosPlano.vantagens && !atual ? <List itens={dadosPlano.vantagens} /> : null}

            {/* Texto Pós Lista */}
            { !atual ? <TextoPosLista>Isso tudo com a mesma qualidade e eficiência que você já testou e comprovou em nossos materiais</TextoPosLista> : null }

            {/* Texto Coaching */}
            { !atual && lote === 1 ? <TextoCoaching>* 30 dias de acompanhamento grátis com um Coach</TextoCoaching> : null }

            {/* Cartão */}
            {dadosPlano.cartao ? <Cartao tipo={tipo} dados={dadosPlano.cartao} /> : null}

            {/* Boleto */}
            {dadosPlano.boleto ? <Boleto tipo={tipo} dados={dadosPlano.boleto} /> : null}
        
        </Box>
    )
}
