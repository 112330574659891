import React from 'react'
import MenuButton from 'components/MenuButton'
import { NavLink } from 'react-router-dom'

export default function LinkMenuButton ({ to, external = false, ...props }) {
  return (
    <MenuButton
      to={to}
      // activeClassName='isActive'
      renderWith={external ? Link : NavLink}
      {...props}
    />
  )
}

const Link = ({children, to, ...props}) => {
  return <a href={to} target='_blank' {...props} >{children}</a>
}
