import React from 'react'
import FetchAuth from 'components/FetchAuth'
import Post from 'components/BlogPost'


export default function Blog () {
  return (
  <FetchAuth
    url='api/aluno/blog-ecj'
    spinner
    >
    {({ data }) => (
      <React.Fragment>
      {(data && data.data.length) ? data.data.map((item, key) => (
        <Post
          key={key}
          date={item.data}
          body={item.resumo}
          title={item.titulo}
          url={item.url}
        /> 
      )) : (
        <span>Nenhum artigo encontrado</span>
      )}
      </React.Fragment>
    )}
  </FetchAuth>
  );
 
}
