import React from 'react'
import Screen from 'containers/Screen'
import Tickets from 'containers/Tickets'
import CardItem from 'components/CardItem'

export default function IngressosScreen () {
  return (
    <Screen title='Ingressos' setHeadTitle>
      <Tickets>
        {({ records }) => records.map(ticket => (
          <CardItem
            key={ticket.id}
            label={ticket.data}
            title={ticket.nome}
            buttonLabel='Imprimir'
            href={ticket.link}
            renderWith='a'
            target='_blank'
            download
          />
        ))}
      </Tickets>
    </Screen>
  )
}
