import withResourceList from 'store/utils/withResourceList'

function Tickets ({ records, children }) {
  return children({ records })
}

export default withResourceList({
  resource: 'tickets',
  spinnerText: 'Carregando ingressos...'
})(Tickets)
