import React, { useState } from 'react'
import { dateToBR } from 'utils/helpers'
import { formatHour } from 'modules/Date'
import Screen from 'containers/Screen'
import Downloads from 'containers/Downloads'
import Pagination from 'components/Pagination'
import { API_URL } from 'config/constants'
import Client from 'modules/Client'
import InputMask from "react-input-mask"
import { dateToAM } from 'utils/helpers'
import {
  ContainerHeader,
  BoxTotal,
  IconeLista,
  TituloTotal,
  NumeroTotal,
  BoxData,
  InputButton,
  BoxDownloads, 
  ListaDownloads, 
  ItemDownload, 
  BlocoNome, 
  IconeNome,
  BlocoNomeTexto, 
  NomeCurso, 
  NomeAula,
  BlocoTotalDownloads,
  NumeroDownloads,
  TextoDownloads,
  BlocoData,
  NumeroData,
  TextoData,
  PaginationContainer
} from './style'
import IcDownload from 'images/ic-download.svg'
import IcLista from 'images/ic-lista.svg'

export default function DownloadsUser () {

  const [downloads, setDownloads] = useState(null)
  const [totais, setTotais] = useState(null)
  const [paginacao, setPaginacao] = useState({})
  const [dataInicial, setDataInicial] = useState('')
  const [validaDataInicial, setValidaDataInicial] = useState(true)
  const [dataFinal, setDataFinal] = useState('')
  const [validaDataFinal, setValidaDataFinal] = useState(true)
  const [filtroData, setFiltroData] = useState(false)

  const listaDownloads = () => {

    if (downloads) {
      
      let listaDownloads = downloads.map((download, index) => (          
        <ItemDownload key={index}>

          {/* Nome */}
          <BlocoNome>
            <IconeNome src={IcDownload} alt='Download' />
            <BlocoNomeTexto>
              <NomeCurso>{ download.curso }</NomeCurso>
              <NomeAula>{ download.aula }</NomeAula>
            </BlocoNomeTexto>
          </BlocoNome>

          {/* Total de Downloads */}
          <BlocoTotalDownloads>
            <NumeroDownloads>{ download.total }</NumeroDownloads>
            <TextoDownloads>{ download.total > 1 ? 'downloads' : 'download' }</TextoDownloads>
          </BlocoTotalDownloads>

          {/* Primeiro Download */}
          <BlocoData>
            <NumeroData>{ dateToBR(download.primeiro) } às { formatHour(download.primeiro) }</NumeroData>
            <TextoData>primeiro download</TextoData>
          </BlocoData>

          {/* Último Download */}
          <BlocoData>
            <NumeroData>{ dateToBR(download.ultimo) } às { formatHour(download.ultimo) }</NumeroData>
            <TextoData>último download</TextoData>
          </BlocoData>

        </ItemDownload>
      ))

      return listaDownloads

    }

  }

  // Muda página
  const onPageChange = (pagina) => {
    fetchResults(pagina)
  }

  const fetchResults = async (pagina) => {
    try {
      let paginateData = null
      if (filtroData) {
        paginateData = await Promise.resolve(Client.get(`${API_URL}/api/aluno/downloads?page=${pagina}&data_inicio=${dateToAM(dataInicial)}&data_fim=${dateToAM(dataFinal)}`))
      } else {
        paginateData = await Promise.resolve(Client.get(`${API_URL}/api/aluno/downloads?page=${pagina}`))
      }
      setDownloads(paginateData.data)
      setPaginacao(paginateData.meta)
    } catch (err) {
      console.log('Erro ao buscar dados')
    }
  }

  // Faz filtro por data
  const filterDate = () => {

    dataInicial.replaceAll('_', '').length < 10 ? setValidaDataInicial(false) : setValidaDataInicial(true)
    dataFinal.replaceAll('_', '').length < 10 ? setValidaDataFinal(false) : setValidaDataFinal(true)

    if (dataInicial.replaceAll('_', '').length >= 10 && dataFinal.replaceAll('_', '').length >= 10) {
      setFiltroData(true)
      fetchResultsData()
    } else {
      setFiltroData(false)
    }

  }

  const fetchResultsData = async params => {
    try {

      // Aulas
      let results = await Promise.resolve(Client.get(`${API_URL}/api/aluno/downloads?data_inicio=${dateToAM(dataInicial)}&data_fim=${dateToAM(dataFinal)}`))
      setDownloads(results.data)
      setPaginacao(results.meta)

      // Totais
      let resultsTotais = await Promise.resolve(Client.get(`${API_URL}/api/aluno/totaldownloads?data_inicio=${dateToAM(dataInicial)}&data_fim=${dateToAM(dataFinal)}`))
      setTotais(resultsTotais)
      
    } catch (err) {
      console.log('Erro ao buscar dados')
    }
  }

  // Seta os novos dados
  const getAulas = (data) => {
    if (Object.keys(data).length > 0) {
      setDownloads(data.data)
      setPaginacao(data.meta)
    }    
  }

  // Seta os novos dados
  const getTotais = (total) => {
    if (Object.keys(total).length > 0) {
      setTotais(total)
    }    
  }

  return (
    <Screen title='Meus Downloads' setHeadTitle>
      <Downloads>
        {({ data, total }) => (
          <React.Fragment>

            { downloads === null ? getAulas(data) : null }
            { totais === null ? getTotais(total) : null }

            <ContainerHeader>

              <BoxTotal>
                <IconeLista src={IcLista} alt='Lista' />
                <TituloTotal>Total de downloads:</TituloTotal>
                <NumeroTotal>{ totais !== null ? totais.total : null }</NumeroTotal>
              </BoxTotal>

              <BoxTotal>
                <IconeLista src={IcLista} alt='Lista' />
                <TituloTotal>Dowloads únicos:</TituloTotal>
                <NumeroTotal>{ totais !== null ? totais.total_aulas : null }</NumeroTotal>
              </BoxTotal>

              <BoxData>
                <InputMask className={ !validaDataInicial ? 'required' : '' } placeholder='Data Inicial' mask="99/99/9999" onChange={(e) => setDataInicial(e.target.value)} value={dataInicial} />
                <InputMask className={ !validaDataFinal ? 'required' : '' } placeholder='Data Final' mask="99/99/9999" onChange={(e) => setDataFinal(e.target.value)} value={dataFinal} />
                <InputButton onClick={filterDate}>Filtrar</InputButton>
              </BoxData>

            </ContainerHeader>

            <BoxDownloads>
              <ListaDownloads>
                { listaDownloads(downloads) }
              </ListaDownloads>
            </BoxDownloads>

            { paginacao && paginacao.last_page > 1 && <PaginationContainer>
                <Pagination
                  center
                  current={paginacao.current_page}
                  total={paginacao.last_page}
                  onChange={onPageChange}
                />
              </PaginationContainer>
            }

          </React.Fragment>

        )}
      </Downloads>
    </Screen>
  )
}
