import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Item = styled.label`
  display: block;
  text-align: center;
  cursor: pointer;
  width: 10%;

  input {
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: #d1d1;
    cursor: pointer;
  }
  
  span {
    font-size: 12px;
  }
`

export default class FormFieldScale extends Component {
  state = {
    value: this.props.value
  }

  setValue (value) {
    this.setState({ value })
    this.props.onChange(value)
  }

  render () {
    return (
      <Container>
        {this.props.answers.map(answer => (
          <Item key={answer.id}>
            <input
              type='radio'
              {...this.props}
              onChange={() => this.setValue(answer.resposta)}
              checked={answer.resposta === this.state.value}
            />
            <span>{answer.resposta}</span>
          </Item>
        ))}
      </Container>
    )
  }

  static defaultProps = {
    answers: []
  }

}
