import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

export default {
  axios: axios.create(),

  headers: {},

  baseURL: '',

  setHeader (key, value) {
    this.headers[key] = value
  },

  setBaseURL (baseURL) {
    this.baseURL = baseURL
    this.axios = axios.create({ baseURL })
  },

  setupMock (callback) {
    const mock = new MockAdapter(this.axios)
    callback(mock)
  },

  request (method, url, config = {}) {
    const { headers = {}, ...settings } = config
    return this.axios({
      method,
      url,
      headers: {
        ...this.headers,
        ...headers
      },
      ...settings
    }).then(response => response.data)
  },

  get (url, data) {
    return this.request('get', url, data)
  },

  post (url, data) {
    return this.request('post', url, data)
  },

  put (url, data) {
    return this.request('put', url, data)
  },

  delete (url, data) {
    return this.request('delete', url, data)
  }
}
