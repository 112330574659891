import React, { useState } from 'react'

import { getLocalStorage, setCookie } from 'utils/helpers'
import { BoxAviso, Icone, Texto, IconeClose } from './Styled'
import icAviso from 'images/icons/ic-aviso-renovacao.svg'
import icFecha from 'images/icons/ic-close-aviso.svg'

export default function Aviso () {

    const [aviso, setAviso] = useState(true)

    const validaVertical = () => {
        return getLocalStorage('menuSidebar') === 'ecj'
    }
 
    const fechaAviso = () => {
        setCookie('aviso_ecj', "close", 30)
        setAviso(false)
    }

    const returnHtml = () => (
        <BoxAviso>
            <Icone src={icAviso} />
            <Texto>Alguns dos cursos estão disponíveis em nossa nova plataforma, acesse <a class="linkPlataformaECJ" href="https://cj.estrategia.com/mesa-de-estudo/" target='_blank' rel='noopener noreferrer'>clicando aqui.</a></Texto>
            <IconeClose src={icFecha} onClick={fechaAviso} />
        </BoxAviso>
    )

    return validaVertical() && aviso ? returnHtml() : null
}
