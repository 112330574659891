import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import 'normalize.css/normalize.css'
import 'styles/index.css'
import * as serviceWorker from 'registerServiceWorker'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

serviceWorker.unregister()
