import styled from 'styled-components'
import theme from 'theme'
import { Link } from 'react-router-dom'

export const Card = styled.section`
  display: flex;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  font-family: ${theme.fonts.primary};
  position: relative;
  transition: opacity 0.8s;
  opacity: 1;
`

export const BoxLoaderFavorito = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 17px;
  top: 13px;
  z-index: 999;
  width: 22px;
  height: 24px;

  &.none {
    display: none;
  }

  & img {
    width: 20px;
  }
`

export const Favorito = styled.i`
  position: absolute;
  right: 17px;
  top: 13px;
  font-size: 24px;
  transition: all 0.1s linear;
  color: #C1C1C1;
  cursor: pointer;
  z-index: 999;

  &:hover {
    transform: scale(1.1);
    color: #e79d35;
  }

  &.active {
    color: #e79d35!important;
  }

  &.none {
    display: none;
  }
`

export const LinkStyled = styled(Link)`
  display: flex;
  padding: 15px 20px;
  width: 100%;
  text-decoration: none;

  &:not([disabled]):hover {
    background-color: #f5f5f5;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

export const LinkA = styled.a`
  display: flex;
  padding: 15px 20px;
  width: 100%;
  text-decoration: none;

  &:not([disabled]):hover {
    background-color: #f5f5f5;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
`

export const Title = styled.h1`
  color: ${props => props.disabled ? 'rgba(127, 127, 127, 0.6)' : '#333'};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  margin: 20px 0 10px 0;
  margin-top: 0;
  min-height: 56px;
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  font-size: 14px;
  padding-top: 15px;
  color: #7f7f7f;
  border-top: 1px solid rgb(229, 229, 229);
  padding-bottom: 7px;
  ${props => props.disabled && `
    img {
      opacity: 0.3;
      filter: grayscale(100%);
    }
  `}
`

export const Label = styled.span`
    font-size: 13px;
`

export const LabelValue = styled.span`
  font-size: 13px;
  ${props => !props.disabled && `
    
  `}
  @media only screen and (max-width: 1200px) {
    
    font-size: 12px;

  }
`

export const BotaoArquive = styled.span`
  position: absolute;
  z-index: 999;
  bottom: 15px;
  right: 17px;
  line-height: 100%;
  cursor: pointer;
`

export const BarraDePorcentagem = styled.div`
  width: calc(100% + 2px);
  height: 8px;
  background: #e2e2e2;
  margin: 0;
  border-radius: 0 0 3px 3px;
  position: absolute;
  bottom: 0;
  left: -1px;
  overflow: hidden;
`

export const BarraDePorcentagemInterna = styled.div`
  width: ${props => props.width ? `${props.width}%` : '0'};
  height: 8px;
  position: absolute;
  left: 0;
  background: ${props => props.color ? `${props.color}` : null};
`
