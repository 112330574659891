import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Course from 'containers/Course'
import ForumMessages from 'containers/ForumMessages'
import CourseInfo from 'components/CourseInfo'
import ForumHeader from 'components/ForumHeader'
import routes from 'config/routes'
import getRoute from 'utils/getRoute'
import {
  isSubmitting,
  isFetching,
  create,
  fetchAll
} from 'store/ducks/forumMessages'

class Forum extends Component {
  state = {
    lessonChanged: null
  }

  componentWillUpdate (nextProps, nextState) {
    if (nextState.lessonChanged) {
      this.setState({ lessonChanged: null })
    }
  }

  render () {
    if (this.state.lessonChanged) {
      const to = getRoute(routes.forum, {
        courseId: this.props.courseId,
        lessonId: this.state.lessonChanged
      })
      return <Redirect to={to} />
    }

    const { lessonId } = this.props

    return (
      <div>
        <Course id={this.props.courseId} showSpinner>
          {({ course, lessons }) => (
            <div>
              <CourseInfo
                title={course.nome}
                type={course.tipo}
                teachers={course.professores}
              />
              <ForumHeader
                lessons={lessons}
                selectedLesson={lessonId}
                onSubmit={({ aula, ...data }, notificationsId) => {
                  this.props.create(aula, data, notificationsId)
                }}
                isSubmitting={this.props.isSubmitting}
                isForumOpen={course.permite_forum}
                onLessonChange={({ value }) => {
                  if (value !== lessonId) {
                    this.setState({ lessonChanged: value })
                  }
                }}
              />
            </div>
          )}
        </Course>
        <ForumMessages lessonId={lessonId} />
      </div>
    )
  }

  static propTypes = {
    // From Component
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.number.isRequired,

    // From State
    isSubmitting: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,

    // From Dispatch
    create: PropTypes.func.isRequired,
    fetchAll: PropTypes.func.isRequired
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(state),
  isFetching: isFetching(state)
})

export default connect(
  mapStateToProps,
  { create, fetchAll }
)(Forum)
