import { normalize } from 'normalizr'
import Client from 'modules/Client'
import { concurso, curso } from './schemas'

export default {
  fetchAll () {
    return Client.get('api/aluno/curso')
      .then(response => normalize(response.data, [ concurso ]))
  },

  fetchOne (id) {
    return Client.get(`api/aluno/curso/${id}`)
      .then(response => normalize(response.data, curso))
  },

  updateRating (courseId, data) {
    return Client.put(`api/aluno/curso/${courseId}/avaliacao`, { data })
  },

  sendDiscursive (courseId, data) {
    const formData = new window.FormData()
    formData.set('discursiva', data.discursiva)

    return Client.post(`api/aluno/curso/${courseId}/discursiva`, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  fetchResearch (courseId) {
    return Client.get(`api/aluno/curso/${courseId}/avaliacao`)
  },

  sendResearch (courseId, data) {
    const respostas = Object.keys(data).map(key => ({
      id: key,
      avaliacao: data[key]
    }))
    return Client.post(`api/aluno/curso/${courseId}/avaliacao`, { data: { respostas } })
  },

  ignoreResearch (courseId) {
    return Client.post(`api/aluno/curso/${courseId}/avaliacao/ignorar`)
  }
}
